import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModule } from '../../shared/modules/mat.module';
import { DetailViewerComponent } from './detail-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    MatModule
  ],
  declarations: [DetailViewerComponent],
  exports: [DetailViewerComponent]
})
export class DetailsViewerModule { }
