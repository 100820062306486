import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {EmailGroupsDict} from '../../models/email-group';
import {nameGenerator} from '../../helpers/name-generator';

@Component({
  selector: 'app-emails-widget',
  templateUrl: './emails-widget.component.html',
  styleUrls: ['./emails-widget.component.scss']
})
export class EmailsWidgetComponent implements OnInit {

  constructor(
    private fb: FormBuilder
  ) { }
  private newGroupFnCounter = 0;
  isLoaded = false;
  emailForm: FormGroup;
  _emailGroupsDict: EmailGroupsDict = {};

  get emailGroupsDict(): EmailGroupsDict {
    return this._emailGroupsDict;
  }
  @Input() set emailGroupsDict (v: EmailGroupsDict) {
    if (typeof v !== 'undefined') {
      this._emailGroupsDict = v;
      this.createEmailForm();
      this.isLoaded = true;
    }
  }
  @Output() onEmailGroupsChange = new EventEmitter<any>();


  ngOnInit() {
    // this.createEmailForm();
  }

  private createEmailForm() {
    const formGroup = {};
    for (const key in this.emailGroupsDict) {
      if (this.emailGroupsDict.hasOwnProperty(key)) {
        formGroup[key] = this.fb.group({
          name: new FormControl(this.emailGroupsDict[key].name),
          emails: new FormControl(this.emailGroupsDict[key].emails)
        });
      }
    }
    this.emailForm = new FormGroup(formGroup);
    this.onEmailGroupsChange.emit(this.emailForm);
  }

  get getUniqueId() {
    return 'id' + (new Date()).getTime();
  }

  addNewGroup() {
    const id = this.getUniqueId;
    if (this.emailForm.get(id)) {
      this.addNewGroup();
      return;
    }
    this.newGroupFnCounter++;
    let name = nameGenerator();

    let isUniqueName = true;
    for (const [key, value] of Object.entries(this.emailForm.getRawValue())) {
      if (value && value['name'] == name) {
        isUniqueName = false;
      }
    }
    if (!isUniqueName) {
      if (this.newGroupFnCounter <= 10) {
        this.addNewGroup();
        return;
      } else {
        name = id;
      }
    }

    this.emailForm.addControl(id, this.fb.group({
      name: new FormControl(name),
      emails: new FormControl('')
    }));
    this.newGroupFnCounter = 0;
  }

  deleteGroup(groupKey: string) {
    this.emailForm.removeControl(groupKey);
  }

  get emailFormControls() {
    return this.emailForm.get('Emails') as FormControl;
  }

}
