<!-- FILEPATH: /Users/az/work/omniportal/src/app/test500/test500.component.html -->

<table>
    <thead>
        <tr>
            <th>#</th>
            <th>Comment</th>
            <th>File Size</th>
            <th>Is Readed</th>
            <th>Start Timestamp</th>
            <th>Step Type</th>
            <th>Storage Path</th>
            <th>Storage URL</th>
            <th>Timestamp</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let record of records">
            <td>{{ record.id }}</td>
            <td>{{ record.comment }}</td>
            <td>{{ record.fileSize }}</td>
            <td>{{ record.isReaded }}</td>
            <td>{{ formatDate(record.startTimestamp) }}</td>
            <td>{{ record.stepType }}</td>
            <td>{{ record.storagePath }}</td>
            <td><a href="{{ record.storageUrl }}" target="_blank">{{ record.storageUrl }}</a></td>
            <td>{{ record.timestamp }}</td>
        </tr>
    </tbody>
</table>
