import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-sensor-type',
  templateUrl: './sensor-type.component.html',
  styleUrls: ['./sensor-type.component.scss']
})
export class SensorTypeComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  }
}
