import {
  Component, OnInit
} from '@angular/core';
import { environment } from 'src/environments/environment';

declare const window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
  ) {
    localStorage.removeItem('firebase:previous_websocket_failure');
  }

  ngOnInit() {
    var script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key='+environment.googleAPIKey+'&loading=async&callback=initMap';
    script.async = true;

    // Attach your callback function to the `window` object
    window.initMap = function() {
      // JS API is loaded and available
    };

    // Append the 'script' element to 'head'
    document.head.appendChild(script);
  }
}

const title = 'Location and Contacts'

