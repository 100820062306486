import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'timestampToDate',
})
export class TimestampToDatePipe implements PipeTransform {
   transform(timestamp: any): string {
      const date = new Date(timestamp);
      let day = date.getDate() + '';
      let month = date.getMonth() + 1 + '';
      let year = date.getFullYear() + '';
      let hour = date.getHours() + '';
      let minutes = date.getMinutes() + '';
      let seconds = date.getSeconds() + '';

      day = this.checkDate(day);
      month = this.checkDate(month);
      year = this.checkDate(year);
      hour = this.checkDate(hour);
      minutes = this.checkDate(minutes);
      seconds = this.checkDate(seconds);

      return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
   }
   private checkDate(date: string): string {
      if (date.length < 2) {
         date = '0' + date;
      }
      return date;
   }
}
