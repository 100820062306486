import { Pipe, PipeTransform } from '@angular/core';
import { global_variables } from '../../environments/global_variables';

@Pipe({
  name: 'analyticDataFilter'
})
export class AnalyticDataFilterPipe implements PipeTransform {
  transform(
    objData: Object,
    objInputData: Object,
    arrStrTargetKeys: string[],
    bIsShow: boolean,
    objSensorData: Object,
    strUserRole: string,
    isUserStaff: boolean,
    bIsShowUnChemicals: boolean = false
  ): any {
    let arrReturns = [];
    const arrInputs = [];
    const arrOptions = [];
    const arrInputKeys = global_variables['AnalyticalInputs'];

    if ((strUserRole !== global_variables['userRoles'][0] || !isUserStaff) && arrInputKeys.includes('Calibrated (ppb)')) { // unless user is admin
      arrInputKeys.splice(0, 1);
    }

    const sensorTypeRows = objSensorData['types']['rows'].filter((row) => {
      return row['name'] === objData['Chemical_Name'];
    });
    let rowId = '';
    let p5Id = '';
    let p6Id = '';

    if (sensorTypeRows[0]) {
      rowId = sensorTypeRows[0]['id'];
    }

    const p5SensorTypeHeads = objSensorData['types']['heads'].filter((head: Object) => {
      return head['name'] === 'p5';
    });

    if (p5SensorTypeHeads[0]) {
      p5Id = p5SensorTypeHeads[0]['id'];
    }

    const p6SensorTypeHeads = objSensorData['types']['heads'].filter((head: Object) => {
      return head['name'] === 'p6';
    });

    if (p6SensorTypeHeads[0]) {
      p6Id = p6SensorTypeHeads[0]['id'];
    }

    const focusData = objSensorData['values'][rowId];
    let constratedValue = null;

    if (rowId && p5Id && p6Id) {
      if (!isNaN(parseFloat(focusData[p5Id])) && !isNaN(parseFloat(focusData[p6Id])) && !isNaN(parseFloat(objData['Concentration (ppb)']))) {
        constratedValue = parseFloat(focusData[p5Id]) * parseFloat(objData['Concentration (ppb)']) + parseFloat(focusData[p6Id]);
      }
    }

    for (const key in objData) {
      if (arrStrTargetKeys.indexOf(key) === -1) {
        let value = objData[key];
        if (objData['Chemical_Name'] === 'unknown chemical' && key === 'Concentration (ppb)') {
          value = bIsShowUnChemicals ? (value && value !== 'N/A') ? value + ' (toluene equivalent*)' : 'N/A' : 'Click the button above to show';
        }
        arrReturns.push({key: key, value: value, editable: false});
      } else if ((arrStrTargetKeys.indexOf(key) !== -1) && bIsShow) {
        arrOptions.push({key: key, value: objData[key], editable: false});
      }
    }

    for (let i = 0; i < arrInputKeys.length; i++) {
      const strKey = arrInputKeys[i];

      if (i === 0 && strUserRole === global_variables['userRoles'][0]) {
        arrInputs.push({key: strKey, value: constratedValue ? constratedValue.toFixed(2) : '', editable: false});
        continue;
      }

      let strVal = '';

      if (objInputData && objInputData.hasOwnProperty(strKey)) {
        strVal = objInputData[strKey];
      }

      arrInputs.push({key: strKey, value: strVal, editable: true});
    }


    if (arrInputs.length > 0) {
      arrReturns = arrReturns.concat(arrInputs);
    }

    if (bIsShow && arrOptions.length > 0) {
      arrReturns = arrReturns.concat(arrOptions);
    }

    return arrReturns;
  }
}
