export const alert = {
  'alertTemplate': {
    'Gases': {
      'benzene': {
        'Enabled': false,
        'Label': 'Benzene level (ppb)',
        'Low': 0.05,
        'High': 0.8,
        'CurrentReading': 0.2,
        'UserChangeable': true,
        'UserVisible': true,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 111,
        'Action': 'notify',
        'Order': 2,
        'Comments': 'Benzene level (ppb)',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor threshold alert for {{Name}}'
      },
      'toluene': {
        'Enabled': false,
        'Label': 'Toluene level (ppb)',
        'Low': 0.05,
        'High': 0.8,
        'CurrentReading': 0.2,
        'UserChangeable': true,
        'UserVisible': true,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 112,
        'Order': 3,
        'Action': 'notify',
        'Comments': 'Toluene level (ppb)',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor threshold alert for {{Name}}'
      },
      'm-xylene': {
        'Enabled': false,
        'Label': 'M-Xylene level (ppb)',
        'Low': 0.05,
        'High': 0.8,
        'CurrentReading': 0.2,
        'UserChangeable': true,
        'UserVisible': true,
        'AdminChangeable': true,
        'Order': 4,
        'AdminVisible': true,
        'ErrorCode': 113,
        'Action': 'notify',
        'Comments': 'M-Xylene level (ppb)',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor threshold alert for {{Name}}'
      },
      'o-xylene': {
        'Enabled': false,
        'Label': 'O-Xylene level (ppb)',
        'Low': 0.05,
        'High': 0.8,
        'CurrentReading': 0.2,
        'UserChangeable': true,
        'UserVisible': true,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 114,
        'Order': 5,
        'Action': 'notify',
        'Comments': 'O-Xylene level (ppb)',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor threshold alert for {{Name}}'
      },
      'UnknownGas' : {
        'Action' : 'notify',
        'AdminChangeable' : true,
        'AdminVisible' : true,
        'BodyEmailTemplate' : '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'Comments' : 'Unknown Gas(es) level (ppb)',
        'CurrentReading' : 0.2,
        'Enabled' : false,
        'ErrorCode' : 117,
        'High' : 0.8,
        'Label' : 'Unknown Gas(es) level (ppb)',
        'Low' : 0.05,
        'NotifyList' : "[]",
        'Order' : 6,
        'SubjectEmailTemplate': 'Omniscent Sensor threshold alert for {{Name}}',
        'UserChangeable' : true,
        'UserVisible' : true
      },
      'UnknownGas1': {
        'Enabled': false,
        'Label': 'Unknown Gas(es) level (ppb)',
        'Low': 0.05,
        'High': 0.8,
        'CurrentReading': 0.2,
        'UserChangeable': true,
        'UserVisible': true,
        'Order': 6,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 117,
        'Action': 'notify',
        'Comments': 'Unknown Gas(es) level (ppb)',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor threshold alert for {{Name}}'
      },
      'styrene': {
        'Enabled': false,
        'Label': 'Styrene level (ppb)',
        'Low': 0.05,
        'High': 0.8,
        'CurrentReading': 0.2,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'Order': 7,
        'AdminVisible': true,
        'ErrorCode': 115,
        'Action': 'notify',
        'Comments': 'Styrene level (ppb)',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor threshold alert for {{Name}}'
      },
      'ethylbenzene': {
        'Enabled': false,
        'Label': 'Ethylbenzene level (ppb)',
        'Low': 0.05,
        'High': 0.8,
        'CurrentReading': 0.2,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 116,
        'Order': 8,
        'Action': 'notify',
        'Comments': 'Ethylbenzene level (ppb)',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor threshold alert for {{Name}}'
      },
      'methylal': {
        'Enabled': false,
        'Label': 'Methylal level (ppb)',
        'Low': 0.05,
        'High': 0.8,
        'CurrentReading': 0.2,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 118,
        'Order': 9,
        'Action': 'notify',
        'Comments': 'Methylal level (ppb)',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor threshold alert for {{Name}}'
      },
      'pidLevel': {
        'Enabled': false,
        'Label': 'PID level (ppb)',
        'Low': 0.05,
        'High': 0.8,
        'CurrentReading': 0.2,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 110,
        'Order': 10,
        'Action': 'notify',
        'Comments': 'PID level (ppb)',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Concentration Acceptable Range (ppb): {{Low}} - {{High}}</div><div>Actual Detected Concentration (ppb): {{ChemConc}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor PID threshold alert'
      }
    },
    'Errors': {
      'FlowRateSensor2': {
        'Enabled': true,
        'Label': 'Flow Rate Sensor #2',
        'Low': 0.3,
        'High': 0.8,
        'CurrentReading': 0.5,
        'UserChangeable': false,
        'Order': 2,
        'UserVisible': true,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 100,
        'Action': 'stopCycle',
        'Comments': 'Measure during Sepration',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'HumidityLevel': {
        'Enabled': false,
        'Label': 'Humidity Level',
        'Low': 0,
        'High': 85,
        'CurrentReading': 50,
        'Order': 1,
        'UserChangeable': true,
        'UserVisible': true,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 101,
        'Action': 'stopCycle',
        'Comments': 'Measure during Sepration',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'FlowRateSensor1': {
        'Enabled': false,
        'Label': 'Flow Rate Sensor #1',
        'Low': 0.3,
        'High': 0.8,
        'CurrentReading': 0.5,
        'UserChangeable': false,
        'UserVisible': false,
        'Order': 3,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 102,
        'Action': 'stopCycle',
        'Comments': 'Flow Rate Sensor #1',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'FlowRateSensor3': {
        'Enabled': false,
        'Label': 'Flow Rate Sensor #3',
        'Low': 0.3,
        'High': 0.8,
        'CurrentReading': 0.5,
        'Order': 4,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 103,
        'Action': 'stopCycle',
        'Comments': 'Flow Rate Sensor #3',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'EnclTemp': {
        'Enabled': true,
        'Label': 'Encl Temp Sensor',
        'Low': 2,
        'High': 40,
        'CurrentReading': 5,
        'Order': 5,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 104,
        'Action': 'stopCycle',
        'Comments': 'From sensor on the board',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'TempBelowDewPoint': {
        'Enabled': false,
        'Label': 'Temp Below Dew Point',
        'Low': 0,
        'High': 0,
        'Order': 6,
        'CurrentReading': 0,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 105,
        'Action': 'notify',
        'Comments': 'Dew point is calculated in the sensor',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'PreconcentratorTempDuringSeparation': {
        'Enabled': false,
        'Label': 'Preconcentrator Temp during separation',
        'Low': 0,
        'High': 0,
        'Order': 7,
        'CurrentReading': 0,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 106,
        'Action': 'stopCycle',
        'Comments': 'Preconcentrator Temp during separation',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'PreconcentratorTempDuringSampling': {
        'Enabled': false,
        'Label': 'Preconcentrator Temp During Sampling',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'UserChangeable': false,
        'UserVisible': false,
        'Order': 8,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 107,
        'Action': 'stopCycle',
        'Comments': 'Preconcentrator Temp During Sampling',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'DetectorCapacitance': {
        'Enabled': false,
        'Label': 'Detector Capacitance',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'UserChangeable': false,
        'Order': 9,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 108,
        'Action': 'stopCycle',
        'Comments': 'Detector Capacitance',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'i2cBus': {
        'Enabled': true,
        'Label': 'If any sensor on i2c bus not responding',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'Order': 10,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 109,
        'Action': 'stopCycle',
        'Comments': 'If any sensor on i2c bus not responding',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'SystemReboot': {
        'Enabled': true,
        'Label': 'System Reboot',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'UserChangeable': false,
        'Order': 11,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 150,
        'Action': 'restartNextCycle',
        'Comments': 'System Reboot',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Event occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor event alert: {{Name}}'
      },
      'SensorOffline': {
        'Enabled': false,
        'Label': 'Sensor Go Off Line',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'UserChangeable': true,
        'UserVisible': true,
        'AdminChangeable': true,
        'Order': 12,
        'AdminVisible': true,
        'ErrorCode': 151,
        'Action': 'notify',
        'Comments': 'Sensor Go Off Line',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      },
      'RunStop': {
        'Enabled': false,
        'Label': 'Run Stop by Usr',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'Order': 13,
        'ErrorCode': 152,
        'Action': 'notify',
        'Comments': 'Run Stop by Usr',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Event occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor event alert: {{Name}}'
      },
      'SetOfRunsStarted': {
        'Enabled': false,
        'Label': 'Set of runs Started',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'Order': 14,
        'ErrorCode': 153,
        'Action': 'notify',
        'Comments': 'Set of runs Started',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Event occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor event alert: {{Name}}'
      },
      'ConfigurationAssigned': {
        'Enabled': true,
        'Label': 'A Configuration Assigned',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'Order': 15,
        'UserChangeable': false,
        'UserVisible': false,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 154,
        'Action': 'notify',
        'Comments': 'A Configuration Assigned',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Event occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor event alert: {{Name}}'
      },
      'NewSensorMade': {
        'Enabled': false,
        'Label': 'New Sensor Made/Deleted',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'Order': 16,
        'UserChangeable': true,
        'UserVisible': true,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 155,
        'Action': 'notify',
        'Comments': 'New Sensor Made/Deleted',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Event occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor event alert: {{Name}}'
      },
      'NumberOfRunExceeded': {
        'Enabled': false,
        'Label': 'Number of run exceeded',
        'Low': 1,
        'High': 9999,
        'Order': 17,
        'CurrentReading': 1,
        'UserChangeable': true,
        'UserVisible': true,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 156,
        'Action': 'notify',
        'Comments': 'Number of run exceeded',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Event occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor event alert: {{Name}}'
      },
      'WeatherDataMissing': {
        'Enabled': true,
        'Label': 'Wind Data Missing',
        'Low': 0,
        'High': 0,
        'CurrentReading': 0,
        'UserChangeable': true,
        'Order': 18,
        'UserVisible': true,
        'AdminChangeable': true,
        'AdminVisible': true,
        'ErrorCode': 200,
        'Action': 'notify',
        'Comments': 'Triggered when wind sensor configured but no wind data recorded during a run',
        'NotifyList': "[]",
        'BodyEmailTemplate': '<div>Error occured: {{Name}}</div><div>Additional information: {{message_text}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor error alert: {{Name}}'
      }
    },
    'Calibrations': {
      'CycleMultipleError' : {
        'Action' : 'notify',
        'AdminChangeable' : true,
        'AdminVisible' : true,
        'Comments' : 'A Cycle are not multiple of 3',
        'CurrentReading' : 0,
        'Enabled' : true,
        'ErrorCode' : 9999997,
        'High' : 0,
        'Label' : 'Cycle multiple error',
        'Low' : 0,
        'NotifyList': "[]",
        'Order' : 1,
        'UserChangeable' : true,
        'UserVisible' : true,
        'BodyEmailTemplate': '<div>Calibration process reported an error: {{Name}}</div><div>Additional information: {{Event}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor calibration error alert: {{Name}}'        
      },
      'NumberOfStepsError' : {
        'Action' : 'notify',
        'AdminChangeable' : true,
        'AdminVisible' : true,
        'Comments' : 'Number of steps are not 42',
        'CurrentReading' : 0,
        'Enabled' : true,
        'ErrorCode' : 9999998,
        'High' : 0,
        'Label' : 'Number of steps',
        'Low' : 0,
        'NotifyList': "[]",
        'Order' : 2,
        'UserChangeable' : true,
        'UserVisible' : true,
        'BodyEmailTemplate': '<div>Calibration process reported an error: {{Name}}</div><div>Additional information: {{Event}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor calibration error alert: {{Name}}'        
      },
      'ProcessingFailedError' : {
        'Action' : 'notify',
        'AdminChangeable' : true,
        'AdminVisible' : true,
        'Comments' : 'Calibration processing failed due to any reason',
        'CurrentReading' : 0,
        'Enabled' : true,
        'ErrorCode' : 9999999,
        'High' : 0,
        'Label' : 'Calibration processing',
        'Low' : 0,
        'NotifyList': "[]",
        'Order' : 3,
        'UserChangeable' : true,
        'UserVisible' : true,
        'BodyEmailTemplate': '<div>Calibration process reported an error: {{Name}}</div><div>Additional information: {{Event}}</div>',
        'SubjectEmailTemplate': 'Omniscent Sensor calibration error alert: {{Name}}'        
      }
    }
  }
};
