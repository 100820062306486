import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-test500',
  templateUrl: './test500.component.html',
  styleUrls: ['./test500.component.scss']
})
export class Test500Component implements OnInit {

  records: any[] = [];
  sensorSub: any;
  sensorKey: string = '-LUBTGzM3D3-_Sq8y3I3';
  maxRecords: number = 400;

  constructor(private _httpService: HttpService) { }

  ngOnInit(): void {
    this.fetchFirebaseData();
  }

  fetchFirebaseData(): void {

    console.log('test500.component.ts -> fetchFirebaseData: starting FB pull');
    let ts = Date.now().valueOf();
    this.sensorSub = this._httpService.getListAsValuesByOrderWithLimit(`${environment.APIS.ANAYLYTICALDATA}/${this.sensorKey}`, 'startTimestamp', this.maxRecords)
      .subscribe({
      next: (data: any[]) => {
          console.log('test500.component.ts -> fetchFirebaseData: FB pull complete in ' + (Date.now().valueOf() - ts) + ' ms');
          if (data) {
              this.records = data.reverse();
              // unwrap the data from the object and add it's sequential number as a property called 'id'              
              this.records = Object.keys(data).map((key, index) => ({ id: index + 1, ...data[key] }));
              //log number of items processed
              console.log('test500.component.ts -> fetchFirebaseData: records.length = ' + this.records.length);
              //log the time it ook from the beginning
              console.log('test500.component.ts -> fetchFirebaseData: data map completed in ' + (Date.now().valueOf() - ts) + ' ms');
          } else {
              console.log('No records found');
          }         
      },
      error: error => {
         console.error('test500.component.ts -> fetchFirebaseData: ', error);
      }
   });
  }



  fetchFirebaseData1(): void {

    console.log('test500.component.ts -> fetchFirebaseData1: starting FB pull');
    let ts = Date.now().valueOf();
    this.sensorSub = this._httpService.getAsObject(`${environment.APIS.ANAYLYTICALDATA}/${this.sensorKey}`, 3).subscribe({
      next: (data: any[]) => {
          console.log('test500.component.ts -> fetchFirebaseData1: FB pull complete in ' + (Date.now().valueOf() - ts) + ' ms');
          if (data) {
              this.records = data;
              // unwrap the data from the object and add it's sequential number as a property called 'id'              
              this.records = Object.keys(data).map((key, index) => ({ id: index + 1, ...data[key] }));
              //log number of items processed
              console.log('test500.component.ts -> fetchFirebaseData1: records.length = ' + this.records.length);
              //log the time it ook from the beginning
              console.log('test500.component.ts -> fetchFirebaseData1: data map completed in ' + (Date.now().valueOf() - ts) + ' ms');
          } else {
              console.log('No records found');
          }         
      },
      error: error => {
         console.error('test500.component.ts -> fetchFirebaseData1: ', error);
      }
   });
  }

  formatDate(timestamp: number): string {
    return new Date(timestamp).toLocaleString();
  }
 
}
