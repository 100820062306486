import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'wind'
})
export class WindPipe implements PipeTransform {

  transform(runsDict: object, cycleIndex: number, selectedSensor): any {
    return runsDict[cycleIndex];
  }
}
