import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormControlOptions, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '../components/password-validation';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { StorageManagerService } from '../services/storage-manager.service';

declare var $: any;

@Component({
   selector: 'app-reset-password',
   templateUrl: './reset-password.component.html',
   styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
   resetForm: FormGroup;
   formErrors: Object;
   validationMessages: Object;
   routeSub: Subscription;

   isLoadPage: boolean;
   isSubmit: boolean;
   actionCode: string;
   email: string;
   contentUrl: string;
   hide: boolean=true;
   hide_confirm: boolean=true;

   constructor(
      private _fb: FormBuilder,
      private _route: ActivatedRoute,
      private storageManagerService: StorageManagerService,
      private _auth: AuthService,
      private _router: Router,
      private _nofication: NotificationService
   ) {
      this.validationMessages = {
         password: {
            required: 'Password is required',
            pattern: 'Password does not meet the strength requirements (see below)',
            maxlength: 'Max length is 72',
         },
         confirmPassword: {
            required: 'Confirm password is required',
            MatchPassword: 'Passwords do not match',
         },
      };

      this.formErrors = {
         password: '',
         confirmPassword: '',
      };

      this.isLoadPage = false;
      this.isSubmit = false;
      this.actionCode = '';
      this.email = '';
      this.contentUrl = '';

      this.storageManagerService.clearStorage();
   }

   ngOnInit() {
      this.createRegisterForm();

      this.routeSub = this._route.queryParams.subscribe({
         next: (params) => {
            this.actionCode = params.oobCode;
            this.contentUrl = params.continueUrl;

            if (this.actionCode) {
               this._auth
                  .handleResetPassword(this.actionCode)
                  .then((email: string) => {
                     this.email = email;
                     this.isLoadPage = true;
                  })
                  .catch(error => {
                     console.log(error);
                     this.isLoadPage = true;
                     this._nofication.createNotification(
                        'error',
                        'Invalid Reset Link',
                        'We can not handle reset password!'
                     );
                  });
            } else {
               this._nofication.createNotification('error', 'Invalid Reset Link', 'There is no action code!');
            }
         },
         error: error => console.error('reset-password.component.ts -> this.routeSub', error)
      });
   }

   ngOnDestroy() {
      if (this.routeSub) {
         this.routeSub.unsubscribe();
      }
   }

   createRegisterForm() {
      const passwordRegEx = /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      this.resetForm = this._fb.group(
         {
            password: new FormControl('', [
               Validators.required,
               Validators.pattern(passwordRegEx),
               Validators.maxLength(72),
            ]),
            confirmPassword: new FormControl('', [Validators.required]),
         },
         { validator: PasswordValidation.MatchPassword } as FormControlOptions
      );

      this.resetForm.valueChanges.subscribe({
         next: (data) => this.onValueChanged(data),
         error: error => console.error('reset-password.component.ts -> createRegisterForm(): ', error)
      });
   }

   onValueChanged(data?: any) {
      if (!this.resetForm) {
         return;
      }

      const form = this.resetForm;
      for (const field in this.formErrors) {
         if (this.formErrors.hasOwnProperty(field)) {
            this.formErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
               const messages = this.validationMessages[field];
               for (const key in control.errors) {
                  if (control.errors.hasOwnProperty(key)) {
                     this.formErrors[field] += messages[key] + ' ';
                  }
               }
            }
         }
      }
   }

   submit() {
      if (this.resetForm.invalid) {
         return;
      }

      if (!this.actionCode || !this.email) {
         this._nofication.createNotification('error', 'Not enough information', 'There is no action code or email!');
         return;
      }

      this.isSubmit = true;

      this._auth
         .processResetPassword(this.actionCode, this.resetForm['value']['password'])
         .then(() => {
            if (this.contentUrl) {
               window.location.href = this.contentUrl;
            }
         })
         .catch(() => {
            this.isSubmit = false;
         });
   }
}
