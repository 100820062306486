<mat-dialog-content>
  <form [formGroup]="templateForm">
    <div class="row align-items-center">
      <div class="col">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Subject email template</mat-label>
            <mat-label>Email subject text</mat-label>
            <input type="text" formControlName="SubjectEmailTemplate" matInput >
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col">
        <div class="form-group">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Body email template</mat-label>
            <textarea
              matInput
              formControlName="BodyEmailTemplate"
              placeholder="Email body text"
              rows="5"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="footer">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="updateTemplate()">Save template</button>
</mat-dialog-actions>
