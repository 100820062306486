import { Component } from '@angular/core';
import { SensorsDashboardData } from './sensors-dashboard-data';
import { CommonModule } from '@angular/common';
import { ZoneService } from '../zone-report/zone.service';
import { MatCardModule } from '@angular/material/card';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sensors-dashboard',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  providers: [ZoneService],
  templateUrl: './sensors-dashboard.component.html',
  styleUrl: './sensors-dashboard.component.scss'
})
export class SensorsDashboardComponent {
  sensors: SensorsDashboardData[] = [];
  constructor(private _zoneService: ZoneService) { }
  ngOnInit() {
    let statusData = this._zoneService.getQueryStatusData(environment.SystemType);
    // Using an Observer object
    statusData.subscribe({
      next: (data) => {

        this.sensors = data['sensors']
        console.log('Fetched status data', this.sensors);
      },
      error: (error) => {
        console.error('Error fetching status data', error); // Set default value on error
      }
    });

  }
  extractMinutes(timeElapsed: string): number {
    if (!timeElapsed) return 0;

    let minutes = 0;

    // Extract days, hours, and minutes using regex
    const daysMatch = timeElapsed.match(/(\d+)\s*days?/);
    const hoursMatch = timeElapsed.match(/(\d+)\s*hrs?/);
    const minutesMatch = timeElapsed.match(/(\d+)\s*mins?/);

    if (daysMatch) minutes += parseInt(daysMatch[1], 10) * 1440; // 1 day = 1440 mins
    if (hoursMatch) minutes += parseInt(hoursMatch[1], 10) * 60;  // 1 hour = 60 mins
    if (minutesMatch) minutes += parseInt(minutesMatch[1], 10);   // Add minutes directly

    return minutes;
  }



}


