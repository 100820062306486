import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponseType } from '../../models/response';


@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent implements OnInit {
  strInputType: string;
  strTitle: string;
  strLabel: string;
  value: any;
  arrStrInputTypes: string[];
  arrStrOptions: string[];

  constructor(public dialogRef: MatDialogRef<EditModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.strInputType = data['inputType'];
    this.strTitle = data['title'] ? data['title'] : 'Edit Modal';
    this.strLabel = data['label'];
    this.value = data['value'];
    this.arrStrOptions = data['options'] ? data['options'] : [];
    this.arrStrInputTypes = ['number', 'text', 'textarea', 'date', 'option'];
  }

  ngOnInit() {
    this.checkInputType();
  }

  /**
   * check if the input type is in input type list
   */
  checkInputType() {
    if (this.arrStrInputTypes.indexOf(this.strInputType) === -1) {
      const response: ResponseType = new ResponseType();
      response.status = 'error';
      response.msg = 'Invalid input type';
      this.dialogRef.close(response);
    }
  }

  /**
   * close modal with updated value
   */
  onFinish() {
    const response: ResponseType = new ResponseType();
    response.status = 'success';
    response.msg = 'success';
    response.data = this.value;
    this.dialogRef.close(response);
  }

  /**
   * close modal
   */
  onClose() {
    const response: ResponseType = new ResponseType();
    response.status = 'none';
    response.msg = '';
    this.dialogRef.close(response);
  }

}

