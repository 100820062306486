export enum RolesEnum {
   ADMIN = 'admin',
   DEVELOPER = 'developer',
   OPERATOR = 'operator',
   VIEWER = 'viewer',
   DEBUGGER = 'debugger',
}

export enum UserTypeEnum {
   STAFF = 'staffs',
   CUSTOMER = 'customers',
}
