<div class="system-parameter-logs-container">
  <table mat-table #table class="omni-table full-width" [dataSource]="dataSource">
    <!-- Sequence Column -->
    <ng-container matColumnDef="sequence">
      <th mat-header-cell *matHeaderCellDef>
        <div class="omni-table-header">
          No.
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="omni-col-wrap">
          {{ element?.sequence + 1 }}
        </div>
      </td>
    </ng-container>
    <!-- Sequence Column -->

    <!-- Date Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef>
        <div class="omni-table-header">
          Date
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="omni-col-wrap">
          <div class="omni-col-item">
            {{ element?.time }}
          </div>
        </div>
      </td>
    </ng-container>
    <!-- Date Column -->

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        <div class="omni-table-header">
          Action
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div fxLayout fxLayoutAlign="center center">
          <div class="pointer mx-3"
            matTooltip="Delete"
          >
            <mat-icon color="warn" (click)="onDelete(element)">delete_outline</mat-icon>
          </div>

          <mat-spinner
            *ngIf="isDownloading && selectedItemKey === element['key']; else downloadEle"
            class="small-spinner" color="primary"></mat-spinner>
          
          <ng-template #downloadEle>
            <div class="pointer edit-item"
              matTooltip="Download"
            >
              <mat-icon (click)="onDownload(element)">cloud_download</mat-icon>
            </div>
          </ng-template>
        </div>
      </td>
    </ng-container>
    <!-- Action Column -->

    <tr mat-header-row class="no-padding" *matHeaderRowDef="columns"></tr>
		<tr mat-row class="no-padding" *matRowDef="let row; columns: columns;"></tr>
  </table>

  <mat-paginator #paginator
    [pageSize]="5"
    [pageSizeOptions]="[5, 20, 50]">
  </mat-paginator>
</div>