import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {global_variables} from 'src/environments/global_variables';
import {RolesEnum} from '../../admin/roles.enum';

export enum BULK_ACTION {
  EDIT,
  SAVE,
}

@Component({
  selector: 'app-cleaning-cycle',
  templateUrl: './cleaning-cycle.component.html',
  styleUrls: ['./cleaning-cycle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CleaningCycleComponent implements OnInit {

  private _sensor: any;
  @Input() set sensor(sensor) {
    this._sensor = sensor;
    this.paramValue = sensor && sensor.cleaningCycle || '';
  }

  get sensor() {
    return this._sensor;
  }

  @Input() strUserType: string;
  @Input() strUserRole: string;
  @Output() updateCleaningCycle = new EventEmitter();

  BULK_ACTION = BULK_ACTION;
  bulkAction: BULK_ACTION = BULK_ACTION.EDIT;
  bulkActionCleaningCycle: BULK_ACTION = BULK_ACTION.EDIT;
  paramValue: string;

  arrStrUserTypes = global_variables.userTypes;
  arrStrUserRoles = global_variables.userRoles;

  get isAdminRole() {
    return this.strUserRole === RolesEnum.ADMIN;
  }

  ngOnInit() {
  }


  onUpdate() {
    console.log('this.paramValue');
    this.updateCleaningCycle.emit(this.paramValue);
    this.bulkAction = BULK_ACTION.EDIT;
  }

  hasEditPermission(): boolean {
    return (this.strUserType === this.arrStrUserTypes[0] // is OmniscentUser
      || this.strUserRole === this.arrStrUserRoles[0])
      && this.strUserRole !== this.arrStrUserRoles[3];
    // is Customer admin
  }
}
