import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PurehttpService {
   options: {
      headers?:
         | HttpHeaders
         | {
              [header: string]: string | string[];
           };
   };
   returnValue: any; // for a unit test

   constructor(private http: HttpClient) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.options = {
         headers: headers, // Add the responseType option
      };
   }

   // start checking sensor status
   callFirebaseFunction(strUrl: string, objPostData: Object = {}) {
      return this.http.post(strUrl, objPostData, this.options).pipe(
         // @ts-ignore
         map((res: Response) => res),
         catchError((error: any) => this.handleError(error))
      );
   }

   pureCallFirebaseFunction(strUrl: string, objPostData: Object = {}) {
      const requestOptions = {
         ...this.options,
      };

      return this.http
         .post(strUrl, objPostData, { ...requestOptions, responseType: 'json' }) // Set responseType to 'text'
         .pipe(catchError((error: any) => this.handleError(error)));
   }

   handleError(error: any) {
      const errMsg = error.message
         ? error.message
         : error.status
         ? `${error.status} - ${error.statusText}`
         : 'Server error';
      return observableThrowError(errMsg);
   }
}
