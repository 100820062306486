import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { global_variables } from '../../environments/global_variables';

@Injectable({
  providedIn: 'root'
})
export class ConfigDataService {
  private readonly arrStrStepActions = global_variables['stepActions'];
  public missedProps = [];

  constructor() { }

  fillDefaultConfigProps(config) {
    const updatedConfig = _.cloneDeep(config);
    const currentType = updatedConfig[updatedConfig.Current_Type];
    const currentModeConfig = currentType.Mode_Config;

    for (const currentModeConfigKey in currentModeConfig) {
      if (currentModeConfig.hasOwnProperty(currentModeConfigKey)) {

        const step = currentModeConfig[currentModeConfigKey];
        if (step && step.stepAction === this.arrStrStepActions[0]) {

          // Logic for default missing values:
          if (!step.NafionTube) {
            if (!this.missedProps.includes('NafionTube')) {
              this.missedProps.push('NafionTube');
            }
            step.NafionTube = {
              t1: 0,
              t2: 0,
              t3: 0,
              tIdle: 0,
              tTarget: 0
            };
          }
          if (!step.isRepeatedSamplingInRun) {
            if (!this.missedProps.includes('isRepeatedSamplingInRun')) {
              this.missedProps.push('isRepeatedSamplingInRun');
            }
            step.isRepeatedSamplingInRun = false;
          }

          if (!step.Max_Sampling_Time_Per_Step) {
            if (!this.missedProps.includes('Max_Sampling_Time_Per_Step')) {
              this.missedProps.push('Max_Sampling_Time_Per_Step');
            }
            step.Max_Sampling_Time_Per_Step = 0;
          }
        }
        if (step && (step.stepAction === this.arrStrStepActions[0] || step.stepAction === this.arrStrStepActions[2])) {
          if (step.isCleaning === undefined) {
            step.isCleaning = false;
            if (!this.missedProps.includes('isCleaning')) {
              this.missedProps.push('isCleaning');
            }
          }

        }
      }
    }
    return updatedConfig;
  }
}
