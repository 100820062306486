import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { global_variables } from '../../../environments/global_variables';
import { HttpService } from '../../services/http.service';

@Component({
   selector: 'app-user-modal',
   templateUrl: './user-modal.component.html',
   styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent implements OnInit {
   context: any;
   strUserType: string;
   bIsProcess: boolean;

   arrObjRoles: Object[];
   arrStrUserTypes: string[];
   arrStrUserStatus: string[];

   settingForm: FormGroup;
   formErrors: Object;
   validationMessages: Object;

   constructor(
      public dialgoRef: MatDialogRef<UserModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _httpService: HttpService,
      public _snackBar: MatSnackBar
   ) {
      this.context = data;
      this.strUserType = this.context['type'];
      this.arrStrUserTypes = global_variables['userTypes'];
      this.arrStrUserStatus = global_variables['userStatus'];

      if (this.strUserType === this.arrStrUserTypes[0]) {
         // staff
         this.arrObjRoles = [
            { value: 'admin', display: 'Administrator' },
            { value: 'developer', display: 'Developer' },
            { value: 'debugger', display: 'Debug Operator' },
            { value: 'operator', display: 'Operator' },
            { value: 'viewer', display: 'Viewer' },
         ];
      } else {
         // customer
         this.arrObjRoles = [
            { value: 'admin', display: 'Administrator' },
            { value: 'operator', display: 'Operator' },
            { value: 'viewer', display: 'Viewer' },
         ];
      }

      this.bIsProcess = false;
   }

   ngOnInit() {
      this.settingForm = new FormGroup({
         role: new FormControl(
            this.context['user']['role'] ? this.context['user']['role'] : this.arrObjRoles[0]['value'],
            [<any>Validators.required]
         ),
         status: new FormControl(this.context['user']['status'], [<any>Validators.required]),
      });
   }

   /**
    * submit form
    */
   onSubmit() {
      const objUpdateValue = {
         role: this.settingForm['value']['role'],
         status: this.settingForm['value']['status'],
      };
      const selectedUser = this.context['user'];
      this.bIsProcess = true;

      this._httpService
         .updateAsObject(
            `${environment['APIS']['USERS']}/${this.strUserType}/${selectedUser['key']}/action`,
            objUpdateValue
         )
         .then(
            () => {
               this._snackBar.open('User information update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
               this.bIsProcess = false;
               this.dialgoRef.close();
            },
            error => {
               this._snackBar.open('Fail to update in firebase', 'Error', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
               console.error(error);
               this.bIsProcess = false;
               this.dialgoRef.close();
            }
         );
   }

   close(event: any) {
      event.preventDefault();
      this.dialgoRef.close();
   }
}
