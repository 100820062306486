import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../services/notification.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-set-days',
  templateUrl: './set-days.component.html',
  styleUrls: ['./set-days.component.scss']
})
export class SetDaysComponent implements OnInit {

  myForm: FormGroup;
  maxDate = new Date();
  constructor(
    private fb: FormBuilder,
    private _nofication: NotificationService,
  ) {}

  errorsDict = {
    min: 'Must be 1 day in a report at least',
    max: 'Must be no more than 31 days',
    required: 'Field is required'
  };


  @Input() days = 14;
  @Input() showExportDaysControl = false;
  @Input() dataSpanFrom: any;
  @Input() getDataSpanFrom: string;
  @Output() updateDays = new EventEmitter<any>();

  get range() {
    return `${moment(this.dataSpanFrom).format('YYYY-MM-DD')} - ${moment(this.dataSpanFrom).subtract(this.days, 'days').format('YYYY-MM-DD')}`;
  }


  ngOnInit() {
    this.myForm = this.fb.group({
      days: [this.days, [
        Validators.min(1),
        Validators.max(31),
        Validators.required
      ]],
      dataSpan: [new Date(this.dataSpanFrom.valueOf()), [
        Validators.required
      ]],
    });
  }

  onSubmit() {
    if (this.myForm.valid) {
      this.updateDays.next(this.myForm.value);
      this._nofication.createNotification('success', 'Success', 'Set days updated');
    } else {
      this._nofication.createNotification('warning', 'Form', 'Controls must be valid');
    }

  }
}
