import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {global_variables} from 'src/environments/global_variables';
import {RolesEnum} from '../../admin/roles.enum';

export enum BULK_ACTION {
  EDIT,
  SAVE,
}

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeatherComponent {

  private _sensor: any;
  @Input() set sensor(sensor) {
    this._sensor = sensor;
    this.paramValue = sensor && sensor.weatherSensor || '';
  };

  get sensor() {
    return this._sensor;
  }

  @Input() strUserType: string;
  @Input() strUserRole: string;
  @Output() updateWeather = new EventEmitter();
  @Output() updateWeatherSensor = new EventEmitter();
  BULK_ACTION = BULK_ACTION;
  bulkAction: BULK_ACTION = BULK_ACTION.EDIT;
  bulkActionWeatherSensor: BULK_ACTION = BULK_ACTION.EDIT;
  paramValue: string;
  weatherSensorId: number;

  arrStrUserTypes = global_variables.userTypes;
  arrStrUserRoles = global_variables.userRoles;

  get isAdminRole() {
    return this.strUserRole === RolesEnum.ADMIN;
  }
  constructor() {
  }

  onUpdate() {
    this.updateWeather.emit(this.paramValue);
    this.bulkAction = BULK_ACTION.EDIT;
  }

  onUpdateWeatherSensorId() {
    this.updateWeatherSensor.emit(this.weatherSensorId);
    this.bulkActionWeatherSensor = BULK_ACTION.EDIT;
  }

  hasEditPermission(): boolean {
    return (this.strUserType === this.arrStrUserTypes[0] // is OmniscentUser
      || this.strUserRole === this.arrStrUserRoles[0])
      && this.strUserRole !== this.arrStrUserRoles[3];
    // is Customer admin
  }

}
