<div class="omniscent-view">
	<div class="omniscent-title mx-3" fxLayout fxLayoutAlign="space-between center">
		<button mat-mini-fab color="primary" class="mr-1" (click)="onBack()">
			<mat-icon>keyboard_return</mat-icon>
		</button>
		<div>
			Create a Customer
		</div>
		<div></div>
	</div>

	<div class="mx-3 mb-3">
		<mat-progress-bar mode="indeterminate" *ngIf="isCreateCustomer"></mat-progress-bar>
		<mat-card appearance="outlined">
			<mat-card-content>
				<form [formGroup]="customerForm" class="container-fluid" (ngSubmit)="save()">
					<div class="row">
						<div class="form-group col-sm-6">

							<mat-form-field class="full-width">
								<mat-label>Name</mat-label>
								<input matInput type="text" formControlName="name">
								<mat-error *ngIf="formErrors['name']">
									{{ formErrors['name'] }}
								</mat-error>
							</mat-form-field>

						</div>

						<div class="form-group col-sm-6">
							<mat-form-field class="full-width">
								<mat-label>Company Industry</mat-label>
								<mat-select placeholder="Company Industry *" formControlName="industry">
									<mat-option *ngFor="let type of arrStringIndustries" [value]="type">
										{{ type }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-sm-6">

							<mat-form-field class="full-width">
								<mat-label>Headquarter Address</mat-label>
								<input matInput type="text" formControlName="address">
								<mat-error *ngIf="formErrors['address']">
									{{ formErrors['address'] }}
								</mat-error>
							</mat-form-field>

						</div>

						<div class="form-group col-sm-6">

							<mat-form-field class="full-width">
								<mat-label>Site Name</mat-label>
								<input matInput type="text" formControlName="path">
								<mat-error *ngIf="formErrors['path']">
									{{ formErrors['path'] }}
								</mat-error>
							</mat-form-field>

						</div>
					</div>

					<div class="row">
						<div class="form-group col-sm-12">
							<fieldset>
								<legend>Logo Upload Option *</legend>

								<mat-form-field class="full-width">
									<input matInput style="display: none" >
									<mat-radio-group class="full-width" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" formControlName="uploadOption" name="uploadOption">
										<mat-radio-button color="primary" [value]="1" fxFlex="1 1 0">
											Upload with Url
										</mat-radio-button>

										<mat-radio-button color="primary" [value]="0" fxFlex="1 1 0">
											Upload with file
										</mat-radio-button>
									</mat-radio-group>
									<mat-error>
										hello
									</mat-error>
								</mat-form-field>
							</fieldset>
						</div>
					</div>
	
					<div class="row">
						<div class="form-group col-sm-12" *ngIf="customerForm.value.uploadOption === 1">
							<mat-form-field class="full-width">
								<mat-label>Logo Url</mat-label>
								<input matInput type="text" formControlName="logo">
							</mat-form-field>
						</div>

						<div class="form-group col-sm-12" *ngIf="customerForm.value.uploadOption === 0">
							<label class="control-label" for="address">File </label>
							<input type="file" class="form-control" id="logo_file_upload">
						</div>
					</div>
	
					<div class="row">
						<div class="form-group col-sm-6">
							<mat-form-field class="full-width">
								<mat-label>Email</mat-label>
								<input matInput type="email" placeholder="Email" formControlName="email">
							</mat-form-field>
						</div>
	
						<div class="form-group col-sm-6">
							<mat-form-field class="full-width">
								<mat-label>Sign On Date</mat-label>
								<input matInput type="text" formControlName="date">
							</mat-form-field>
						</div>
					</div>
	
					<div class="row">
						<div class="form-group col-sm-6">
							<fieldset>
								<legend>Contract1:</legend>
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>First Name</mat-label>
										<input matInput type="text" formControlName="c1FirstName">
									</mat-form-field>
								</div>
	
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>Last Name</mat-label>
										<input matInput type="text" formControlName="c1LastName">
									</mat-form-field>
								</div>
	
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>Email</mat-label>
										<input matInput type="email" formControlName="c1Email">
									</mat-form-field>
								</div>
	
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>Phone Number</mat-label>
										<input matInput type="email" formControlName="c1PhoneNumber">
									</mat-form-field>
								</div>
							</fieldset>
						</div>
	
						<div class="form-group col-sm-6">
							<fieldset>
								<legend>Contract2:</legend>
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>First Name</mat-label>
										<input matInput type="text" formControlName="c2FirstName">
									</mat-form-field>
								</div>
	
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>Last Name</mat-label>
										<input matInput type="text" formControlName="c2LastName">
									</mat-form-field>
								</div>
	
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>Email</mat-label>
										<input matInput type="email" formControlName="c2Email">
									</mat-form-field>
								</div>
	
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>Phone Number</mat-label>
										<input matInput type="email" formControlName="c2PhoneNumber">
									</mat-form-field>
								</div>
							</fieldset>
						</div>
					</div>
	
					<div class="row">
						<div class="form-group col-sm-6">
							<fieldset>
								<legend>Emergency Contact:</legend>
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>First Name</mat-label>
										<input matInput type="text" formControlName="eFirstName">
									</mat-form-field>
								</div>
	
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>Last Name</mat-label>
										<input matInput type="text" formControlName="eLastName">
									</mat-form-field>
								</div>
	
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>Email</mat-label>
										<input matInput type="email" formControlName="eEmail">
									</mat-form-field>
								</div>
	
								<div class="form-group">
									<mat-form-field class="full-width">
										<mat-label>Phone Number</mat-label>
										<input matInput type="email" formControlName="ePhoneNumber">
									</mat-form-field>
								</div>
							</fieldset>
						</div>
					</div>
	
					<div class="row" *ngIf="!isCreateCustomer">
						<div class="form-group col-sm-12">
							<div fxLayout="row" fxLayoutAlign="center center">
								<button mat-raised-button class="mr-2" color="primary" [disabled]="customerForm.invalid">Create Customer</button>
								<a mat-raised-button class="btn btn-default" (click)="customerForm.reset()">Reset</a>
							</div>
						</div>
					</div>
				</form>
			</mat-card-content>
		</mat-card>
	</div>
</div>
