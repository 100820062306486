import { HttpClientModule } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Chemical, DataPlotFilter, DataPlotPoint, RawDataFilter, RawDataPlotModuleConfig } from './raw-data-plot.types';
import { map } from 'rxjs/operators';

export const RAW_DATA_PLOT_MODULE_CONFIG = new InjectionToken<RawDataPlotModuleConfig>('raw-data-plot-module.config');

@Injectable({
  providedIn: 'root'
})
export class RawDataPlotDataService {
  private _lookups: Chemical[];

  constructor(
    @Inject(RAW_DATA_PLOT_MODULE_CONFIG) private config: RawDataPlotModuleConfig,
    private http: HttpClientModule
  ) { }

  requestDataPlotPoints(filter: RawDataFilter, freq?: DataPlotFilter['value']['agg']['freq']) {
    const path = `${this.config.apiBaseUrl}${this.getEndpointFromFreq(freq)}`;

    const options = {
      headers: {
        'Authorization': `Bearer ${this.config.accessToken}`
      },
      params: { filter: JSON.stringify(filter) }
    };

    // @ts-ignore
    return this.http.get<DataPlotPoint[]>(path, options)
      // @ts-ignore
      .pipe(map(dpp => dpp.sort((a, b) => a.timestamp - b.timestamp)));
  }

  async Lookups(): Promise<Chemical[]> {

    if (!this._lookups) {
      const path = `${this.config.apiBaseUrl}${this.config.chemicalsEndpoint}`;

      const options = {
        headers: {
          'Authorization': `Bearer ${this.config.accessToken}`
        }
      };

      // @ts-ignore
      this._lookups = await this.http.get<Chemical[]>(path, options).toPromise();
    }
    return this._lookups;

  }

  getEndpointFromFreq(freq?: DataPlotFilter['value']['agg']['freq']) {
    switch (freq) {
      case 'fx1':
        return this.config.dataDayEndpoint;
      case 'fx4':
        return this.config.rawDataEndpoint;
      case 'fx24':
        return this.config.dataHoursEndpoint;
      case 'fx1440':
        return this.config.dataMinutesEndpoint;
      default:
        return this.config.rawDataEndpoint;
    }
  }
}
