<div class="peak-table-area">
  <div class="full-width" *ngIf="peaks.length; else emptyEle">
    <table class="table table-bordered">
      <tbody *ngFor="let peak of peaks; let i=index;">
        <tr>
          <td
            [attr.colspan]="peak.data && peak.data.length > 0 ? objectKeys(peak.data[0]).length : 17"
            class="text-left"
          >
            <div class="d-flex flex-row align-items-center">
              <div class="mr-2">
                {{ i + 1 }} - {{ peak?.info?.measurementDate }} - {{ peak?.info?.uploadDate }} - Graph color: {{ peak?.info?.color }}
              </div>

              <div class="peak-color" [ngStyle]="{'background-color': peak?.info?.color}"></div>
            </div>
          </td>
        </tr>

        <div *ngIf="peak.data && peak.data.length > 0; else noDataEle">
          <tr *ngFor="let row of peak.data">
            <td *ngFor="let key of objectKeys(row)">
              {{ row[key] }}
            </td>
          </tr>
        </div>

        <ng-template #noDataEle>
          <tr>
            <td>
              <div class="alert alert-info">There is no data.</div>
            </td>
          </tr>
        </ng-template>
        
      </tbody>
    </table>
  </div>

  <ng-template #emptyEle>
    <div class="alert alert-info full-width text-center">
      No data.
    </div>
  </ng-template>
</div>
