import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommentModalComponent } from '../../modals/comment-modal/comment-modal.component';

@Component({
   selector: 'app-comment',
   templateUrl: './comment.component.html',
   styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnChanges {
   @Input() strComment: string;
   @Input() key: string;
   @Output() updateComment = new EventEmitter();

   @ViewChild('displayElement', { static: true }) displayElement: ElementRef;

   bIsHover: boolean;

   constructor(public dialog: MatDialog) {
      this.bIsHover = false;
   }

   ngOnInit() {}

   ngOnChanges() {
      this.applyToElement();
   }

   /**
    * apply content to element
    */
   applyToElement() {
      if (!this.strComment) {
         this.strComment = '';
      }
      this.displayElement.nativeElement.innerHTML = '';
      this.displayElement.nativeElement.insertAdjacentHTML('beforeend', this.strComment);
   }

   /**
    * over function
    */
   onOver() {
      this.bIsHover = true;
   }

   /**
    * edit element
    * @param bIsEdit edit status
    */
   onEdit(bIsEdit: boolean) {
      const config = {
         width: '400px',
         disableClose: false,
         data: {
            bIsEdit: bIsEdit,
            strComment: this.strComment,
         },
      };
      const dialogRef = this.dialog.open(CommentModalComponent, config);
      dialogRef.afterClosed().subscribe({
         next: (result) => {
            if (result && result['status']) {
               this.updateComment.emit({
                  comment: result['comment'],
                  key: this.key,
               });
            }
         },
         error: (error) => {
            console.error('comment.component.ts -> onEdit(): ', error);
         }
      });
   }

   /**
    * leave from element
    */
   onLeave() {
      this.bIsHover = false;
   }
}
