<div class="main-app create-wrapper">
	<div class="create-customer-section" *ngIf="createType===0">
		<app-create-customer></app-create-customer>
	</div>

	<div class="create-zone-section" *ngIf="createType===1">
		<app-create-zone
		[createName]="createName"
		[customerId]="customerId"
		></app-create-zone>
	</div>

	<div class="create-sensor-section" *ngIf="createType===2">
		<app-create-sensor
		[createName]="createName"
		[customerId]="customerId"
		[zoneId]="zoneId"
		></app-create-sensor>
	</div>
</div>