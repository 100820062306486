<div *ngIf="!bIsGetAssignedCongiguration" fxLayout="column" fxLayoutAlign="center center" class="m-3">
   <h5 class="text-center mb-3">Loading...</h5>
   <mat-spinner>Loading</mat-spinner>
</div>

<div class="config-modal-area" *ngIf="bIsGetAssignedCongiguration">
   <button type="button" class="close" (click)="close()">&times;</button>
   <h4 class="text-center mb-3">Sensor Configuration</h4>

   <div class="modal-step" *ngIf="nCurrentStep === 0 && strCurrentUserType === arrStrUserTypes[0]">
      <mat-dialog-content>
         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-12">
                  <mat-form-field class="full-width">
                     <mat-label>Select the Configuration Type</mat-label>
                     <mat-select placeholder="Select the Configuration Type" class="full-width"
                        [(ngModel)]="strCurrentConfigType" [required]="true">
                        <mat-option *ngFor="let configType of arrObjConfigTypes" [value]="configType['value']">
                           {{ configType['label'] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
         </div>
      </mat-dialog-content>

      <mat-dialog-actions class="footer">
         <button mat-button disabled="true">Previous</button>
         <button mat-button (click)="nextStep()">Next</button>
      </mat-dialog-actions>
   </div>

   <div class="modal-step" *ngIf="nCurrentStep === 1">
      <mat-dialog-content class="overflow-y">
         <div class="container-fluid">
            <div class="row" *ngIf="strCurrentConfigType === arrObjConfigTypes[0]['value']; else loadSelectionEl">
               <div class="col-sm-6" *ngIf="bIsAssignMode">
                  <mat-form-field class="full-width form-group">
                     <mat-label>Select the sensor type</mat-label>
                     <mat-select placeholder="Select the sensor type" class="full-width" [(ngModel)]="nModalType"
                        [required]="true" (selectionChange)="onChangeSetting()">
                        <mat-option [value]="2">Omni-2100</mat-option>
                     </mat-select>
                  </mat-form-field>

                  <mat-form-field class="full-width form-group">
                     <mat-label>Select the modal</mat-label>
                     <mat-select placeholder="Select the modal" class="full-width" [(ngModel)]="strCurrentDataType"
                        [required]="true" (selectionChange)="onChangeSetting()">
                        <mat-option *ngFor="let type of arrStrDataTypes" [value]="type">
                           {{ type }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>

               <div class="simple-configuration-container full-width">
                  <form [formGroup]="settingForm" class="full-width"
                     *ngIf="objAssignedData && nModalType !== arrIntModalTypes[0] && settingForm">
                     <div class="row">
                        <div class="col-sm-6">
                           <mat-form-field class="full-width form-group">
                              <mat-label>Number of runs</mat-label>
                              <input matInput type="number" placeholder="Number of runs" formControlName="runNumber"
                                 min="1" [max]="objSelectedSensor.maxRuns || 999" step="1" />
                           </mat-form-field>
                        </div>
                     </div>

                     <ng-container *ngIf="!isFastModeEnabled">
                        <!-- Sampling Time for a specific modal type -->
                        <div class="row mb-3" *ngIf="nModalType === arrIntModalTypes[1]">
                           <div class="col-sm-6">
                              <mat-form-field class="full-width">
                                 <mat-label>Sampling Time</mat-label>
                                 <mat-select placeholder="Sampling Time" class="full-width" [required]="true"
                                    formControlName="samplingTime">
                                    <mat-option *ngFor="let samplingTime of arrObjSamplingTimes"
                                       [value]="samplingTime['value']">
                                       {{ samplingTime['label'] }}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>
                        </div>

                        <!-- Detailed Sampling Time for another modal type -->
                        <div class="row mb-3" *ngIf="nModalType === arrIntModalTypes[2]">
                           <div class="col-sm-4">
                              <mat-form-field class="full-width">
                                 <mat-label>Sampling Time (hours)</mat-label>
                                 <mat-select placeholder="Sampling time (hours)" class="full-width"
                                    formControlName="samplingTimeHours">
                                    <mat-option *ngFor="let hour of arrIntSamplingHours" [value]="hour">
                                       {{ hour }}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>

                           <div class="col-sm-4">
                              <mat-form-field class="full-width">
                                 <mat-label>Sampling Time (minutes)</mat-label>
                                 <mat-select placeholder="Sampling time (minutes)" class="full-width" [required]="true"
                                    formControlName="samplingTimeMins">
                                    <mat-option *ngFor="let minute of arrIntSamplingMinutes" [value]="minute">
                                       {{ minute }}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>

                           <div class="col-sm-4">
                              <mat-form-field class="full-width">
                                 <mat-label>Sampling Time (seconds)</mat-label>
                                 <mat-select placeholder="Sampling time (seconds)" class="full-width" [required]="true"
                                    formControlName="samplingTimeSecs">
                                    <mat-option *ngFor="let second of arrIntSamplingSeconds" [value]="second">
                                       {{ second }}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>
                        </div>

                        <!-- Idle Time between each run -->
                        <div class="row">
                           <div class="col-sm-6">
                              <mat-form-field class="full-width form-group">
                                 <mat-label>Idle time between each run (seconds)</mat-label>
                                 <input matInput type="number" placeholder="Idle time between each run (seconds)"
                                    formControlName="idleTime" />
                              </mat-form-field>
                           </div>
                        </div>
                     </ng-container>

                  </form>

                  <div>The following will be saved in the Comments for all generated data files:</div>
                  <textarea class="full-width my-3" [(ngModel)]="strConfigurationComment"></textarea>

                  <div class="col-sm-12 alert alert-info full-width" *ngIf="strEstimatedTotalTime">
                     <strong>Estimated total time:</strong> {{ strEstimatedTotalTime }}
                  </div>
               </div>

               <div class="col-sm-12 my-2" *ngIf="bIsAssignMode">
                  <h5>Select complete configuration file</h5>
               </div>
            </div>

            <ng-template #loadSelectionEl>
               <div class="row">
                  <div class="col-sm-12">
                     <mat-form-field class="full-width">
                        <mat-label>Select the configuration type</mat-label>
                        <mat-select placeholder="Select the configuration type" class="full-width text-center"
                           [(ngModel)]="strCurrentSetupType" [required]="true" (selectionChange)="onChangeConfigType()">
                           <mat-option [value]="arrStrSetupTypes[0]">Load configuration file from the Cloud</mat-option>
                           <mat-option [value]="arrStrSetupTypes[1]" *ngIf="!bIsAssignMode">Load configuration file from
                              local</mat-option>
                           <mat-option [value]="arrStrSetupTypes[2]" *ngIf="!bIsAssignMode">Create new configuration
                              file</mat-option>
                        </mat-select>
                     </mat-form-field>
                  </div>
               </div>

               <div class="row">
                  <div class="col-6">
                     <mat-form-field class="full-width form-group">
                        <mat-label>Select the sensor type</mat-label>
                        <mat-select placeholder="Select the sensor type" class="full-width" [(ngModel)]="nModalType"
                           (selectionChange)="onChangeSetting()" [required]="true">
                           <mat-option [value]="arrIntModalTypes[2]">Omni-2100</mat-option>
                        </mat-select>
                     </mat-form-field>
                  </div>

                  <div class="col-6">
                     <mat-form-field class="full-width form-group">
                        <mat-label>Select the mode</mat-label>
                        <mat-select placeholder="Select the mode" class="full-width" [(ngModel)]="strCurrentDataType"
                           (selectionChange)="onChangeSetting()" [required]="true">
                           <mat-option *ngFor="let type of arrStrDataTypes" [value]="type">
                              {{ type }}
                           </mat-option>
                        </mat-select>
                     </mat-form-field>
                  </div>
               </div>
            </ng-template>

            <div class="row" *ngIf="bIsAssignMode || strCurrentConfigType === arrObjConfigTypes[1]['value']">
               <div class="col-sm-12" *ngIf="bIsGetConfigurations">
                  <div class="load-area">
                     <mat-spinner></mat-spinner>
                  </div>
               </div>

               <div class="col-sm-12" *ngIf="!bIsGetConfigurations">
                  <div *ngIf="strCurrentSetupType === arrStrSetupTypes[0]">
                     <div class="load-area" *ngIf="!bIsGetCloudConfigs && bIsTryToGetCC">
                        <mat-spinner></mat-spinner>
                     </div>

                     <div class="alert alert-danger full-width"
                        *ngIf="bIsTryToGetCC && bIsGetCloudConfigs && arrConfigs.length === 0">
                        There is no configuration data from the Cloud.
                     </div>

                     <div class="config-table-area" *ngIf="bIsGetCloudConfigs && arrConfigs.length > 0">
                        <div class="my-3 filter-container">
                           <mat-form-field class="full-width">
                              <mat-label>Search...</mat-label>
                              <input type="text" [(ngModel)]="strFilerText" matInput placeholder="Search..."
                                 (keyup)="onUpdateFilter($event)" />
                           </mat-form-field>
                        </div>

                        <table mat-table #table [dataSource]="dataSource">
                           <!-- Position Column -->
                           <ng-container matColumnDef="position">
                              <th mat-header-cell *matHeaderCellDef>No.</th>
                              <td mat-cell *matCellDef="let element" style="flex: 1">{{ element?.position }}</td>
                           </ng-container>

                           <!-- Name Column -->
                           <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef class="px-3">File Name</th>
                              <td mat-cell *matCellDef="let element" class="px-3 file-name">
                                 {{ element?.name }}
                              </td>
                           </ng-container>

                           <!-- Upload Time Column -->
                           <ng-container matColumnDef="time">
                              <th mat-header-cell *matHeaderCellDef class="px-3">Upload Time</th>
                              <td mat-cell *matCellDef="let element" class="px-3">{{ element?.time }}</td>
                           </ng-container>

                           <!-- Actions Column -->
                           <ng-container matColumnDef="actions">
                              <th mat-header-cell *matHeaderCellDef>Actions</th>
                              <td mat-cell *matCellDef="let element">
                                 <div class="multi-action-btn-wrap" *ngIf="strSelectedFileKey !== element['key']">
                                    <mat-icon class="pointer" color="primary" (click)="onLoadConfig(element)"
                                       matTooltip="Load">play_arrow</mat-icon>

                                    <mat-icon color="warn" class="pointer" matTooltip="Delete"
                                       (click)="onDeleteConfig(element['path'], element['key'])">delete_outline</mat-icon>

                                    <mat-icon class="pointer green-text"
                                       (click)="onDownloadJSONFile(element['url'], element['name'])"
                                       matTooltip="Download">cloud_download</mat-icon>
                                 </div>

                                 <div *ngIf="strSelectedFileKey === element['key']" class="flex-center">
                                    <mat-spinner class="small-spinner"
                                       *ngIf="bIsLoadConfiguration; else submitStepEl"></mat-spinner>

                                    <ng-template #submitStepEl>
                                       <mat-icon class="pointer" color="primary" matTooltip="Submit"
                                          (click)="submitStep()">launch</mat-icon>
                                    </ng-template>
                                 </div>
                              </td>
                           </ng-container>

                           <tr mat-header-row *matHeaderRowDef="arrStrDisplayedColumns"></tr>
                           <tr mat-row *matRowDef="let row; columns: arrStrDisplayedColumns"></tr>
                        </table>

                        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"> </mat-paginator>
                     </div>
                  </div>

                  <div class="form-group" *ngIf="strCurrentSetupType === arrStrSetupTypes[1]">
                     <label class="control-label" for="logo_file_upload">Pick a JSON File</label>
                     <input type="file" class="form-control" id="logo_file_upload" name="logo" accept=".json"
                        (change)="onImport($event)" />
                  </div>
               </div>
            </div>

            <div class="row" *ngIf="bIsConfigMessage">
               <div class="alert alert-success full-width" role="alert" *ngIf="bIsAlertSuccess; else errorAlertEle">
                  <strong>Ready</strong> to go!
               </div>

               <ng-template #errorAlertEle>
                  <div class="alert alert-danger full-width" role="alert">
                     {{ strConfigurationAlert }}
                  </div>
               </ng-template>
            </div>
         </div>
      </mat-dialog-content>

      <mat-dialog-actions class="footer">
         <div *ngIf="bIsAssignMode || strCurrentUserType === arrStrUserTypes[1]"></div>
         <button mat-button (click)="previousStep()"
            *ngIf="!bIsAssignMode && strCurrentUserType === arrStrUserTypes[0]">
            Previous
         </button>
         <button mat-button
            *ngIf="bIsAssignMode || (!bIsAssignMode && strCurrentConfigType === arrObjConfigTypes[1]['value'])"
            (click)="submitStep()" [disabled]="
               (settingForm && !settingForm.valid) ||
               !strSelectedFileKey ||
               !bIsConfigMessage ||
               (strCurrentConfigType === arrObjConfigTypes[1]['value'] && strCurrentSetupType !== arrStrSetupTypes[0])
            ">
            Submit
         </button>
         <button mat-button *ngIf="!bIsAssignMode && strCurrentConfigType === arrObjConfigTypes[0]['value']"
            (click)="submitStep()" [disabled]="(settingForm && settingForm.invalid) || !bIsCorrectSimpleConfiguration">
            Save
         </button>
         <button mat-button *ngIf="!bIsAssignMode && strCurrentConfigType === arrObjConfigTypes[1]['value']"
            (click)="nextStep()" [disabled]="
               (settingForm && !settingForm.valid) ||
               (bIsLoadConfiguration && strCurrentSetupType === arrStrSetupTypes[0]) ||
               (strCurrentConfigType === arrObjConfigTypes[0]['value'] && !bIsImportFile) ||
               (strCurrentConfigType === arrObjConfigTypes[1]['value'] &&
                  strCurrentSetupType !== arrStrSetupTypes[2] &&
                  !bIsImportFile)
            ">
            Next
         </button>
      </mat-dialog-actions>
   </div>

   <div class="modal-step" *ngIf="nCurrentStep === nMainStepNumber - 1">
      <mat-dialog-content>
         <form [formGroup]="numberForm" class="container-fluid">
            <div class="row">
               <div class="col-6 form-group">
                  <mat-form-field class="full-width">
                     <mat-label>Number of Steps</mat-label>
                     <input matInput type="number" placeholder="Number of Steps" formControlName="stepNumber" min="1"
                        max="100" />
                  </mat-form-field>
               </div>

               <div class="col-6 form-group">
                  <mat-form-field class="full-width">
                     <mat-label>Number of Runs</mat-label>
                     <input matInput type="number" placeholder="Number of Runs" formControlName="runNumber" min="1"
                        [max]="objSelectedSensor.maxRuns || 999" step="1" />
                  </mat-form-field>
               </div>
            </div>
         </form>
      </mat-dialog-content>

      <mat-dialog-actions class="footer">
         <button mat-button (click)="previousStep()">Previous</button>
         <button mat-button (click)="nextStep()" [disabled]="!numberForm.valid">Next</button>
      </mat-dialog-actions>
   </div>

   <div class="modal-step modal-step-config"
      *ngIf="nCurrentStep >= nMainStepNumber && nCurrentStep < nStepNumber + nMainStepNumber">
      <div class="sub-title">Step-{{ nCurrentStep - nMainStepNumber + 1 }}</div>

      <mat-dialog-content class="overflow-y">
         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-6">
                  <mat-form-field class="full-width">
                     <mat-label>Step Action</mat-label>
                     <mat-select placeholder="Step Action" class="full-width" [(ngModel)]="strCurrentStepAction"
                        [required]="true" [disabled]="nCurrentStep === nMainStepNumber">
                        <mat-option *ngFor="let action of arrStrStepActions" [value]="action" [disabled]="
                              (action === arrStrStepActions[2] &&
                                 ((arrObjChemicalCRs.length === 0 &&
                                    arrObjChemicalCDs.length === 0 &&
                                    arrObjChemicalPDs.length === 0) ||
                                    arrObjUploadOptions.length == 0)) ||
                              (action === arrStrStepActions[3] &&
                                 (arrObjChemicalCRs.length === 0 ||
                                    arrObjChemicalCDs.length === 0 ||
                                    arrObjChemicalPDs.length === 0))
                           ">
                           {{ action }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
         </div>

         <form [formGroup]="configForm" class="config-form" *ngIf="strCurrentStepAction === arrStrStepActions[0]">
            <div class="container-fluid mb-3">
               <div class="row" ngStyle.gt-sm="min-width: 900px;">
                  <div class="col-sm-6">
                     <div class="table-responsive">
                        <table class="table table-bordered">
                           <thead>
                              <tr>
                                 <th>Params</th>
                                 <th>T_idle</th>
                                 <th>T_target</th>
                                 <th>t1</th>
                                 <th>t2</th>
                                 <th>t3</th>
                              </tr>
                           </thead>

                           <tbody>
                              <tr *ngFor="let row of objTKeyTable['rows']; let rowIndex = index">
                                 <td>
                                    <div *ngIf="
                                          strCurrentDataType === arrStrDataTypes[1] || nModalType === 2;
                                          else kpEle
                                       ">
                                       {{ row }}
                                    </div>

                                    <ng-template #kpEle>
                                       <div *ngIf="rowIndex === 0">KPA</div>
                                       <div *ngIf="rowIndex === 1">KPB</div>
                                       <div *ngIf="rowIndex > 1">
                                          {{ row }}
                                       </div>
                                    </ng-template>
                                 </td>
                                 <td *ngFor="let header of objTKeyTable['headers']">
                                    <input type="number" class="text-center" [formControlName]="
                                          row.toLowerCase() + header.charAt(0).toUpperCase() + header.slice(1)
                                       " required />
                                    <mat-error *ngIf="
                                          objConfigFormErrors[
                                             row.toLowerCase() + header.charAt(0).toUpperCase() + header.slice(1)
                                          ]
                                       ">
                                       {{
                                          objConfigFormErrors[
                                             row.toLowerCase() + header.charAt(0).toUpperCase() + header.slice(1)
                                          ]
                                       }}
                                    </mat-error>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>

                     <ng-template *ngIf="nModalType === arrIntModalTypes[2]; then extraConfigurationEle"></ng-template>
                  </div>

                  <div class="col-sm-6">
                     <div class="table-responsive sensor-name-value" *ngIf="nModalType === arrIntModalTypes[2]">
                        <table class="table table-bordered">
                           <thead>
                              <tr>
                                 <th width="155">Name</th>
                                 <th width="90">Idle value</th>
                                 <th width="90">Target value</th>
                                 <th>t1</th>
                                 <th>t2</th>
                                 <th>t3</th>
                              </tr>
                           </thead>

                           <tbody>
                              <tr *ngFor="let row of objValuePumpKeyTable['rows']; let rowIndex = index">
                                 <td>
                                    {{ row }} <span *ngIf="rowIndex === 5"> (V)</span><span *ngIf="rowIndex === 6">
                                       (Hz)</span>
                                 </td>
                                 <td *ngFor="let header of objValuePumpKeyTable['headers']; let headerIndex = index">
                                    <!-- on/off field start -->
                                    <div *ngIf="
                                          headerIndex >= 0 && headerIndex < 2 && rowIndex >= 0 && rowIndex < 5;
                                          else VPNumberEle
                                       ">
                                       <mat-form-field class="full-width">
                                          <mat-select class="full-width text-center"
                                             [formControlName]="row.split(' ').join('_') + '_' + header"
                                             [required]="true">
                                             <mat-option [value]="'on'">On</mat-option>
                                             <mat-option [value]="'off'">Off</mat-option>
                                          </mat-select>
                                       </mat-form-field>
                                    </div>
                                    <!-- on/off field end -->
                                    <ng-template #VPNumberEle>
                                       <!-- disabled field start -->
                                       <div *ngIf="headerIndex === 3 && rowIndex >= 0 && rowIndex < 5; else VPNAEle">
                                          N/A
                                       </div>
                                       <!-- disabled field end -->
                                       <!-- number field start -->
                                       <ng-template #VPNAEle>
                                          <input type="number" class="text-center"
                                             [formControlName]="row.split(' ').join('_') + '_' + header" required />
                                          <mat-error
                                             *ngIf="objConfigFormErrors[row.split(' ').join('_') + '_' + header]">
                                             {{ objConfigFormErrors[row.split(' ').join('_') + '_' + header] }}
                                          </mat-error>
                                       </ng-template>
                                       <!-- number field end -->
                                    </ng-template>
                                 </td>
                              </tr>
                           </tbody>
                        </table>

                     </div>
                     <div class="container">
                        <div class="row mb-1">
                           <div class="col-sm-6 d-flex align-items-center">
                              <mat-checkbox formControlName="saveData" name="saveData">Save Data</mat-checkbox>
                           </div>
                           <div class="col-sm-6 d-flex align-items-center">
                              <mat-checkbox formControlName="ampEnable" name="ampEnable">Flow Sensor
                                 Enable</mat-checkbox>
                           </div>
                        </div>

                        <div class="row mb-1">
                           <div class="col-sm-6 d-flex align-items-center">
                              <mat-checkbox formControlName="isCleaning" name="isCleaning">Only run during
                                 cleaning</mat-checkbox>
                           </div>
                           <div class="col-sm-6 d-flex align-items-center">
                              <mat-checkbox formControlName="isRepeatedSamplingInRun"
                                 name="isRepeatedSamplingInRun">Repeated Sampling In
                                 Run</mat-checkbox>
                           </div>
                        </div>
                     </div>




                     <ng-template *ngIf="nModalType !== arrIntModalTypes[2]; then extraConfigurationEle"></ng-template>
                  </div>
               </div>
            </div>

            <ng-template #extraConfigurationEle>
               <div class="row">
                  <div class="col-sm-6">
                     <div class="mat-select-wrapper">
                        <mat-form-field class="full-width" id="height-inputField">
                           <mat-label>Total Runtime(s)</mat-label>
                           <input type="number" matInput placeholder="Total Runtime(s)" formControlName="totalRuntime"
                              name="totalRuntime" required (ngModelChange)="onChangeTotalRunTime($event)" />
                        </mat-form-field>
                     </div>
                  </div>

                  <div class="col-sm-6">
                     <mat-form-field class="full-width" id="height-inputField">
                        <mat-label>Target board temperature (°C)</mat-label>
                        <input type="number" matInput placeholder="Target board temperature (°C)"
                           formControlName="targetBoardTemp" />
                     </mat-form-field>
                  </div>
               </div>

               <div class="row" *ngIf="nModalType === 1 || nModalType === 2">
                  <div class="col-sm-6">
                     <div class="mat-select-wrapper">
                        <mat-form-field class="full-width" id="height-inputField">
                           <mat-label>Step Category</mat-label>
                           <mat-select placeholder="Step Category" class="full-width" formControlName="stepType"
                              name="stepType" [required]="true">
                              <mat-option *ngFor="let type of arrStrStepTypes" [value]="type">
                                 {{ type }}
                              </mat-option>
                           </mat-select>
                        </mat-form-field>
                     </div>
                  </div>

                  <div class="col-sm-6">
                     <mat-form-field class="full-width" id="height-inputField">
                        <mat-label>Board temperature control</mat-label>
                        <mat-select placeholder="Board temperature control" class="full-width"
                           formControlName="boardTempControl">
                           <mat-option [value]="1">On</mat-option>
                           <mat-option [value]="0">Off</mat-option>
                        </mat-select>
                     </mat-form-field>
                  </div>
               </div>

               <div class="row">
                  <div class="col-sm-6">
                     <mat-form-field class="full-width" id="height-inputField">
                        <mat-label>Max. sampling time per step (s)</mat-label>
                        <input type="number" matInput placeholder="Max. sampling time per step (s)"
                           formControlName="maxSamplingTimePerStep" />
                     </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                     <mat-form-field class="full-width" id="height-inputField">
                        <mat-label>Fan control</mat-label>
                        <mat-select placeholder="Fan control" class="full-width" formControlName="fanControl"
                           [(ngModel)]="configForm['value']['boardTempControl'] === 1 ? 1 : configForm['value']['fanControl']"
                           [disabled]="configForm['value']['boardTempControl'] === 1">
                           <mat-option [value]="1">On</mat-option>
                           <mat-option [value]="0">Off</mat-option>
                        </mat-select>
                     </mat-form-field>
                  </div>
               </div>
            </ng-template>


         </form>

         <div class="container-fluid data-processing-step" *ngIf="strCurrentStepAction === arrStrStepActions[1]">
            <div class="row form-group">
               <div class="mat-select-wrapper" [ngClass]="{
                     'col-sm-4': nModalType === arrIntModalTypes[0],
                     'col-sm-6': nModalType === arrIntModalTypes[1] || nModalType === arrIntModalTypes[2]
                  }">
                  <mat-form-field class="full-width">
                     <mat-label>Select the algorithm</mat-label>
                     <mat-select placeholder="Select the algorithm" class="full-width" [(ngModel)]="strCurrentAlgorithm"
                        [required]="true">
                        <mat-option *ngFor="let algorithm of arrStrAlgorithm" [value]="algorithm">
                           {{ algorithm }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>

               <div class="mat-select-wrapper" [ngClass]="{
                     'col-sm-4': nModalType === arrIntModalTypes[0],
                     'col-sm-6': nModalType === arrIntModalTypes[1] || nModalType === arrIntModalTypes[2]
                  }">
                  <mat-form-field class="full-width">
                     <mat-label>Select raw chromatogram</mat-label>
                     <mat-select placeholder="Select raw chromatogram" class="full-width" [(ngModel)]="nCurrentChroma"
                        [required]="true">
                        <mat-option *ngFor="let item of arrObjChromatograms" [value]="item['value']">
                           {{ item['label'] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>

               <div class="mat-select-wrapper" *ngIf="nModalType === arrIntModalTypes[0]" [ngClass]="{
                     'col-sm-4': nModalType === arrIntModalTypes[0],
                     'col-sm-6': nModalType === arrIntModalTypes[1]
                  }">
                  <mat-form-field class="full-width">
                     <mat-label>Select flow calibration data</mat-label>
                     <mat-select placeholder="Select flow calibration data" [(ngModel)]="nCurrentCalib"
                        [required]="true">
                        <mat-option *ngFor="let item of arrObjCalibrations" [value]="item['value']">
                           {{ item['label'] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>
         </div>

         <div class="container-fluid data-processing-step" *ngIf="strCurrentStepAction === arrStrStepActions[2]">
            <div class="row" *ngFor="let data of arrObjUploadData">
               <div class="col-sm-6 mat-select-wrapper form-group">
                  <mat-form-field class="full-width">
                     <mat-label>Select data in which step to be uploaded</mat-label>
                     <mat-select placeholder="Select data in which step to be uploaded" class="full-width"
                        [(ngModel)]="data['step']" [required]="true">
                        <mat-option *ngFor="let item of arrObjUploadOptions" [value]="item['value']">
                           {{ item['label'] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>

            <div class="row">
               <div class="col-sm-12 form-group">
                  Add data to be uploaded
                  <button mat-icon-button color="accent" (click)="onAddUploadData()">
                     <mat-icon class="mat-24">add</mat-icon>
                  </button>
               </div>
               <div class="col-sm-12 form-group">
                  <div class="pull-left input-padding">
                     <section class="full-width">
                        <mat-checkbox name="isCleaning" [(ngModel)]="isCleaningUploadStep">Only run during
                           cleaning</mat-checkbox>
                     </section>
                  </div>
               </div>
            </div>
         </div>

         <div class="container-fluid data-processing-step" *ngIf="strCurrentStepAction === arrStrStepActions[3]">
            <div class="row">
               <div class="col-sm-6 mat-select-wrapper form-group">
                  <mat-form-field class="full-width">
                     <mat-label>Select raw chromatogram</mat-label>
                     <mat-select placeholder="Select raw chromatogram" class="full-width" [(ngModel)]="nChemicalCR"
                        [required]="true">
                        <mat-option *ngFor="let item of arrObjChemicalCRs" [value]="item['value']">
                           {{ item['label'] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>

               <div class="col-sm-6 mat-select-wrapper form-group" *ngIf="nModalType === arrIntModalTypes[0]">
                  <mat-form-field class="full-width">
                     <mat-label>Select flow calibration data</mat-label>
                     <mat-select class="full-width" placeholder="Select flow calibration data" [(ngModel)]="nChemicalCD"
                        [required]="true">
                        <mat-option *ngFor="let item of arrObjChemicalCDs" [value]="item['value']">
                           {{ item['label'] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>

               <div class="col-sm-6 mat-select-wrapper form-group">
                  <mat-form-field class="full-width">
                     <mat-label>Select processed data</mat-label>
                     <mat-select placeholder="Select processed data" class="full-width" [(ngModel)]="nChmicalPD"
                        [required]="true">
                        <mat-option *ngFor="let item of arrObjChemicalPDs" [value]="item['value']">
                           {{ item['label'] }}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>

               <div class="col-sm-6 form-group mat-select-wrapper" *ngIf="nModalType === arrIntModalTypes[0]">
                  <mat-checkbox [(ngModel)]="bIsChemicalSaveData" name="saveData">Flow sensor enabled</mat-checkbox>
               </div>
            </div>

            <div class="row">
               <div class="col-sm-6 form-group mat-select-wrapper">
                  <mat-form-field class="full-width">
                     <mat-label>Separation start time in the Separation step (s)</mat-label>
                     <input type="number" matInput placeholder="Separation start time in the Separation step (s)"
                        [(ngModel)]="nChemicalStartTime" />
                  </mat-form-field>
               </div>

               <div class="col-sm-6 form-group mat-select-wrapper">
                  <mat-form-field class="full-width">
                     <mat-label>Total sampling time (hrs)</mat-label>
                     <input type="number" matInput placeholder="Total sampling time (hrs)"
                        [(ngModel)]="nChemicalTotal" />
                  </mat-form-field>
               </div>
            </div>
         </div>
      </mat-dialog-content>

      <div class="alert alert-danger" *ngIf="configForm && configForm.invalid">Invalid form</div>

      <div class="alert alert-danger" *ngIf="bIsOverTotalRunTime && strCurrentStepAction === arrStrStepActions[0]">
         Total RunTime should be bigger than the sum of t1, t2 and t3.
      </div>

      <mat-dialog-actions class="footer">
         <button mat-button (click)="previousStep()">Previous</button>
         <button color="primary" mat-button (click)="onAddStep()">Add</button>
         <button color="warn" mat-button (click)="onDeleteStep()" [disabled]="
               nCurrentStep === nMainStepNumber &&
               !(
                  (arrParams[1] && arrParams[1]['stepAction'] === arrStrStepActions[0]) ||
                  (!arrParams[1] && bIsMultiMRStep)
               )
            ">
            Delete
         </button>
         <button mat-button (click)="nextStep()" [disabled]="
               (configForm && configForm.invalid) ||
               (bIsOverTotalRunTime && strCurrentStepAction === arrStrStepActions[0])
            ">
            Next
         </button>
      </mat-dialog-actions>
   </div>

   <div class="modal-step" *ngIf="nCurrentStep === nMainStepNumber + nStepNumber">
      <div class="alert alert-info text-center mt-3" role="alert">
         Thanks for setting your configuration through all steps!
      </div>

      <mat-dialog-content>
         <div class="container-fluid" *ngIf="!bIsSaveConfiguration">
            <div class="row">
               <div class="col-sm-6 form-group">
                  <mat-form-field class="full-width">
                     <mat-label>File Name</mat-label>
                     <input matInput placeholder="File Name" [(ngModel)]="strConfigFileName" />
                  </mat-form-field>
               </div>
            </div>

            <div class="row">
               <div class="col-sm-6 form-group">
                  <button mat-raised-button class="full-width" color="primary" (click)="downloadParam()">
                     Download Configuration To Local
                  </button>
               </div>

               <div class="col-sm-6 form-group">
                  <button mat-raised-button class="full-width" color="primary" (click)="uploadParamsToCloud()"
                     [disabled]="bIsUploadConfigurationToCloud">
                     Upload Configuration To Cloud
                  </button>
               </div>
            </div>
         </div>

         <div class="alert-area" *ngIf="bIsAlert && !bIsSaveConfiguration">
            <div class="alert alert-success" role="alert" *ngIf="nAlertType === 0">
               <strong>Well done!</strong> File upload is successful.
            </div>

            <div class="alert alert-danger" role="alert" *ngIf="nAlertType === 1">
               <strong>Oh snap!</strong> File upload is failed.
            </div>

            <div class="alert alert-danger" role="alert" *ngIf="nAlertType === 2">You should input the file name.</div>
         </div>

         <div class="load-area" *ngIf="bIsSaveConfiguration">
            <mat-spinner></mat-spinner>
         </div>
      </mat-dialog-content>

      <mat-dialog-actions class="footer">
         <button mat-button (click)="previousStep()">Previous</button>
         <button mat-button (click)="submitParams()">Submit</button>
      </mat-dialog-actions>
   </div>
</div>