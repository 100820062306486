<div class="main-app senosor-area" *ngIf="isPageLoading">
	<div class="sensor-topbar-area">
		<app-sensor-topbar
		[sensorName]="selectedSensor['name']"
		></app-sensor-topbar>
	</div>

	<div class="sensor-main-area">
		<app-sensor-detail
		[selectedSensor]="selectedSensor"
		[sensorKey]="sensorKey"
		></app-sensor-detail>
	</div>
</div>
