import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-key-table',
  templateUrl: './key-table.component.html',
  styleUrls: ['./key-table.component.scss']
})
export class KeyTableComponent implements OnInit, OnChanges {
  dataSource: any;
  @Input() data: Object[];
  columns: string[];

  constructor() {
    this.dataSource = new MatTableDataSource();
    this.columns = ['name', 'id'];
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setTableData();
  }

  /**
   * set table data
   */
  setTableData() {
    this.dataSource.data = this.data;
  }

}
