<div #detailWrapper *ngIf="isTypeLoading" class="sensor-detail-wrapper mx-3">
   <div fxLayout="row wrap" fxLayoutAlign="start center">
      <!--action wrapper-->
      <div class="mb-2 mr-2">
         <button
            (click)="changeStatus(0)"
            [ngClass]="{
               'btn-primary': nActionNumber === 0,
               'btn-default': nActionNumber !== 0
            }"
            class="btn"
            mat-raised-button>
            Status
         </button>
      </div>

      <div class="mb-2 mr-2 relative">
         <button
            (click)="changeStatus(1)"
            [disabled]="bIsMulitpleProcessedLoad"
            [ngClass]="{
               'btn-primary': nActionNumber === 1,
               'btn-default': nActionNumber !== 1
            }"
            class="btn"
            mat-raised-button>
            Analytical Results
         </button>
         <div *ngIf="arrObjNewAnalyticalData.length > 0" class="new-notification">
            New <span class="badge">{{ arrObjNewAnalyticalData.length }}</span>
         </div>
      </div>

      <div class="mb-2 mr-2 relative">
         <button
            (click)="changeStatus(3)"
            [ngClass]="{
               'btn-primary': nActionNumber === 3,
               'btn-default': nActionNumber !== 3
            }"
            class="btn"
            mat-raised-button>
            Processed Data
         </button>
         <div *ngIf="arrObjNewProcessedData.length > 0" class="new-notification">
            New <span class="badge">{{ arrObjNewProcessedData.length }}</span>
         </div>
      </div>

      <div class="mb-2 mr-2 relative">
         <button
            (click)="changeStatus(2)"
            [disabled]="bIsMulitpleProcessedLoad"
            [ngClass]="{
               'btn-primary': nActionNumber === 2,
               'btn-default': nActionNumber !== 2
            }"
            class="btn"
            mat-raised-button>
            Raw Data
         </button>
         <div *ngIf="arrObjNewRawData.length > 0" class="new-notification">
            New <span class="badge">{{ arrObjNewRawData.length }}</span>
         </div>
      </div>

      <div *ngIf="strUserType === arrStrUserTypes[0]" class="mb-2">
         <button
            (click)="changeStatus(4)"
            [ngClass]="{
               'btn-primary': nActionNumber === 4,
               'btn-default': nActionNumber !== 4
            }"
            class="btn"
            mat-raised-button>
            Debug
         </button>
      </div>
   </div>
   <!--action wrapper-->

   <mat-card appearance="outlined" *ngIf="isEditStatus && nActionNumber === 0">
      <mat-card-title>Control</mat-card-title>
      <mat-card-subtitle>
         <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div class="mr-2">Status:</div>
            <div
               [ngClass]="{
                  on: isOnlineDevice,
                  off: !isOnlineDevice,
                  'status-circle': true
               }"></div>
            <div *ngIf="selectedSensor['fastMode']; else normalModeTemplate">Fast Mode is enabled.</div>
            <ng-template #normalModeTemplate> Normal Mode is enabled. </ng-template>
         </div>
      </mat-card-subtitle>
      <mat-card-content class="content">
         <div *ngIf="!isGetConfig" class="action row">
            <div class="col-sm-12 flex-wrapper">
               <mat-spinner></mat-spinner>
            </div>
         </div>

         <div *ngIf="isGetConfig" class="action" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxLayout="row wrap">
               <div class="mb-2 mr-3">
                  <button
                     (click)="controlSensorDevice(2)"
                     [disabled]="
                        !isOnlineDevice ||
                        [1, 2, 7, 8, 11, 12].indexOf(nActionStatus) >= 0 ||
                        (configData && !configData['Current_Type'])
                     "
                     class="btn btn-icon btn-success btn-circle"
                     mat-mini-fab>
                     <mat-icon>play_arrow</mat-icon>
                  </button>
               </div>
               <div class="mb-2">
                  <button
                     (click)="controlSensorDevice(3)"
                     [disabled]="
                        !isOnlineDevice ||
                        [1, 3, 7, 8, 11].indexOf(nActionStatus) >= 0 ||
                        (configData && !configData['Current_Type'])
                     "
                     class="btn btn-icon btn-danger btn-circle"
                     mat-mini-fab>
                     <mat-icon>stop</mat-icon>
                  </button>
               </div>
            </div>

            <div class="sensor-control-container" fxLayout="column wrap">
               <div class="mb-2">
                  <button
                     (click)="controlSensorDevice(1)"
                     [disabled]="!isOnlineDevice || [2, 7, 8, 12].indexOf(nActionStatus) >= 0"
                     class="btn btn-info"
                     mat-raised-button>
                     <mat-icon>settings</mat-icon>
                     <div>Configure</div>
                  </button>
               </div>
               <div class="mb-2">
                  <button
                     [disabled]="[2, 7, 8, 12].indexOf(nActionStatus) >= 0"
                     (click)="setNotification()"
                     class="btn btn-info"
                     mat-raised-button>
                     <mat-icon>notifications_active</mat-icon>
                     <div>Alerts</div>
                  </button>
               </div>
               <div class="mb-2">
                  <button
                     (click)="controlSensorDevice(-1)"
                     [disabled]="!configData || (configData && !configData['Current_Type'])"
                     class="btn btn-info"
                     mat-raised-button>
                     <mat-icon>personal_video</mat-icon>
                     <div fxLayout="column wrap" fxLayoutAlign="center center">
                        <div class="multi-line">Configure</div>
                        <div class="multi-line">View</div>
                     </div>
                  </button>
               </div>
               <div class="mb-2">
                  <button
                     (click)="controlSensorDevice(4)"
                     [disabled]="
                        nActionStatus === 4 ||
                        !isOnlineDevice ||
                        nActionStatus === 1 ||
                        (configData && !configData['Current_Type'])
                     "
                     class="btn btn-success"
                     mat-raised-button>
                     <mat-icon>refresh</mat-icon>
                     <div>Reboot</div>
                  </button>
               </div>
               <div class="mb-2" *ngIf="isStuff()">
                  <button
                     style="font-size: 15px"
                     mat-raised-button
                     class="btn mat-raised-button btn-warning w-100 text-white"
                     (click)="calibrateSensor()"
                     [disabled]="!isOnlineDevice || [2, 7, 8, 12].indexOf(nActionStatus) >= 0">
                     <mat-icon svgIcon="calibration" class="calibrate-icon"></mat-icon>
                     <div>Calibrate</div>
                  </button>
               </div>
               <div *ngIf="strUserType === arrStrUserTypes[0]" class="mb-2">
                  <button
                     (click)="controlSensorDevice(6)"
                     [disabled]="
                        nActionStatus === 6 ||
                        !isOnlineDevice ||
                        nActionStatus === 1 ||
                        (configData && !configData['Current_Type'])
                     "
                     class="btn btn-danger"
                     mat-raised-button>
                     <mat-icon>power_settings_new</mat-icon>
                     <div>Shut Down</div>
                  </button>
               </div>
            </div>
         </div>
      </mat-card-content>
   </mat-card>

   <mat-card appearance="outlined" *ngIf="nActionNumber === 4">
      <mat-card-title>Control</mat-card-title>

      <mat-card-content>
         <div class="row">
            <div class="col-sm-6">
               <button
                  (click)="debugTask()"
                  [disabled]="nActionStatus === 2 || !isOnlineDevice"
                  class="btn btn-primary full-width"
                  mat-raised-button>
                  DEBUG
               </button>
            </div>
         </div>
      </mat-card-content>
   </mat-card>

   <mat-card appearance="outlined" *ngIf="nActionNumber === 4">
      <mat-card-title>Debug Files</mat-card-title>

      <mat-card-content class="overflow-x content">
         <div *ngIf="!bIsDebugData" class="flex-wrapper">
            <mat-spinner></mat-spinner>
         </div>

         <div *ngIf="bIsDebugData" class="static-wrapper">
            <table #debugTable [dataSource]="debugDataSource" class="omni-table full-width" mat-table>
               <!-- Position Column -->
               <ng-container matColumnDef="position">
                  <th *matHeaderCellDef mat-header-cell>
                     <div class="omni-table-header">No.</div>
                  </th>
                  <td *matCellDef="let element" mat-cell>
                     <div class="omni-col-wrap">
                        {{ element['position'] }}
                     </div>
                  </td>
               </ng-container>

               <!-- Name Column -->
               <ng-container matColumnDef="name">
                  <th *matHeaderCellDef mat-header-cell>
                     <div class="omni-table-header">Name</div>
                  </th>
                  <td *matCellDef="let element" mat-cell>
                     <div class="omni-col-wrap">
                        <div class="omni-col-item">
                           <div [matTooltip]="element['name']">
                              {{ element['name'] }}
                           </div>

                           <div
                              *ngIf="element['url']"
                              [matTooltip]="'Download'"
                              class="pointer ml-2"
                              fxLayout
                              fxLayoutAlign="center center">
                              <mat-icon (click)="onDownloadFile(element['url'], element['name'])" color="primary"
                                 >cloud_download
                              </mat-icon>
                           </div>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Upload Time Column -->
               <ng-container matColumnDef="date">
                  <th *matHeaderCellDef mat-header-cell>
                     <div class="omni-table-header">Upload Time</div>
                  </th>
                  <td *matCellDef="let element" mat-cell>
                     <div class="omni-col-wrap">
                        <div class="omni-col-item">
                           {{ element['date'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Size Column -->
               <ng-container matColumnDef="size">
                  <th *matHeaderCellDef mat-header-cell>
                     <div class="omni-table-header">Size</div>
                  </th>
                  <td *matCellDef="let element" mat-cell>
                     <div class="omni-col-wrap">
                        <div class="omni-col-item">
                           {{ element['size'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <tr *matHeaderRowDef="arrStrDebugDisplayedColumns" mat-header-row></tr>
               <tr *matRowDef="let row; columns: arrStrDebugDisplayedColumns" mat-row></tr>
            </table>
         </div>
      </mat-card-content>
   </mat-card>

   <mat-card appearance="outlined">
      <mat-card-title>{{ progressText() }}</mat-card-title>
      <mat-card-content *ngIf="bIsProgressBar" class="overflow-x content">
         <div *ngIf="!isOnlineDevice" class="static-wrapper text-center">
            <div class="alert alert-danger">Network Disconnected</div>
         </div>
         <div *ngIf="isOnlineDevice" class="static-wrapper">
            <div class="alert alert-danger text-center" *ngIf="nActionStatus === 11">
               {{ actionStatusText }}
            </div>
            <div *ngIf="nActionStatus === 7">
               <div *ngFor="let errorMsg of runProgress['errorMsgs']" class="alert alert-danger text-center">
                  {{ errorMsg }}
               </div>

               <div class="mb-3" fxLayoutAlign="center center">
                  <button (click)="onClearError()" color="warn" mat-raised-button>Clear the error</button>
               </div>
            </div>

            <div *ngIf="nActionStatus === 8" class="alert alert-info text-center">Please wait for initialization</div>

            <div *ngIf="nActionStatus === 9" class="alert alert-info text-center">Ready to run</div>

            <div *ngIf="bIsProgressDes && !bIsDoneProgress" class="progress-description-area">
               <div>
                  Current run: Run {{ runProgress['runNumber'] }} of {{ runProgress['runTotalNumber'] }},
                  {{ runProgress['runProgressPercent'] }}% completed, {{ runProgress['runRemainTime'] }}s /
                  {{ runProgress['runTotalTime'] }}s remaining for this run
               </div>

               <div>
                  Current step: Step {{ runProgress['stepNumber'] }}, {{ runProgress['stepProgressPercent'] }}%
                  completed, {{ runProgress['stepRemainTime'] }}s / {{ runProgress['stepTotalTime'] }}s remaining for
                  this step
               </div>

               <div *ngIf="runProgress && runProgress['startRunTimestamp']">
                  Started: {{ runProgress['startRunTimestamp'] }}, projected finish:
                  {{ runProgress['endRunTimestamp'] }} by {{ runProgress['timezone'] }}
               </div>
            </div>

            <div
               class="progress-bar-area"
               *ngIf="isEditStatus && configData[configData['Current_Type']]['Num_of_Cycle'] <= 100">
               <div
                  *ngFor="let runItem of arrRunProgress"
                  [ngClass]="{
                     active: runItem === 2 || (runItem !== 3 && bIsDoneProgress),
                     progress: runItem === 1 && !bIsDoneProgress,
                     'progress-done-item': runItem !== 3 && bIsDoneProgress,
                     error: runItem === 3
                  }"
                  class="progress-item">
                  <div class="main-frame">
                     <div class="circle-item"></div>
                     <div class="bar-item"></div>
                  </div>
                  <div [style.width]="runProgress['runProgressPercent'] + '%'" class="belt-frame"></div>
               </div>

               <div
                  [ngClass]="{
                     active: runProgress['errorRunNumber'] <= 0 && bIsDoneProgress
                  }"
                  class="progress-item end-progress-item">
                  <div class="main-frame">
                     <div class="circle-item"></div>
                  </div>
               </div>
            </div>

            <div *ngIf="isEditStatus" class="step-progress-area">
               <div class="step-label-area label-area">
                  <div *ngFor="let runItem of arrStepProgress; let i = index" class="label-item flex1">
                     Step{{ runItem['index'] }}:
                  </div>
                  <div class="empty-label"></div>
               </div>

               <div class="category-label-area label-area">
                  <div *ngFor="let runItem of arrStepProgress" class="label-item flex1">
                     {{ runItem['categoryName'] }}
                  </div>
                  <div class="empty-label"></div>
               </div>

               <div class="start-label-area label-area">
                  <div class="label-item flex1">Start</div>
                  <div class="label-item flex1 text-right">End</div>
               </div>

               <div class="progress-bar-area">
                  <div
                     *ngFor="let runItem of arrStepProgress"
                     [ngClass]="{
                        active: runItem['status'] === 2 || bIsDoneProgress,
                        progress: runItem['status'] === 1 && !bIsDoneProgress,
                        'progress-done-item': bIsDoneProgress
                     }"
                     class="progress-item">
                     <div class="main-frame">
                        <div class="circle-item"></div>
                        <div class="bar-item"></div>
                     </div>
                     <div [style.width]="runProgress['stepProgressPercent'] + '%'" class="belt-frame"></div>
                  </div>

                  <div [ngClass]="{ active: bIsDoneProgress }" class="progress-item end-progress-item">
                     <div class="main-frame">
                        <div class="circle-item"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </mat-card-content>
   </mat-card>

   <mat-card appearance="outlined" *ngIf="nActionNumber === 0 && strAmbientTotalVOC !== 'N/A' && isOnlineDevice">
      <mat-card-title>Live TVOC Sensor</mat-card-title>
      <mat-card-content class="overflow-x content">
         <div *ngIf="bIsTVOCStatus" class="alert alert-warning text-center">
            Data use may exceed monthly limit of 250MB and additional charges may incur by using TVOC Live mode on LTE
            connection.
         </div>
         <div class="static-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div class="tvoc-control p-3" fxFlex="320px" fxLayout="column" fxLayoutAlign="space-around stretch">
               <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <mat-slide-toggle (change)="onTVOCChange($event)" [checked]="bIsTVOCStatus" color="primary">
                  </mat-slide-toggle>

                  <mat-form-field fxFlex="0 1 100px">
                     <mat-label>Reporting Interval</mat-label>
                     <mat-select
                        [(value)]="nReportingInterval"
                        [disabled]="bIsTVOCStatus"
                        placeholder="Reporting Interval">
                        <mat-option [value]="10">10s</mat-option>
                        <mat-option [value]="30">30s</mat-option>
                        <mat-option [value]="60">60s</mat-option>
                     </mat-select>
                  </mat-form-field>

                  <mat-form-field fxFlex="0 1 100px">
                     <mat-label>Gas Library</mat-label>
                     <mat-select
                        [(value)]="strGasLibrary"
                        [disabled]="bIsTVOCStatus"
                        panelClass="gas-library"
                        placeholder="Gas Library">
                        <mat-option [value]="'Isobutylene'"
                           >Isobutylene&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Response Factor=1
                        </mat-option>
                        <mat-option [value]="'B/T/X'"
                           >Benzene/Toluene/Xylenes(B/T/X)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Response Factor=0.5
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </div>
            </div>

            <div fxFlex="calc( 100% - 320px )">
               <div #tvocEle *ngIf="bIsTVOCStatus; else tvocParameterEle">
                  <app-plotly
                     *ngIf="bIsTVOC; else tvocWaitingEle"
                     [data]="tvocData"
                     [layout]="tvocLayout"
                     [options]="{}"
                     [style]="tvocStyle"></app-plotly>
                  <ng-template #tvocWaitingEle>
                     <div class="load-area tvoc">
                        <mat-spinner></mat-spinner>
                     </div>
                  </ng-template>
               </div>
               <ng-template #tvocParameterEle>
                  <div class="ambient-tvoc-container" fxLayoutAlign="center center">
                     <div class="wrapper" fxLayout fxLayoutAlign="center center">
                        <div class="item">Instantaneous Ambient Total VOC</div>
                        <div class="item" fxLayout fxLayoutAlign="center center">
                           <div>
                              {{ strAmbientTotalVOC }}
                           </div>
                           <div class="iso-equ-text">(Isobutylene equivalent)</div>
                        </div>
                     </div>
                  </div>
               </ng-template>
            </div>
         </div>
      </mat-card-content>
   </mat-card>

   <mat-card
      appearance="outlined"
      *ngIf="
         isEditStatus &&
         nActionNumber === 2 &&
         ((strUserType === arrStrUserTypes[0] &&
            (strUserRole === arrStrUserRoles[0] ||
               strUserRole === arrStrUserRoles[1] ||
               strUserRole === arrStrUserRoles[4])) ||
            (strUserType === arrStrUserTypes[1] &&
               (strUserRole === arrStrUserRoles[0] || strUserRole === arrStrUserRoles[2])))
      ">
      <mat-card-title>
         <div class="data-title-wrapper">
            <div class="main-title inline">Raw Data</div>
            <div
               *ngIf="arrObjRawFiles.length > 0 && strRawStartTime === arrObjRawFiles[0]['startTimestamp']"
               class="status-title inline">
               Latest Data
            </div>
            <div
               *ngIf="arrObjRawFiles.length > 0 && strRawStartTime !== arrObjRawFiles[0]['startTimestamp']"
               class="status-title blue-text inline">
               Earlier Data
            </div>
         </div>
      </mat-card-title>

      <mat-card-subtitle *ngIf="strSelectedRawKey">
         Measurement Start Time: {{ strRawStartTime ? strRawStartTime : 'N/A' }}
      </mat-card-subtitle>

      <mat-card-subtitle *ngIf="strSelectedRawKey">
         Data Upload Time: {{ strLCDChartDate ? strLCDChartDate : 'N/A' }}
      </mat-card-subtitle>

      <mat-card-content class="overflow-x content">
         <div
            *ngIf="bIsStartCycleLoad && !strSelectedRawKey; else availableRawEle"
            class="alert alert-info full-width text-center">
            <div>No matching Raw Data file found.</div>
            <div>Please select one in the table below, or switch back to the previous tab and select another file.</div>
         </div>

         <!--      TODO:-->
         <ng-template #availableRawEle>
            <div #chartBodyElement class="static-wrapper">
               <div *ngIf="bIsLoadRawData; else alertRawDataEle">
                  <div
                     *ngIf="!rawData || (rawData && rawData.length === 0); else rawDataChartEle"
                     class="alert alert-info full-width text-center">
                     Unknown file format or empty file.
                  </div>

                  <ng-template #rawDataChartEle>
                     <mat-tab-group (selectedIndexChange)="onSelectTab($event)" [selectedIndex]="nSelectedChartInd">
                        <mat-tab label="Capacitance"
                           ><!-- CDC start -->
                           <div #cdcTabElement class="chart-wrapper">
                              <app-plotly
                                 *ngIf="isCdcCalled"
                                 [data]="plotlyCDCData"
                                 [layout]="plotlyCDCLayout"
                                 [options]="{}"
                                 [style]="plotlyCDCStyle"></app-plotly>
                           </div> </mat-tab
                        ><!-- CDC start -->

                        <mat-tab [disabled]="bIsMulRawDataLoad" label="Temperature"
                           ><!-- ADC start -->
                           <div #adcTabElement class="chart-wrapper">
                              <app-plotly
                                 *ngIf="isAdcCalled"
                                 [data]="plotlyADCData"
                                 [layout]="plotlyADCLayout"
                                 [options]="{}"
                                 [style]="plotlyADCStyle"></app-plotly>
                           </div> </mat-tab
                        ><!-- ADC end -->

                        <mat-tab [disabled]="bIsMulRawDataLoad" label="Flow Rate"
                           ><!-- Flow start -->
                           <div #flowTabElement class="chart-wrapper">
                              <app-plotly
                                 *ngIf="isFlowCalled"
                                 [data]="plotlyFlowData"
                                 [layout]="plotlyFlowLayout"
                                 [options]="{}"
                                 [style]="plotlyFlowStyle"></app-plotly>
                           </div> </mat-tab
                        ><!-- Flow start -->
                     </mat-tab-group>
                  </ng-template>
               </div>

               <div *ngIf="bIsMulRawDataLoad && !isCdcCalled && bIsMulRawDataLoad">
                  <div class="load-area">
                     <mat-spinner></mat-spinner>
                  </div>
               </div>

               <app-raw-table *ngIf="bIsMulRawDataLoad && isCdcCalled" [arrMulCdcInfo]="arrMulCdcInfo"></app-raw-table>

               <ng-template #alertRawDataEle>
                  <div class="load-area">
                     <mat-spinner></mat-spinner>
                  </div>

                  <div *ngIf="bIsGetAllRawData" class="alert alert-info full-width text-center">
                     Parsing raw data file...
                  </div>
               </ng-template>
            </div>
         </ng-template>
      </mat-card-content>
   </mat-card>

   <mat-card
      appearance="outlined"
      *ngIf="
         isEditStatus &&
         nActionNumber === 2 &&
         ((strUserType === arrStrUserTypes[0] &&
            (strUserRole === arrStrUserRoles[0] ||
               strUserRole === arrStrUserRoles[1] ||
               strUserRole === arrStrUserRoles[2] ||
               strUserRole === arrStrUserRoles[4])) ||
            (strUserType === arrStrUserTypes[1] &&
               (strUserRole === arrStrUserRoles[0] || strUserRole === arrStrUserRoles[2])))
      ">
      <div fxLayout fxLayoutAlign="space-between">
         <mat-card-title>Raw Data Files</mat-card-title>
         <div fxLayout fxLayoutAlign="space-between">
            <mat-form-field class="mr-4">
               <mat-label>Search in all fields</mat-label>
               <input
                  (keyup)="globalFilterStr$.next($event.target?.value)"
                  [value]="globalFilter"
                  matInput
                  placeholder="Search in all fields"
                  type="text" />
            </mat-form-field>

            <mat-form-field class="mr-4">
               <mat-label>Start date (measurement)</mat-label>
               <input
                  (ngModelChange)="startDateChange($event)"
                  [(ngModel)]="fromDate"
                  [matDatepicker]="picker1"
                  matInput
                  placeholder="mm/dd/yyyy" />
               <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
               <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="mr-4">
               <mat-label>End date (measurement)</mat-label>
               <input
                  (ngModelChange)="endDateChange($event)"
                  [(ngModel)]="toDate"
                  [matDatepicker]="picker2"
                  matInput
                  placeholder="mm/dd/yyyy" />
               <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
               <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <button (click)="clearFilter(rawDataSource)" class="btn btn-default clear-btn" mat-button mat-raised-button>
               Show all Files
            </button>
         </div>
      </div>

      <div>
         <mat-form-field class="sensor-type-handler full-width">
            <mat-label>Bulk Action</mat-label>
            <mat-select (selectionChange)="onSelectBulk($event, 'rawData')" [(value)]="strRawDataFilterStatus">
               <mat-option value="select"> Select Action </mat-option>
               <mat-option value="load"> Load Selected </mat-option>
               <mat-option value="download"> Download Selected Files </mat-option>
               <mat-option value="remove"> Remove Selected Files </mat-option>
            </mat-select>
         </mat-form-field>
      </div>

      <mat-card-content class="overflow-x content">
         <div *ngIf="!bIsGetAllRawData" class="load-area">
            <mat-spinner></mat-spinner>
         </div>

         <div *ngIf="bIsGetAllRawData" class="static-wrapper">
            <table #rawTable [dataSource]="rawDataSource" class="omni-table full-width" mat-table>
               <ng-container matColumnDef="select">
                  <th *matHeaderCellDef fxFlex="64px" mat-header-cell>
                     <div class="omni-table-header">Select</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="64px" mat-cell>
                     <div class="omni-col-wrap">
                        <mat-checkbox
                           [disabled]="!rawDataItemsSelected[element['cycleIndex']] && isRawCheckSelected"
                           (change)="onChangeRawDataSelect($event.checked, element['cycleIndex'])"
                           [checked]="rawDataItemsSelected[element['cycleIndex']]"></mat-checkbox>
                     </div>
                  </td>
               </ng-container>

               <!-- Weather Wind Column -->
               <ng-container matColumnDef="weather_wind">
                  <th *matHeaderCellDef fxFlex="60px" mat-header-cell>
                     <div class="omni-table-header">Wind</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="60px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           <ng-container
                              *ngIf="
                                 (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg ||
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg === 0;
                                 else noWeatherConfig
                              ">
                              {{ (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg }}
                           </ng-container>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <ng-container matColumnDef="wind_direction">
                  <th *matHeaderCellDef fxFlex="80px" mat-header-cell>
                     <div class="omni-table-header">Direction</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="80px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           <ng-container
                              *ngIf="
                                 (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg ||
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg === 0;
                                 else noWeatherWind
                              ">
                              <ng-container
                                 *ngIf="
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather &&
                                       (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg !==
                                          0;
                                    else emptyDirection
                                 ">
                                 {{
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_direction
                                 }}
                                 <span>&deg;&nbsp;</span>
                                 {{
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_direction
                                       | windDirection
                                 }}
                              </ng-container>
                           </ng-container>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Position Column -->
               <ng-container matColumnDef="position">
                  <th *matHeaderCellDef fxFlex="64px" mat-header-cell>
                     <div class="omni-table-header">No.</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="64px" mat-cell>
                     <div class="omni-col-wrap">
                        {{ element['position'] + 1 }}
                     </div>
                  </td>
               </ng-container>

               <!-- Measurement Start Time Column -->
               <ng-container matColumnDef="startTimestamp">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">Measurement Start Time</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div class="omni-col-item">
                           {{ element['startTimestamp'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Step Category Column -->
               <ng-container matColumnDef="type">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">Step Category</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           {{ data['type'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Name Column -->
               <ng-container matColumnDef="name">
                  <th *matHeaderCellDef fxFlex="250px" mat-header-cell>
                     <div class="omni-table-header">Name</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="250px" mat-cell>
                     <div class="omni-col-wrap">
                        <div
                           *ngFor="let data of element['data']"
                           [matTooltip]="data['description']"
                           class="omni-col-item">
                           <div class="ellipsis">
                              {{ data['description'] }}
                           </div>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Upload Time Column -->
               <ng-container matColumnDef="date">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">Upload Time</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           {{ data['date'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Size Column -->
               <ng-container matColumnDef="size">
                  <th *matHeaderCellDef fxFlex="100px" mat-header-cell>
                     <div class="omni-table-header">Size</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="100px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           {{ data['size'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Actions Column -->
               <ng-container matColumnDef="actions">
                  <th *matHeaderCellDef fxFlex="170px" mat-header-cell>
                     <div class="omni-table-header">Actions</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="250px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           <div class="pointer edit-item mr-3" matTooltip="Edit file name">
                              <mat-icon (click)="onEditRawDataFile(data)">edit</mat-icon>
                           </div>

                           <div
                              *ngIf="
                                 bIsLoadRawData || (!bIsLoadRawData && strSelectedRawKey !== data['key']);
                                 else waitLoadButton
                              "
                              class="pointer"
                              matTooltip="Load">
                              <mat-icon
                                 (click)="onLoadOneCycleData(data, 2, element['data']['position'])"
                                 color="primary">
                                 play_arrow
                              </mat-icon>
                           </div>
                           <ng-template #waitLoadButton>
                              <mat-spinner class="small-spinner" color="primary"></mat-spinner>
                           </ng-template>

                           <div class="pointer mx-3" matTooltip="Delete">
                              <mat-icon (click)="onDeleteDataFile(data, 'raw')" color="warn">delete_outline </mat-icon>
                           </div>

                           <div class="pointer mr-3" matTooltip="Download">
                              <mat-icon (click)="onDownloadFile(data['url'], data['description'])" color="primary">
                                 cloud_download
                              </mat-icon>
                           </div>

                           <div class="pointer" matTooltip="Full Data">
                              <img
                                 width="28px"
                                 height="28px"
                                 (click)="getFullData(element)"
                                 src="https://img.icons8.com/color/50/000000/ms-excel.png" />
                           </div>

                           <div matTooltip="File is missing in other tabs" class="pointer ml-3">
                              <mat-icon
                                 style="color: #8e8e00"
                                 *ngIf="rawDataItemsInvalid[element['cycleIndex']]"
                                 color="accent"
                                 class="mat-icon"
                                 >warning
                              </mat-icon>
                           </div>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Comment Column -->
               <ng-container matColumnDef="comment">
                  <th *matHeaderCellDef fxFlex mat-header-cell>
                     <div class="omni-table-header">Comment</div>
                  </th>
                  <td *matCellDef="let element" fxFlex mat-cell>
                     <div class="omni-col-wrap no-border">
                        <div *ngFor="let data of element['data']" class="omni-col-item relative">
                           <app-comment
                              (updateComment)="onUpdateRawComment($event)"
                              [key]="data['key']"
                              [strComment]="data['comment']"></app-comment>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <tr *matHeaderRowDef="arrStrDisplayedColumns" class="no-padding" mat-header-row></tr>
               <tr
                  *matRowDef="let row; columns: arrStrDisplayedColumns"
                  [ngClass]="{
                     'light-green-bg': strSelectedRawCycle === row.cycleIndex
                  }"
                  class="no-padding"
                  mat-row></tr>
            </table>

            <mat-paginator
               #rawPaginator
               (page)="onRawDataPaginateChange($event)"
               [pageIndex]="nRawDataPageIndex"
               [pageSizeOptions]="[5, 50, 200]"
               [pageSize]="nRawDataPageSize">
            </mat-paginator>
         </div>
      </mat-card-content>
   </mat-card>

   <mat-card
      appearance="outlined"
      *ngIf="
         isEditStatus &&
         nActionNumber === 3 &&
         ((strUserType === arrStrUserTypes[0] &&
            (strUserRole === arrStrUserRoles[0] ||
               strUserRole === arrStrUserRoles[1] ||
               strUserRole === arrStrUserRoles[2] ||
               strUserRole === arrStrUserRoles[4])) ||
            (strUserType === arrStrUserTypes[1] &&
               (strUserRole === arrStrUserRoles[0] || strUserRole === arrStrUserRoles[2])))
      ">
      <mat-card-title>
         <div class="data-title-wrapper">
            <div class="main-title inline">Processed Data</div>
            <div
               *ngIf="
                  !bIsMulitpleProcessedLoad &&
                  arrObjProcessedFiles[0] &&
                  strSelectedProcessKey === arrObjProcessedFiles[0]['key']
               "
               class="status-title inline">
               Latest Data
            </div>
            <div
               *ngIf="
                  !bIsMulitpleProcessedLoad &&
                  arrObjProcessedFiles[0] &&
                  strSelectedProcessKey !== arrObjProcessedFiles[0]['key']
               "
               class="status-title blue-text inline">
               Earlier Data
            </div>
         </div>
      </mat-card-title>

      <mat-card-subtitle *ngIf="strSelectedProcessKey">
         <div *ngIf="!bIsMulitpleProcessedLoad; else multipleProcessedLabel">
            Measurement Start Time:
            {{ strProcessStartTime ? strProcessStartTime : 'N/A' }}
         </div>

         <ng-template #multipleProcessedLabel> Multiple files selected, see the legend below the graph </ng-template>
      </mat-card-subtitle>

      <mat-card-content class="overflow-x content">
         <div
            *ngIf="bIsStartCycleLoad && !strSelectedProcessKey; else availableProcessedDataEle"
            class="alert alert-info full-width text-center">
            <div>No matching Processed Data file found.</div>
            <div>Please select one in the table below, or switch back to the previous tab and select another file.</div>
         </div>

         <ng-template #availableProcessedDataEle>
            <div *ngIf="bIsOverMultiProcessedDataSelection" class="alert alert-warning text-center my-2">
               Selection of multiple files is limited to 20 or fewer files.
            </div>

            <div *ngIf="!bIsOverMultiProcessedDataSelection" class="full-width chromatogram-area static-wrapper">
               <mat-card-subtitle *ngIf="!bIsMulitpleProcessedLoad">
                  Chromatogram(Data Upload Time:
                  {{ strChromatogramTime ? strChromatogramTime : 'N/A' }})
               </mat-card-subtitle>

               <div *ngIf="bIsChromatogram; else alertChromatgramEle" class="wrapper">
                  <div *ngIf="bIsProcessedChromatogramLoad; else errorChromatogramEle" class="full-width display-area">
                     <app-plotly
                        [data]="arrPlotyChromatogramData"
                        [layout]="plotlyProcessLayout"
                        [options]="{}"
                        [style]="plotlyProcessStyle"></app-plotly>
                  </div>

                  <ng-template #errorChromatogramEle>
                     <div class="load-area">
                        <mat-spinner></mat-spinner>
                     </div>
                  </ng-template>
               </div>

               <ng-template #alertChromatgramEle>
                  <div class="load-area">
                     <mat-spinner></mat-spinner>
                  </div>

                  <div *ngIf="bIsProcessedData" class="full-width text-center">Parsing chromatogram file ...</div>
               </ng-template>
            </div>

            <div *ngIf="!bIsOverMultiProcessedDataSelection">
               <div *ngIf="!bIsMulitpleProcessedLoad; else mulPeakEle" class="peak-area static-wrapper">
                  <mat-card-subtitle>
                     Detected Peaks(Data Upload Time:
                     {{ strPeakTime ? strPeakTime : 'N/A' }})
                  </mat-card-subtitle>

                  <div *ngIf="bIsPeakLoad; else alertPeakLoadEle" class="wrapper">
                     <div *ngIf="arrStrPeakHeaders.length; else errorPeakEle" class="full-width table-responsive">
                        <table class="table table-bordered">
                           <thead>
                              <tr>
                                 <th *ngFor="let head of arrStrPeakHeaders">{{ head }}</th>
                              </tr>
                           </thead>

                           <tbody>
                              <tr *ngFor="let row of arrStrPeakValues">
                                 <td *ngFor="let item of row">
                                    {{ item }}
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>

                     <ng-template #errorPeakEle>
                        <div class="alert alert-info full-width text-center">Unknown format or empty file.</div>
                     </ng-template>
                  </div>

                  <ng-template #alertPeakLoadEle>
                     <div class="load-area">
                        <mat-spinner></mat-spinner>
                     </div>

                     <div *ngIf="bIsProcessedData" class="full-width text-center">Parsing peaks file ...</div>
                  </ng-template>
               </div>

               <ng-template #mulPeakEle>
                  <app-peak-table
                     *ngIf="bIsMulProcessedPeakmLoad; else loadMulPeakEle"
                     [peaks]="arrMulPeakData"></app-peak-table>

                  <ng-template #loadMulPeakEle>
                     <div class="load-area">
                        <mat-spinner></mat-spinner>
                     </div>
                  </ng-template>
               </ng-template>
            </div>
         </ng-template>
      </mat-card-content>
   </mat-card>

   <mat-card
      appearance="outlined"
      *ngIf="
         isEditStatus &&
         nActionNumber === 3 &&
         ((strUserType === arrStrUserTypes[0] &&
            (strUserRole === arrStrUserRoles[0] ||
               strUserRole === arrStrUserRoles[1] ||
               strUserRole === arrStrUserRoles[2] ||
               strUserRole === arrStrUserRoles[4])) ||
            (strUserType === arrStrUserTypes[1] &&
               (strUserRole === arrStrUserRoles[0] || strUserRole === arrStrUserRoles[2])))
      ">
      <div fxLayout fxLayoutAlign="space-between">
         <mat-card-title>Processed Data Files</mat-card-title>
         <div fxLayout>
            <mat-form-field class="mr-4">
               <mat-label>Search in all fields</mat-label>
               <input
                  (keyup)="globalFilterStr$.next($event.target?.value)"
                  [value]="globalFilter"
                  matInput
                  placeholder="Search in all fields"
                  type="text" />
            </mat-form-field>

            <mat-form-field class="mr-4">
               <mat-label>Start date (measurement)</mat-label>
               <input
                  (ngModelChange)="startDateChange($event)"
                  [(ngModel)]="fromDate"
                  [matDatepicker]="picker1"
                  matInput
                  placeholder="mm/dd/yyyy" />
               <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
               <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="mr-4">
               <mat-label>End date (measurement)</mat-label>
               <input
                  (ngModelChange)="endDateChange($event)"
                  [(ngModel)]="toDate"
                  [matDatepicker]="picker2"
                  matInput
                  placeholder="mm/dd/yyyy" />
               <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
               <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <button
               (click)="clearFilter(processedDataSource)"
               class="btn btn-default clear-btn"
               mat-button
               mat-raised-button>
               Show all Files
            </button>
         </div>
      </div>
      <mat-card-content class="overflow-x content">
         <div *ngIf="!bIsProcessedData" class="flex-wrapper">
            <mat-spinner></mat-spinner>
         </div>

         <div>
            <mat-form-field class="sensor-type-handler full-width">
               <mat-label>Bulk Action</mat-label>
               <mat-select
                  (selectionChange)="onSelectBulk($event, 'processData')"
                  [(value)]="strProcessedDataFilterStatus">
                  <mat-option value="select"> Select Action </mat-option>
                  <mat-option value="load"> Load Selected </mat-option>
                  <mat-option value="download"> Download Selected Files </mat-option>
                  <mat-option value="remove"> Remove Selected Files </mat-option>
               </mat-select>
            </mat-form-field>
         </div>

         <div *ngIf="bIsProcessedData" class="static-wrapper">
            <table #processedTable [dataSource]="processedDataSource" class="omni-table full-width" mat-table>
               <!-- Select Column -->
               <ng-container matColumnDef="select">
                  <th *matHeaderCellDef fxFlex="64px" mat-header-cell>
                     <div class="omni-table-header">Select</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="64px" mat-cell>
                     <div class="omni-col-wrap">
                        <mat-checkbox
                           [disabled]="isProcessCheckSelected && !processedDataItemsSelected[element['cycleIndex']]"
                           (change)="onChangeProcessedDataSelect($event.checked, element['cycleIndex'])"
                           [checked]="processedDataItemsSelected[element['cycleIndex']]"></mat-checkbox>
                     </div>
                  </td>
               </ng-container>

               <!-- Position Column -->
               <ng-container matColumnDef="position">
                  <th *matHeaderCellDef fxFlex="64px" mat-header-cell>
                     <div class="omni-table-header">No.</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="64px" mat-cell>
                     <div class="omni-col-wrap">
                        {{ element['position'] + 1 }}
                     </div>
                  </td>
               </ng-container>

               <!-- Measurement Start Time Column -->
               <ng-container matColumnDef="startTimestamp">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">Measurement Start Time</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div class="omni-col-item">
                           {{ element['startTimestamp'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Weather Wind Column -->
               <ng-container matColumnDef="weather_wind">
                  <th *matHeaderCellDef fxFlex="60px" mat-header-cell>
                     <div class="omni-table-header">Wind</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="60px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           <ng-container
                              *ngIf="
                                 (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg ||
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg === 0;
                                 else noWeatherConfig
                              ">
                              {{ (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg }}
                           </ng-container>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <ng-container matColumnDef="wind_direction">
                  <th *matHeaderCellDef fxFlex="80px" mat-header-cell>
                     <div class="omni-table-header">Direction</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="80px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           <ng-container
                              *ngIf="
                                 (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg ||
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg === 0;
                                 else noWeatherWind
                              ">
                              <ng-container
                                 *ngIf="
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather &&
                                       (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg !==
                                          0;
                                    else emptyDirection
                                 ">
                                 {{
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_direction
                                 }}
                                 <span>&deg;&nbsp;</span>
                                 {{
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_direction
                                       | windDirection
                                 }}
                              </ng-container>
                           </ng-container>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Step Category Column -->
               <ng-container matColumnDef="type">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">File Category</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           {{ data['type'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Name Column -->
               <ng-container matColumnDef="name">
                  <th *matHeaderCellDef fxFlex="250px" mat-header-cell>
                     <div class="omni-table-header">Name</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="250px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           <div [matTooltip]="data['description']" class="ellipsis">
                              {{ data['description'] }}
                           </div>

                           <div class="pointer edit-item ml-1" fxLayout fxLayoutAlign="center center">
                              <div
                                 [matTooltip]="'Edit file name'"
                                 class="pointer edit-item"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditProcessedDataFile(element['key'], data)">edit </mat-icon>
                              </div>

                              <div [matTooltip]="'Download'" class="pointer" fxLayout fxLayoutAlign="center center">
                                 <mat-icon (click)="onDownloadFile(data['url'], data['description'])" color="primary">
                                    cloud_download
                                 </mat-icon>
                              </div>
                           </div>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Upload Time Column -->
               <ng-container matColumnDef="date">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">Upload Time</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           {{ data['date'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Size Column -->
               <ng-container matColumnDef="size">
                  <th *matHeaderCellDef fxFlex="100px" mat-header-cell>
                     <div class="omni-table-header">Size</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="100px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           {{ data['size'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Actions Column -->
               <ng-container matColumnDef="actions">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">Actions</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div class="omni-col-item">
                           <div
                              *ngIf="
                                 (bIsPeakLoad && bIsChromatogram) ||
                                    (!(bIsPeakLoad && bIsChromatogram) && strSelectedProcessKey !== element['key']);
                                 else waitLoadButton
                              "
                              class="mx-3">
                              <mat-icon
                                 (click)="onLoadOneCycleData(element, 1, element['position'])"
                                 class="pointer"
                                 color="primary">
                                 play_arrow
                              </mat-icon>
                           </div>
                           <ng-template #waitLoadButton>
                              <mat-spinner class="small-spinner mx-3" color="primary"></mat-spinner>
                           </ng-template>

                           <div>
                              <mat-icon (click)="onDeleteDataFile(element, 'process')" class="pointer" color="warn">
                                 delete_outline
                              </mat-icon>
                           </div>
                           <div matTooltip="File is missing in other tabs" class="pointer">
                              <mat-icon
                                 style="color: #8e8e00"
                                 *ngIf="processDataItemsInvalid[element['cycleIndex']]"
                                 class="mat-icon"
                                 >warning
                              </mat-icon>
                           </div>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Comment Column -->
               <ng-container matColumnDef="comment">
                  <th *matHeaderCellDef fxFlex mat-header-cell>
                     <div class="omni-table-header">Comment</div>
                  </th>
                  <td *matCellDef="let element" fxFlex mat-cell>
                     <div class="omni-col-wrap no-border">
                        <div class="omni-col-item relative">
                           <app-comment
                              (updateComment)="onUpdateProcessComment($event)"
                              [key]="element['key']"
                              [strComment]="element['comment']"></app-comment>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <tr *matHeaderRowDef="arrStrProcessedDataColumns" class="no-padding" mat-header-row></tr>
               <tr
                  *matRowDef="let row; columns: arrStrProcessedDataColumns"
                  [ngClass]="{ 'light-green-bg': strSelectedProcessKey === row.key }"
                  class="no-padding"
                  mat-row></tr>
            </table>

            <mat-paginator
               #processedPaginator
               (page)="onProcessedDataPaginateChange($event)"
               [pageIndex]="nProcessedDataPageIndex"
               [pageSizeOptions]="[5, 50, 200]"
               [pageSize]="nProcessedDataPageSize">
            </mat-paginator>
         </div>
      </mat-card-content>
   </mat-card>

   <mat-card appearance="outlined" *ngIf="nActionNumber === 4">
      <mat-card-title>Hardware tracking</mat-card-title>
      <mat-card-content class="content">
         <div *ngIf="!arrParams[2]['isSet']" class="flex-wrapper">
            <mat-spinner></mat-spinner>
         </div>
         <div *ngIf="arrParams[2]['isSet']" class="table-responsive">
            <!--display wrapper-->
            <table
               *ngIf="!arrParams[2]['isRowHeader'] && arrParams[2]['types']"
               class="table table-bordered fixed-table">
               <!--header type-->
               <thead>
                  <tr>
                     <th *ngFor="let head of arrParams[2]['types']['heads']">
                        {{ head.name }}
                     </th>
                  </tr>
               </thead>

               <tbody *ngIf="arrParams[2]['isSet']">
                  <tr *ngFor="let data of arrParams[2]['values']; let colInd = index">
                     <td *ngFor="let head of arrParams[2]['types']['heads']">
                        <div *ngIf="data[head.id] === 'primaryKey'">
                           {{ colInd + 1 }}
                        </div>

                        <div *ngIf="data[head.id] !== 'primaryKey'">
                           {{ data[head.id] ? data[head.id] : 'N/A' }}
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
            <!--header type-->

            <table
               *ngIf="arrParams[2]['isRowHeader'] && arrParams[2]['types']"
               class="table table-bordered fixed-table">
               <!--header row type-->
               <thead *ngIf="arrParams[2]['isHeaderShow']">
                  <tr>
                     <th *ngFor="let head of arrParams[2]['types']['heads']">
                        {{ head.name }}
                     </th>
                  </tr>
               </thead>

               <tbody *ngIf="arrParams[2]['isSet'] && arrParams[2]['isRowShow'] && arrParams[2]['types']['heads']">
                  <tr *ngFor="let row of arrParams[2]['types']['rows']">
                     <td *ngFor="let head of arrParams[2]['types']['heads']">
                        <div *ngIf="head['primaryKey']">
                           {{ row.name }}
                        </div>

                        <div *ngIf="!head['primaryKey']">
                           <div *ngIf="arrParams[2]['values'] && arrParams[2]['values'][row.id]">
                              <app-input
                                 (doneEdit)="onChangeDebugParameter($event, row.id, head.id)"
                                 *ngIf="row.valueType !== arrValueTypes[4]"
                                 [strInputType]="row.valueType"
                                 [value]="arrParams[2]['values'][row.id][head.id]"></app-input>

                              <app-input
                                 (doneEdit)="onChangeDebugParameter($event, row.id, head.id)"
                                 *ngIf="row.valueType === arrValueTypes[4]"
                                 [arrStrOptions]="row.defaultValue"
                                 [strInputType]="row.valueType"
                                 [value]="
                                    arrParams[2]['values'] ? arrParams[2]['values'][row.id][head.id] : ''
                                 "></app-input>
                           </div>
                           <div
                              *ngIf="
                                 !arrParams[2]['values'] || (arrParams[2]['values'] && !arrParams[2]['values'][row.id])
                              ">
                              <app-input
                                 (doneEdit)="onChangeDebugParameter($event, row.id, head.id)"
                                 *ngIf="row.valueType !== arrValueTypes[4]"
                                 [strInputType]="row.valueType"
                                 [value]="''"></app-input>
                              <app-input
                                 (doneEdit)="onChangeDebugParameter($event, row.id, head.id)"
                                 *ngIf="row.valueType === arrValueTypes[4]"
                                 [arrStrOptions]="row.defaultValue"
                                 [strInputType]="row.valueType"
                                 [value]="
                                    arrParams[2]['values'] ? arrParams[2]['values'][row.id][head.id] : ''
                                 "></app-input>
                           </div>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
            <!--header row type-->

            <div
               *ngIf="arrParams[2]['isSet'] && arrParams[2]['isRowShow'] && !arrParams[2]['types']['heads']"
               class="alert alert-info full-width text-center">
               You need to set the headers in sensor types edit page for this sensor type.
            </div>
         </div>
         <!--display wrapper-->
      </mat-card-content>
   </mat-card>

   <mat-card appearance="outlined" *ngIf="nActionNumber === 4">
      <mat-card-title>Chemical Recognition Parameters</mat-card-title>
      <!-- Fast Mode -->
      <span *ngIf="selectedSensor['fastMode']; else normalMode" style="width: 65px" class="status-title inline"
         >Fast Mode</span
      >

      <!-- Normal Mode -->
      <ng-template #normalMode>
         <span class="status-title inline" style="width: 82px">Normal Mode</span>
      </ng-template>
      <mat-card-content class="content">
         <div *ngIf="!arrParams[1]['isSet']" class="flex-wrapper">
            <mat-spinner></mat-spinner>
         </div>
         <div *ngIf="arrParams[1]['isSet']" class="table-responsive">
            <!--display wrapper-->
            <table
               *ngIf="!arrParams[1]['isRowHeader'] && arrParams[1]['types']"
               class="table table-bordered fixed-table">
               <!--header type-->
               <thead>
                  <tr>
                     <th *ngFor="let head of arrParams[1]['types']['heads']">
                        {{ head.name }}
                     </th>
                  </tr>
               </thead>

               <tbody *ngIf="arrParams[1]['isSet']">
                  <tr *ngFor="let data of arrParams[1]['values']; let colInd = index">
                     <td *ngFor="let head of arrParams[1]['types']['heads']">
                        <div *ngIf="data[head.id] === 'primaryKey'">
                           {{ colInd + 1 }}
                        </div>

                        <div *ngIf="data[head.id] !== 'primaryKey'">
                           {{ data[head.id] ? data[head.id] : 'N/A' }}
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
            <!--header type-->

            <!--header row type start-->
            <table
               *ngIf="arrParams[1]['isRowHeader'] && arrParams[1]['types']"
               [dataSource]="crpDataSource"
               class="omni-table full-width"
               mat-table>
               <ng-container *ngFor="let column of arrCRPColumns" [matColumnDef]="column">
                  <th *matHeaderCellDef mat-header-cell>
                     <div class="omni-table-header">
                        {{ crpLabelColumns[column] }}
                     </div>
                  </th>
                  <td *matCellDef="let element" mat-cell>
                     <div class="omni-col-wrap">
                        <div class="omni-col-item height-auto">
                           <div *ngIf="element[column]['isPrimary']; else normalEle">
                              {{ element[column]['name'] }}
                           </div>
                           <ng-template #normalEle>
                              <app-input
                                 (doneEdit)="onChangeChemicalParam($event, element[column]['rowId'], column)"
                                 [arrStrOptions]="element[column]['defaultValue']"
                                 [strInputType]="element[column]['valueType']"
                                 [value]="
                                    arrParams[1]['values'] && arrParams[1]['values'][element[column]['rowId']]
                                       ? arrParams[1]['values'][element[column]['rowId']][column]
                                       : ''
                                 "></app-input>
                           </ng-template>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <tr *matHeaderRowDef="arrCRPColumns" mat-header-row></tr>
               <tr *matRowDef="let row; columns: arrCRPColumns" mat-row></tr>
            </table>

            <!--header row type end-->
         </div>
         <!--display wrapper-->
      </mat-card-content>
   </mat-card>

   <mat-card appearance="outlined" *ngIf="isEditStatus && nActionNumber === 1">
      <mat-card-title>
         <div class="data-title-wrapper">
            <div class="main-title inline">Analytical Data</div>
            <div
               *ngIf="arrObjAnalyticList.length && strSelectedAnalyticCycle === arrObjAnalyticList[0]['cycleIndex']"
               class="status-title inline">
               Latest Data
            </div>
            <div
               *ngIf="arrObjAnalyticList.length && strSelectedAnalyticCycle !== arrObjAnalyticList[0]['cycleIndex']"
               class="status-title blue-text inline">
               Earlier Data
            </div>

            <div *ngIf="strSelectedAnalyticCycle && !_analyticalService.bIsMultipleAnalyticalData" class="sub-title">
               &nbsp;&nbsp;Measurement Start Time: {{ strAnalyticalStartTime ? strAnalyticalStartTime : 'N/A' }}
            </div>

            <div *ngIf="strSelectedAnalyticCycle && !_analyticalService.bIsMultipleAnalyticalData" class="sub-title">
               &nbsp;&nbsp;Data Upload Time: {{ strAnalyticalDate ? strAnalyticalDate : 'N/A' }}
            </div>
         </div>
      </mat-card-title>
      <!--    <mat-card-subtitle-->
      <!--      *ngIf="-->
      <!--        strSelectedAnalyticCycle &&-->
      <!--        !_analyticalService.bIsMultipleAnalyticalData-->
      <!--      "-->
      <!--    >-->
      <!--      Measurement Start Time:-->
      <!--      {{ strAnalyticalStartTime ? strAnalyticalStartTime : "N/A" }}-->
      <!--    </mat-card-subtitle>-->
      <!--    <mat-card-subtitle-->
      <!--      *ngIf="-->
      <!--        strSelectedAnalyticCycle &&-->
      <!--        !_analyticalService.bIsMultipleAnalyticalData-->
      <!--      "-->
      <!--    >-->
      <!--      Data Upload Time: {{ strAnalyticalDate ? strAnalyticalDate : "N/A" }}-->
      <!--    </mat-card-subtitle>-->

      <mat-card-content class="overflow-x content">
         <div *ngIf="bIsParseAnalyticData; else alertAnalyticalDataEle">
            <div
               *ngIf="bIsStartCycleLoad && !strSelectedAnalyticCycle; else availableAnalyticalEle"
               class="alert alert-info full-width text-center">
               <div>No matching Analytical Data file found.</div>
               <div>
                  Please select one in the table below, or switch back to the previous tab and select another file.
               </div>
            </div>

            <ng-template #availableAnalyticalEle>
               <div *ngIf="analyticalData?.length">
                  <div class="control-rentention-visiblity">
                     <button
                        (click)="bIsShowRententionProperties = !bIsShowRententionProperties"
                        class="btn btn-primary"
                        mat-raised-button>
                        <div *ngIf="bIsShowRententionProperties; else showRenTimesEle">Hide Rentention Times</div>

                        <ng-template #showRenTimesEle> Show Rentention Times </ng-template>
                     </button>
                     <button
                        (click)="bIsShowUnChemicals = !bIsShowUnChemicals"
                        class="btn btn-primary ml-3"
                        mat-raised-button>
                        <div *ngIf="bIsShowUnChemicals; else showUnChemicalLabelEle">Hide unknown chemicals</div>

                        <ng-template #showUnChemicalLabelEle> Show unknown chemicals </ng-template>
                     </button>
                     <div class="d-inline-block ml-3 description-text">
                        <span
                           >* - <i>The chemical has a similar retention time as a known gas in tR range column</i></span
                        >
                        <br />
                        <span
                           >** -
                           <i>The low concentration of this chemical may have affected its identification</i></span
                        >
                     </div>
                  </div>

                  <div
                     *ngIf="!_analyticalService.bIsMultipleAnalyticalData; else loadMultipleAnalyticData"
                     class="table-responsive">
                     <table class="table table-bordered">
                        <thead>
                           <tr>
                              <th
                                 *ngFor="
                                    let item of analyticalData[0]
                                       | analyticDataFilter
                                          : arrAnalyticalInputs[0]
                                          : arrStrRetentionProperties
                                          : bIsShowRententionProperties
                                          : arrParams[1]
                                          : strUserRole
                                          : isUserStaff
                                 ">
                                 {{ item.key }}
                              </th>
                           </tr>
                        </thead>

                        <tbody>
                           <tr *ngFor="let data of analyticalData; let dataInd = index">
                              <td
                                 *ngFor="
                                    let item of data
                                       | analyticDataFilter
                                          : arrAnalyticalInputs[dataInd]
                                          : arrStrRetentionProperties
                                          : bIsShowRententionProperties
                                          : arrParams[1]
                                          : strUserRole
                                          : isUserStaff
                                          : bIsShowUnChemicals
                                 ">
                                 <div *ngIf="!item.editable; else analyticalInputEle">
                                    {{ item.value }}
                                 </div>

                                 <ng-template *ngIf="strUserRole === 'admin'" #analyticalInputEle>
                                    <div
                                       *ngIf="arrStrAnalyticalInputs.indexOf(item.key) !== -1"
                                       class="analytical-input">
                                       <app-comment
                                          (updateComment)="onUpdateAnalyticInput($event, dataInd)"
                                          [key]="item.key"
                                          [strComment]="item.value"></app-comment>
                                    </div>
                                 </ng-template>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div *ngIf="bIsShowUnChemicals" class="alert alert-danger mt-3 text-center">
                     * Actual unknown chemical concentration might vary up to a factor of 10 from the displayed toluene
                     equivalent.
                  </div>

                  <ng-template #errorAnalyticalDataEle>
                     <div class="alert alert-info full-width text-center">Unknown file format or empty file.</div>
                  </ng-template>
               </div>
            </ng-template>

            <ng-template #loadMultipleAnalyticData>
               <app-multiple-analytic-table
                  (updateInput)="onUpdateAnalyticInput($event)"
                  *ngFor="let analytic of mulAnalyticalData; let i = index"
                  [analytic]="analytic"
                  [arrAnalyticalInputs]="arrMulAnalyticalInputs"
                  [arrParams]="arrParams"
                  [bIsShowRententionProperties]="bIsShowRententionProperties"
                  [bIsShowUnChemicals]="bIsShowUnChemicals"
                  [index]="i">
               </app-multiple-analytic-table>
            </ng-template>
         </div>

         <ng-template #alertAnalyticalDataEle>
            <div class="load-area">
               <mat-spinner></mat-spinner>
            </div>

            <div *ngIf="bIsAnalyticalData" class="alert alert-info text-center" role="alert">
               Parsing analytical data...
            </div>
         </ng-template>
      </mat-card-content>
   </mat-card>

   <mat-card appearance="outlined" *ngIf="isEditStatus && nActionNumber === 1">
      <div fxLayout fxLayoutAlign="space-between align-items-center">
         <mat-card-title>Analytical Data Files</mat-card-title>
         <div fxLayout>
            <mat-form-field class="mr-4">
               <mat-label>Search in all fields</mat-label>
               <input
                  (keyup)="globalFilterStr$.next($event.target?.value)"
                  [value]="globalFilter"
                  matInput
                  placeholder="Search in all fields"
                  type="text" />
            </mat-form-field>

            <mat-form-field class="mr-4">
               <mat-label>Start date (measurement)</mat-label>
               <input
                  (ngModelChange)="startDateChange($event)"
                  [(ngModel)]="fromDate"
                  [matDatepicker]="picker1"
                  matInput
                  placeholder="mm/dd/yyyy" />
               <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
               <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="mr-4">
               <mat-label>End date (measurement)</mat-label>
               <input
                  (ngModelChange)="endDateChange($event)"
                  [(ngModel)]="toDate"
                  [matDatepicker]="picker2"
                  matInput
                  placeholder="mm/dd/yyyy" />
               <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
               <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <button
               (click)="clearFilter(analyticalDataSource)"
               class="btn btn-default clear-btn"
               mat-button
               mat-raised-button>
               Show all Files
            </button>
         </div>
      </div>

      <div>
         <mat-form-field class="sensor-type-handler col-3 pl-0">
            <mat-label>Bulk Action</mat-label>
            <mat-select
               (selectionChange)="onSelectBulk($event, 'analyticalData')"
               [(value)]="strAnalyticalDataFilterStatus">
               <mat-option value="select"> Select Action </mat-option>
               <mat-option value="load"> Load Selected Files </mat-option>
               <mat-option value="download"> Download Selected Files </mat-option>
               <mat-option value="remove"> Remove Selected Files </mat-option>
            </mat-select>
         </mat-form-field>
         <button
            *ngIf="isAdminRole()"
            mat-raised-button
            color="primary"
            [disabled]="isExportAnalyticalDataDisabled"
            (click)="exportAnalyticalData()">
            Save as Excel ({{ dataSpanDays }} day(s))
         </button>
         <app-set-days
            *ngIf="isAdminRole()"
            [days]="dataSpanDays"
            [showExportDaysControl]="showExportDaysControl"
            [getDataSpanFrom]="getDataSpanFrom"
            [dataSpanFrom]="dataSpanFrom"
            (updateDays)="updateDateSpan($event)"></app-set-days>
         &nbsp;&nbsp;
         <button
            *ngIf="isAdminRole() && !showExportDaysControl"
            mat-raised-button
            color="primary"
            [disabled]="isExportAnalyticalDataDisabled"
            (click)="showExportDaysControl = true">
            Customize
         </button>
      </div>

      <mat-card-content class="overflow-x content">
         <div *ngIf="!bIsAnalyticalData" class="load-area">
            <mat-spinner></mat-spinner>
         </div>

         <div *ngIf="bIsAnalyticalData" class="static-wrapper">
            <ng-container *ngIf="isExportAnalyticalDataDisabled">
               <mat-progress-bar
                  [color]="'primary'"
                  [mode]="'buffer'"
                  [value]="totalTime"
                  [bufferValue]="100"></mat-progress-bar>
               <span
                  >Calculated Files:
                  {{ filesCount >= totalAnalyticFiles.length ? totalAnalyticFiles.length : filesCount }} /
                  {{ totalAnalyticFiles.length || 0 }}</span
               >
            </ng-container>
            <table #table [dataSource]="analyticalDataSource" class="omni-table full-width" mat-table>
               <ng-container matColumnDef="select">
                  <th *matHeaderCellDef fxFlex="64px" mat-header-cell>
                     <div class="omni-table-header">Select</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="64px" mat-cell>
                     <div class="omni-col-wrap">
                        <mat-checkbox
                           [disabled]="isAnalyticalCheckSelected && !analyticalDataItemsSelected[element['cycleIndex']]"
                           (change)="onChangeAnalyticalDataSelect($event.checked, element['cycleIndex'])"
                           [checked]="analyticalDataItemsSelected[element['cycleIndex']]"></mat-checkbox>
                     </div>
                  </td>
               </ng-container>
               <!-- Position Column -->
               <ng-container matColumnDef="position">
                  <th *matHeaderCellDef fxFlex="64px" mat-header-cell>
                     <div class="omni-table-header">No.</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="64px" mat-cell>
                     <div class="omni-col-wrap">
                        {{ element['position'] + 1 }}
                     </div>
                  </td>
               </ng-container>

               <!-- Measurement Start Time Column -->
               <ng-container matColumnDef="startTimestamp">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">Measurement Start Time</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div class="omni-col-item">
                           {{ element['startTimestamp'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Step Category Column -->
               <ng-container matColumnDef="type">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">Step Category</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           {{ data['type'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Name Column -->
               <ng-container matColumnDef="name">
                  <th *matHeaderCellDef fxFlex="250px" mat-header-cell>
                     <div class="omni-table-header">Name</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="250px" mat-cell>
                     <div class="omni-col-wrap">
                        <div
                           *ngFor="let data of element['data']"
                           [matTooltip]="data['description']"
                           class="omni-col-item">
                           <div class="ellipsis">
                              {{ data['description'] }}
                           </div>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Upload Time Column -->
               <ng-container matColumnDef="date">
                  <th *matHeaderCellDef fxFlex="150px" mat-header-cell>
                     <div class="omni-table-header">Upload Time</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="150px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           {{ data['date'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Size Column -->
               <ng-container matColumnDef="size">
                  <th *matHeaderCellDef fxFlex="100px" mat-header-cell>
                     <div class="omni-table-header">Size</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="100px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           {{ data['size'] }}
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Actions Column -->
               <ng-container matColumnDef="actions">
                  <th *matHeaderCellDef fxFlex="170px" mat-header-cell>
                     <div class="omni-table-header">Actions</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="200px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           <div class="pointer edit-item mr-3" matTooltip="Edit file name">
                              <mat-icon (click)="onEditAnalyticalDataFile(data)">edit </mat-icon>
                           </div>

                           <div
                              *ngIf="
                                 bIsParseAnalyticData ||
                                    (!bIsParseAnalyticData && strSelectedAnalyticKey !== data['key']);
                                 else waitLoadButton
                              "
                              class="pointer"
                              matTooltip="Load">
                              <mat-icon
                                 (click)="onLoadOneCycleData(data, 0, element['data']['position'])"
                                 color="primary">
                                 play_arrow
                              </mat-icon>
                           </div>
                           <ng-template #waitLoadButton>
                              <mat-spinner class="small-spinner" color="primary"></mat-spinner>
                           </ng-template>

                           <div class="pointer mx-3" matTooltip="Delete">
                              <mat-icon (click)="onDeleteDataFile(data, 'analytical')" color="warn"
                                 >delete_outline
                              </mat-icon>
                           </div>

                           <div class="pointer" matTooltip="Download">
                              <mat-icon (click)="onDownloadFile(data['url'], data['description'])" color="primary">
                                 cloud_download
                              </mat-icon>
                           </div>
                           <div matTooltip="File is missing in other tabs" class="pointer ml-3">
                              <mat-icon
                                 style="color: #8e8e00"
                                 *ngIf="analyticDataItemsInvalid[element['cycleIndex']]"
                                 class="mat-icon"
                                 >warning
                              </mat-icon>
                           </div>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Weather Wind Column -->
               <ng-container matColumnDef="weather_wind">
                  <th *matHeaderCellDef fxFlex="60px" mat-header-cell>
                     <div class="omni-table-header">Wind</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="60px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           <ng-container
                              *ngIf="
                                 (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg ||
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg === 0;
                                 else noWeatherConfig
                              ">
                              {{ (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg }}
                           </ng-container>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <ng-container matColumnDef="wind_direction">
                  <th *matHeaderCellDef fxFlex="80px" mat-header-cell>
                     <div class="omni-table-header">Direction</div>
                  </th>
                  <td *matCellDef="let element" fxFlex="80px" mat-cell>
                     <div class="omni-col-wrap">
                        <div *ngFor="let data of element['data']" class="omni-col-item">
                           <ng-container
                              *ngIf="
                                 (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg ||
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg === 0;
                                 else noWeatherWind
                              ">
                              <ng-container
                                 *ngIf="
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather &&
                                       (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_avg !==
                                          0;
                                    else emptyDirection
                                 ">
                                 {{
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_direction
                                 }}
                                 <span>&deg;&nbsp;</span>
                                 {{
                                    (runsDict | wind : element['cycleIndex'] : selectedSensor)?.weather?.wind_direction
                                       | windDirection
                                 }}
                              </ng-container>
                           </ng-container>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <!-- Comment Column -->
               <ng-container matColumnDef="comment">
                  <th *matHeaderCellDef fxFlex mat-header-cell>
                     <div class="omni-table-header">Comment</div>
                  </th>
                  <td *matCellDef="let element" fxFlex mat-cell>
                     <div class="omni-col-wrap no-border">
                        <div *ngFor="let data of element['data']" class="omni-col-item relative">
                           <app-comment
                              (updateComment)="onUpdateAnalyticComment($event)"
                              [key]="data['key']"
                              [strComment]="data['comment']"></app-comment>
                        </div>
                     </div>
                  </td>
               </ng-container>

               <tr *matHeaderRowDef="arrStrDisplayedColumns" class="no-padding" mat-header-row></tr>
               <tr
                  *matRowDef="let row; columns: arrStrDisplayedColumns"
                  [ngClass]="{
                     'light-green-bg': strSelectedAnalyticCycle === row.cycleIndex
                  }"
                  class="no-padding"
                  mat-row></tr>
            </table>

            <mat-paginator
               #analyticalPaginator
               (page)="onAnalyticalPaginateChange($event)"
               [pageIndex]="nAnalyticalPageIndex"
               [pageSizeOptions]="[5, 50, 200]"
               [pageSize]="nAnalyticalPageSize">
            </mat-paginator>
         </div>
      </mat-card-content>
   </mat-card>

   <mat-card appearance="outlined" *ngIf="nActionNumber === 0">
      <mat-card-title>Data</mat-card-title>
      <mat-card-content class="content container-fluid">
         <div class="row">
            <div *ngIf="strUserRole !== arrStrUserRoles[3]" class="col-sm table-responsive">
               <h5>Geo Location</h5>
               <table class="table table-bordered table-striped">
                  <thead>
                     <tr>
                        <th>Title</th>
                        <th>Value</th>
                        <th *ngIf="strUserRole !== arrStrUserRoles[3]">Action</th>
                     </tr>
                  </thead>

                  <tbody>
                     <tr>
                        <td>Name</td>

                        <td>
                           <div *ngIf="nStatus !== nStatusEnum.name">
                              {{ selectedSensor.name }}
                           </div>

                           <div *ngIf="nStatus === nStatusEnum.name">
                              <mat-form-field class="full-width">
                                 <input [(ngModel)]="paramValue" matInput required type="text" />
                              </mat-form-field>
                              <div *ngIf="error" class="alert alert-danger">
                                 {{ error }}
                              </div>
                           </div>
                        </td>

                        <td *ngIf="strUserRole !== arrStrUserRoles[3]">
                           <div fxLayout fxLayoutAlign="center">
                              <div
                                 *ngIf="nStatus !== nStatusEnum.name"
                                 class="edit-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditValue(nStatusEnum.name)">edit</mat-icon>
                              </div>

                              <mat-icon
                                 (click)="onUpdate()"
                                 *ngIf="nStatus === nStatusEnum.name"
                                 class="pointer mr-1"
                                 color="primary"
                                 >check
                              </mat-icon>

                              <div
                                 *ngIf="nStatus === nStatusEnum.name"
                                 class="delete-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="cancel()">close</mat-icon>
                              </div>
                           </div>
                        </td>
                     </tr>

                     <tr *ngIf="isAdminRole()">
                        <td>Customer Name</td>

                        <td>
                           <div *ngIf="nStatus !== nStatusEnum.customerName">
                              {{ selectedSensor.customerName }}
                           </div>

                           <div *ngIf="nStatus === nStatusEnum.customerName">
                              <mat-form-field class="full-width">
                                 <mat-select [(value)]="paramValue">
                                    <mat-option *ngFor="let customer of customers" [value]="customer.name">
                                       {{ customer.name }}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                              <div *ngIf="error" class="alert alert-danger">
                                 {{ error }}
                              </div>
                           </div>
                        </td>

                        <td *ngIf="strUserRole !== arrStrUserRoles[3]">
                           <div fxLayout fxLayoutAlign="center">
                              <div
                                 *ngIf="nStatus !== nStatusEnum.customerName"
                                 class="edit-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditValue(nStatusEnum.customerName)">edit</mat-icon>
                              </div>

                              <mat-icon
                                 (click)="onUpdate()"
                                 *ngIf="nStatus === nStatusEnum.customerName"
                                 class="pointer mr-1"
                                 color="primary"
                                 >check
                              </mat-icon>

                              <div
                                 *ngIf="nStatus === nStatusEnum.customerName"
                                 class="delete-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="cancel()">close</mat-icon>
                              </div>
                           </div>
                        </td>
                     </tr>

                     <tr>
                        <td>Zone Name</td>

                        <td>
                           <div *ngIf="nStatus !== nStatusEnum.zoneName">
                              {{ selectedSensor.zoneName }}
                           </div>

                           <div *ngIf="nStatus === nStatusEnum.zoneName">
                              <mat-form-field class="full-width">
                                 <mat-select [(value)]="paramValue">
                                    <mat-option *ngFor="let zone of filteredZones" [value]="zone.name">
                                       {{ zone.name }}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                              <div *ngIf="error" class="alert alert-danger">
                                 {{ error }}
                              </div>
                           </div>
                        </td>

                        <td *ngIf="strUserRole !== arrStrUserRoles[3]">
                           <div fxLayout fxLayoutAlign="center">
                              <div
                                 *ngIf="nStatus !== nStatusEnum.zoneName"
                                 class="edit-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditValue(nStatusEnum.zoneName)">edit</mat-icon>
                              </div>

                              <mat-icon
                                 (click)="onUpdate()"
                                 *ngIf="nStatus === nStatusEnum.zoneName"
                                 class="pointer mr-1"
                                 color="primary"
                                 >check
                              </mat-icon>

                              <div
                                 *ngIf="nStatus === nStatusEnum.zoneName"
                                 class="delete-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="cancel()">close</mat-icon>
                              </div>
                           </div>
                        </td>
                     </tr>

                     <tr>
                        <td>Address</td>

                        <td>
                           <div *ngIf="nStatus !== nStatusEnum.address">
                              {{ selectedSensor.address }}
                           </div>

                           <div *ngIf="nStatus === nStatusEnum.address">
                              <mat-form-field class="full-width">
                                 <input [(ngModel)]="paramValue" matInput required type="text" />
                              </mat-form-field>
                              <div *ngIf="error" class="alert alert-danger">
                                 {{ error }}
                              </div>
                           </div>
                        </td>

                        <td *ngIf="strUserRole !== arrStrUserRoles[3]">
                           <div fxLayout fxLayoutAlign="center">
                              <div
                                 *ngIf="nStatus !== nStatusEnum.address"
                                 class="edit-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditValue(nStatusEnum.address)">edit</mat-icon>
                              </div>

                              <mat-icon
                                 (click)="onUpdate()"
                                 *ngIf="nStatus === nStatusEnum.address"
                                 class="pointer mr-1"
                                 color="primary"
                                 >check
                              </mat-icon>

                              <div
                                 *ngIf="nStatus === nStatusEnum.address"
                                 class="delete-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="cancel()">close</mat-icon>
                              </div>
                           </div>
                        </td>
                     </tr>

                     <tr>
                        <td>Latitude</td>

                        <td>
                           <div *ngIf="nStatus !== nStatusEnum.lat">
                              {{ selectedSensor.lat }}
                           </div>

                           <div *ngIf="nStatus === nStatusEnum.lat">
                              <mat-form-field class="full-width">
                                 <input [(ngModel)]="paramValue" matInput required type="number" />
                              </mat-form-field>
                              <div *ngIf="error" class="alert alert-danger">
                                 {{ error }}
                              </div>
                           </div>
                        </td>

                        <td *ngIf="strUserRole !== arrStrUserRoles[3]">
                           <div fxLayout fxLayoutAlign="center">
                              <div
                                 *ngIf="nStatus !== nStatusEnum.lat"
                                 class="edit-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditValue(nStatusEnum.lat)">edit</mat-icon>
                              </div>

                              <mat-icon
                                 (click)="onUpdate()"
                                 *ngIf="nStatus === nStatusEnum.lat"
                                 class="pointer mr-1"
                                 color="primary"
                                 >check
                              </mat-icon>

                              <div
                                 *ngIf="nStatus === nStatusEnum.lat"
                                 class="delete-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="cancel()">close</mat-icon>
                              </div>
                           </div>
                        </td>
                     </tr>

                     <tr>
                        <td>Longitude</td>

                        <td>
                           <div *ngIf="nStatus !== nStatusEnum.lng">
                              {{ selectedSensor.lng }}
                           </div>

                           <div *ngIf="nStatus === nStatusEnum.lng">
                              <mat-form-field class="full-width">
                                 <input [(ngModel)]="paramValue" matInput required type="number" />
                              </mat-form-field>
                              <div *ngIf="error" class="alert alert-danger">
                                 {{ error }}
                              </div>
                           </div>
                        </td>

                        <td *ngIf="strUserRole !== arrStrUserRoles[3]">
                           <div fxLayout fxLayoutAlign="center">
                              <div
                                 *ngIf="nStatus !== nStatusEnum.lng"
                                 class="edit-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditValue(nStatusEnum.lng)">edit</mat-icon>
                              </div>

                              <mat-icon
                                 (click)="onUpdate()"
                                 *ngIf="nStatus === nStatusEnum.lng"
                                 class="pointer mr-1"
                                 color="primary"
                                 >check
                              </mat-icon>

                              <div
                                 *ngIf="nStatus === nStatusEnum.lng"
                                 class="delete-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="cancel()">close</mat-icon>
                              </div>
                           </div>
                        </td>
                     </tr>

                     <tr>
                        <td>Description</td>

                        <td>
                           <div *ngIf="nStatus !== nStatusEnum.description">
                              {{ selectedSensor.description }}
                           </div>

                           <div *ngIf="nStatus === nStatusEnum.description">
                              <mat-form-field class="full-width">
                                 <textarea [(ngModel)]="paramValue" matInput required></textarea>
                              </mat-form-field>
                              <div *ngIf="error" class="alert alert-danger">
                                 {{ error }}
                              </div>
                           </div>
                        </td>

                        <td *ngIf="strUserRole !== arrStrUserRoles[3]">
                           <div fxLayout fxLayoutAlign="center">
                              <div
                                 *ngIf="nStatus !== nStatusEnum.description"
                                 class="edit-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditValue(nStatusEnum.description)">edit</mat-icon>
                              </div>

                              <mat-icon
                                 (click)="onUpdate()"
                                 *ngIf="nStatus === nStatusEnum.description"
                                 class="pointer mr-1"
                                 color="primary"
                                 >check
                              </mat-icon>

                              <div
                                 *ngIf="nStatus === nStatusEnum.description"
                                 class="delete-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="cancel()">close</mat-icon>
                              </div>
                           </div>
                        </td>
                     </tr>

                     <tr>
                        <td>Serial Number</td>

                        <td>
                           <div *ngIf="nStatus !== nStatusEnum.serialNumber">
                              {{ selectedSensor['serialNumber'] }}
                           </div>

                           <div *ngIf="nStatus === nStatusEnum.serialNumber">
                              <mat-form-field class="full-width">
                                 <input [(ngModel)]="paramValue" matInput required type="text" />
                              </mat-form-field>
                              <div *ngIf="error" class="alert alert-danger">
                                 {{ error }}
                              </div>
                           </div>
                        </td>

                        <td *ngIf="strUserRole !== arrStrUserRoles[3]">
                           <div *ngIf="strUserType === arrStrUserTypes[0]" fxLayout fxLayoutAlign="center">
                              <div
                                 *ngIf="nStatus !== nStatusEnum.serialNumber"
                                 class="edit-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditValue(nStatusEnum.serialNumber)">edit</mat-icon>
                              </div>

                              <mat-icon
                                 (click)="onUpdate()"
                                 *ngIf="nStatus === nStatusEnum.serialNumber"
                                 class="pointer mr-1"
                                 color="primary"
                                 >check
                              </mat-icon>

                              <div
                                 *ngIf="nStatus === nStatusEnum.serialNumber"
                                 class="delete-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="cancel()">close</mat-icon>
                              </div>
                           </div>
                        </td>
                     </tr>

                     <tr>
                        <td>Time Zone</td>
                        <td>
                           <div *ngIf="nStatus !== nStatusEnum.timezone">
                              {{ selectedSensor['timezone'] }}
                           </div>

                           <div *ngIf="nStatus === nStatusEnum.timezone">
                              <mat-form-field class="full-width">
                                 <mat-select [(ngModel)]="paramValue">
                                    <mat-option>
                                       <ngx-mat-select-search
                                          placeholderLabel="Find Timezone..."
                                          noEntriesFoundLabel="'No matching timezones found'"
                                          ngModel
                                          (ngModelChange)="filterZones($event)"
                                          >>
                                       </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let timezone of filteredTimeZones" [value]="timezone">
                                       {{ timezone }}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                              <div *ngIf="error" class="alert alert-danger">
                                 {{ error }}
                              </div>
                           </div>
                        </td>

                        <td *ngIf="strUserRole !== arrStrUserRoles[3]">
                           <div *ngIf="strUserType === arrStrUserTypes[0]" fxLayout fxLayoutAlign="center">
                              <div
                                 *ngIf="nStatus !== nStatusEnum.timezone"
                                 class="edit-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="onEditValue(nStatusEnum.timezone)">edit</mat-icon>
                              </div>

                              <mat-icon
                                 (click)="onUpdate()"
                                 *ngIf="nStatus === nStatusEnum.timezone"
                                 class="pointer mr-1"
                                 color="primary"
                                 >check
                              </mat-icon>

                              <div
                                 *ngIf="nStatus === nStatusEnum.timezone"
                                 class="delete-item pointer"
                                 fxLayout
                                 fxLayoutAlign="center center">
                                 <mat-icon (click)="cancel()">close</mat-icon>
                              </div>
                           </div>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <h5 class="mt-2">Weather</h5>
               <app-weather
                  [strUserType]="strUserType"
                  [strUserRole]="strUserRole"
                  [sensor]="sensor"
                  (updateWeather)="onUpdateWeather($event)"
                  (updateWeatherSensor)="updateWeatherSensorId($event)">
               </app-weather>

               <!--              FAST MODE -->
               <ng-container *ngIf="strUserRole !== arrStrUserRoles[3] && strUserType === arrStrUserTypes[0]">
                  <h5>Omniscent Admin Only Settings</h5>
                  <table class="table table-bordered table-striped fast-mode-sensor">
                     <thead>
                        <tr>
                           <th>Title</th>
                           <th>Value</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>Fast Mode</td>
                           <td>
                              <mat-slide-toggle
                                 (change)="onChangeFastMode($event)"
                                 color="primary"
                                 [ngModel]="selectedSensor['fastMode']">
                              </mat-slide-toggle>
                           </td>
                        </tr>

                        <ng-container *ngIf="selectedSensor['fastMode']">
                           <tr>
                              <td>
                                 <mat-form-field class="full-width mr-4">
                                    <mat-label>Enter name for the fast mode configuration 1</mat-label>
                                    <input
                                       required
                                       placeholder="Enter name for the fast mode configuration 1"
                                       matInput
                                       type="text"
                                       [ngModelOptions]="{ updateOn: 'blur' }"
                                       (ngModelChange)="onChangeFastModeLabel($event, 'one')"
                                       [(ngModel)]="selectedSensor['fastModeConfigName1']" />
                                 </mat-form-field>
                              </td>
                              <td *ngIf="arrConfigs.length">
                                 <mat-form-field class="full-width">
                                    <mat-label>Select 1 min. sampling file</mat-label>
                                    <mat-select
                                       placeholder="Select 1 min. sampling file"
                                       class="full-width"
                                       (ngModelChange)="onChangeOneMinuteSamplingFile($event)"
                                       [(ngModel)]="oneMinuteSamplingFile">
                                       <mat-option>
                                          <ngx-mat-select-search
                                             placeholderLabel="Search by file name..."
                                             noEntriesFoundLabel="'No matching file found'"
                                             (ngModelChange)="onSearchElement($event, 'one')"
                                             [(ngModel)]="searchOneMinute">
                                          </ngx-mat-select-search>
                                       </mat-option>
                                       <mat-option
                                          *ngFor="let configType of filteredConfigsOne"
                                          [value]="configType['key']">
                                          {{ configType['name'] }}
                                       </mat-option>
                                    </mat-select>
                                 </mat-form-field>
                              </td>
                           </tr>

                           <tr>
                              <td>
                                 <mat-form-field class="full-width mr-4">
                                    <mat-label>Enter name for the fast mode configuration 2</mat-label>
                                    <input
                                       required
                                       placeholder="Enter name for the fast mode configuration 2"
                                       matInput
                                       type="text"
                                       [ngModelOptions]="{ updateOn: 'blur' }"
                                       (ngModelChange)="onChangeFastModeLabel($event, 'two')"
                                       [(ngModel)]="selectedSensor['fastModeConfigName2']" />
                                 </mat-form-field>
                              </td>
                              <td>
                                 <mat-form-field class="full-width">
                                    <mat-label>Select 2 min. sampling file</mat-label>
                                    <mat-select
                                       placeholder="Select 2 min. sampling file"
                                       class="full-width"
                                       (ngModelChange)="onChangeTwoMinutesSamplingFile($event)"
                                       [(ngModel)]="twoMinuteSamplingFile">
                                       <mat-option>
                                          <ngx-mat-select-search
                                             placeholderLabel="Search by file name..."
                                             noEntriesFoundLabel="'No matching file found'"
                                             (ngModelChange)="onSearchElement($event, 'two')"
                                             [(ngModel)]="searchTwoMinute">
                                          </ngx-mat-select-search>
                                       </mat-option>
                                       <mat-option
                                          *ngFor="let configType of filteredConfigsTwo"
                                          [value]="configType['key']">
                                          {{ configType['name'] }}
                                       </mat-option>
                                    </mat-select>
                                 </mat-form-field>
                              </td>
                           </tr>
                        </ng-container>
                        <tr>
                           <td>Max Runs</td>
                           <td>
                              <mat-form-field class="full-width">
                                 <mat-label>Max Runs</mat-label>
                                 <input
                                    matInput
                                    type="number"
                                    placeholder="Max Runs"
                                    [ngModel]="selectedSensor['maxRuns']"
                                    min="1"
                                    [max]="999"
                                    step="1"
                                    (change)="onChangeMaxRuns($event)" />
                              </mat-form-field>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </ng-container>

               <!--   Fast Mode Settings-->
               <ng-container *ngIf="selectedSensor['fastMode']">
                  <h5>Fast Mode Settings</h5>
                  <table class="table table-bordered table-striped fast-mode-sensor">
                     <thead>
                        <tr>
                           <th>Title</th>
                           <th>Value</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>Current Fast Mode Configuration</td>
                           <td>
                              <mat-form-field class="full-width">
                                 <mat-label>Select fast mode sampling time</mat-label>
                                 <mat-select
                                    placeholder="Select fast mode sampling time"
                                    class="full-width"
                                    (ngModelChange)="onAssignSamplingTime($event)"
                                    [(ngModel)]="samplingFile">
                                    <mat-option *ngFor="let timeKey of arrSamplingTimes" [value]="timeKey['key']">
                                       {{ timeKey['name'] }}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </ng-container>

               <h5 class="mt-2">Cleaning Cycle</h5>
               <app-cleaning-cycle
                  [strUserType]="strUserType"
                  [strUserRole]="strUserRole"
                  [sensor]="sensor"
                  (updateCleaningCycle)="updateCleaningCycle($event)"></app-cleaning-cycle>
            </div>

            <div class="col-sm">
               <div class="network-status-container mb-4">
                  <h5>Network Status</h5>
                  <table class="table table-bordered table-striped">
                     <thead>
                        <tr>
                           <th>LAN IP</th>
                           <th>N/A</th>
                           <th>Signal Strength</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>
                              <div fxLayout fxLayoutAlign="center center">
                                 <mat-icon class="mr-2 wifi-icon">wifi</mat-icon>
                                 WIFI IP
                              </div>
                           </td>

                           <td>{{ isOnlineDevice ? strWifiIP : 'N/A' }}</td>
                           <td>
                              <app-network-power [powerCount]="isOnlineDevice ? nWifiSignal : 0"></app-network-power>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div fxLayout fxLayoutAlign="center center">
                                 <mat-icon class="mr-2 signal-icon">signal_cellular_alt </mat-icon>
                                 Cellular IP
                              </div>
                           </td>
                           <td>{{ isOnlineDevice ? strCellularIP : 'N/A' }}</td>
                           <td>
                              <app-network-power
                                 [powerCount]="isOnlineDevice ? nCellularSignal : 0"></app-network-power>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <div class="system-parameters-container">
                  <h5>System Parameters</h5>
                  <mat-card-subtitle>
                     <div *ngIf="!bIsSensorTypeEdit; else editSensorType" fxLayout fxLayoutAlign>
                        Sensor Type:
                        <div class="text-info">{{ strSensorTypeName }}</div>
                        <div
                           *ngIf="_authService.isUserStaff"
                           class="pointer edit-item ml-3"
                           fxLayout
                           fxLayoutAlign="center center">
                           <mat-icon (click)="onEditSensorType()">edit</mat-icon>
                        </div>
                     </div>

                     <ng-template #editSensorType>
                        <div fxLayout fxLayoutAlign>
                           <mat-form-field class="sensor-type-handler full-width">
                              <mat-label>Sensor Type</mat-label>
                              <mat-select [(value)]="strSensorTypeId" placeholder="Sensor Type *">
                                 <mat-option *ngFor="let type of arrObjSensorTypes" [value]="type.key">
                                    {{ type.name }}
                                 </mat-option>
                              </mat-select>
                           </mat-form-field>

                           <span class="pointer delete-item ml-3" fxLayout fxLayoutAlign="center center">
                              <mat-icon (click)="onDoneEditSensorType()">close</mat-icon>
                           </span>
                        </div>
                     </ng-template>
                  </mat-card-subtitle>
                  <div *ngIf="!arrParams[0]['isSet']" class="flex-wrapper">
                     <mat-spinner></mat-spinner>
                  </div>
                  <div *ngIf="arrParams[0]['isSet']" class="table-responsive">
                     <!--display wrapper-->
                     <table
                        *ngIf="!arrParams[0]['isRowHeader'] && arrParams[0]['types']"
                        class="table table-bordered table-striped">
                        <!--header type-->
                        <thead>
                           <tr>
                              <th *ngFor="let head of arrParams[0]['types']['heads']">
                                 {{ head.name }}
                              </th>
                           </tr>
                        </thead>

                        <tbody *ngIf="arrParams[0]['isSet']">
                           <tr *ngFor="let data of arrParams[0]['values']; let colInd = index">
                              <td *ngFor="let head of arrParams[0]['types']['heads']">
                                 <div *ngIf="data[head.id] === 'primaryKey'">
                                    {{ colInd + 1 }}
                                 </div>

                                 <div *ngIf="data[head.id] !== 'primaryKey'">
                                    {{ data[head.id] ? data[head.id] : 'N/A' }}
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <!--header type-->

                     <table
                        *ngIf="arrParams[0]['isRowHeader'] && arrParams[0]['types']"
                        class="table table-bordered table-striped">
                        <!--header row type-->
                        <thead *ngIf="arrParams[0]['isHeaderShow']">
                           <tr>
                              <th *ngFor="let head of arrParams[0]['types']['heads']">
                                 {{ head.name }}
                              </th>
                           </tr>
                        </thead>

                        <tbody
                           *ngIf="arrParams[0]['isSet'] && arrParams[0]['isRowShow'] && arrParams[0]['types']['heads']">
                           <tr *ngFor="let row of arrParams[0]['types']['rows']">
                              <td *ngFor="let head of arrParams[0]['types']['heads']">
                                 <div
                                    *ngIf="
                                       ((!arrParams[0]['values'] ||
                                          (arrParams[0]['values'] && !arrParams[0]['values'][row.id])) &&
                                          head['primaryKey']) ||
                                       (arrParams[0]['values'] &&
                                          arrParams[0]['values'][row.id] &&
                                          arrParams[0]['values'][row.id][head.id] === 'primaryKey')
                                    ">
                                    {{ row.name }}
                                 </div>
                                 <div *ngIf="arrParams[0]['values']">
                                    <div
                                       *ngIf="
                                          arrParams[0]['values'][row.id] &&
                                          arrParams[0]['values'][row.id][head.id] !== 'primaryKey'
                                       ">
                                       {{
                                          arrParams[0]['values'][row.id][head.id]
                                             ? arrParams[0]['values'][row.id][head.id]
                                             : 'N/A'
                                       }}
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        </tbody>

                        <tbody
                           *ngIf="
                              arrParams[0]['isSet'] && arrParams[0]['isRowShow'] && !arrParams[0]['types']['heads']
                           ">
                           <tr *ngFor="let row of arrParams[0]['types']['rows']">
                              <td>{{ row.name }}</td>
                              <td>
                                 <div *ngIf="arrParams[0]['values']">
                                    {{ arrParams[0]['values'][row.id] ? arrParams[0]['values'][row.id] : 'N/A' }}
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <!--header row type-->
                  </div>
                  <!--display wrapper-->
               </div>

               <div class="table-responsive">
                  <table class="table table-bordered table-striped">
                     <ng-container *ngIf="sensorCalibration$ | async as sensorCalibration; else emptyRowCalibration">
                        <tr>
                           <td>Last Calibration Date and Time</td>
                           <td>{{ sensorCalibration?.dateTime | date : 'long' }}</td>
                        </tr>
                        <tr>
                           <td>Last Calibration User</td>
                           <td>{{ sensorCalibration?.userName }}</td>
                        </tr>
                     </ng-container>
                     <ng-template #emptyRowCalibration>
                        <tr>
                           <td colspan="100%" style="background-color: rgb(194, 221, 248); text-align: center">
                              No calibration data
                           </td>
                        </tr>
                     </ng-template>
                  </table>
               </div>
            </div>
         </div>
      </mat-card-content>
   </mat-card>

   <mat-card
      appearance="outlined"
      *ngIf="
         nActionNumber === 4 &&
         ((strUserType === arrStrUserTypes[0] &&
            (strUserRole === arrStrUserRoles[0] || strUserRole === arrStrUserRoles[1])) ||
            (strUserType === arrStrUserTypes[1] && strUserRole === arrStrUserRoles[0]))
      ">
      <mat-card-title>System Parameter Keys</mat-card-title>
      <div *ngIf="arrParams && arrParams.length > 0 && arrParams[0]['types']">
         <div *ngIf="arrParams[0]['types']['tableType'] === arrStrTableTypes[1]">
            <div *ngIf="arrParams[0]['types']['heads'] && arrParams[0]['types']['heads'].length > 0">
               <mat-card-subtitle>Headers</mat-card-subtitle>
               <mat-card-content class="content">
                  <div class="table-responsive">
                     <app-key-table [data]="arrParams[0]['types']['heads']"></app-key-table>
                  </div>
               </mat-card-content>
            </div>

            <div *ngIf="arrParams[0]['types']['rows'] && arrParams[0]['types']['rows'].length > 0">
               <mat-card-subtitle>Rows</mat-card-subtitle>
               <mat-card-content class="content">
                  <div class="table-responsive">
                     <app-key-table [data]="arrParams[0]['types']['rows']"></app-key-table>
                  </div>
               </mat-card-content>
            </div>

            <div *ngIf="arrParams[0]['types']['tableType'] === arrStrTableTypes[0]">
               <mat-card-subtitle>Headers</mat-card-subtitle>
               <mat-card-content class="content">
                  <div class="table-responsive">
                     <app-key-table [data]="arrParams[0]['types']['heads']"></app-key-table>
                  </div>
               </mat-card-content>
            </div>
         </div>
      </div>
   </mat-card>

   <mat-card
      appearance="outlined"
      *ngIf="
         nActionNumber === 4 &&
         ((strUserType === arrStrUserTypes[0] &&
            (strUserRole === arrStrUserRoles[0] || strUserRole === arrStrUserRoles[1])) ||
            (strUserType === arrStrUserTypes[1] && strUserRole === arrStrUserRoles[0]))
      ">
      <mat-card-title>Analytical Keys</mat-card-title>
      <div *ngIf="arrParams && arrParams.length > 1 && arrParams[1]['types']">
         <div *ngIf="arrParams[1]['types']['tableType'] === arrStrTableTypes[1]">
            <div *ngIf="arrParams[1]['types']['heads'] && arrParams[1]['types']['heads'].length > 0">
               <mat-card-subtitle>Headers</mat-card-subtitle>
               <mat-card-content class="content">
                  <div class="table-responsive">
                     <app-key-table [data]="arrParams[1]['types']['heads']"></app-key-table>
                  </div>
               </mat-card-content>
            </div>

            <div *ngIf="arrParams[1]['types']['rows'] && arrParams[1]['types']['rows'].length > 0">
               <mat-card-subtitle>Rows</mat-card-subtitle>
               <mat-card-content class="content">
                  <div class="table-responsive">
                     <app-key-table [data]="arrParams[1]['types']['rows']"></app-key-table>
                  </div>
               </mat-card-content>
            </div>

            <div *ngIf="arrParams[1]['types']['tableType'] === arrStrTableTypes[0]">
               <mat-card-subtitle>Headers</mat-card-subtitle>
               <mat-card-content class="content">
                  <div class="table-responsive">
                     <app-key-table [data]="arrParams[1]['types']['heads']"></app-key-table>
                  </div>
               </mat-card-content>
            </div>
         </div>
      </div>
   </mat-card>

   <mat-card appearance="outlined" *ngIf="nActionNumber === 4">
      <mat-card-title>System Parameter Logs</mat-card-title>
      <mat-card-content>
         <app-system-parameter-logs [sensorKey]="sensorKey" [sensorType]="sensorType"></app-system-parameter-logs>
      </mat-card-content>
   </mat-card>
</div>

<ng-template #noConfig>
   <ng-container *ngIf="selectedSensor.weatherSensor; else noWeather"> NA </ng-container>
</ng-template>

<ng-template #noWeatherWind>
   <ng-container *ngIf="selectedSensor.weatherSensor; then emptyDirection; else noWeather"> </ng-container>
</ng-template>

<ng-template #noWeather>
   <span matTooltip="Weather sensor is not configured" class="pointer material-icons red-color"> highlight_off </span>
</ng-template>

<ng-template #emptyDirection> </ng-template>
