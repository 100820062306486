<div class="reset-container">
  <mat-card appearance="outlined">
    <mat-card-title>
      Reset Cache Page
    </mat-card-title>
    
    <mat-card-content>
      <div *ngIf="bIsProcess;else waitEle" fxLayout="column" fxLayoutAlign="center center">
        <div class="alert alert-info">Reset caches successfully!</div>
        <a [routerLink]="['/']">Go to the portal</a>
      </div>
      <ng-template #waitEle>
        <div class="alert alert-warning">Processing to reset caches...</div>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
