import { CalibrationUpdateModalComponent } from './../calibration-update-modal/calibration-update-modal.component';
import { Location } from '@angular/common';
import {
   ChangeDetectorRef,
   Component,
   ElementRef,
   Input,
   OnChanges,
   OnDestroy,
   OnInit,
   ViewChild,
} from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { serverTimestamp } from 'firebase/database';
import { combineLatest, forkJoin, Observable, Subject, throwError, TimeoutError, Subscription } from 'rxjs';
import {
   catchError,
   debounceTime,
   distinctUntilChanged,
   filter,
   first,
   map,
   switchMap,
   take,
   takeUntil,
   timeout,
} from 'rxjs/operators';
import { NotificationModalComponent } from 'src/app/modals/notification-modal/notification-modal.component';
import { environment } from '../../../environments/environment';
import { global_variables } from '../../../environments/global_variables';
import { RolesEnum } from '../../admin/roles.enum';
import { SpinnerService } from '../../components/spinner/spinner.service';
import { ConfigModalComponent } from '../../modals/config-modal/config-modal.component';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { EditModalComponent } from '../../modals/edit-modal/edit-modal.component';
import { RepeatConfirmModalComponent } from '../../modals/repeat-confirm-modal/repeat-confirm-modal.component';
import { ExcelSheet } from '../../models/xls-sheat';
import { AnalyticalService } from '../../services/analytical.service';
import { AuthService } from '../../services/auth.service';
import { HttpService } from '../../services/http.service';
import { NotificationService } from '../../services/notification.service';
import { PurehttpService } from '../../services/purehttp.service';
import moment from 'moment-timezone';
import { alert } from '../../../alert';
import { timezones } from '../../shared/timezones';
import { convertToDate } from '../../helpers/date-helpers';
import * as FileSaver from 'file-saver-es';
import * as _ from 'lodash-es';
import { NoPermissionsComponent } from '../calibration-steps/no-permissions/no-permissions.component';

import { ConfigDataService } from '../../services/config-data.service';
import { merge, zip } from 'rxjs';
// @ts-nocheck
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
declare let google: any;
const OMNI_PREDEFINED_LABEL = 'Omni-22xx';

export enum nStatusEnum {
   none = 0,
   name = 1,
   address = 2,
   availability = 3,
   lat = 4,
   lng = 5,
   description = 6,
   serialNumber = 7,
   timezone = 8,
   customerName = 9,
   zoneName = 10,
}

@Component({
   selector: 'app-sensor-detail',
   templateUrl: './sensor-detail.component.html',
   styleUrls: ['./sensor-detail.component.scss'],
})
export class SensorDetailComponent implements OnInit, OnDestroy, OnChanges {
   @Input() selectedSensor: any;
   @Input() sensorKey: string;
   @ViewChild('chartBodyElement') chartBodyElement: ElementRef;
   @ViewChild('tvocEle') tvocEle: ElementRef;
   @ViewChild('adcTabElement') adcTabElement: any;
   @ViewChild('cdcTabElement') cdcTabElement: any;
   @ViewChild('flowTabElement') flowTabElement: any;
   @ViewChild('analyticalPaginator') analyticalPaginator: MatPaginator;
   @ViewChild('rawPaginator') rawPaginator: MatPaginator;
   @ViewChild('processedPaginator') processedPaginator: MatPaginator;

   oneMinuteSamplingFile = '';
   twoMinuteSamplingFile = '';
   samplingFile = '';
   OMNI_PREDEFINED_LABEL = OMNI_PREDEFINED_LABEL;
   timezones: Array<string> = timezones;
   // ALERT
   alert = alert;
   queryParamSub: Subscription;
   arrCategorySub: Subscription[];
   sensorTypeSub: Subscription;
   sensorActionSub: Subscription;
   rawAllSub: Subscription;
   processedDataSub: Subscription;
   analyticalDataSub: Subscription;
   tvocDataRef: Subscription;
   analyticalData: any;
   rawData: any;
   searchOneMinute = '';
   searchTwoMinute = '';
   arrSamplingTimes: Array<object> = [];

   plotlyADCData: any;
   plotlyADCLayout: Object;
   plotlyADCStyle: Object;

   plotlyCDCData: any;
   objectKeys = Object.keys;
   plotlyCDCLayout: Object;
   plotlyCDCStyle: Object;
   runsDict: Object;

   tvocData: any[];
   tvocLayout: Object;
   tvocStyle: Object;

   plotlyFlowData: any;
   plotlyFlowLayout: Object;
   plotlyFlowStyle: Object;

   arrPlotyChromatogramData: Object[];
   plotlyProcessLayout: Object;
   plotlyProcessStyle: Object;
   public timezone: string;

   configData: any;
   orginConfigData: any;

   strSensorTypeName: string;
   paramValue: string;
   error: string;
   strUserRole: string;
   arrStrUserRoles: string[];
   arrStrUserTypes: string[];
   arrStrStepActions: string[];
   arrStrProcessedDataTypes: string[];
   arrStrAnalyticalInputs: string[];
   arrStrDisplayedColumns: string[];
   arrStrProcessedDataColumns: string[];
   arrStrDebugDisplayedColumns: string[];
   arrStrTableTypes: string[];
   responseValue: string;
   strChromatogramTime: string;
   strPeakTime: string;
   strChartCSVUrl: string;
   strLCDChartDate: string;
   strUserType: string;
   strSelectedProcessKey: string;
   strFireFunctionUrl: string;
   strSelectedRawKey: string;
   strSelectedRawCycle: string;
   strSelectedAnalyticKey: string;
   strSelectedAnalyticCycle: string;
   strAnalyticalDate: string;
   strAnalyticalStartTime: string;
   strProcessStartTime: string;
   strRawStartTime: string;
   strSensorTypeId: string;
   strWifiIP: string;
   strCellularIP: string;
   strGasLibrary: string;
   strAmbientTotalVOC: string;
   strProcessedDataFilterStatus: string;
   strAnalyticalDataFilterStatus: string;

   processData: any[];

   nStatusEnum = nStatusEnum;
   nActionNumber: number;
   nStatus: nStatusEnum;
   nSelectedChartInd: number;
   nActionStatus: number;
   nDeleteProcessTotal: number;
   nRunPercent: number;
   nRunRemainTime: number;
   nStepPercent: number;
   nStepRemainTime: number;
   nRunTotalTime: number;
   nStepTotalTime: number;
   nWifiSignal: number;
   nCellularSignal: number;
   nCurrentModalType: number;
   nReportingInterval: number;
   nTVOCStartTimestamp: number;
   nTVOCEquivalent: number;
   nAnalyticalPageIndex: number;
   nProcessedDataPageIndex: number;
   nRawDataPageIndex: number;
   nAnalyticalPageSize: number;
   nProcessedDataPageSize: number;
   nRawDataPageSize: number;

   isTypeLoading: boolean;
   isRowHeader: boolean;
   isEditStatus: boolean;
   bIsLoadRawData: boolean;
   isAdcCalled: boolean;
   isCdcCalled: boolean;
   isFlowCalled: boolean;
   isGetConfig: boolean;
   isOnlineDevice: boolean;
   bIsGetAllRawData: boolean;
   bIsProcessedData: boolean;
   bIsChromatogram: boolean;
   bIsPeakLoad: boolean;
   bIsStuff: boolean;
   bIsProgressBar: boolean;
   bIsDoneProgress: boolean;
   bIsReadRaw: boolean;
   bIsReadProcess: boolean;
   bIsReadAnalytic: boolean;
   bIsProgressDes: boolean;
   bIsAnalyticalData: boolean;
   bIsParseAnalyticData: boolean;
   bIsShowRententionProperties: boolean;
   bIsSensorTypeEdit: boolean;
   bIsDebugData: boolean;
   bIsTVOC: boolean;
   bIsTVOCStatus: boolean;
   bIsUpdateTVOC: boolean;
   bIsStartCycleLoad: boolean;
   bIsMulitpleProcessedLoad: boolean;
   bIsProcessedChromatogramLoad: boolean;
   bIsMulProcessedPeakmLoad: boolean;
   bIsShowUnChemicals: boolean;
   bIsOverMultiProcessedDataSelection: boolean;

   isStatus: boolean;
   isVocAnalytics: boolean;
   isVocAnalyticsEditable: boolean;
   isDebugEditable: boolean;

   sensorType: Object;
   runProgress: Object;
   crpLabelColumns: Object;
   processedDataItemsSelected: Object;
   analyticalDataItemsSelected: Object;
   arrObjRawFiles: Object[]; // raw data files
   arrObjProcessedFiles: any[]; // processed data files
   runs: any[]; // runs data
   arrObjAnalyticList: any[]; // analytical data files
   arrObjDebugFiles: Object[];
   arrAnalyticalCycles: Object[];
   arrObjRawCycles: Object[];
   arrRunProgress: Object[];
   arrStepProgress: Object[];
   arrStrPeakValues: any[];
   arrValueTypes: string[];
   arrStrPeakHeaders: string[];
   arrStrRetentionProperties: string[];
   arrParams: Object[];
   arrObjNewRawData: Object[];
   arrObjNewProcessedData: Object[];
   arrObjNewAnalyticalData: Object[];
   arrObjSensorTypes: Object[];
   arrAnalyticalInputs: any[];
   arrMulAnalyticalInputs: any[];
   arrMulPeakData: any[];
   arrMulChromatogramInfo: any[];
   arrMulCdcInfo: any[];
   arrXTVOCs: number[];
   arrYTVOCs: number[];

   PARATYPE = ['option'];
   SENSOR_MAP = [
      'name',
      'address',
      'availability',
      'lat',
      'lng',
      'description',
      'serialNumber',
      'timezone',
      'customerName',
      'zoneName',
   ];
   arrStrAvailabilites: string[];
   arrCRPColumns: string[];

   analyticalDataSource: any;
   processedDataSource: any;
   debugDataSource: any;
   rawDataSource: any;
   crpDataSource: any; // chemical recognition parameter datasource
   geocoder: any;
   sensorTypesSub: any;
   debugDataSub: any;
   public startTimestamp: Date;
   public endTimestamp: Date;
   globalFilter: string;
   fromDate: Date;
   toDate: Date;
   globalFilterStr$: Subject<string> = new Subject<string>();
   strRawDataFilterStatus: string;
   public analyticDataItemsInvalid: Object;
   public rawDataItemsInvalid: Object;
   public processDataItemsInvalid: Object;
   private destroy$: Subject<boolean> = new Subject();
   private rawDataItemsSelected: Object;
   private globalFilterSub: Subscription;
   private bIsMulRawDataLoad: boolean;
   private bIsOverMultiRawDataSelection: boolean;
   private mulAnalyticalData: any[];
   private analyticalRows: string[];
   private isUserStaff: boolean;
   isRawCheckSelected: boolean;
   isProcessCheckSelected: boolean;
   isAnalyticalCheckSelected: boolean;
   private entireAlertTemplate: any;
   private filteredTimeZones: Array<string> = this.timezones;
   private actionStatusText: string;
   private readonly currentDataType = 'Frac_Delta';
   sensor: Object;
   sensorCalibration$: Observable<any>;
   customers: any[] = [];
   zones: any[] = [];
   public totalTime = 0;
   public arrConfigs: Array<object> = [];
   public filteredConfigsOne: Array<object> = [];
   public filteredConfigsTwo: Array<object> = [];
   public totalAnalyticFiles: any[] = [];
   filesCount = 0;
   private runsSub: Subscription;
   private parseChromatogramSub: Subscription;
   private parsePeaksSub: Subscription;
   private onLoadConfigDataSub: Subscription;
   private deviceSub: Subscription;
   private getRawDataSub: Subscription;
   private analyticalSub: Subscription;
   private sensorDeviceResSub: Subscription;
   private downloadSub: Subscription;
   private processDataPeakSub: Subscription;
   private mulRawDataSub: Subscription;
   private analyticalDataMulSub: Subscription;

   get filteredZones() {
      return this.zones.filter(z => z.customerId === this.selectedSensor.customerId) || [];
   }

   private stopShowCalibrationUpdateModal$: Subject<boolean> = new Subject();

   isExportAnalyticalDataDisabled = false;

   dataSpanDays = 14;
   dataSpanFrom = moment();

   get getDataSpanFrom() {
      return this.dataSpanFrom.isSame(moment(), 'day') ? 'Today' : this.dataSpanFrom.fromNow();
   }

   showExportDaysControl = false;

   constructor(
      private _httpService: HttpService,
      private _analyticalService: AnalyticalService,
      private _spinner: SpinnerService,
      private _router: Router,
      private _activeRoute: ActivatedRoute,
      private _nofication: NotificationService,
      //public _mapApiLoader: MapsAPILoader,
      private _purehttpService: PurehttpService,
      public dialog: MatDialog,
      public _snackBar: MatSnackBar,
      private _location: Location,
      private _authService: AuthService,
      private matIconRegistry: MatIconRegistry,
      private domSanitizer: DomSanitizer,
      private storage: AngularFireStorage,
      private _cdRef: ChangeDetectorRef,
      private configDataService: ConfigDataService
   ) {
      this.nActionNumber = -1; /* 0: status, 1:voc analytics, 2:voc raw, 3:processed data, 4:debug */
      this.nStatus = nStatusEnum.none; // 0: none, 1>=: edit status
      this.nActionStatus = -1;
      this.nSelectedChartInd = 0;
      this.nRunPercent = 0;
      this.nRunRemainTime = 0;
      this.nStepPercent = 0;
      this.nStepRemainTime = 0;
      this.nRunTotalTime = 0;
      this.nStepTotalTime = 0;
      this.nWifiSignal = 0;
      this.nCellularSignal = 0;
      this.nCurrentModalType = -1;
      this.nReportingInterval = 10;
      this.nTVOCEquivalent = -1;
      this.nAnalyticalPageIndex = 0;
      this.nProcessedDataPageIndex = 0;
      this.nRawDataPageIndex = 0;
      this.nAnalyticalPageSize = 50;
      this.nProcessedDataPageSize = 50;
      this.nRawDataPageSize = 50;

      this.isTypeLoading = false;
      this.bIsProgressDes = false;

      this.isRowHeader = false;
      this.isEditStatus = false;
      this.isStatus = false;
      this.isVocAnalytics = false;
      this.isVocAnalyticsEditable = false;
      this.isDebugEditable = false;
      this.isGetConfig = false;
      this.isOnlineDevice = false;
      this.bIsGetAllRawData = false;
      this.bIsProcessedData = false;
      this.bIsChromatogram = false;
      this.bIsPeakLoad = false;
      this.bIsProgressBar = false;
      this.bIsDoneProgress = false;
      this.bIsReadRaw = true;
      this.bIsReadProcess = true;
      this.bIsReadAnalytic = true;
      this.bIsAnalyticalData = false;
      this.bIsParseAnalyticData = false;
      this.bIsShowRententionProperties = false;
      this.bIsSensorTypeEdit = false;
      this.bIsDebugData = false;
      this.bIsTVOC = false;
      this.bIsTVOCStatus = false;
      this.bIsUpdateTVOC = false;
      this.bIsStartCycleLoad = false;
      this.bIsMulitpleProcessedLoad = false;
      this.bIsProcessedChromatogramLoad = false;
      this.bIsMulProcessedPeakmLoad = false;
      this.bIsMulRawDataLoad = false;
      this.bIsShowUnChemicals = false;
      this.bIsOverMultiProcessedDataSelection = false;

      this.arrObjRawFiles = [];
      this.arrObjProcessedFiles = [];
      this.processData = [];
      this.arrStrPeakValues = [];
      this.arrStrPeakHeaders = [];
      this.arrRunProgress = [];
      this.arrStepProgress = [];
      this.arrPlotyChromatogramData = [];
      this.arrObjNewRawData = [];
      this.arrObjNewProcessedData = [];
      this.arrObjAnalyticList = [];
      this.arrObjNewAnalyticalData = [];
      this.arrAnalyticalCycles = [];
      this.arrObjRawCycles = [];
      this.arrObjSensorTypes = [];
      this.arrObjDebugFiles = [];
      this.arrXTVOCs = [];
      this.arrYTVOCs = [];
      this.arrCRPColumns = [];
      this.arrMulPeakData = [];
      this.mulAnalyticalData = [];
      this.arrMulChromatogramInfo = [];
      this.crpLabelColumns = {};
      this.processedDataItemsSelected = {};
      this.analyticalDataItemsSelected = {};
      this.rawDataItemsSelected = {};
      this.rawDataItemsInvalid = {};
      this.analyticDataItemsInvalid = {};
      this.processDataItemsInvalid = {};
      this.strRawDataFilterStatus = '';
      this.strSelectedProcessKey = '';
      this.strSelectedRawKey = '';
      this.strSelectedRawCycle = '';
      this.strSelectedAnalyticKey = '';
      this.strSelectedAnalyticCycle = '';
      this.strAnalyticalStartTime = '';
      this.strProcessStartTime = '';
      this.strRawStartTime = '';
      this.strWifiIP = '';
      this.strCellularIP = '';
      this.strProcessedDataFilterStatus = '';
      this.strAnalyticalDataFilterStatus = '';
      this.strGasLibrary = 'Isobutylene';
      this.strAmbientTotalVOC = 'N/A';

      this.matIconRegistry.addSvgIcon(
         'startIcon',
         this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/start.svg')
      );
      this.matIconRegistry.addSvgIcon(
         'stopIcon',
         this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/stop.svg')
      );
      this.matIconRegistry.addSvgIcon(
         'exitIcon',
         this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/exit.svg')
      );
      this.matIconRegistry.addSvgIcon(
         'rebootIcon',
         this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/reboot.svg')
      );
      this.matIconRegistry.addSvgIcon(
         'calibration',
         this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/calibration.svg')
      );

      // for sensor types
      this.arrCategorySub = [];
      this.arrParams = [];

      for (let i = 0; i < global_variables['Categories'].length; i++) {
         this.arrParams[i] = {
            types: <any>{},
            values: [],
            isRowHeader: false,
            isHeaderShow: false,
            isRowShow: false,
            isSet: false,
         };
      }

      this.arrStrDisplayedColumns = [
         'select',
         'position',
         'startTimestamp',
         'type',
         'name',
         'date',
         'size',
         'weather_wind',
         'wind_direction',
         'actions',
         'comment',
      ];
      this.arrStrProcessedDataColumns = [
         'select',
         'position',
         'startTimestamp',
         'type',
         'name',
         'date',
         'size',
         'weather_wind',
         'wind_direction',
         'actions',
         'comment',
      ];
      this.arrStrDebugDisplayedColumns = ['position', 'name', 'date', 'size'];
      this.arrStrUserRoles = global_variables['userRoles'];

      this.analyticalRows = ['Chemical_Name', 'Concentration (ppb)'].concat(global_variables['AnalyticalInputs']);
      this.arrStrUserTypes = global_variables['userTypes'];
      this.arrStrAvailabilites = global_variables['deviceStatus'];
      this.arrStrStepActions = global_variables['stepActions'];
      this.arrStrProcessedDataTypes = global_variables['ProcessedDataTypes'];
      this.arrValueTypes = global_variables['ValueTypes'];
      this.arrStrRetentionProperties = global_variables['RententionProperties'];
      this.arrStrAnalyticalInputs = global_variables['AnalyticalInputs'];
      this.arrStrTableTypes = global_variables['tableTypes'];
      this.strFireFunctionUrl = environment['FirebaseFunctionUrlCloud']; // cloud
      this.globalFilter = '';
      this.crpDataSource = new MatTableDataSource();
   }

   ngOnInit() {
      if (!this.sensorKey) {
         this._snackBar.open("The sensor key can't be empty", 'Error', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });

         this._router.navigate(['/dashboard']);
         return;
      }

      if (this.globalFilterSub) {
         this.globalFilterSub.unsubscribe();
      }

      this.onLoadConfigFiles().subscribe({
         next: configs => {
            console.log('onLoadConfigFiles');
            let pos = 0;
            this.arrConfigs = configs
               .filter(item => {
                  return item.modalType === 2; // omni-2100
               })
               .sort((a, b) => {
                  return parseFloat(b.timestamp) - parseFloat(a.timestamp);
               })
               .map(item => {
                  pos++;

                  return {
                     position: pos,
                     name: item['path'].split('/').pop(),
                     url: item['configUrl'],
                     path: item['path'],
                     time: this.convertToDate(item['timestamp']),
                     key: item['key'],
                  };
               });
            this.filteredConfigsOne = this.arrConfigs.slice();
            this.filteredConfigsTwo = this.arrConfigs.slice();

            if (!this.selectedSensor['maxRuns']) {
               this.selectedSensor['maxRuns'] = 999;
               this.onChangeMaxRuns(null, true);
            }

            if (this.selectedSensor['fastMode']) {
               this.selectedSensor['fastModeConfigName2'] = 'Fast Mode Config 2';
               this.selectedSensor['fastModeConfigName1'] = 'Fast Mode Config 1';

               if (!this.selectedSensor['fastModeConfigName1']) {
                  void this.onChangeFastModeLabel('Fast Mode Config 1', 'one');
               }

               if (!this.selectedSensor['fastModeConfigName2']) {
                  void this.onChangeFastModeLabel('Fast Mode Config 2', 'two');
               }
            }

            if (this.selectedSensor['fastModeFile']) {
               this.oneMinuteSamplingFile = this.selectedSensor['fastModeFile']['key'];
               this.arrSamplingTimes[0] = this.selectedSensor['fastModeFile'];
               this.arrSamplingTimes[0]['name'] = this.selectedSensor['fastModeConfigName1'] || 'Fast Mode Config 1';
            }
            if (this.selectedSensor['fastModeFile2']) {
               this.twoMinuteSamplingFile = this.selectedSensor['fastModeFile2']['key'];
               this.arrSamplingTimes[1] = this.selectedSensor['fastModeFile2'];
               this.arrSamplingTimes[1]['name'] = this.selectedSensor['fastModeConfigName2'] || 'Fast Mode Config 2';
            }

            if (this.selectedSensor['samplingFile']) {
               this.samplingFile = this.selectedSensor['samplingFile']['key'];
            }
         },
         error: error => {
            console.error('sensor-detail.component.ts -> ', error);
         },
      });

      this.globalFilterSub = this.globalFilterStr$.pipe(distinctUntilChanged(), debounceTime(500)).subscribe({
         next: filter => {
            switch (this.nActionNumber) {
               case 1:
                  this.applyFilter(this.analyticalDataSource, filter);
                  break;
               case 2:
                  this.applyFilter(this.rawDataSource, filter);
                  break;
               case 3:
                  this.applyFilter(this.processedDataSource, filter);
                  break;
            }
         },
         error: error => console.error('sensor-detail.component.ts -> globalFilterSub: ', error),
      });

      if (!this.selectedSensor) {
         this._snackBar.open("The sensor can't be empty", 'Error', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });

         this._router.navigate(['/dashboard']);
         return;
      }

      if (this._authService.isCheckUser && this._authService.isUserEmailLoggedIn) {
         this.initData();
         this.getCustomersData();
         this.getZones();
      }
   }

   isAdminRole() {
      return this.strUserRole === RolesEnum.ADMIN;
   }

   /**
    * init data
    */
   initData() {
      this.strUserRole = this._authService.userData['action']['role'];
      this.strSensorTypeId = this.selectedSensor.sensorTypeId;
      this.isUserStaff = this._authService.isUserStaff;
      this.loadGeoCoder();
      this.checkUserType();

      this.queryParamSub = this._activeRoute.queryParams.subscribe({
         next: params => {
            this.isEditStatus = params['type'] === 'edit';
            console.log('this.isEditStatus', params);
         },
         error: error => console.error('sensor-detail.component.ts -> initData(): ', error),
      });

      if (this.sensorActionSub) {
         this.sensorActionSub.unsubscribe();
      }

      const sensorDeviceUrl = environment.APIS.SENSORDEVICES;
      this.sensorActionSub = this._httpService
         .getAsObject(`${sensorDeviceUrl}/${this.sensorKey}`)
         .pipe(
            switchMap(device => {
               if (device) {
                  if (device['actionStatus'] || device['actionStatus'] === 0) {
                     this.nActionStatus = device['actionStatus'];
                     console.log('this.nActionStatus', this.nActionStatus);
                  }

                  this.actionStatusText = device['actionStatusText'];

                  this.strWifiIP = device['wifiIP'] ? device['wifiIP'] : 'N/A';
                  this.strCellularIP = device['cellularIP'] ? device['cellularIP'] : 'N/A';
                  this.nWifiSignal = this.calculateSignalStrength(device['wifiSignal']);
                  this.nCellularSignal = this.calculateSignalStrength(device['cellularSignal']);
                  this.bIsTVOCStatus = device['tvocStatus'] && device['tvocStatus'] === 'on' ? true : false;
                  this.nTVOCStartTimestamp = device['tvocStartTimestamp'] ? device['tvocStartTimestamp'] : null;
                  this.strGasLibrary = device['gatLibraryName'] ? device['gatLibraryName'] : '';
               }

               console.log('sensorActionSub');
               return this._httpService.getAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`);
            })
         )
         .subscribe({
            next: sensor => {
               this.sensor = sensor;
               console.log({ sensor });
            },
            error: error => {
               console.log(error);
            },
         });

      if (this.deviceSub) {
         this.deviceSub.unsubscribe();
      }

      this.deviceSub = this._httpService.getAsObject(`${sensorDeviceUrl}/${this.sensorKey}`, 1).subscribe({
         next: device => {
            console.log('deviceSub');
            if (device) {
               if (!device.hasOwnProperty('resVal')) {
                  const updateResValue = <any>{};
                  updateResValue['resVal'] = '';

                  this._httpService.updateAsObject(`${sensorDeviceUrl}/${this.sensorKey}`, updateResValue).then(
                     () => {
                        console.log('Sensor device resVal field is set to default status');
                     },
                     error => console.error(error)
                  );
               }
            }
         },
         error: error => console.error('sensor-detail.component.ts -> deviceSub: ', error),
      });

      if (this.sensorTypesSub) {
         this.sensorTypesSub.unsubscribe();
      }
      this.sensorTypesSub = this._httpService.getAsList(`${environment.APIS.SENSORTYPES}`).subscribe({
         next: sensorTypes => {
            console.log('SENSORTYpe', sensorTypes);
            if (sensorTypes && sensorTypes.length > 0) {
               const sensorItem = sensorTypes.find(type => type['typeName'] === this.OMNI_PREDEFINED_LABEL);

               this.arrObjSensorTypes = sensorTypes
                  .filter(item => item['typeName'] === this.OMNI_PREDEFINED_LABEL)
                  .map(type => {
                     return {
                        key: type['key'],
                        name: type['typeName'],
                     };
                  });
               console.log('fastMode: ', this.selectedSensor['fastMode'], sensorItem);
               console.log('normalMode: ', !this.selectedSensor['fastMode']);

               this.showNewCalibrationModal(sensorItem);
               if (sensorItem.alertTemplate) {
                  this.entireAlertTemplate = sensorItem.alertTemplate;
                  return;
               }

               if (sensorItem) {
                  this._httpService
                     .updateAsObject(`${environment.APIS.SENSORTYPES}/${this.selectedSensor.sensorTypeId}`, this.alert)
                     .then(() => {
                        this.entireAlertTemplate = this.alert.alertTemplate;
                     })
                     .catch(e => this._nofication.createNotification('warning', 'Warning', e));
               }
            }
         },
         error: error => console.error('sensor-detail.component.ts -> sensorTypes: ', error),
      });

      this.tvocDataRef = this._httpService
         .getAsObject(`${environment['APIS']['SENSORDATA']}/tvoc/${this.sensorKey}`)
         .subscribe({
            next: (data: any) => {
               if (this.bIsTVOCStatus && this.nTVOCStartTimestamp) {
                  if (this.bIsUpdateTVOC) {
                     console.log('Updating TVOC data...');
                  } else {
                     this.arrXTVOCs = [];
                     this.arrYTVOCs = [];
                     const limit = 120;
                     let startX = 0;
                     let endX = limit;
                     let minY = Infinity;
                     let maxY = 0;
                     let latestTimestamp = 0;

                     if (data && Object.keys(data).length > 0) {
                        delete data['key'];
                        const currentTimestamp = Date.now();
                        const distance = (currentTimestamp - this.nTVOCStartTimestamp) / 1000 / 60;
                        const over = distance % limit;
                        const times = Math.floor(distance / limit);
                        console.log('times: ', times);
                        console.log('start time distance: ', distance);
                        const latestData = {};
                        if (distance <= limit) {
                           startX = 0;
                           endX = limit;
                        } else {
                           startX = over + (times - 1) * limit;
                           endX = distance;
                        }

                        for (const key in data) {
                           if (data.hasOwnProperty(key)) {
                              const element = data[key];
                              let bIsAppendant = false;
                              let xAxios;
                              if (element['timestamp'] >= this.nTVOCStartTimestamp) {
                                 if (distance <= limit) {
                                    xAxios = (element['timestamp'] - this.nTVOCStartTimestamp) / 1000 / 60;
                                    bIsAppendant = true;
                                 } else {
                                    xAxios = (element['timestamp'] - this.nTVOCStartTimestamp) / 1000 / 60;

                                    if (xAxios > (times - 1) * limit && xAxios <= (times + 1) * limit) {
                                       bIsAppendant = true;
                                    }
                                 }

                                 const value = element['value'] > 0 ? element['value'] : 0;

                                 if (bIsAppendant) {
                                    this.arrXTVOCs.push(xAxios);
                                    this.arrYTVOCs.push(value);
                                    if (minY > parseFloat(value)) {
                                       minY = parseFloat(value);
                                    }

                                    if (maxY < parseFloat(value)) {
                                       maxY = parseFloat(value);
                                    }

                                    if (latestTimestamp < element['timestamp']) {
                                       latestTimestamp = element['timestamp'];
                                       this.nTVOCEquivalent = value;
                                    }
                                 }
                              }

                              const elementDistance = (currentTimestamp - element['timestamp']) / 1000 / 60;
                              if (elementDistance <= limit * 2) {
                                 latestData[key] = data[key];
                              }
                           }
                        }

                        if (Object.keys(data).length - Object.keys(latestData).length > 100) {
                           this.bIsUpdateTVOC = true;
                           this._httpService
                              .postAsObject(`${environment['APIS']['SENSORDATA']}/tvoc/${this.sensorKey}`, latestData)
                              .then(() => {
                                 this.bIsUpdateTVOC = false;
                              });
                        }
                     }

                     this.buildTvocChart(startX, endX, maxY, minY);
                  }
               } else {
                  this.arrXTVOCs = [];
                  this.arrYTVOCs = [];
                  this.buildTvocChart();
               }
            },
            error: error => console.error('sensor-detail.component.ts -> tvocDataRef: ', error),
         });

      if (this.debugDataSub) {
         this.debugDataSub.unsubscribe();
      }
      this.debugDataSub = this._httpService.getAsList(`${environment.APIS.DEBUGDATA}/${this.sensorKey}`).subscribe({
         next: debgData => {
            this.arrObjDebugFiles = [];

            if (debgData && debgData.length > 0) {
               let position = 0;

               this.arrObjDebugFiles = debgData
                  .filter(item => {
                     return item['timestamp'] && item['storagePath'];
                  })
                  .sort((a: Object, b: Object) => {
                     return parseFloat(b['timestamp']) - parseFloat(a['timestamp']);
                  })
                  .map((item: Object) => {
                     position++;

                     return {
                        position: position,
                        name:
                           item['storagePath'] && item['storagePath'].indexOf('/') > -1
                              ? item['storagePath'].split('/').pop()
                              : '',
                        url: item['storageUrl'] ? item['storageUrl'] : '',
                        date: item['timestamp'] ? convertToDate(item['timestamp'], this.selectedSensor) : 'N/A',
                        size: this.formatBytes(item['fileSize']),
                     };
                  });
            }

            this.bIsDebugData = true;
            this.debugDataSource = new MatTableDataSource(this.arrObjDebugFiles);
         },
         error: error => console.error('sensor-detail.component.ts -> debugDataSub: ', error),
      });

      this.sensorCalibration$ = this._httpService
         .getAsObject(`${environment.APIS.SENSORCALIBRATION}/${this.sensorKey}/history`)
         .pipe(
            map(data => {
               if (data && data.key) {
                  delete data.key;
               }
               const [lastHistory, ...other] = _.orderBy(data, 'dateTime', 'desc');
               return lastHistory;
            })
         );
   }

   getCustomersData() {
      this._httpService
         .getAsList(environment.APIS.CUSTOMERS)
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: customers => {
               this.customers = customers;
            },
            error: error => {
               console.log(error);
            },
         });
   }

   /**
    * check if the time is zero
    * @param time of date
    */
   checkDate(time: string) {
      if (time.length === 1) {
         time = '0' + time;
      }
      return time;
   }

   /**
    * convert the firebase timestamp to local date
    * @param timestamp date
    */
   convertToDate(timestamp: any) {
      const date = new Date(timestamp);
      let day = date.getDate() + '';
      let month = date.getMonth() + 1 + '';
      let year = date.getFullYear() + '';
      let hour = date.getHours() + '';
      let minutes = date.getMinutes() + '';
      let seconds = date.getSeconds() + '';

      day = this.checkDate(day);
      month = this.checkDate(month);
      year = this.checkDate(year);
      hour = this.checkDate(hour);
      minutes = this.checkDate(minutes);
      seconds = this.checkDate(seconds);
      return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
   }

   getZones() {
      this._httpService
         .getAsList(environment.APIS.ZONES)
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: zones => {
               this.zones = zones;
            },
            error: error => {
               console.log(error);
            },
         });
   }

   /**
    * calculate signal strength
    * @param nSignalStrengthPercent signal strength percentage
    */
   calculateSignalStrength(nSignalStrengthPercent: number) {
      if (!nSignalStrengthPercent) {
         return 0;
      }

      if (nSignalStrengthPercent >= 90) {
         return 5;
      } else if (nSignalStrengthPercent >= 70) {
         return 4;
      } else if (nSignalStrengthPercent >= 50) {
         return 3;
      } else if (nSignalStrengthPercent >= 30) {
         return 2;
      } else if (nSignalStrengthPercent >= 10) {
         return 1;
      }

      return 0;
   }

   loadGeoCoder() {
      /* this._mapApiLoader.load().then(() => {
         this.geocoder = new google.maps.Geocoder();
      }); */

      /* const loader = new Loader({
         apiKey: environment.googleAPIKey,
         version: "weekly",
       }); */

      //loader.importLibrary('maps').then(() => {
      google.maps.importLibrary('maps').then(() => {
         this.geocoder = new google.maps.Geocoder();
      });
   }

   checkUserType() {
      if (this._authService.isUserStaff) {
         // staff
         this.strUserType = this.arrStrUserTypes[0];
      } else {
         // customer
         this.strUserType = this.arrStrUserTypes[1];
      }
   }

   /**
    * convert size formated bytes
    * @param bytes number
    */
   formatBytes(bytes: any) {
      bytes = parseInt(bytes, 10);

      if (isNaN(bytes)) {
         return 'Unknown';
      } else {
         if (bytes < 1024) {
            return bytes + ' Bytes';
         } else if (bytes < 1048576) {
            return (bytes / 1024).toFixed(3) + ' KB';
         } else if (bytes < 1073741824) {
            return (bytes / 1048576).toFixed(3) + ' MB';
         } else {
            return (bytes / 1073741824).toFixed(3) + ' GB';
         }
      }
   }

   /**
    * load one cycle data for analytical, processed and raw data
    * @param objData one row
    * @param nType 0 - analytic, 1 - processed, 2 - raw
    * @param nPosition index of rows
    */
   onLoadOneCycleData(objData: Object, nType: number, nPosition: number) {
      this.bIsStartCycleLoad = true;
      this._analyticalService.bIsMultipleAnalyticalData = false;
      this.bIsOverMultiRawDataSelection = true;
      this.bIsOverMultiProcessedDataSelection = false;
      // load analytic data
      if (nType === 0) {
         this.onLoadAnalyticalData(objData, nPosition, false);
      } else {
         this.strSelectedAnalyticCycle = '';

         if (this.arrObjAnalyticList) {
            const arrObjFilteredAnalyticCycle = this.arrAnalyticalCycles.filter(function (objItem) {
               return objItem['cycleIndex'] === objData['cycleIndex'];
            });

            if (
               arrObjFilteredAnalyticCycle.length > 0 &&
               arrObjFilteredAnalyticCycle[0]['data'] &&
               arrObjFilteredAnalyticCycle[0]['data'].length > 0
            ) {
               this.onLoadAnalyticalData(
                  arrObjFilteredAnalyticCycle[0]['data'][0],
                  arrObjFilteredAnalyticCycle[0]['position']
               );
            }
         }
      }

      // load processed data
      if (nType === 1) {
         this.onLoadProcessedData(objData, nPosition, false);
      } else {
         this.strSelectedProcessKey = '';

         if (this.arrObjProcessedFiles) {
            const arrObjProcessedData = this.arrObjProcessedFiles.filter(function (objItem) {
               return objItem['cycleIndex'] === objData['cycleIndex'];
            });

            if (arrObjProcessedData.length > 0) {
               this.onLoadProcessedData(arrObjProcessedData[0], arrObjProcessedData[0]['position']);
            }
         }
      }

      // load raw data
      if (nType === 2) {
         this.onLoadRawData(objData, nPosition, false);
      } else {
         if (this.arrObjRawFiles) {
            const arrObjFilteredRawCycle = this.arrObjRawCycles.filter(function (objItem) {
               return objItem['cycleIndex'] === objData['cycleIndex'];
            });

            if (
               arrObjFilteredRawCycle.length > 0 &&
               arrObjFilteredRawCycle[0]['data'] &&
               arrObjFilteredRawCycle[0]['data'].length > 0
            ) {
               if (arrObjFilteredRawCycle[0]['data'].length > 1) {
                  const arrObjSeparationData = arrObjFilteredRawCycle[0]['data'].filter((obj: Object) => {
                     return obj['type'] === global_variables['StepTypes'][3]; // separation step
                  });

                  if (arrObjSeparationData.length > 0) {
                     this.onLoadRawData(arrObjSeparationData[0], arrObjFilteredRawCycle[0]['position']);
                  } else {
                     this.onLoadRawData(arrObjFilteredRawCycle[0]['data'][0], arrObjFilteredRawCycle[0]['position']);
                  }
               } else {
                  this.onLoadRawData(arrObjFilteredRawCycle[0]['data'][0], arrObjFilteredRawCycle[0]['position']);
               }
            }
         }
      }
   }

   /**
    * Load analytic data
    * @param objAnalyticData anaylytical data
    * @param nPosition index of rows
    * @param bIsUpdatePageIndex flag to get page index
    */
   onLoadAnalyticalData(objAnalyticData: Object, nPosition: number = 0, bIsUpdatePageIndex: boolean = true) {
      this.strSelectedAnalyticKey = objAnalyticData['key'];
      this.strProcessedDataFilterStatus = 'select';

      if (bIsUpdatePageIndex) {
         this.nAnalyticalPageIndex = this.getPaginateIndex(nPosition, this.nAnalyticalPageSize);
      }
      this.strSelectedAnalyticCycle = objAnalyticData['cycleIndex'];
      this.getAnalyticalFileData(objAnalyticData['url']);
      this.strAnalyticalDate = objAnalyticData['date'];
      this.arrAnalyticalInputs = objAnalyticData['input'];
      this.strAnalyticalStartTime = convertToDate(objAnalyticData['cycleIndex'], this.selectedSensor);
   }

   /**
    * load processed data
    * @param objProcessedData processed data
    * @param nPosition index of rows
    * @param bIsUpdatePageIndex flag to get page index
    */
   onLoadProcessedData(objProcessedData: Object, nPosition: number = 0, bIsUpdatePageIndex: boolean = true) {
      this.strSelectedProcessKey = objProcessedData['key'];
      this.bIsMulitpleProcessedLoad = false;
      this.strProcessedDataFilterStatus = 'select';

      if (bIsUpdatePageIndex) {
         this.nProcessedDataPageIndex = this.getPaginateIndex(nPosition, this.nProcessedDataPageSize);
      }

      if (objProcessedData['data'] && objProcessedData['data'].length > 0) {
         for (let i = 0; i < objProcessedData['data'].length; i++) {
            if (objProcessedData['data'][i]['type'] === this.arrStrProcessedDataTypes[0]) {
               // Chromatogram
               const objC = objProcessedData['data'][i];
               this.parseChromatogram(objC['url'], objC['date']);
            }

            if (objProcessedData['data'][i]['type'] === this.arrStrProcessedDataTypes[1]) {
               // Detected Peaks
               const objD = objProcessedData['data'][i];
               this.parsePeaks(objD['url'], objD['date']);
            }
         }
      }

      this.strProcessStartTime = convertToDate(objProcessedData['cycleIndex'], this.selectedSensor);
   }

   /**
    * load raw data
    * @param objRawData raw data
    * @param nPosition index of rows
    * @param bIsUpdatePageIndex flag to get page index
    */
   onLoadRawData(objRawData: Object, nPosition: number = 0, bIsUpdatePageIndex: boolean = true) {
      this.isCdcCalled = false;
      this.bIsMulRawDataLoad = false;
      this.isAdcCalled = false;
      this.isFlowCalled = false;
      this.strSelectedRawKey = objRawData['key'];
      if (bIsUpdatePageIndex) {
         this.nRawDataPageIndex = this.getPaginateIndex(nPosition, this.nRawDataPageSize);
      }
      this.strSelectedRawCycle = objRawData['cycleIndex'];
      this.getRawData(objRawData['url']);
      this.strLCDChartDate = objRawData['date'];
      this.strRawStartTime = objRawData['startTimestamp'];
   }

   /**
    * get analytical data through node api
    * @param strAnalyticalUrl analytical url
    */
   getAnalyticalFileData(strAnalyticalUrl: string) {
      this.bIsParseAnalyticData = false;
      const objPostData = {
         url: strAnalyticalUrl,
         isHeader: 'yes',
      };

      if (this.analyticalSub) {
         this.analyticalSub.unsubscribe();
      }
      this.analyticalSub = this._purehttpService
         .callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
         .subscribe({
            next: (res: any) => {
               this.analyticalData = res['data'];
               this.bIsParseAnalyticData = true;
            },
            error: error => {
               console.log('Fail to getting the analytic file data.');
               console.log(error);
            },
         });
   }

   /**
    * get raw data
    * @param strCSVUrl csv url
    */
   getRawData(strCSVUrl: string) {
      this.bIsLoadRawData = false;
      const objPostData = {
         url: strCSVUrl,
         isHeader: 'yes',
      };

      if (this.getRawDataSub) {
         this.getRawDataSub.unsubscribe();
      }
      this.getRawDataSub = this._purehttpService
         .callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
         .subscribe({
            next: (res: any) => {
               this.nSelectedChartInd = 0;
               this.rawData = res.data;
               this.bIsLoadRawData = true;
               this.checkChartElements(this.nSelectedChartInd);
            },
            error: error => {
               this.bIsLoadRawData = true;
               console.log('Fail to getting the csv file data.');
               console.log(error);
            },
         });
   }

   /**
    * get analytical data files
    */
   getAnalyticalData() {
      if (this.analyticalDataSub) {
         this.analyticalDataSub.unsubscribe();
      }

      console.time('getAnalyticalData');
      // analytical data files
      this.analyticalDataSub = this._httpService
         .getAsList(`${environment['APIS']['ANAYLYTICALDATA']}/${this.sensorKey}`)
         .subscribe({
            next: csvs => {
               this.arrObjAnalyticList = [];
               console.timeEnd('getAnalyticalData');
               this.arrAnalyticalCycles = [];
               this.analyticalData = [];

               if (csvs.length) {
                  if (this.bIsReadAnalytic) {
                     // readable
                     this.arrObjAnalyticList = csvs
                        .filter(item => {
                           return item['timestamp'] && item['startTimestamp'] && item['storagePath'];
                        })
                        .map((item: Object) => {
                           const selectedRunWeather = (
                              this.runs.find(r => r['startTimestamp'] === item['startTimestamp']) || {}
                           ).weather;

                           return {
                              description: item['description']
                                 ? item['description']
                                 : item['storagePath'] && item['storagePath'].indexOf('/') > -1
                                 ? item['storagePath'].split('/').pop()
                                 : '',
                              url: item['storageUrl'] ? item['storageUrl'] : '',
                              storageUrl: item['storagePath'] ? item['storagePath'] : '',
                              wind: (selectedRunWeather && selectedRunWeather.wind_avg) || null,
                              wind_direction: (selectedRunWeather && selectedRunWeather.wind_direction) || null,
                              date: item['timestamp'] ? convertToDate(item['timestamp'], this.selectedSensor) : 'N/A',
                              timestamp: item['timestamp'],
                              key: item['key'],
                              size: this.formatBytes(item['fileSize']),
                              startTimestamp: convertToDate(item['startTimestamp'], this.selectedSensor),
                              cycleIndex: item['startTimestamp'],
                              type: item['stepType'] ? item['stepType'] : 'N/A',
                              isReaded: item['isReaded'] ? item['isReaded'] : false,
                              comment: item['comment'] ? item['comment'] : '',
                              input: item['input'] ? item['input'] : [],
                           };
                        })
                        .sort((a: Object, b: Object) => {
                           return parseFloat(b['timestamp']) - parseFloat(a['timestamp']);
                        })
                        .sort((a: Object, b: Object) => {
                           return parseFloat(b['cycleIndex']) - parseFloat(a['cycleIndex']);
                        });

                     this.arrObjNewAnalyticalData = [];
                     let nCount = 0;

                     for (let i = 0; i < this.arrObjAnalyticList.length; i++) {
                        if (
                           i === 0 ||
                           (i > 0 &&
                              this.arrObjAnalyticList[i - 1]['cycleIndex'] !== this.arrObjAnalyticList[i]['cycleIndex'])
                        ) {
                           this.arrAnalyticalCycles.push({
                              cycleIndex: this.arrObjAnalyticList[i]['cycleIndex'],
                              startTimestamp: this.arrObjAnalyticList[i]['startTimestamp'],
                              data: [this.arrObjAnalyticList[i]],
                              position: nCount,
                           });
                           nCount++;
                        } else {
                           this.arrAnalyticalCycles[nCount - 1]['data'].push(this.arrObjAnalyticList[i]);
                        }

                        if (!this.arrObjAnalyticList[i]['isReaded']) {
                           this.arrObjNewAnalyticalData.push(this.arrObjAnalyticList[i]);
                        }
                     }

                     if (this.nActionNumber === 1 && this.arrObjNewAnalyticalData.length > 0) {
                        // check if current tab is raw data one
                        this.visitAnalyticData();
                     }

                     if (this.arrObjAnalyticList.length > 0) {
                        if (!this.strSelectedAnalyticKey) {
                           this.onLoadAnalyticalData(this.arrObjAnalyticList[0]);
                        } else {
                           const that = this;
                           const arrObjSelected = this.arrObjAnalyticList.filter(function (objItem) {
                              return objItem['key'] === that.strSelectedAnalyticKey;
                           });

                           if (arrObjSelected.length > 0) {
                              this.onLoadAnalyticalData(arrObjSelected[0]);
                           }
                        }
                     } else {
                        this.strSelectedAnalyticKey = '';
                        this.bIsParseAnalyticData = true;
                     }
                  }
               } else {
                  this.bIsParseAnalyticData = true;
               }

               this.bIsAnalyticalData = true;
               this.initAnalyticalDataTable();
            },
            error: error => {
               console.log(error);
            },
         });
   }

   initAnalyticalDataTable(nCount: number = 0) {
      if (this.nActionNumber === 1) {
         if (nCount > 50) {
            console.log('Timeout to init the analytical table, Try again.');
         } else if (!this.analyticalPaginator) {
            nCount++;
            setTimeout(() => this.initAnalyticalDataTable(nCount), 50);
         } else {
            if (this.analyticalDataSource) {
               this.analyticalDataSource.data = [...this.arrAnalyticalCycles];
               return;
            }
            this.analyticalDataSource = new MatTableDataSource(this.arrAnalyticalCycles);
            this.analyticalDataSource.filterPredicate = this.getFilterPredicate();
            this.analyticalDataSource.paginator = this.analyticalPaginator;
         }
      }
   }

   /**
    * get all data in RawData table
    */
   getAllRawData() {
      if (this.rawAllSub) {
         this.rawAllSub.unsubscribe();
      }

      // raw data files
      this.rawAllSub = this._httpService.getAsList(`${environment.APIS.RAWDATA}/${this.sensorKey}`).subscribe({
         next: csvs => {
            this.arrObjRawFiles = [];
            this.arrObjRawCycles = [];
            this.bIsLoadRawData = false;
            this.strSelectedRawKey = '';
            this.strSelectedRawCycle = '';

            if (csvs && csvs.length) {
               if (this.bIsReadRaw) {
                  // readable
                  this.arrObjRawFiles = csvs
                     .filter(item => {
                        return item['timestamp'] && item['startTimestamp'] && item['storagePath'];
                     })
                     .map(item => {
                        return {
                           description: item['description']
                              ? item['description']
                              : item['storagePath'] && item['storagePath'].indexOf('/') > -1
                              ? item['storagePath'].split('/').pop()
                              : '',
                           url: item['storageUrl'] ? item['storageUrl'] : '',
                           storageUrl: item['storagePath'] ? item['storagePath'] : '',
                           date: item['timestamp'] ? convertToDate(item['timestamp'], this.selectedSensor) : 'N/A',
                           timestamp: item['timestamp'],
                           key: item['key'],
                           size: this.formatBytes(item['fileSize']),
                           startTimestamp: convertToDate(item['startTimestamp'], this.selectedSensor),
                           cycleIndex: item['startTimestamp'],
                           type: item['stepType'] ? item['stepType'] : 'N/A',
                           isReaded: item['isReaded'] ? item['isReaded'] : false,
                           comment: item['comment'] ? item['comment'] : '',
                        };
                     })
                     .sort((a, b) => {
                        return parseFloat(b['timestamp']) - parseFloat(a['timestamp']);
                     })
                     .sort((a, b) => {
                        return parseFloat(b['cycleIndex']) - parseFloat(a['cycleIndex']);
                     });

                  this.arrObjNewRawData = [];
                  let nCount = 0;

                  for (let i = 0; i < this.arrObjRawFiles.length; i++) {
                     if (
                        i === 0 ||
                        (i > 0 && this.arrObjRawFiles[i - 1]['cycleIndex'] !== this.arrObjRawFiles[i]['cycleIndex'])
                     ) {
                        this.arrObjRawCycles.push({
                           cycleIndex: this.arrObjRawFiles[i]['cycleIndex'],
                           startTimestamp: this.arrObjRawFiles[i]['startTimestamp'],
                           data: [this.arrObjRawFiles[i]],
                           position: nCount,
                        });
                        nCount++;
                     } else {
                        this.arrObjRawCycles[nCount - 1]['data'].push(this.arrObjRawFiles[i]);
                     }

                     if (!this.arrObjRawFiles[i]['isReaded']) {
                        this.arrObjNewRawData.push(this.arrObjRawFiles[i]);
                     }
                  }

                  if (this.nActionNumber === 2 && this.arrObjNewRawData.length > 0) {
                     // check if current tab is raw data one
                     this.visitRawData();
                  }

                  if (this.arrObjRawFiles.length > 0) {
                     if (!this.strSelectedRawKey) {
                        this.onLoadRawData(this.arrObjRawFiles[0]);
                     } else {
                        const that = this;
                        const arrObjSelected = this.arrObjRawFiles.filter(function (objItem) {
                           return objItem['key'] === that.strSelectedRawKey;
                        });

                        if (arrObjSelected.length > 0) {
                           this.onLoadRawData(arrObjSelected[0]);
                        }
                     }
                  } else {
                     this.bIsLoadRawData = true;
                     this.strSelectedRawKey = '';
                     this.strSelectedRawCycle = '';
                  }
               }
            } else {
               this.bIsLoadRawData = true;
            }

            this.bIsGetAllRawData = true;
            this.initRawDataTable();
         },
         error: error => {
            console.log(error);
         },
      });
   }

   initRawDataTable(nCount: number = 0) {
      if (this.nActionNumber === 2) {
         if (nCount > 50) {
            console.log('Timeout to init the raw table, Try again.');
         } else if (!this.rawPaginator) {
            nCount++;
            setTimeout(() => this.initRawDataTable(nCount), 50);
         } else {
            if (this.rawDataSource) {
               this.rawDataSource.data = [...this.arrObjRawCycles];
               return;
            }
            this.rawDataSource = new MatTableDataSource(this.arrObjRawCycles);
            this.rawDataSource.filterPredicate = this.getFilterPredicate();
            this.rawDataSource.paginator = this.rawPaginator;
         }
      }
   }

   // TODO: 1570335842953

   /*
    *** get processed data files
    */
   getProcessedData() {
      if (this.processedDataSub) {
         this.processedDataSub.unsubscribe();
      }

      // processed data files
      this.processedDataSub = this._httpService
         .getAsList(`${environment.APIS.PROCESSEDDATA}/${this.sensorKey}`)
         .subscribe({
            next: csvs => {
               this.arrObjProcessedFiles = [];
               this.bIsChromatogram = false;
               this.bIsPeakLoad = false;
               this.strSelectedProcessKey = '';

               if (csvs.length) {
                  if (this.bIsReadProcess) {
                     // readable
                     let nInd = 0;
                     let nData = 0;

                     this.arrObjProcessedFiles = csvs
                        .filter(item => {
                           return item['startTimestamp'];
                        })
                        .sort((a: Object, b: Object) => {
                           return parseInt(b['startTimestamp'], 10) - parseInt(a['startTimestamp'], 10);
                        })
                        .map((item: Object) => {
                           const objChromatogram = item[this.arrStrProcessedDataTypes[0]];
                           const objDetectedPeaks = item[this.arrStrProcessedDataTypes[1]];
                           let nStartIndex = 0;

                           const objReturn = {
                              cycleIndex: item['startTimestamp'],
                              startTimestamp: convertToDate(parseInt(item['startTimestamp'], 10), this.selectedSensor),
                              key: item['key'],
                              isReaded: item['isReaded'] ? item['isReaded'] : false,
                              comment: item['comment'] ? item['comment'] : '',
                              data: [],
                              position: nData++,
                              isRunStart: false,
                           };

                           if (objChromatogram) {
                              objReturn['data'].push({
                                 description: objChromatogram['description']
                                    ? objChromatogram['description']
                                    : objChromatogram['storagePath'] && objChromatogram['storagePath'].indexOf('/') > -1
                                    ? objChromatogram['storagePath'].split('/').pop()
                                    : '',
                                 url: objChromatogram['storageUrl'],
                                 storageUrl: objChromatogram['storagePath'],
                                 date: convertToDate(parseInt(objChromatogram['timestamp'], 10), this.selectedSensor),
                                 timestamp: objChromatogram['timestamp'],
                                 size: this.formatBytes(objChromatogram['fileSize']),
                                 type: this.arrStrProcessedDataTypes[0],
                                 index: nInd++,
                                 startIndex: nStartIndex++,
                              });
                           }

                           if (objDetectedPeaks) {
                              objReturn['data'].push({
                                 description: objDetectedPeaks['description']
                                    ? objDetectedPeaks['description']
                                    : objDetectedPeaks['storagePath'] &&
                                      objDetectedPeaks['storagePath'].indexOf('/') > -1
                                    ? objDetectedPeaks['storagePath'].split('/').pop()
                                    : '',
                                 url: objDetectedPeaks['storageUrl'],
                                 storageUrl: objDetectedPeaks['storagePath'],
                                 date: convertToDate(parseInt(objDetectedPeaks['timestamp'], 10), this.selectedSensor),
                                 timestamp: objDetectedPeaks['timestamp'],
                                 size: this.formatBytes(objDetectedPeaks['fileSize']),
                                 type: this.arrStrProcessedDataTypes[1],
                                 index: nInd++,
                                 startIndex: nStartIndex++,
                              });
                           }

                           return objReturn;
                        });

                     this.arrObjNewProcessedData = [];

                     for (let i = 0; i < this.arrObjProcessedFiles.length; i++) {
                        if (!this.arrObjProcessedFiles[i]['isReaded']) {
                           this.arrObjNewProcessedData.push(this.arrObjProcessedFiles[i]);
                        }
                     }

                     if (this.nActionNumber === 3 && this.arrObjNewProcessedData.length > 0) {
                        // check if current tab is raw data one
                        this.visitProcessedData();
                     } else {
                        if (this.arrObjProcessedFiles.length > 0) {
                           if (!this.strSelectedProcessKey) {
                              this.onLoadProcessedData(this.arrObjProcessedFiles[0]);
                           } else {
                              const that = this;
                              const arrObjSelected = this.arrObjProcessedFiles.filter(function (objItem) {
                                 return objItem['key'] === that.strSelectedProcessKey;
                              });

                              if (arrObjSelected.length > 0) {
                                 this.onLoadProcessedData(arrObjSelected[0]);
                              }
                           }
                        } else {
                           this.bIsChromatogram = true;
                           this.bIsPeakLoad = true;
                           this.strSelectedProcessKey = '';
                        }
                     }
                  }
               } else {
                  this.bIsChromatogram = true;
                  this.bIsPeakLoad = true;
               }

               this.bIsProcessedData = true;
               if (this.arrObjProcessedFiles.length > 0) {
                  const cycleIndex = this.arrObjProcessedFiles[0]['cycleIndex'];
               }
               this.initProcessedDataTable();
            },
            error: error => {
               console.log(error);
            },
         });
   }

   initProcessedDataTable(nCount: number = 0) {
      if (this.nActionNumber === 3) {
         if (nCount > 50) {
            console.log('Timeout to init the processed table, Try again.');
         } else if (!this.processedPaginator) {
            nCount++;
            setTimeout(() => this.initProcessedDataTable(nCount), 50);
         } else {
            if (this.processedDataSource) {
               this.processedDataSource.data = [...this.arrObjProcessedFiles];
               return;
            }
            this.processedDataSource = new MatTableDataSource(this.arrObjProcessedFiles);
            this.processedDataSource.filterPredicate = this.getFilterPredicateProcessData();
            this.processedDataSource.paginator = this.processedPaginator;
         }
      }
   }

   /**
    * edit processed file name
    * @param key: key
    * @param data: processed data
    */
   onEditProcessedDataFile(key: string, data: Object) {
      if (!this.sensorKey) {
         this._snackBar.open('Current sensor is not available.', 'Alert', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });
         return;
      }

      if (!key) {
         this._snackBar.open('This data is not available to update the description.', 'Alert', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });
         return;
      }

      const config = {
         minWidth: '450px',
         disableClose: true,
         data: {
            inputType: 'text',
            value: data['description'],
         },
      };
      const dialogRef = this.dialog.open(EditModalComponent, config);

      dialogRef
         .afterClosed()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: result => {
               if (result['status'] === 'error') {
                  this._snackBar.open('Unsupported input type.', 'Error', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               } else if (result['status'] === 'success') {
                  if (!result['data']) {
                     this._snackBar.open('The sensor name should not be blank.', 'Alert', {
                        duration: 3000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                     });
                     return;
                  }

                  this._httpService
                     .updateAsObject(`${environment.APIS.PROCESSEDDATA}/${this.sensorKey}/${key}/${data['type']}`, {
                        description: result['data'],
                     })
                     .then(
                        () => {
                           this._snackBar.open('Name update is successful', 'Success', {
                              duration: 3000,
                              verticalPosition: 'top',
                              horizontalPosition: 'center',
                           });
                        },
                        error => {
                           console.error(error);
                           this._snackBar.open('Internet connection error, please try again later.', 'Error', {
                              duration: 3000,
                              verticalPosition: 'top',
                              horizontalPosition: 'center',
                           });
                        }
                     );
               }
            },
            error: error => console.error('sensor-detail.component.ts -> dialogRef: ', error),
         });
   }

   /**
    * edit raw data file name
    * @param data: raw data
    */
   onEditRawDataFile(data: Object) {
      if (!this.sensorKey) {
         this._snackBar.open('Current sensor is not available.', 'Alert', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });
         return;
      }

      if (!data['key']) {
         this._snackBar.open('This data is not available to update the description.', 'Alert', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });
         return;
      }

      const config = {
         minWidth: '450px',
         disableClose: true,
         data: {
            inputType: 'text',
            value: data['description'],
         },
      };
      const dialogRef = this.dialog.open(EditModalComponent, config);

      dialogRef
         .afterClosed()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: result => {
               if (result['status'] === 'error') {
                  this._snackBar.open('Unsupported input type.', 'Error', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               } else if (result['status'] === 'success') {
                  if (!result['data']) {
                     this._snackBar.open('The sensor name should not be blank.', 'Alert', {
                        duration: 3000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                     });
                     return;
                  }

                  this._httpService
                     .updateAsObject(`${environment.APIS.RAWDATA}/${this.sensorKey}/${data['key']}`, {
                        description: result['data'],
                     })
                     .then(
                        () => {
                           this._snackBar.open('Name update is successful', 'Success', {
                              duration: 3000,
                              verticalPosition: 'top',
                              horizontalPosition: 'center',
                           });
                        },
                        error => {
                           console.error(error);
                           this._snackBar.open('Internet connection error, please try again later.', 'Error', {
                              duration: 3000,
                              verticalPosition: 'top',
                              horizontalPosition: 'center',
                           });
                        }
                     );
               }
            },
            error: error => console.error('sensor-detail.component.ts -> dialogRef: ', error),
         });
   }

   /**
    * get paginate index
    * @param currentIndex index in array data
    * @param pageSize page size
    */
   getPaginateIndex(currentIndex: number, pageSize: number) {
      return Math.floor(currentIndex / pageSize);
   }

   ngOnChanges() {
      if (!this.sensorKey) {
         this._snackBar.open("The sensor key can't be empty", 'Error', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });

         this._location.back();
      }

      if (!this.isTypeLoading) {
         this._spinner.start();
      }

      if (this.selectedSensor && this.selectedSensor['availability'] === this.arrStrAvailabilites[0]) {
         // sensor is online
         this.isOnlineDevice = true;
      } else {
         this.isOnlineDevice = false;
      }

      if (this.selectedSensor && this.selectedSensor['timestamp']) {
         this.selectedSensor['timestamp'] = convertToDate(this.selectedSensor['timestamp'], this.selectedSensor);
      }

      if (this.sensorTypeSub) {
         this.sensorTypeSub.unsubscribe();
      }

      this.sensorTypeSub = this._httpService
         .getAsObject(`${environment.APIS.SENSORTYPES}/${this.selectedSensor.sensorTypeId}`)
         .subscribe({
            next: sensorType => {
               this.sensorType = sensorType;

               const arrStrCategories = global_variables['Categories'];
               const promisses = [];

               for (let i = 0; i < arrStrCategories.length; i++) {
                  promisses.push(this.getSensorParamData(arrStrCategories[i], i));
               }

               Promise.all(promisses).then(res => {
                  if (res[0]) {
                     this.getSystemParameterData();
                  } else {
                     this.strAmbientTotalVOC = 'N/A';
                  }

                  this.buildCRPTable();
               });

               this.strSensorTypeName = this.sensorType['typeName'];

               if (this.nActionNumber === -1) {
                  this.changeStatus(0);

                  if (!this.isTypeLoading) {
                     this.isTypeLoading = true;
                     this._spinner.stop();
                  }
               }
            },
            error: error => {
               console.log(error);
               this._router.navigate(['/dashboard']);
            },
         });

      if (this.nActionNumber === -1) {
         // if this is first load
         if (this.runsSub) {
            this.runsSub.unsubscribe();
         }

         this.runsSub = this._httpService
            .getAsList(`${environment.APIS.RUN}/${this.sensorKey}`)
            .pipe(
               first(),
               switchMap(sensorRuns => {
                  this.runs = sensorRuns;

                  this.runsDict = sensorRuns.reduce((prev, run) => {
                     prev[run['startTimestamp']] = run;
                     return prev;
                  }, {});

                  return this._httpService.getAsObject(`${environment.APIS.SENSORCONFIGS}/${this.sensorKey}`);
               })
            )
            .subscribe({
               next: config => {
                  this.orginConfigData = config;
                  if (config && (config['Current_Modal_Type'] || config['Current_Modal_Type'] === 0)) {
                     this.nCurrentModalType = config['Current_Modal_Type'];
                     this.configData = config[config['Current_Modal_Type']];
                     this.bIsProgressBar = false;
                     this.bIsDoneProgress = false;
                     this.buildProgressBar();
                  } else {
                     this.configData = null;
                  }
                  this.isGetConfig = true;
               },
               error: error => {
                  console.log(error);
               },
            });
      }
   }

   /**
    * build chemical recognition parameters
    */
   buildCRPTable() {
      if (
         this.arrParams[1] &&
         this.arrParams[1]['types']['heads'] &&
         this.arrParams[1]['types']['heads'].length > 0 &&
         this.arrParams[1]['types']['rows'] &&
         this.arrParams[1]['types']['rows'].length > 0
      ) {
         const data = [];
         this.crpLabelColumns = {};
         this.arrCRPColumns = [];

         for (let index = 0; index < this.arrParams[1]['types']['heads'].length; index++) {
            const head = this.arrParams[1]['types']['heads'][index];
            this.arrCRPColumns.push(head['id']);
            this.crpLabelColumns[head['id']] = head['name'];
         }

         for (let rowIndex = 0; rowIndex < this.arrParams[1]['types']['rows'].length; rowIndex++) {
            const row = this.arrParams[1]['types']['rows'][rowIndex];
            const item = {};

            for (let headerIndex = 0; headerIndex < this.arrParams[1]['types']['heads'].length; headerIndex++) {
               const header = this.arrParams[1]['types']['heads'][headerIndex];
               item[header['id']] = {
                  rowId: row['id'],
                  isPrimary: header['primaryKey'] ? true : false,
                  name: row['name'],
                  valueType: row['valueType'],
                  defaultValue: row['defaultValue'] ? row['defaultValue'] : '',
               };
            }

            data.push(item);
         }

         this.crpDataSource.data = data;
         console.log(this.arrParams);
      }
   }

   getSystemParameterData() {
      if (this.arrParams[0] && this.arrParams[0]['types']['rows'] && this.arrParams[0]['types']['rows'].length > 0) {
         let ambientTVOCIndex = -1;
         for (let index = 0; index < this.arrParams[0]['types']['rows'].length; index++) {
            const element = this.arrParams[0]['types']['rows'][index];
            if (element && element['name'] && element['name'] === 'Ambient Total VOC') {
               ambientTVOCIndex = index;
               if (this.arrParams[0]['values']) {
                  this.strAmbientTotalVOC = this.arrParams[0]['values'][element['id']]
                     ? this.arrParams[0]['values'][element['id']]
                     : 'N/A';
               }
            }
         }

         if (ambientTVOCIndex > -1) {
            this.arrParams[0]['types']['rows'].splice(ambientTVOCIndex, 1);
         }

         this.arrParams[0]['types']['rows'] = this.arrParams[0]['types']['rows']
            .filter(el => el['name'] !== 'Zerotier ID' || this.isStuff()) // hide Zerotier ID for non stuff
            .filter(el => el['name'] !== 'IMEI' || this.isStuff()); // hide IMEI for non stuff
      }
   }

   isStuff(): boolean {
      return this.strUserType === this.arrStrUserTypes[0];
   }

   getStepTime(nStep: number, nWaitTime: number, configData: any) {
      let nTime = 0;
      for (let j = 0; j < nStep; j++) {
         if (configData && configData.hasOwnProperty('Mode_Config')) {
            const strStepKey = `Step${j + 1}_Config`;
            if (configData['Mode_Config'].hasOwnProperty(strStepKey)) {
               if (configData['Mode_Config'][strStepKey]['stepAction'] === this.arrStrStepActions[0]) {
                  // measurement action
                  nTime += parseInt(configData['Mode_Config'][strStepKey]['Total_Run_Time'], 10);
               }

               nTime += nWaitTime;
            }
         }
      }

      return nTime;
   }

   buildProgressBar() {
      let nRunNumber = 0;
      let nStepNumber = 0;
      let runProgressPercent = 0;
      let stepProgressPercent = 0;
      let stepTotalTime = 0;
      let runTotalTime = 0;
      let stepRemainTime = 0;
      let runRemainTime = 0;
      let errorRunNumber = 0;
      let automToken = '';
      let startRunTimestamp: string;
      let endRunTimestamp: string;
      let timezone: string;
      const errorMsgs = [];

      if (!this.configData) {
         return;
      }

      if (this.configData.hasOwnProperty('errorRunNumber')) {
         errorRunNumber = parseInt(this.configData['errorRunNumber'], 10);
         if (this.configData['errorMsgs']) {
            for (const key in this.configData['errorMsgs']) {
               if (this.configData['errorMsgs'].hasOwnProperty(key)) {
                  const element = this.configData['errorMsgs'][key];
                  errorMsgs.push(element);
               }
            }
         }
      }

      if (this.configData.hasOwnProperty('runNumber')) {
         nRunNumber = parseInt(this.configData['runNumber'], 10);
      }

      if (this.configData.hasOwnProperty('stepNumber')) {
         nStepNumber = parseInt(this.configData['stepNumber'], 10);
      }

      if (this.configData.hasOwnProperty('runProgressPercent')) {
         runProgressPercent = parseInt(this.configData['runProgressPercent'], 10);
      }

      if (this.configData.hasOwnProperty('stepProgressPercent')) {
         stepProgressPercent = parseInt(this.configData['stepProgressPercent'], 10);
      }

      if (this.configData.hasOwnProperty('stepTotalTime')) {
         stepTotalTime = parseInt(this.configData['stepTotalTime'], 10);
      }

      if (this.configData.hasOwnProperty('runTotalTime')) {
         runTotalTime = parseInt(this.configData['runTotalTime'], 10);
      }

      if (this.configData.hasOwnProperty('stepRemainTime')) {
         stepRemainTime = parseInt(this.configData['stepRemainTime'], 10);
      }

      if (this.configData.hasOwnProperty('runRemainTime')) {
         runRemainTime = parseInt(this.configData['runRemainTime'], 10);
      }

      if (this.configData.hasOwnProperty('isFinished')) {
         if (String(this.configData['isFinished']) === 'true') {
            this.bIsDoneProgress = true;
         } else {
            this.bIsDoneProgress = false;
         }
      }

      let nRunTotal = 0;
      let nStepTotal = 0;
      let configData: any;

      if (
         this.configData.hasOwnProperty('Current_Type') &&
         this.configData.hasOwnProperty(this.configData['Current_Type'])
      ) {
         configData = this.configData[this.configData['Current_Type']];

         if (configData.hasOwnProperty('Num_of_Cycle')) {
            nRunTotal = parseInt(configData['Num_of_Cycle'], 10);
         }

         if (configData.hasOwnProperty('Num_of_Step')) {
            nStepTotal = parseInt(configData['Num_of_Step'], 10);
         }
      } else {
         console.log('The configuration data is not existed');
         return;
      }

      if (this.configData.hasOwnProperty('automToken')) {
         automToken = this.configData['automToken'];
      }

      timezone = this.selectedSensor.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (this.runs && automToken) {
         const currentRun =
            this.runs.find(r => r && r.automToken && r.automToken === automToken) || this.runs[this.runs.length - 1];
         if (currentRun['startTimestamp']) {
            startRunTimestamp = new Date(currentRun['startTimestamp']).toLocaleString('en-US', {
               timeZone: this.selectedSensor.timezone,
            });
            endRunTimestamp = new Date(currentRun['startTimestamp'] + runTotalTime * 1000).toLocaleString('en-US', {
               timeZone: this.selectedSensor.timezone,
            });
         }
      }

      this.runProgress = {
         runTotalNumber: nRunTotal,
         runNumber: nRunNumber,
         stepNumber: nStepNumber,
         runProgressPercent: runProgressPercent,
         stepProgressPercent: stepProgressPercent,
         stepTotalTime: stepTotalTime,
         runTotalTime: runTotalTime,
         stepRemainTime: stepRemainTime,
         runRemainTime: runRemainTime,
         errorRunNumber: errorRunNumber,
         errorMsgs: errorMsgs,
         startRunTimestamp: startRunTimestamp,
         endRunTimestamp: endRunTimestamp,
         timezone: timezone,
      };

      this.arrRunProgress = [];
      for (let i = 0; i < nRunTotal; i++) {
         if (i === errorRunNumber - 1) {
            // error report case
            this.arrRunProgress.push(3);
         } else if (nRunNumber - 1 > i) {
            // completed run case
            this.arrRunProgress.push(2);
         } else if (nRunNumber - 1 === i) {
            this.arrRunProgress.push(1);
         } else {
            this.arrRunProgress.push(0);
         }
      }

      this.arrStepProgress = [];
      for (let j = 0; j < nStepTotal; j++) {
         let strCategory = '';
         if (configData && configData.hasOwnProperty('Mode_Config')) {
            const strStepKey = `Step${j + 1}_Config`;
            if (configData['Mode_Config'].hasOwnProperty(strStepKey)) {
               if (configData['Mode_Config'][strStepKey]['stepAction'] === this.arrStrStepActions[0]) {
                  // measurement action
                  strCategory = configData['Mode_Config'][strStepKey]['Step_Type'];
               } else {
                  strCategory = configData['Mode_Config'][strStepKey]['stepAction'];
               }
            }
         }

         let status = 0;
         if (nStepNumber - 1 > j) {
            // completed run case
            status = 2;
         } else if (nStepNumber - 1 === j) {
            status = 1;
         } else {
            status = 0;
         }

         this.arrStepProgress[j] = {
            index: j + 1,
            status: status,
            categoryName: strCategory,
         };
      }

      this.bIsProgressDes = true;
      this.bIsProgressBar = true;
   }

   ngOnDestroy() {
      this.destoryDynSubs();

      if (this.analyticalDataSub) {
         this.analyticalDataSub.unsubscribe();
      }

      if (this.sensorActionSub) {
         this.sensorActionSub.unsubscribe();
      }

      if (this.queryParamSub) {
         this.queryParamSub.unsubscribe();
      }

      if (this.processedDataSub) {
         this.processedDataSub.unsubscribe();
      }

      if (this.rawAllSub) {
         this.rawAllSub.unsubscribe();
      }

      if (this.sensorTypesSub) {
         this.sensorTypesSub.unsubscribe();
      }

      if (this.debugDataSub) {
         this.debugDataSub.unsubscribe();
      }

      if (this.tvocDataRef) {
         this.tvocDataRef.unsubscribe();
      }

      for (let i = 0; i < 3; i++) {
         if (this.arrCategorySub[i]) {
            this.arrCategorySub[i].unsubscribe();
         }
      }

      this.destroy$.next(null);
      this.destroy$.complete();
   }

   destoryDynSubs() {
      if (this.sensorTypeSub) {
         this.sensorTypeSub.unsubscribe();
      }
   }

   onEditSensorType() {
      this.bIsSensorTypeEdit = true;
   }

   onDoneEditSensorType() {
      this.bIsSensorTypeEdit = false;
      const objUpdateVale = {
         sensorTypeId: this.strSensorTypeId,
      };

      this._httpService.updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`, objUpdateVale).then(
         res => {
            this._snackBar.open('Sensor type update is successful!', 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         },
         error => console.error(error)
      );
   }

   formatDate(data: string) {
      if (data.length === 1) {
         data = '0' + data;
      }
      return data;
   }

   // check chart wrapper element ability
   checkChartElements(nChartEleIndex: number, nRepeatCount: number = 0) {
      const arraTabEles = [this.cdcTabElement, this.adcTabElement, this.flowTabElement];
      const selectedTabEle = arraTabEles[nChartEleIndex];

      if (nRepeatCount > 100) {
         console.log('Timeout to wait for chart body element');
      } else if (!this.chartBodyElement || !selectedTabEle) {
         nRepeatCount++;
         setTimeout(() => this.checkChartElements(nChartEleIndex, nRepeatCount), 50);
      } else {
         const arrADCTime = [];
         const arrKp1 = [];
         const arrKp2 = [];
         const arrKp3 = [];
         const arrKp4 = [];
         const arrColumn1 = [];
         const arrColumn2 = [];
         const arrColumn3 = [];
         const arrPCF = [];
         const arrInjector = [];
         const arrThermistors1 = [];
         const arrThermistors2 = [];
         const arrThermistors3 = [];

         const arrCDCTime = [];
         const arrCapDET1 = [];
         const arrCapDET2 = [];

         const arrFlowTime = [];
         const arrFlow1 = [];
         const arrFlow2 = [];
         const arrFlow3 = [];
         let bIsThermistor = false;

         if (this.rawData) {
            for (let i = 0; i < this.rawData.length; i++) {
               const e = this.rawData[i];
               arrADCTime.push(parseFloat(e['ADC Timestamps']));
               arrKp1.push(parseFloat(e['KP1']) < 1000 ? parseFloat(e['KP1']) : null);
               arrKp2.push(parseFloat(e['KP2']) < 1000 ? parseFloat(e['KP2']) : null);
               arrKp3.push(parseFloat(e['KP3']) < 1000 ? parseFloat(e['KP3']) : null);
               arrKp4.push(parseFloat(e['KP4']) < 1000 ? parseFloat(e['KP4']) : null);
               arrColumn1.push(parseFloat(e['Column1']) < 1000 ? parseFloat(e['Column1']) : null);
               arrColumn2.push(parseFloat(e['Column2']) < 1000 ? parseFloat(e['Column2']) : null);
               arrColumn3.push(parseFloat(e['Column3']) < 1000 ? parseFloat(e['Column3']) : null);
               arrPCF.push(parseFloat(e['PCF']) < 1000 ? parseFloat(e['PCF']) : null);
               arrInjector.push(parseFloat(e['Injector']) < 1000 ? parseFloat(e['Injector']) : null);
               if (
                  e.hasOwnProperty('Thermistor1') &&
                  e.hasOwnProperty('Thermistor2') &&
                  e.hasOwnProperty('Thermistor3')
               ) {
                  arrThermistors1.push(parseFloat(e['Thermistor1']) < 1000 ? parseFloat(e['Thermistor1']) : null);
                  arrThermistors2.push(parseFloat(e['Thermistor2']) < 1000 ? parseFloat(e['Thermistor2']) : null);
                  arrThermistors3.push(parseFloat(e['Thermistor3']) < 1000 ? parseFloat(e['Thermistor3']) : null);
                  bIsThermistor = true;
               }

               arrCDCTime.push(parseFloat(e['CDC Timestamps']));
               arrCapDET1.push(parseFloat(e['CapDET1']) < 1000 ? parseFloat(e['CapDET1']) : null);
               arrCapDET2.push(parseFloat(e['CapDET2']) < 1000 ? parseFloat(e['CapDET2']) : null);

               arrFlowTime.push(parseFloat(e['Flow Timestamps']));
               arrFlow1.push(parseFloat(e['Flow1']) < 1000 ? parseFloat(e['Flow1']) : null);
               arrFlow2.push(parseFloat(e['Flow2']) < 1000 ? parseFloat(e['Flow2']) : null);
               arrFlow3.push(parseFloat(e['Flow3']) < 1000 ? parseFloat(e['Flow3']) : null);
            }
         }

         if (!this.isCdcCalled && nChartEleIndex === 0) {
            this.buildCdcChart(arrCDCTime, arrCapDET1, arrCapDET2);
         }

         if (!this.isAdcCalled && nChartEleIndex === 1) {
            if (bIsThermistor) {
               this.buildAdcChart(
                  arrADCTime,
                  arrKp1,
                  arrKp2,
                  arrKp3,
                  arrKp4,
                  arrColumn1,
                  arrColumn2,
                  arrColumn3,
                  arrPCF,
                  arrInjector,
                  arrThermistors1,
                  arrThermistors2,
                  arrThermistors3
               );
            } else {
               this.buildAdcChart(
                  arrADCTime,
                  arrKp1,
                  arrKp2,
                  arrKp3,
                  arrKp4,
                  arrColumn1,
                  arrColumn2,
                  arrColumn3,
                  arrPCF,
                  arrInjector
               );
            }
         }

         if (!this.isFlowCalled && nChartEleIndex === 2) {
            this.buildFlowChart(arrFlowTime, arrFlow1, arrFlow2, arrFlow3);
         }
      }
   }

   buildAdcChart(
      arrADCTime: number[],
      arrKp1: number[],
      arrKp2: number[],
      arrKp3: number[],
      arrKp4: number[],
      arrColumn1: number[],
      arrColumn2: number[],
      arrColumn3: number[],
      arrPCF: number[],
      arrInjector: number[],
      arrThermistors1: number[] = [],
      arrThermistors2: number[] = [],
      arrThermistors3: number[] = []
   ) {
      const trace1 = {
         x: arrADCTime,
         y: arrKp1,
         type: 'scatter',
         mode: 'lines',
         name: 'KP1',
         visible: 'legendonly',
      };

      const trace2 = {
         x: arrADCTime,
         y: arrKp2,
         type: 'scatter',
         mode: 'lines',
         name: 'KP2',
         visible: 'legendonly',
      };

      const trace3 = {
         x: arrADCTime,
         y: arrKp3,
         type: 'scatter',
         mode: 'lines',
         name: 'KP3',
         visible: 'legendonly',
      };

      const trace4 = {
         x: arrADCTime,
         y: arrKp4,
         type: 'scatter',
         mode: 'lines',
         name: 'KP4',
         visible: 'legendonly',
      };

      const trace5 = {
         x: arrADCTime,
         y: arrColumn1,
         type: 'scatter',
         mode: 'lines',
         name: 'Column1',
      };

      const trace6 = {
         x: arrADCTime,
         y: arrColumn2,
         type: 'scatter',
         mode: 'lines',
         name: 'Column2',
      };

      const trace7 = {
         x: arrADCTime,
         y: arrColumn3,
         type: 'scatter',
         mode: 'lines',
         name: 'Column3',
      };

      const trace8 = {
         x: arrADCTime,
         y: arrPCF,
         type: 'scatter',
         mode: 'lines',
         name: 'PCF',
      };

      const trace9 = {
         x: arrADCTime,
         y: arrInjector,
         type: 'scatter',
         mode: 'lines',
         name: 'Injector',
      };

      const trace10 = {
         x: arrADCTime,
         y: arrThermistors1,
         type: 'scatter',
         mode: 'lines',
         name: 'arrThermistors1',
      };

      const trace11 = {
         x: arrADCTime,
         y: arrThermistors2,
         type: 'scatter',
         mode: 'lines',
         name: 'arrThermistors2',
      };

      const trace12 = {
         x: arrADCTime,
         y: arrThermistors3,
         type: 'scatter',
         mode: 'lines',
         name: 'arrThermistors3',
      };

      if (arrThermistors1.length > 0 && arrThermistors2.length > 0 && arrThermistors3.length > 0) {
         this.plotlyADCData = [
            trace1,
            trace2,
            trace3,
            trace4,
            trace5,
            trace6,
            trace7,
            trace8,
            trace9,
            trace10,
            trace11,
            trace12,
         ];
      } else {
         this.plotlyADCData = [
            trace1,
            trace2,
            trace3,
            trace4,
            trace5,
            trace6,
            trace7,
            trace8,
            trace9,
            trace10,
            trace11,
            trace12,
         ];
      }

      this.plotlyADCStyle = {
         height: '500px',
         width: '100%',
      };

      this.plotlyADCLayout = {
         yaxis: {
            title: 'Temperature (°C)',
         },
         xaxis: {
            title: 'Time (s)',
         },
      };

      this.isAdcCalled = true;
   }

   buildTvocChart(
      startX: number = 0,
      endX: number = 120,
      maxY: number = 0,
      minY: number = 0,
      nRepeatCount: number = 0
   ) {
      if (nRepeatCount > 100) {
         console.log('Timeout to wait for tvoc chart.');
      } else if (!this.tvocEle) {
         nRepeatCount++;
         setTimeout(() => this.buildTvocChart(startX, endX, maxY, minY, nRepeatCount), 50);
      } else {
         const trace1 = {
            x: this.arrXTVOCs,
            y: this.arrYTVOCs,
            type: 'scatter',
            mode: 'lines',
            name: 'TVOC',
            line: {
               width: 3,
            },
         };

         this.tvocData = [trace1];
         this.tvocStyle = {
            height: '280px',
            width: '100%',
         };

         if (maxY === 0 && minY === 0) {
            maxY = 1;
         }

         startX = Math.ceil(startX / 10) * 10;
         endX = Math.ceil(endX / 10) * 10;

         this.tvocLayout = {
            title: this.strGasLibrary ? `Live TVOC based on ${this.strGasLibrary} equivalent` : '',
            yaxis: {
               title: 'Concentration (ppm)',
               range: [minY * 0.9, maxY * 1.1],
            },
            xaxis: {
               title: 'Time (Minutes)',
               range: [startX, endX],
            },
         };

         this.bIsTVOC = true;
      }
   }

   onTVOCChange(event: any) {
      if (event['checked']) {
         const objGasLibraries = {
            Isobutylene: 1,
            'B/T/X': 0.53,
         };
         const nGasLibrary = objGasLibraries[this.strGasLibrary];
         this._httpService
            .updateAsObject(`${environment['APIS']['SENSORDEVICES']}/${this.sensorKey}`, {
               tvocStatus: 'on',
               reportingInterval: this.nReportingInterval,
               gasLibrary: nGasLibrary,
               gatLibraryName: this.strGasLibrary,
               //tvocStartTimestamp: database.ServerValue.TIMESTAMP,
               tvocStartTimestamp: serverTimestamp(),
            })
            .then(
               () => {
                  this.arrXTVOCs = [];
                  this.arrYTVOCs = [];
                  this.buildTvocChart();
                  this._nofication.createNotification('success', 'Success', 'Live TVOC is started!');
               },
               error => {
                  console.error(error);
                  this._nofication.createNotification('error', 'Error', 'Please try again later.');
               }
            );
      } else {
         this._httpService
            .updateAsObject(`${environment['APIS']['SENSORDEVICES']}/${this.sensorKey}`, {
               tvocStatus: 'off',
               tvocStartTimestamp: null,
            })
            .then(
               () => {
                  this._nofication.createNotification('success', 'Success', 'Live TVOC is stopped!');
               },
               error => {
                  console.error(error);
                  this._nofication.createNotification('error', 'Error', 'Please try again later.');
               }
            );
      }
   }

   buildCdcChart(arrCDCTime: number[], arrCapDET1: number[], arrCapDET2: number[]) {
      const trace1 = {
         x: arrCDCTime,
         y: arrCapDET1,
         type: 'scatter',
         mode: 'lines',
         name: 'CapDET1',
      };

      const trace2 = {
         x: arrCDCTime,
         y: arrCapDET2,
         type: 'scatter',
         mode: 'lines',
         name: 'CapDET2',
         visible: 'legendonly',
         marker: {
            color: 'green',
         },
      };

      this.plotlyCDCData = [trace1, trace2];
      this.plotlyCDCStyle = {
         height: '500px',
         width: '100%',
      };
      this.plotlyCDCLayout = {
         yaxis: { title: 'Capacitance (pF)' },
         xaxis: {
            title: 'Time (s)',
         },
      };

      this.isCdcCalled = true;
   }

   buildFlowChart(arrFlowTime: number[], arrFlow1: number[], arrFlow2: number[], arrFlow3: number[]) {
      const trace1 = {
         x: arrFlowTime,
         y: arrFlow1,
         type: 'scatter',
         mode: 'lines',
         name: 'Flow1',
         visible: 'legendonly',
      };

      const trace2 = {
         x: arrFlowTime,
         y: arrFlow2,
         type: 'scatter',
         mode: 'lines',
         name: 'Flow2',
         marker: {
            color: 'green',
         },
      };

      const trace3 = {
         x: arrFlowTime,
         y: arrFlow3,
         type: 'scatter',
         mode: 'lines',
         name: 'Flow3',
         visible: 'legendonly',
         marker: {
            color: 'purple',
         },
      };

      this.plotlyFlowData = [trace1, trace2, trace3];
      this.plotlyFlowStyle = {
         height: '500px',
         width: '100%',
      };
      this.plotlyFlowLayout = {
         yaxis: { title: 'Flow Rate (sccm)' },
         xaxis: {
            title: 'Time (s)',
         },
      };

      this.isFlowCalled = true;
   }

   onSelectTab(event: any) {
      this.checkChartElements(event);
   }

   /*
  get sensor params data for status, and analytical results
  *** params
  - strCategoryName: name like status, and analytical results
  - nCategory: number, 0: status, 1: analytical results
  */
   getSensorParamData(strCategoryName: string, nCategory: number): Promise<any> {
      return new Promise<any>((resolve, reject) => {
         // get the category name for the button clicked currently
         this.arrParams[nCategory]['types'] = this.sensorType[strCategoryName];

         if (!this.arrParams[nCategory]['types']) {
            this.arrParams[nCategory]['isSet'] = true;
            console.log('The sensor type of this sensor is not defined.');
            return resolve(false);
         }

         // get the table type of the sensor type
         let tableType;
         if (this.arrParams[nCategory]['types'].hasOwnProperty('tableType')) {
            tableType = this.arrParams[nCategory]['types']['tableType'];
         } else {
            this.arrParams[nCategory]['isSet'] = true;
            console.log('The selected sensor category does not have table type property');
            return resolve(false);
         }

         if (this.arrParams[nCategory]['types'].hasOwnProperty('heads')) {
            this.arrParams[nCategory]['isHeaderShow'] = true;
         } else {
            this.arrParams[nCategory]['isHeaderShow'] = false;
         }

         if (this.arrParams[nCategory]['types'].hasOwnProperty('rows')) {
            this.arrParams[nCategory]['isRowShow'] = true;
         } else {
            this.arrParams[nCategory]['isRowShow'] = false;
         }

         if (this.arrCategorySub[nCategory]) {
            this.arrCategorySub[nCategory].unsubscribe();
         }
         let categoryUrl = '';
         // Adjust categoryUrl based on fastMode
         if (!this.selectedSensor['fastMode']) {
            categoryUrl = `${environment['APIS']['SENSORDATA']}/${strCategoryName}/${this.selectedSensor['key']}/recent`;
         } else {
            categoryUrl = `${environment['APIS']['SENSORDATA']}/${strCategoryName}/${this.selectedSensor['key']}/fast/recent`;
         }
         this.arrCategorySub[nCategory] = this._httpService.getAsObject(categoryUrl).subscribe({
            next: (data: any) => {
               if (tableType === this.arrStrTableTypes[1]) {
                  // header_row_type
                  if (data) {
                     if (data.hasOwnProperty('$value') && !data['$value']) {
                        this.arrParams[nCategory]['values'] = null;
                     } else {
                        this.arrParams[nCategory]['values'] = data['value'];
                     }
                  }
               } else {
                  // header_type
                  this.arrParams[nCategory]['values'] = data ? data['value'] : null;
               }

               this.arrParams[nCategory]['isRowHeader'] = true;
               this.arrParams[nCategory]['isSet'] = true;
               return resolve(true);
            },
            error: error => {
               console.log(error);
               return resolve(false);
            },
         });
      });
   }

   /**
    * change checmical recognition parameters
    */
   onChangeChemicalParam(event: any, strRowId: string, strHeadId: string) {
      const strCategoryName = global_variables['Categories'][1];
      const categoryUrl = `${environment['APIS']['SENSORDATA']}/${strCategoryName}/${this.selectedSensor['key']}/recent/value`;
      const objUpdateVale = {
         [strHeadId]: event || event === 0 ? event : '',
      };

      this._httpService.updateAsObject(`${categoryUrl}/${strRowId}`, objUpdateVale).then(
         () => {
            this._snackBar.open('Chemical value update is successful!', 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         },
         error => console.error(error)
      );
   }

   onChangeDebugParameter(event: any, strRowId: string, strHeadId: string) {
      const strCategoryName = global_variables['Categories'][2];
      const categoryUrl = `${environment['APIS']['SENSORDATA']}/${strCategoryName}/${this.selectedSensor['key']}/recent/value`;
      const objUpdateVale = {
         [strHeadId]: event,
      };
      this._httpService.updateAsObject(`${categoryUrl}/${strRowId}`, objUpdateVale).then(
         () => {
            this._snackBar.open('Debug value update is successful!', 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         },
         error => console.error(error)
      );
   }

   /**
    * visit analytical data files
    */
   visitAnalyticData() {
      if (this.arrObjNewAnalyticalData.length > 0) {
         this.bIsReadAnalytic = false;
         this._spinner.start();
         const updateData = {};

         for (let i = 0; i < this.arrObjNewAnalyticalData.length; i++) {
            updateData[`${this.arrObjNewAnalyticalData[i]['key']}/isReaded`] = true;
         }

         this._httpService.updateAsObject(`${environment.APIS.ANAYLYTICALDATA}/${this.sensorKey}`, updateData).then(
            () => {
               console.log('All analytic data are read');
               this._spinner.stop();
               this.bIsReadAnalytic = true;
               this.getAnalyticalData();
            },
            error => {
               console.log(error);
               this._snackBar.open('Firebase connection error', 'Error', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            }
         );
      } else {
         this.bIsReadAnalytic = true;
      }
   }

   /**
    * visit raw data files
    */
   visitRawData() {
      if (this.arrObjNewRawData && this.arrObjNewRawData.length > 0) {
         this.bIsReadRaw = false;
         this._spinner.start();
         const updateData = {};

         for (let i = 0; i < this.arrObjNewRawData.length; i++) {
            updateData[`${this.arrObjNewRawData[i]['key']}/isReaded`] = true;
         }

         this._httpService.updateAsObject(`${environment.APIS.RAWDATA}/${this.sensorKey}`, updateData).then(
            () => {
               console.log('All raw data are read');
               this._spinner.stop();
               this.bIsReadRaw = true;
               this.getAllRawData();
            },
            error => {
               console.log(error);
               this._snackBar.open('Firebase connection error', 'Error', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            }
         );
      } else {
         this.bIsReadRaw = true;
      }
   }

   /**
    * visit processed data
    */
   visitProcessedData() {
      if (this.arrObjNewProcessedData.length > 0) {
         this.bIsReadProcess = false;
         this._spinner.start();
         const updateData = {};

         for (let i = 0; i < this.arrObjNewProcessedData.length; i++) {
            updateData[`${this.arrObjNewProcessedData[i]['key']}/isReaded`] = true;
         }

         this._httpService.updateAsObject(`${environment.APIS.PROCESSEDDATA}/${this.sensorKey}`, updateData).then(
            () => {
               console.log('All processed data are read');
               this._spinner.stop();
               this.bIsReadProcess = true;
               this.getProcessedData();
            },
            error => {
               console.log(error);
               this._snackBar.open('Firebase connection error', 'Error', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            }
         );
      } else {
         this.bIsReadProcess = true;
      }
   }

   changeStatus(statusIndex: number) {
      this.nActionNumber = statusIndex;
      this.strProcessedDataFilterStatus = 'select';
      this.strAnalyticalDataFilterStatus = 'select';
      this.strRawDataFilterStatus = 'select';
      this.clearFilter();

      switch (statusIndex) {
         case 0:
            break;

         case 1:
            this.getAnalyticalData();
            this.visitAnalyticData();
            break;

         case 2:
            this.getAllRawData();
            this.checkChartElements(this.nSelectedChartInd);
            this.visitRawData();
            break;

         case 3:
            this.getProcessedData();
            this.visitProcessedData();
            break;

         case 4:
            this.checkChartElements(this.nSelectedChartInd);
            break;

         default:
            // code...
            break;
      }
   }

   onEditValue(index: nStatusEnum) {
      if (this.strUserRole === this.arrStrUserRoles[3]) {
         this._snackBar.open("You don't have the edit permission.", 'Error', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });
      } else {
         this.nStatus = index;
         this.paramValue = this.selectedSensor[this.SENSOR_MAP[index - 1]];
      }
   }

   setNotification() {
      this._spinner.start();
      const { Gases, Errors, Calibrations } = this.entireAlertTemplate;
      const config = {
         panelClass: 'full-width-dialog',
         disableClose: true,
         data: {
            sensorKey: this.sensorKey,
            selectedSensor: this.selectedSensor,
            Gases,
            Errors,
            Calibrations,
            isAdminRole: this.isAdminRole(),
            sensorType: this.sensorType,
         },
      };

      const timeOut = setTimeout(() => {
         this._spinner.stop();
         const dialogRef = this.dialog.open(NotificationModalComponent, config);
      }, 1000);
   }

   onUpdate() {
      if (this.strUserRole === this.arrStrUserRoles[3]) {
         this._snackBar.open("You don't have the edit permission.", 'Error', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });
         return;
      }

      const strSensorUrl = environment.APIS.SENSORS;

      if (!this.paramValue) {
         this.error = 'This field is required';
         return;
      }

      this._spinner.start();
      const objUpdateValue = <any>{};
      if (this.nStatus === nStatusEnum.customerName) {
         const selectedCustomer = this.customers.find(c => c.name === this.paramValue) || {};
         const firstZone = (this.zones.filter(c => c.customerId === selectedCustomer.key) || [
            { key: null, name: null },
         ])[0];
         if (selectedCustomer.key === undefined || selectedCustomer.name === undefined) {
            this._snackBar.open("Can't find customer", 'Customer', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            this._spinner.stop();
            return;
         }
         objUpdateValue['customerId'] = selectedCustomer.key;
         objUpdateValue['customerName'] = selectedCustomer.name;
         objUpdateValue['zoneId'] = firstZone.key;
         objUpdateValue['zoneName'] = firstZone.name;
      } else if (this.nStatus === nStatusEnum.zoneName) {
         const selectedZone = this.zones.find(c => c.name === this.paramValue) || {};
         if (selectedZone.key === undefined || selectedZone.name === undefined) {
            this._snackBar.open("Can't find zone", 'Zone', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            this._spinner.stop();
            return;
         }
         objUpdateValue['zoneId'] = selectedZone.key;
         objUpdateValue['zoneName'] = selectedZone.name;
      } else {
         objUpdateValue[this.SENSOR_MAP[this.nStatus - 1]] = this.paramValue;
      }

      switch (this.nStatus) {
         case nStatusEnum.address:
            this.getLatitudeLongitude(this.showResult, this.paramValue, this);
            break;

         case nStatusEnum.lat:
            this.getAddress(parseFloat(this.paramValue), parseFloat((this.selectedSensor as any).lng));
            break;

         case nStatusEnum.lng:
            this.getAddress(parseFloat((this.selectedSensor as any).lat), parseFloat(this.paramValue));
            break;

         case nStatusEnum.serialNumber:
            this._httpService
               .getListByOrder(strSensorUrl, this.SENSOR_MAP[this.nStatus - 1], this.paramValue, 1)
               .subscribe({
                  next: sensors => {
                     if (sensors && sensors.length > 0) {
                        this._snackBar.open('This serial number is already existed.', 'Exist', {
                           duration: 3000,
                           verticalPosition: 'top',
                           horizontalPosition: 'center',
                        });
                        this._spinner.stop();
                     } else {
                        this._httpService
                           .updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, objUpdateValue)
                           .then(
                              () => {
                                 this._spinner.stop();
                                 this.clearEdit();
                                 this._snackBar.open('The serial number updates successful!', 'Success', {
                                    duration: 3000,
                                    verticalPosition: 'top',
                                    horizontalPosition: 'center',
                                 });
                              },
                              error => {
                                 this._spinner.stop();
                                 this._snackBar.open('Firebase Error!', 'Error', {
                                    duration: 3000,
                                    verticalPosition: 'top',
                                    horizontalPosition: 'center',
                                 });
                                 console.error(error);
                              }
                           );
                     }
                  },
                  error: error => console.error('sensor-detail.component.ts -> serialNumber: ', error),
               });
            break;

         default:
            this._httpService.updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, objUpdateValue).then(
               () => {
                  this._spinner.stop();
                  this.clearEdit();
                  this._snackBar.open('The sensor param updated successful!', 'Success', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               },
               error => {
                  this._spinner.stop();
                  this._snackBar.open('Firebase Error!', 'Error', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
                  console.error(error);
               }
            );
            break;
      }
   }

   cancel() {
      this.clearEdit();
   }

   clearEdit() {
      this.nStatus = nStatusEnum.none;
      this.paramValue = '';
      this.error = '';
   }

   showResult(result: any, strAddress: string, that: any) {
      let lat = result.geometry.location.lat();
      let lng = result.geometry.location.lng();
      lat = lat.toFixed(6);
      lng = lng.toFixed(6);

      // update latitude
      const objUpdateValue: Object = {
         lat: lat,
         lng: lng,
         address: strAddress,
      };
      // update the lat/lng and address
      that._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${that.selectedSensor['key']}`, objUpdateValue)
         .then(
            () => {
               that._spinner.stop();
               that.clearEdit();
               that._snackBar.open('The address updates successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => {
               that._spinner.stop();
               that._snackBar.open('Firebase Error', 'Error', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
               console.error(error);
            }
         );
   }

   getLatitudeLongitude(callback, strAddress: string, that: any) {
      if (strAddress && strAddress !== '') {
         // If adress is not supplied, use default value 'Ferrol, Galicia, Spain'
         strAddress = strAddress || 'Ferrol, Galicia, Spain';
         // Initialize the Geocoder
         if (that.geocoder) {
            that.geocoder = new google.maps.Geocoder();
            that.geocoder.geocode(
               {
                  address: strAddress,
               },
               function (results, status) {
                  if (status === google.maps.GeocoderStatus.OK) {
                     callback(results[0], strAddress, that);
                  }
               }
            );
         }
      } else {
         console.log('Address is required.');
      }
   }

   getAddress(lat: number, lng: number) {
      if (this.geocoder) {
         this.geocoder = new google.maps.Geocoder();
         const latlng = new google.maps.LatLng(lat, lng);
         const request = { latLng: latlng };

         this.geocoder.geocode(request, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
               const result = results[0];
               if (result !== null) {
                  const objUpdateValue: Object = {
                     lat: lat,
                     lng: lng,
                     address: result.formatted_address,
                  };

                  this._httpService
                     .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor['key']}`, objUpdateValue)
                     .then(
                        () => {
                           this._spinner.stop();
                           this.clearEdit();
                           this._snackBar.open('The address param updates successful!', 'Success', {
                              duration: 3000,
                              verticalPosition: 'top',
                              horizontalPosition: 'center',
                           });
                        },
                        error => {
                           this._spinner.stop();
                           this._snackBar.open('Firebase Error', 'Error', {
                              duration: 3000,
                              verticalPosition: 'top',
                              horizontalPosition: 'center',
                           });
                           console.error(error);
                        }
                     );
               } else {
                  this._spinner.stop();
                  this._snackBar.open('Invalid latitude or longitude', 'Error', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               }
            }
         });
      }
   }

   runStartAction(isUpdateCommet: boolean = false, strComment: string = '') {
      this._httpService
         .updateAsObject(`${environment['APIS']['SENSORCONFIGS']}/${this.sensorKey}/${this.nCurrentModalType}`, {
            isFinished: false,
            runNumber: 0,
            runProgressPercent: 0,
            runRemainTime: 0,
            stepNumber: 0,
            stepProgressPercent: 0,
            stepRemainTime: 0,
            errorRunNumber: -1,
            errorMsgs: null,
         })
         .then(
            () => {
               this.updateSensorDevice(2);
               let update = {};

               if (isUpdateCommet) {
                  update = {
                     Comment: strComment,
                  };
               } else {
                  update = {
                     IsRunned: true,
                  };
               }
               this._httpService.updateAsObject(`${environment['APIS']['SENSORCONFIGS']}/${this.sensorKey}`, update);
            },
            error => console.error(error)
         );
   }

   /**
    * check pre configuration comment status
    */
   checkConfigurationComment() {
      if (!this.orginConfigData) {
         return;
      }

      if (this.orginConfigData['IsRunned'] && this.orginConfigData['Estimate_Label']) {
         const config = {
            width: '600px',
            disableClose: true,
            data: {
               estimate: this.orginConfigData['Estimate_Label'],
               value: this.orginConfigData['Comment'] ? this.orginConfigData['Comment'] : '',
            },
         };
         const dialogRef = this.dialog.open(RepeatConfirmModalComponent, config);
         dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
               next: result => {
                  let strComment = '';
                  if (result['status'] === 'success') {
                     strComment = result['data'];
                     this.runStartAction(true, strComment);
                  }
               },
               error: error => console.error('sensor-detail.component.ts -> dialogRef: ', error),
            });
      } else {
         this.confirmToAct(2, 'start this run');
      }
   }

   confirmToAct(status: number, strActionName: string) {
      const config = {
         disableClose: true,
         data: {
            title: 'Confirmation',
            description: `Are you sure you want to ${strActionName}?`,
         },
      };
      const dialogRef = this.dialog.open(ConfirmModalComponent, config);
      dialogRef
         .afterClosed()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: result => {
               if (result) {
                  if (status === 2) {
                     this.runStartAction();
                  } else if (status === 4) {
                     this._httpService
                        .updateAsObject(
                           `${environment['APIS']['SENSORCONFIGS']}/${this.sensorKey}/${this.nCurrentModalType}`,
                           {
                              errorRunNumber: -1,
                              errorMsgs: null,
                           }
                        )
                        .then(
                           () => {
                              this.updateSensorDevice(status);
                           },
                           error => console.error(error)
                        );
                  } else {
                     this.updateSensorDevice(status);
                  }
               }
            },
            error: error => console.error('sensor-detail.component.ts -> dialogRef: ', error),
         });
   }

   progressText() {
      return this.nActionStatus === 12 ? 'Calibration Progress' : 'Progress';
   }

   /**
   status - 0:online, 1:config, 2:start, 3:stop, 4:reboot, 5: debug, 6:shutdown
   **/
   controlSensorDevice(status: number) {
      if (this.strUserRole === this.arrStrUserRoles[3]) {
         // user's role is viewer
         this._nofication.createNotification('error', 'Permission Denied', "You don't have a permission.");
         return;
      }

      if (status === -1) {
         this._router.navigate(['/configure', this.sensorKey]);
      } else if (status === 1) {
         const config = {
            minWidth: '560px',
            disableClose: true,
            data: {
               sensor: this.selectedSensor,
               configData: this.orginConfigData,
            },
         };
         const dialogRef = this.dialog.open(ConfigModalComponent, config);

         dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
               next: result => {
                  if (result) {
                     this.nCurrentModalType = result['modalType'];
                     this._httpService
                        .postAsObject(`${environment['APIS']['SENSORCONFIGS']}/${this.sensorKey}`, {
                           Current_Modal_Type: this.nCurrentModalType,
                           File_Name: result['fileName'] ? result['fileName'] : '',
                           Comment: result['configrationComment'] ? result['configrationComment'] : '',
                           Estimate_Label: result['estimateLabel'] ? result['estimateLabel'] : '',
                           [this.nCurrentModalType]: result['data'],
                        })
                        .then(
                           () => {
                              console.log('Modal type update is successful!');
                              this.updateSensorDevice(status);
                           },
                           error => console.error(error)
                        );
                  }
               },
               error: error => console.error('sensor-detail.component.ts -> dialogRef: ', error),
            });
      } else {
         if (status === 0 || status === 5) {
            this.updateSensorDevice(status);
         } else {
            let strActionName = '';
            switch (status) {
               case 2:
                  strActionName = 'start this run';
                  break;

               case 3:
                  strActionName = 'stop this run';
                  break;

               case 4:
                  strActionName = 'reboot';
                  break;

               case 6:
                  strActionName = 'shut down';
                  break;
            }

            if (status === 2) {
               this.checkConfigurationComment();
            } else {
               this.confirmToAct(status, strActionName);
            }
         }
      }
   }

   // update sensorDevice status
   updateSensorDevice(status: number) {
      const resVal = Math.random().toString(36).slice(-5);

      this._httpService
         .updateAsObject(`${environment['APIS']['SENSORDEVICES']}/${this.sensorKey}`, {
            resVal: resVal,
         })
         .then(
            () => {
               console.log('Ping to node.js server');
               this.responseValue = '';
               this.checkSensorDeviceResponse(status);
            },
            error => console.error(error)
         );
   }

   /**
    * check sensor device response in some time
    */
   checkSensorResponseOnWait() {
      setTimeout(() => this.watchSensorDeviceResponse(), 5000);
   }

   /**
    * check real sensor device response
    * @param status of device
    */
   checkSensorDeviceResponse(status: number) {
      const sensorDevicUrl = environment.APIS.SENSORDEVICES;
      this._httpService
         .updateAsObject(`${sensorDevicUrl}/${this.sensorKey}`, {
            actionStatus: status,
         })
         .then(
            () => {
               console.log('The sensor device status is changed.');
               this._spinner.start();
               this.checkSensorResponseOnWait();

               if (this.sensorDeviceResSub) {
                  this.sensorDeviceResSub.unsubscribe();
               }
               this.sensorDeviceResSub = this._httpService
                  .getAsObject(`${sensorDevicUrl}/${this.sensorKey}/resVal`, 2)
                  .subscribe({
                     next: res => {
                        this.responseValue = res['$value'];
                        this.responseValue = this.responseValue.toString();

                        if (this.responseValue === global_variables['sensorResponse']) {
                           console.log('Response from node.js server');
                           this._spinner.stop();

                           if (!this.isOnlineDevice) {
                              this._httpService
                                 .updateAsObject(`${sensorDevicUrl}/${this.sensorKey}`, {
                                    actionStatus: 0,
                                 })
                                 .then(
                                    () => {
                                       this._nofication.createNotification(
                                          'info',
                                          'Status',
                                          'Sensor device is now online'
                                       );
                                    },
                                    error => console.error(error)
                                 );
                           } else {
                              this._nofication.createNotification(
                                 'success',
                                 'Status',
                                 'This action is correctly applied.'
                              );
                           }
                        }
                     },
                     error: error => console.log(error),
                  });
            },
            error => console.error(error)
         );
   }

   /**
    * watch sensor device response
    */
   watchSensorDeviceResponse() {
      if (this.responseValue !== global_variables['sensorResponse']) {
         console.log('No response from node.js server!');
         this._nofication.createNotification('info', 'Sensor Device', "Sensor device isn't running at a moment");

         // set action status to 0, none
         this._httpService
            .updateAsObject(`${environment.APIS.SENSORDEVICES}/${this.sensorKey}`, { actionStatus: 0 })
            .then(
               () => {
                  console.log('The sensor device is set to none');
                  this._spinner.stop();
               },
               error => console.error(error)
            );

         // set sensor availability to offline
         this._httpService
            .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`, {
               availability: this.arrStrAvailabilites[1],
            })
            .then(
               () => {
                  console.log('The sensor availability is set to offline');
                  this._spinner.stop();
               },
               error => console.error(error)
            );
      }
   }

   /**
    * delete analytical data
    * @param objAnalyticalData anaylytical data
    */
   onDeleteAnalyticalData(objAnalyticalData: Object) {
      this.strSelectedAnalyticKey = objAnalyticalData['key'];

      const strStorageUrl = objAnalyticalData['storageUrl'];
      // delete the analytic file from storage
      this.deleteStorageFile(strStorageUrl);

      const strAnalyticUrl = `${environment['APIS']['ANAYLYTICALDATA']}/${this.sensorKey}/${objAnalyticalData['key']}`;

      this._httpService.deleteAsObject(strAnalyticUrl).then(
         () => {
            this.strSelectedAnalyticKey = '';
            console.log('Successfully delete analytic database: ' + strAnalyticUrl);
            this._snackBar.open('Analytical Data file delete successfully!', 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         },
         error => {
            console.log('Fail to delete analytic database: ' + strAnalyticUrl);
            console.error(error);
         }
      );
   }

   /**
    * edit analytical data file name
    * @param data: analytical data
    */
   onEditAnalyticalDataFile(data: Object) {
      if (!this.sensorKey) {
         this._snackBar.open('Current sensor is not available.', 'Alert', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });
         return;
      }

      if (!data['key']) {
         this._snackBar.open('This data is not available to update the description.', 'Alert', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });
         return;
      }

      const config = {
         minWidth: '450px',
         disableClose: true,
         data: {
            inputType: 'text',
            value: data['description'],
         },
      };
      const dialogRef = this.dialog.open(EditModalComponent, config);

      dialogRef
         .afterClosed()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: result => {
               if (result['status'] === 'error') {
                  this._snackBar.open('Unsupported input type.', 'Error', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               } else if (result['status'] === 'success') {
                  if (!result['data']) {
                     this._snackBar.open('The sensor name should not be blank.', 'Alert', {
                        duration: 3000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                     });
                     return;
                  }

                  this._httpService
                     .updateAsObject(`${environment.APIS.ANAYLYTICALDATA}/${this.sensorKey}/${data['key']}`, {
                        description: result['data'],
                     })
                     .then(
                        () => {
                           this._snackBar.open('Name update is successful', 'Success', {
                              duration: 3000,
                              verticalPosition: 'top',
                              horizontalPosition: 'center',
                           });
                        },
                        error => {
                           console.error(error);
                           this._snackBar.open('Internet connection error, please try again later.', 'Error', {
                              duration: 3000,
                              verticalPosition: 'top',
                              horizontalPosition: 'center',
                           });
                        }
                     );
               }
            },
            error: error => console.error('sensor-detail.component.ts -> dialogRef: ', error),
         });
   }

   /**
    * delete raw data
    * @param objRawData raw data
    */
   onDeleteRawData(objRawData: Object) {
      this.strSelectedRawKey = objRawData['key'];
      this.strSelectedRawCycle = objRawData['cycleIndex'];

      const strStorageUrl = objRawData['storageUrl'];
      // delete the raw file from storage
      this.deleteStorageFile(strStorageUrl);

      const strRawUrl = `${environment['APIS']['RAWDATA']}/${this.sensorKey}/${objRawData['key']}`;

      this._httpService.deleteAsObject(strRawUrl).then(
         () => {
            this.strSelectedRawKey = '';
            this.strSelectedRawCycle = '';
            console.log('Successfully delete raw database: ' + strRawUrl);
            this._snackBar.open('Raw Data files delete successfully!', 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         },
         error => {
            this._snackBar.open('When deleting the raw data, some error occurred', 'Error', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            console.log('Fail to delete raw database: ' + strRawUrl);
            console.error(error);
         }
      );
   }

   /**
    * build process chart
    * @param arrTime time array
    * @param arrValues value array regards to time
    */
   buildProcessChart(arrTime: number[], arrValues: number[]) {
      this.arrPlotyChromatogramData = [];

      if (arrTime.length > 0 && arrValues.length > 0) {
         const trace1 = {
            x: arrTime,
            y: arrValues,
            type: 'scatter',
            mode: 'lines',
            name: 'Processed Data',
         };

         this.arrPlotyChromatogramData = [trace1];
         this.plotlyProcessStyle = {
            height: '500px',
            width: '100%',
         };
         this.plotlyProcessLayout = {
            xaxis: {
               title: 'Time (s)',
            },
            yaxis: {
               title: '∆C (fF)',
            },
         };
      }

      if (<HTMLInputElement>document.getElementById('result_file_upload')) {
         (<HTMLInputElement>document.getElementById('result_file_upload')).value = '';
      }

      this.bIsProcessedChromatogramLoad = true;
   }

   /**
    * parse Chromatogram of processed data
    * @param strUrl chromatogram url
    * @param strDate chromatomgram date
    */
   parseChromatogram(strUrl: string, strDate: string) {
      if (!strUrl) {
         this.bIsChromatogram = true;
         return;
      }

      this.bIsChromatogram = false;
      this.strChromatogramTime = strDate;

      const objPostData = {
         url: strUrl,
         isHeader: 'no',
      };

      if (this.parseChromatogramSub) {
         this.parseChromatogramSub.unsubscribe();
      }

      this.parseChromatogramSub = this._purehttpService
         .callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
         .subscribe({
            next: (res: any) => {
               const data = res.data;
               const arrTime = [];
               const arrValues = [];

               if (data && data.length) {
                  for (let i = 0; i < data.length; i++) {
                     arrTime.push(parseFloat(data[i][1]));
                     arrValues.push(parseFloat(data[i][2]));
                  }
               }

               this.buildProcessChart(arrTime, arrValues);
               this.bIsChromatogram = true;
            },
            error: error => {
               console.log('Fail to getting the csv file data.');
               console.log(error);
               this.bIsChromatogram = true;
            },
         });
   }

   /**
    * parse Detected Peaks of processed data
    * @param strUrl peak
    * @param strDate peak date
    */
   parsePeaks(strUrl: string, strDate: string) {
      if (!strUrl) {
         this.bIsPeakLoad = true;
         return;
      }

      this.bIsPeakLoad = false;
      this.strPeakTime = strDate;

      const objPostData = {
         url: strUrl,
         isHeader: 'no',
      };

      if (this.parsePeaksSub) {
         this.parsePeaksSub.unsubscribe();
      }

      this.parsePeaksSub = this._purehttpService
         .callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
         .subscribe({
            next: (res: any) => {
               const rules = res.data;

               if (rules && rules.length) {
                  this.arrStrPeakValues = [];
                  this.arrStrPeakHeaders = rules[0];
                  for (let i = 1; i < rules.length; i++) {
                     const rowData = [];
                     for (let j = 0; j < rules[i].length; j++) {
                        rowData.push(parseFloat(rules[i][j]).toFixed(2));
                     }

                     this.arrStrPeakValues.push(rowData);
                  }
               } else {
                  this.arrStrPeakValues = [];
                  this.arrStrPeakHeaders = [];
               }

               this.bIsPeakLoad = true;
            },
            error: error => {
               console.log('Fail to getting Detected Peaks file data.');
               console.log(error);
            },
         });
   }

   // delete the file from storage
   deleteStorageFile(strStorageUrl: string) {
      this.storage
         .ref(strStorageUrl)
         .delete()
         .pipe(take(1))
         .subscribe({
            next: () => {
               // File deleted successfully
               console.log('Successfully delete the storage file: ' + strStorageUrl);
            },
            error: err => {
               // Uh-oh, an error occurred!
               console.log('Fail to delete the storage file: ' + strStorageUrl);
               console.log(err);
            },
         });
   }

   onDeleteDataFile(element, type: string) {
      const config = {
         disableClose: true,
         data: {
            title: 'Delete',
            description: `Are you sure you want to delete data file?`,
         },
      };

      this.dialog
         .open(ConfirmModalComponent, config)
         .afterClosed()
         .pipe(take(1))
         .subscribe({
            next: result => {
               if (result) {
                  switch (type) {
                     case 'raw':
                        this.onDeleteRawData(element);
                        break;

                     case 'analytical':
                        this.onDeleteAnalyticalData(element);
                        break;

                     case 'process':
                        this.onDeleteProcessedData(element);
                        break;
                  }
               }
            },
            error: error => console.error('sensor-detail.component.ts -> dialog: ', error),
         });
   }

   /**
    * delete processed data for Chromatogram or DetectedPeaks
    * @param objProcessedData processed data
    */
   onDeleteProcessedData(objProcessedData: Object) {
      if (!objProcessedData) {
         console.log('The processed data is not existed.');
         return;
      }

      this.strSelectedProcessKey = objProcessedData['key'];

      for (let i = 0; i < objProcessedData['data'].length; i++) {
         const objC = objProcessedData['data'][i];
         this.deleteStorageFile(objC['storageUrl']);
      }

      // delete the info in database
      const strDBUrl = `${environment.APIS.PROCESSEDDATA}/${this.sensorKey}/${objProcessedData['key']}`;

      this._httpService.deleteAsObject(strDBUrl).then(
         () => {
            console.log('Successfully delete the info: ' + strDBUrl);
            this._snackBar.open('Processed Data files delete successfully!', 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            this.strSelectedProcessKey = '';
         },
         error => {
            console.log('Fail to delete the info: ' + strDBUrl);
            console.error(error);
         }
      );
   }

   /**
    * download file action
    * @param data downloaded
    * @param strName file name
    */
   downloadFile(data: any, strName: string) {
      const blob = new Blob([data], { type: 'text/csv' });
      if (strName.split('.').pop() === 'gz') {
         strName = strName.replace(strName.substr(strName.lastIndexOf('.')), '');
      }

      FileSaver.saveAs(blob, strName);

      // const url = window.URL.createObjectURL(blob);
      // const link = document.createElement('a');
      // link.setAttribute('href', url);
      // link.setAttribute('download', strName);
      // link.click();
      //  TODO add remove
      //  TODO window.URL.revokeObjectURL(url);
   }

   /**
    * download file event
    * @param strUri downloaded file url
    * @param strName file name
    */
   onDownloadFile(strUri: string, strName: string) {
      if (strUri && strName) {
         const objPostData = {
            url: strUri,
         };

         if (this.downloadSub) {
            this.downloadSub.unsubscribe();
         }
         this.downloadSub = this._purehttpService
            .callFirebaseFunction(`${this.strFireFunctionUrl}/getData`, objPostData)
            .subscribe({
               next: (res: any) => {
                  const data = res.data;
                  this.downloadFile(data, strName);
               },
               error: error => {
                  console.log('Fail to get the data to download.');
                  console.log(error);
                  this._nofication.createNotification(
                     'error',
                     `Error ${error.stats ? error.stats : ''}`,
                     `${strName} ${error.statusText ? error.statusText : 'Can not get the file from server'}`
                  );
               },
            });
      } else {
         this._nofication.createNotification('error', 'Error', `${strName} URL or name is broken`);
      }
   }

   /**
    * update analytic data comment
    * @param event comment data
    */
   onUpdateAnalyticComment(event: Object) {
      if (event.hasOwnProperty('comment') && event['key']) {
         const objUpdateVale = {
            comment: event['comment'],
         };

         this._httpService
            .updateAsObject(`${environment.APIS.ANAYLYTICALDATA}/${this.sensorKey}/${event['key']}`, objUpdateVale)
            .then(
               () => {
                  this._snackBar.open('The analytical comment update is successful!', 'Success', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               },
               error => console.error(error)
            );
      } else {
         console.log('The comment or key is empty');
      }
   }

   /**
    * update raw data comment
    * @param event raw data
    */
   onUpdateRawComment(event: Object) {
      if (event.hasOwnProperty('comment') && event['key']) {
         const objUpdateVale = {
            comment: event['comment'],
         };

         this._httpService
            .updateAsObject(`${environment['APIS']['RAWDATA']}/${this.sensorKey}/${event['key']}`, objUpdateVale)
            .then(
               () => {
                  this._snackBar.open('The raw comment update is successful!', 'Success', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               },
               error => console.error(error)
            );
      } else {
         console.log('The comment or key is empty');
      }
   }

   /**
    * update processed data comment
    * @param event processed data
    */
   onUpdateProcessComment(event: Object) {
      if (event.hasOwnProperty('comment') && event['key']) {
         const objUpdateVale = {
            comment: event['comment'],
         };

         this._httpService
            .updateAsObject(`${environment.APIS.PROCESSEDDATA}/${this.sensorKey}/${event['key']}`, objUpdateVale)
            .then(
               () => {
                  this._snackBar.open('The processed data comment update is successful!', 'Success', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               },
               error => console.error(error)
            );
      } else {
         console.log('The comment or key is empty');
      }
   }

   /**
    * update analytic input
    * @param event analytic data
    * @param nInd data index on local
    */
   onUpdateAnalyticInput(event: any, nInd?: number) {
      const key = event.key ? event.key : event.event.key;
      let comment = event.comment;
      let dataIndex = nInd;

      let objUpdateVal;

      // multiple analytic files
      if (event.hasOwnProperty('event')) {
         dataIndex = event.nInd;
         const selectedItems = this._analyticalService.loadMultipleAnalyticalData(
            this.analyticalDataItemsSelected,
            this.arrObjAnalyticList
         );
         this.strSelectedAnalyticKey = selectedItems[event.index]['key'];
         comment = event.event.comment;

         if (!this.arrMulAnalyticalInputs[event.index][dataIndex]) {
            this.arrMulAnalyticalInputs[event.index][dataIndex] = <any>{};
            this.arrMulAnalyticalInputs[event.index][dataIndex][key] = comment;
         } else {
            if (!this.arrMulAnalyticalInputs[event.index][dataIndex]) {
               this.arrMulAnalyticalInputs[event.index][dataIndex] = <any>{};
               this.arrMulAnalyticalInputs[event.index][dataIndex][key] = comment;
            } else {
               this.arrMulAnalyticalInputs[event.index][dataIndex][key] = comment;
            }
         }
         objUpdateVal = {
            input: this.arrMulAnalyticalInputs[event.index],
         };
      } else {
         if (!this.arrAnalyticalInputs[dataIndex]) {
            this.arrAnalyticalInputs[dataIndex] = <any>{};
            this.arrAnalyticalInputs[dataIndex][key] = comment;
         } else {
            if (!this.arrAnalyticalInputs[dataIndex]) {
               this.arrAnalyticalInputs[dataIndex] = <any>{};
               this.arrAnalyticalInputs[dataIndex][key] = comment;
            } else {
               this.arrAnalyticalInputs[dataIndex][key] = comment;
            }
         }

         objUpdateVal = {
            input: this.arrAnalyticalInputs,
         };
      }

      this._httpService
         .updateAsObject(
            `${environment['APIS']['ANAYLYTICALDATA']}/${this.sensorKey}/${this.strSelectedAnalyticKey}`,
            objUpdateVal
         )
         .then(
            () => {
               console.log('Sensor device resVal field is set to default status');
            },
            error => console.error(error)
         );
   }

   debugTask() {
      if (this.nActionStatus !== 2 && this.isOnlineDevice) {
         // status is not inprogress
         this.updateSensorDevice(5);
      }
   }

   /**
    * clear error
    */
   onClearError() {
      const config = {
         disableClose: true,
         data: {
            title: 'Confirmation',
            description: 'Are you sure to clear?',
         },
      };
      const dialogRef = this.dialog.open(ConfirmModalComponent, config);
      dialogRef
         .afterClosed()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: result => {
               if (result) {
                  this._httpService
                     .updateAsObject(`${environment['APIS']['SENSORDEVICES']}/${this.sensorKey}`, {
                        actionStatus: 8,
                     })
                     .then(
                        () => {
                           this._nofication.createNotification(
                              'success',
                              'Status',
                              'This action is correctly applied.'
                           );
                        },
                        error => {
                           console.error(error);
                           this._nofication.createNotification(
                              'error',
                              'Error',
                              'Internet error, please try again later.'
                           );
                        }
                     );
               }
            },
            error: error => console.error('sensor-detail.component.ts -> dialogRef: ', error),
         });
   }

   /**
    * change anayltical paginate change
    * @param event of paginate
    */
   onAnalyticalPaginateChange(event: any) {
      this.nAnalyticalPageSize = event['pageSize'];
      this.nAnalyticalPageIndex = event['pageIndex'];
   }

   /**
    * change processed data paginate change
    * @param event of paginate
    */
   onProcessedDataPaginateChange(event: any) {
      this.nProcessedDataPageSize = event['pageSize'];
      this.nProcessedDataPageIndex = event['pageIndex'];
   }

   /**
    * change raw data paginate change
    * @param event of paginate
    */
   onRawDataPaginateChange(event: any) {
      this.nRawDataPageSize = event['pageSize'];
      this.nRawDataPageIndex = event['pageIndex'];
   }

   /**
    * download file action
    * @param selectedOption downloaded
    * @param typeData type of data
    */

   onSelectBulk(selectedOption: MatSelectChange, typeData: string) {
      let dataItemsSelected;

      switch (typeData) {
         case 'processData':
            dataItemsSelected = this.processedDataItemsSelected;
            break;
         case 'analyticalData':
            dataItemsSelected = this.analyticalDataItemsSelected;
            break;
         case 'rawData':
            dataItemsSelected = this.rawDataItemsSelected;
            break;
      }

      switch (selectedOption['value']) {
         case 'load':
            if (Object.keys(dataItemsSelected).length < 2) {
               this._snackBar.open('Two data should be selected at least.', 'Alert', {
                  duration: 5000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
               this._cdRef.detectChanges();
            } else {
               if (typeData === 'processData') {
                  this.loadMultipleProcessedData();
               }
               if (typeData === 'rawData') {
                  this.loadMultipleRawData();
               }
               if (typeData === 'analyticalData') {
                  this.arrMulAnalyticalInputs = [];

                  const selectedItems = this._analyticalService.loadMultipleAnalyticalData(
                     this.analyticalDataItemsSelected,
                     this.arrObjAnalyticList
                  );

                  for (let i = 0; i < selectedItems.length; i++) {
                     this.arrMulAnalyticalInputs[i] = selectedItems[i]['input'];
                  }

                  if (this.analyticalDataMulSub) {
                     this.analyticalDataMulSub.unsubscribe();
                  }

                  this.analyticalDataMulSub = this._analyticalService.loadAnalyticDataForMul(selectedItems).subscribe({
                     next: result => {
                        this.mulAnalyticalData = result;
                        console.log('ANALYTICAL DAT');

                        this.mulAnalyticalData.forEach((data, i) => {
                           data['startTimestamp'] = selectedItems[i]['startTimestamp'];
                           data['description'] = selectedItems[i]['description'];
                           data['date'] = selectedItems[i]['date'];
                        });

                        this._analyticalService.bIsMultipleAnalyticalData = true;
                     },
                     error: error => console.error('sensor-detail.component.ts -> analyticalDataMulSub: ', error),
                  });
               }
            }
            break;

         case 'download':
            if (Object.keys(dataItemsSelected).length) {
               let selectedDataItems;

               switch (typeData) {
                  case 'processData':
                     selectedDataItems = this.getSelectedProcessData();
                     for (const key in selectedDataItems) {
                        if (selectedDataItems.hasOwnProperty(key)) {
                           for (let i = 0; i < selectedDataItems[key]['data'].length; i++) {
                              const url = selectedDataItems[key]['data'][i]['url'];
                              const description = selectedDataItems[key]['data'][i]['description'];
                              this.onDownloadFile(url, description);
                           }
                        }
                     }

                     break;

                  case 'rawData':
                     selectedDataItems = this.getSelectedRawData();
                     for (const key in selectedDataItems) {
                        if (selectedDataItems.hasOwnProperty(key)) {
                           const url = selectedDataItems[key]['url'];
                           const description = selectedDataItems[key]['description'];
                           this.onDownloadFile(url, description);
                        }
                     }
                     break;

                  case 'analyticalData':
                     selectedDataItems = this.getSelectedAnalyticalData();
                     for (const key in selectedDataItems) {
                        if (selectedDataItems.hasOwnProperty(key)) {
                           const url = selectedDataItems[key]['url'];
                           const description = selectedDataItems[key]['description'];

                           this.onDownloadFile(url, description);
                        }
                     }
                     break;
               }

               this._nofication.createNotification('info', 'Downloading', 'We are preparing files for you...');
            } else {
               this.snackBarForEmptyItem();
               this._cdRef.detectChanges();
            }
            break;

         case 'remove':
            if (Object.keys(dataItemsSelected).length) {
               let selectedDataItems;

               const config = {
                  disableClose: true,
                  data: {
                     title: 'Delete',
                     description: '',
                  },
               };

               switch (typeData) {
                  case 'processData':
                     selectedDataItems = this.getSelectedProcessData();
                     config.data.description = `Are you sure you want to delete process data files? <br> Total files: ${selectedDataItems.length}`;

                     this.dialog
                        .open(ConfirmModalComponent, config)
                        .afterClosed()
                        .pipe(take(1))
                        .subscribe({
                           next: result => {
                              if (result) {
                                 for (const key in selectedDataItems) {
                                    if (selectedDataItems.hasOwnProperty(key)) {
                                       this.onDeleteProcessedData(selectedDataItems[key]);
                                    }
                                 }
                              }
                           },
                           error: error => console.error('sensor-detail.component.ts -> dialog: ', error),
                        });

                     break;

                  case 'analyticalData':
                     selectedDataItems = this.getSelectedAnalyticalData();
                     config.data.description = `Are you sure you want to delete analytical data files? <br> Total files: ${selectedDataItems.length}`;

                     this.dialog
                        .open(ConfirmModalComponent, config)
                        .afterClosed()
                        .pipe(take(1))
                        .subscribe({
                           next: result => {
                              if (result) {
                                 for (const key in selectedDataItems) {
                                    if (selectedDataItems.hasOwnProperty(key)) {
                                       this.onDeleteAnalyticalData(selectedDataItems[key]);
                                    }
                                 }
                              }
                           },
                           error: error => console.error('sensor-detail.component.ts -> dialog: ', error),
                        });

                     break;

                  case 'rawData':
                     selectedDataItems = this.getSelectedRawData();
                     config.data.description = `Are you sure you want to delete raw data files? <br> Total files: ${selectedDataItems.length}`;

                     this.dialog
                        .open(ConfirmModalComponent, config)
                        .afterClosed()
                        .pipe(take(1))
                        .subscribe({
                           next: result => {
                              if (result) {
                                 for (const key in selectedDataItems) {
                                    if (selectedDataItems.hasOwnProperty(key)) {
                                       this.onDeleteRawData(selectedDataItems[key]);
                                    }
                                 }
                              }
                           },
                           error: error => console.error('sensor-detail.component.ts -> dialog: ', error),
                        });

                     break;
               }
            } else {
               this._cdRef.detectChanges();
               this.snackBarForEmptyItem();
            }
            break;
      }
   }

   onChangeProcessedDataSelect(checked: boolean, cycleIndex: number) {
      this.processedDataItemsSelected[cycleIndex] = false;
      this.strProcessedDataFilterStatus = 'select';
      this.arrMulPeakData = [];
      this._cdRef.detectChanges();

      if (checked) {
         this.processedDataItemsSelected[cycleIndex] = true;
      } else {
         delete this.processedDataItemsSelected[cycleIndex];
         delete this.processDataItemsInvalid[cycleIndex];
      }

      this.checkMatchingFiles('process');
   }

   onChangeAnalyticalDataSelect(checked: boolean, cycleIndex: number) {
      this.analyticalDataItemsSelected[cycleIndex] = false;
      this.strAnalyticalDataFilterStatus = 'select';
      this.arrMulPeakData = [];
      this._cdRef.detectChanges();

      if (checked) {
         this.analyticalDataItemsSelected[cycleIndex] = true;
      } else {
         delete this.analyticalDataItemsSelected[cycleIndex];
         delete this.analyticDataItemsInvalid[cycleIndex];
      }

      this.checkMatchingFiles('analytical');
   }

   onChangeRawDataSelect(checked: boolean, cycleIndex: number) {
      this.rawDataItemsSelected[cycleIndex] = false;
      this.arrMulPeakData = [];
      this.strRawDataFilterStatus = 'select';
      this._cdRef.detectChanges();

      if (checked) {
         this.rawDataItemsSelected[cycleIndex] = true;
      } else {
         delete this.rawDataItemsSelected[cycleIndex];
         delete this.rawDataItemsInvalid[cycleIndex];
      }

      this.checkMatchingFiles('raw');
   }

   loadMultipleProcessedData() {
      const selectedKeys = [];
      this.bIsProcessedChromatogramLoad = false;
      this.bIsMulProcessedPeakmLoad = false;
      this.bIsOverMultiProcessedDataSelection = false;
      this.bIsMulitpleProcessedLoad = true;

      const selectedProcessedDataItems = this.getSelectedProcessData();

      if (selectedProcessedDataItems.length > 20) {
         this.bIsOverMultiProcessedDataSelection = true;
         this.bIsProcessedChromatogramLoad = true;
         this.bIsMulProcessedPeakmLoad = true;
      } else {
         this.getMultipleProcessedData(selectedProcessedDataItems);
      }
   }

   generateRandomColors(count: number) {
      const colors = [];

      for (let index = 0; index < count; index++) {
         colors.push('#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6));
      }

      return colors;
   }

   buildGraphColors(count: number) {
      const colors = [
         '#e6194B',
         '#3cb44b',
         '#ffe119',
         '#4363d8',
         '#f58231',
         '#911eb4',
         '#42d4f4',
         '#f032e6',
         '#bfef45',
         '#fabebe',
         '#469990',
         '#e6beff',
         '#9A6324',
         '#fffac8',
         '#800000',
         '#aaffc3',
         '#808000',
         '#ffd8b1',
         '#000075',
         '#a9a9a9',
      ];

      if (count > 20) {
         return [];
      } else {
         return colors.slice(0, count);
      }
   }

   getMultipleProcessedData(selectedProcessedDataItems: Object[]) {
      const arrChromatogramPromises = [];
      const arrPeakPromises = [];
      this.arrMulChromatogramInfo = [];
      const arrMulPeakInfo = [];
      const colors = this.buildGraphColors(selectedProcessedDataItems.length);

      for (let i = 0; i < selectedProcessedDataItems.length; i++) {
         for (let j = 0; j < selectedProcessedDataItems[i]['data'].length; j++) {
            const item = selectedProcessedDataItems[i]['data'][j];

            if (item['type'] === this.arrStrProcessedDataTypes[0]) {
               // Chromatogram
               this.arrMulChromatogramInfo[i] = {
                  startTimestamp: selectedProcessedDataItems[i]['startTimestamp'],
                  timestamp: item['timestamp'],
                  color: colors[i],
               };
               const objPostData = {
                  url: item['url'],
                  isHeader: 'yes',
               };
               arrChromatogramPromises.push(
                  this._purehttpService.callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
               );
            } else if (item['type'] === this.arrStrProcessedDataTypes[1]) {
               // Detected Peaks
               arrMulPeakInfo[i] = {
                  cycleIndex: selectedProcessedDataItems[i]['cycleIndex'],
                  uploadDate: item['date'],
                  measurementDate: convertToDate(
                     parseInt(selectedProcessedDataItems[i]['cycleIndex'], 10),
                     this.selectedSensor
                  ),
                  color: colors[i],
               };
               const objPostData = {
                  url: item['url'],
                  isHeader: 'no',
               };
               arrPeakPromises.push(
                  this._purehttpService.callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
               );
            }
         }
      }

      zip(arrChromatogramPromises).subscribe({
         next: list => {
            const arrChromatogramData = [];
            if (list && list.length > 0) {
               for (let i = 0; i < list.length; i++) {
                  const item = list[i];
                  const arrTime = [];
                  const arrValues = [];
                  // @ts-ignore
                  if (item && item.data && item.data.length) {
                     // @ts-ignore
                     for (let j = 0; j < item.data.length; j++) {
                        // @ts-ignore
                        arrTime.push(parseFloat(item.data[j]['Timestamp']));
                        // @ts-ignore
                        arrValues.push(parseFloat(item.data[j]['Corrected Signal']));
                     }
                  }
                  arrChromatogramData[i] = [arrTime, arrValues];
               }
            }

            this.buildMultipleProcessDataChart(arrChromatogramData, this.arrMulChromatogramInfo);
            this.bIsProcessedChromatogramLoad = true;
         },
         error: error => console.error('sensor-detail.component.ts -> chromatogram: ', error),
      });

      console.log('processDataPeakSub');
      if (this.processDataPeakSub) {
         this.processDataPeakSub.unsubscribe();
      }
      this.processDataPeakSub = zip(arrPeakPromises).subscribe({
         next: list => {
            this.arrMulPeakData = [];
            if (list && list.length > 0) {
               for (let i = 0; i < list.length; i++) {
                  const item = list[i];
                  this.arrMulPeakData.push({
                     // @ts-ignore
                     data: item.data,
                     info: arrMulPeakInfo[i],
                  });
               }
            }
            this.bIsMulProcessedPeakmLoad = true;
         },
         error: error => console.error('sensor-detail.component.ts -> processDataPeakSub: ', error),
      });
   }

   buildMultipleProcessDataChart(arrChromatogramData: any[], arrMulChromatogramInfo: any[]) {
      this.arrPlotyChromatogramData = [];

      if (arrChromatogramData.length > 0 && arrChromatogramData.length > 0) {
         const traces = [];

         for (let index = 0; index < arrChromatogramData.length; index++) {
            traces.push({
               x: arrChromatogramData[index][0],
               y: arrChromatogramData[index][1],
               type: 'scatter',
               mode: 'lines',
               name: arrMulChromatogramInfo[index]['startTimestamp'],
               marker: {
                  color: arrMulChromatogramInfo[index]['color'],
               },
            });
         }

         this.arrPlotyChromatogramData = traces;
         this.plotlyProcessStyle = {
            height: '500px',
            width: '100%',
         };
         this.plotlyProcessLayout = {
            xaxis: {
               title: 'Time (s)',
            },
            yaxis: {
               title: '∆C (fF)',
            },
            showlegend: false,
         };
      }

      if (<HTMLInputElement>document.getElementById('result_file_upload')) {
         (<HTMLInputElement>document.getElementById('result_file_upload')).value = '';
      }
   }

   applyFilter(arraySource?, filter?: string) {
      if (filter) {
         this.globalFilter = filter;
         arraySource.filter = filter;
         this.filterDate();
      } else {
         arraySource.filter = '';
         this.filterDate();
      }
   }

   clearFilter(analyticalDataSource?: any) {
      if (analyticalDataSource) {
         analyticalDataSource.filter = '';
      }
      this.globalFilter = '';
      this.toDate = null;
      this.fromDate = null;
   }

   startDateChange($event: Date) {
      this.fromDate = $event;
      this.filterDate();
   }

   filterDate() {
      if (this.fromDate && this.toDate) {
         if (new Date(this.fromDate).getTime() > new Date(this.toDate).getTime()) {
            return;
         }

         switch (this.nActionNumber) {
            case 1:
               this.analyticalDataSource.filter = '' + Math.random();
               break;
            case 2:
               this.rawDataSource.filter = '' + Math.random();
               break;
            case 3:
               this.processedDataSource.filter = '' + Math.random();
               break;
         }
      }
   }

   endDateChange($event: Date) {
      this.toDate = $event;
      this.filterDate();
   }

   buildMultipleRawDataChart(arrCdcData: any[], arrMulCdcInfo: any[]) {
      if (arrCdcData.length > 0 && arrCdcData.length > 0) {
         const traces = [];

         for (let index = 0; index < arrCdcData.length; index++) {
            traces.push({
               x: arrCdcData[index][0],
               y: arrCdcData[index][1],
               type: 'scatter',
               mode: 'lines',
               name: 'CapDET1',
               marker: {
                  color: arrMulCdcInfo[index]['color'],
               },
            });

            // traces.push({
            //   x: arrCdcData[index][0],
            //   y: arrCdcData[index][2],
            //   type: 'scatter',
            //   mode: 'lines',
            //   visible: 'legendonly',
            //   name: 'CapDET2',
            //   marker: {
            //     color: arrMulCdcInfo[index]['color']
            //   }
            // });
         }

         this.plotlyCDCData = traces;

         this.plotlyCDCStyle = {
            height: '500px',
            width: '100%',
         };

         this.plotlyCDCLayout = {
            yaxis: { title: 'Capacitance (pF)' },
            xaxis: {
               title: 'Time (s)',
            },
         };

         this.isCdcCalled = true;
      }
   }

   private checkMatchingFiles(type: string) {
      let selectedData;

      switch (type) {
         case 'process':
            selectedData = this.getSelectedProcessData();

            if (selectedData.length > 20) {
               this._snackBar.open('Only 20 files can be selected at the same time.', 'OK', {
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
                  panelClass: ['limit-sensors'],
               });
               this.isProcessCheckSelected = true;
            } else {
               this.isProcessCheckSelected = false;
            }

            for (const data of selectedData) {
               const timeStamp = data['cycleIndex'];
               const analyticalList = this.arrObjAnalyticList.filter(
                  analyticData => analyticData['cycleIndex'] === timeStamp
               );
               const rawList = this.arrObjRawFiles.filter(raw => raw['cycleIndex'] === timeStamp);

               if (!analyticalList.length || !rawList.length) {
                  this.processDataItemsInvalid[timeStamp] = true;
               } else {
                  if (
                     !this.runs.filter(run => analyticalList[0]['cycleIndex'] === run['startTimestamp']).length ||
                     !this.runs.filter(run => rawList[0]['cycleIndex'] === run['startTimestamp']).length
                  ) {
                     this.processDataItemsInvalid[timeStamp] = true;
                  }
               }
            }
            break;

         case 'analytical':
            selectedData = this.getSelectedAnalyticalData();
            if (selectedData.length > 20) {
               this._snackBar.open('Only 20 files can be selected at the same time.', 'OK', {
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
                  panelClass: ['limit-sensors'],
               });

               this.isAnalyticalCheckSelected = true;
            } else {
               this.isAnalyticalCheckSelected = false;
            }

            for (const data of selectedData) {
               const timeStamp = data['cycleIndex'];
               const raw = this.arrObjRawFiles.filter(rawData => rawData['cycleIndex'] === timeStamp);
               const process = this.arrObjProcessedFiles.filter(processData => processData['cycleIndex'] === timeStamp);

               if (!raw.length || !process.length) {
                  this.analyticDataItemsInvalid[timeStamp] = true;
               } else {
                  if (
                     !this.runs.filter(run => raw[0]['cycleIndex'] === run['startTimestamp']).length ||
                     !this.runs.filter(run => process[0]['cycleIndex'] === run['startTimestamp']).length
                  ) {
                     this.analyticDataItemsInvalid[timeStamp] = true;
                  }
               }
            }

            break;

         case 'raw':
            selectedData = this.getSelectedRawData();

            if (selectedData.length > 20) {
               this._snackBar.open('Only 20 files can be selected at the same time.', 'OK', {
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
                  panelClass: ['limit-sensors'],
               });

               this.isRawCheckSelected = true;
            } else {
               this.isRawCheckSelected = false;
            }

            for (const data of selectedData) {
               const timeStamp = data['cycleIndex'];
               const analyticalList = this.arrObjAnalyticList.filter(
                  analyticData => analyticData['cycleIndex'] === timeStamp
               );
               const process = this.arrObjProcessedFiles.filter(processData => processData['cycleIndex'] === timeStamp);

               if (!analyticalList.length || !process.length) {
                  this.rawDataItemsInvalid[timeStamp] = true;
               } else {
                  if (
                     !this.runs.filter(run => analyticalList[0]['cycleIndex'] === run['startTimestamp']).length ||
                     !this.runs.filter(run => process[0]['cycleIndex'] === run['startTimestamp']).length
                  ) {
                     this.rawDataItemsInvalid[timeStamp] = true;
                  }
               }
            }
            break;
      }
   }

   private getSelectedProcessData() {
      const selectedKeys = [];

      for (const key in this.processedDataItemsSelected) {
         if (this.processedDataItemsSelected.hasOwnProperty(key)) {
            selectedKeys.push(parseInt(key, 10));
         }
      }

      return this.arrObjProcessedFiles.filter(item => {
         return selectedKeys.indexOf(item['cycleIndex']) > -1;
      });
   }

   private getFilterPredicate() {
      return (data, filter: string): boolean => {
         if (this.fromDate && this.toDate) {
            const dateTo = new Date(this.toDate);
            dateTo.setHours(24, 0, 0, 0);
            return (
               new Date(data['startTimestamp']).getTime() >= new Date(this.fromDate).getTime() &&
               new Date(data['startTimestamp']).getTime() <= dateTo.getTime()
            );
         }

         return (
            data['data'][0]['comment'].toLocaleLowerCase().includes(this.globalFilter.trim().toLocaleLowerCase()) ||
            data['data'][0]['description'].toLocaleLowerCase().includes(this.globalFilter.trim().toLocaleLowerCase())
         );
      };
   }

   private snackBarForEmptyItem() {
      this._snackBar.open('At least one data should be selected.', 'OK', {
         duration: 5000,
         verticalPosition: 'top',
         horizontalPosition: 'center',
      });
   }

   private getFilterPredicateProcessData() {
      return (data, filter: string): boolean => {
         if (this.fromDate && this.toDate) {
            const dateTo = new Date(this.toDate);
            dateTo.setHours(24, 0, 0, 0);
            return (
               new Date(data['startTimestamp']).getTime() >= new Date(this.fromDate).getTime() &&
               new Date(data['startTimestamp']).getTime() <= dateTo.getTime()
            );
         }

         return (
            data['comment'].toLocaleLowerCase().includes(this.globalFilter.trim().toLocaleLowerCase()) ||
            data['data'][0]['description'].toLocaleLowerCase().includes(this.globalFilter.trim().toLocaleLowerCase())
         );
      };
   }

   private getSelectedAnalyticalData() {
      const selectedKeys = [];

      for (const key in this.analyticalDataItemsSelected) {
         if (this.analyticalDataItemsSelected.hasOwnProperty(key)) {
            selectedKeys.push(parseInt(key, 10));
         }
      }

      return this.arrObjAnalyticList.filter(item => {
         return selectedKeys.indexOf(item['cycleIndex']) > -1;
      });
   }

   private getSelectedRawData() {
      const selectedKeys = [];

      for (const key in this.rawDataItemsSelected) {
         if (this.rawDataItemsSelected.hasOwnProperty(key)) {
            selectedKeys.push(parseInt(key, 10));
         }
      }

      return this.arrObjRawFiles.filter(item => {
         return selectedKeys.indexOf(item['cycleIndex']) > -1;
      });
   }

   private loadMultipleRawData() {
      this.isCdcCalled = false;
      this.bIsMulRawDataLoad = true;
      this.bIsOverMultiRawDataSelection = false;
      const selectedRawDataItems = this.getSelectedRawData();

      if (selectedRawDataItems.length > 20) {
         this.bIsOverMultiRawDataSelection = true;
         this.isCdcCalled = true;
      } else {
         this.getMultipleRawData(selectedRawDataItems);
      }
   }

   private getMultipleRawData(selectedRawDataItems: Object[]) {
      const arrCdcPromises = [];
      this.arrMulCdcInfo = [];
      const colors = this.buildGraphColors(selectedRawDataItems.length);

      for (let i = 0; i < selectedRawDataItems.length; i++) {
         const item = selectedRawDataItems[i];

         this.arrMulCdcInfo[i] = {
            startTimestamp: selectedRawDataItems[i]['startTimestamp'],
            timestamp: item['timestamp'],
            color: colors[i],
            description: selectedRawDataItems[i]['description'],
            date: selectedRawDataItems[i]['date'],
         };

         const objPostData = {
            url: item['url'],
            isHeader: 'yes',
         };
         arrCdcPromises.push(
            this._purehttpService.callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
         );
      }

      if (this.mulRawDataSub) {
         this.mulRawDataSub.unsubscribe();
      }

      this.mulRawDataSub = zip(arrCdcPromises).subscribe({
         next: list => {
            const arrCdcData = [];
            if (list && list.length > 0) {
               for (let i = 0; i < list.length; i++) {
                  const item = list[i];
                  const arrCDCTime = [];
                  const arrCapDET1 = [];
                  const arrCapDET2 = [];
                  // @ts-ignore

                  if (item && item.data && item.data.length) {
                     // @ts-ignore
                     for (let j = 0; j < item.data.length; j++) {
                        // @ts-ignore
                        arrCDCTime.push(parseFloat(item.data[j]['CDC Timestamps']));

                        // @ts-ignore
                        arrCapDET1.push(parseFloat(item.data[j]['CapDET1']));

                        // @ts-ignore
                        arrCapDET2.push(parseFloat(item.data[j]['CapDET2']));

                        // @ts-ignore

                        arrCdcData[i] = [arrCDCTime, arrCapDET1, arrCapDET2];
                     }
                  }
               }
            }
            this.bIsOverMultiRawDataSelection = true;
            this.buildMultipleRawDataChart(arrCdcData, this.arrMulCdcInfo);
         },
         error: error => console.error('sensor-detail.component.ts -> mulRawDataSub: ', error),
      });
   }

   getFullData(element: any) {
      const dataIndex = element['cycleIndex'];
      const processFiles = this.arrObjProcessedFiles.filter(process => process['cycleIndex'] === dataIndex)[0];
      const analyticalFiles = this.arrObjAnalyticList.filter(analytical => analytical['cycleIndex'] === dataIndex)[0];
      const rawFiles = this.arrObjRawFiles.filter(analytical => analytical['cycleIndex'] === dataIndex)[0];

      const resultData = {
         process: processFiles,
         analytical: analyticalFiles,
         raw: rawFiles,
      };

      let analyticalData = null;
      const processData = [];
      let rawData = null;

      if (!resultData['process'] || !resultData['analytical'] || !resultData['raw']) {
         this._snackBar.open('Missing one of the file!', 'Alert', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });

         return;
      }

      const objPostData = {
         url: resultData['process']['data'][0]['url'],
         isHeader: 'yes',
      };

      this._purehttpService
         .callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
         .pipe(
            switchMap((process: any) => {
               processData[0] = process.data;
               objPostData['url'] = resultData['process']['data'][1]['url'];

               return this._purehttpService.callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData);
            }),
            switchMap((process: any) => {
               processData[1] = process.data;
               objPostData['url'] = resultData['analytical']['url'];

               return this._purehttpService.callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData);
            }),
            switchMap((analytical: any) => {
               analyticalData = analytical.data;
               objPostData['url'] = resultData['raw']['url'];

               return this._purehttpService.callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData);
            }),
            takeUntil(this.destroy$)
         )
         .subscribe({
            next: (raw: any) => {
               rawData = raw.data;

               const ExcelSheat = new ExcelSheet(processData, analyticalData, rawData, this.configData);

               ExcelSheat.generateExcelFromAllData(element['data'][0]['description']);
            },
            error: error => {
               console.log(error);

               this._snackBar.open(`Couldn't load data from one of the file.`, 'Alert', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
         });
   }

   exportAnalyticalData() {
      const analyticPromises = [];
      const detectedPeaksPromises = [];

      const dateSpanDays = this.dataSpanDays;
      const selectedDateSpan = new Date(this.dataSpanFrom.toDate().getTime() - dateSpanDays * 24 * 60 * 60 * 1000);

      const filteredAnalyticByDate = this.arrObjAnalyticList.filter(
         // @ts-ignore
         item =>
            new Date(item['startTimestamp']) <= this.dataSpanFrom.toDate() &&
            new Date(item['startTimestamp']) >= selectedDateSpan
      );
      this.totalAnalyticFiles = [...filteredAnalyticByDate];
      const filteredProcessedByDate = this.arrObjProcessedFiles.filter(
         // @ts-ignore
         item =>
            new Date(item['startTimestamp']) <= this.dataSpanFrom.toDate() &&
            new Date(item['startTimestamp']) >= selectedDateSpan
      );

      this.isExportAnalyticalDataDisabled = true;

      this.getListOfFiles(filteredAnalyticByDate, filteredProcessedByDate, 50, 0)
         .then((listOfFiles: Record<string, any[]>) => {
            const mergedAnalyticList = filteredAnalyticByDate.reduce((res, item, index) => {
               res.push({
                  ...item,
                  resolvedData: listOfFiles['analyticalData'][index].data,
               });
               return res;
            }, []);

            const mergedDetectedPeaksList = filteredProcessedByDate.reduce((res, item, index) => {
               res.push({
                  ...item,
                  resolvedData: listOfFiles['detectedPeaks'][index].data,
               });
               return res;
            }, []);

            const finalList = mergedAnalyticList.map(analyticItem => {
               const matchedDetectedPeaksItem = mergedDetectedPeaksList.find(
                  detectedPeaksItem =>
                     detectedPeaksItem.startTimestamp &&
                     analyticItem.startTimestamp &&
                     detectedPeaksItem.startTimestamp === analyticItem.startTimestamp
               );
               const data = analyticItem.resolvedData.map(resolvedDataItem => {
                  if (!Number.isNaN(resolvedDataItem['tR'])) {
                     const matchedDetectedPeaksDataItem = matchedDetectedPeaksItem.resolvedData.find(resolvedItem => {
                        return Number(resolvedItem['RetentionTime_sec']).toFixed(2) === resolvedDataItem['tR'];
                     });
                     const Height_fF =
                        matchedDetectedPeaksDataItem && matchedDetectedPeaksDataItem.Height_fF
                           ? matchedDetectedPeaksDataItem.Height_fF
                           : 'N/A';
                     return { ...resolvedDataItem, Height_fF };
                  }
                  return resolvedDataItem;
               });
               const dataWithoutAutomationTimestamp = data.map(item => {
                  if (item && item['Automation TimeStamp']) {
                     delete item['Automation TimeStamp'];
                  }
                  return item;
               });
               return { data: dataWithoutAutomationTimestamp };
            });

            const ExcelSheat = new ExcelSheet('', finalList, '', '');
            ExcelSheat.generateExcelFromData(filteredAnalyticByDate, this.sensorKey, this.configData);
            this.isExportAnalyticalDataDisabled = false;
            this.resetProgressBar();
         })
         .catch(e => {
            this._nofication.createNotification(
               'warning',
               'API error',
               "Couldn't retrieve some file... Please try again",
               { timeout: 3000 }
            );
            this.totalTime = 0;
            this.filesCount = 0;
            this.isExportAnalyticalDataDisabled = false;
            this.resetProgressBar();
         });
   }

   private async getListOfFiles(
      filteredAnalyticByDate,
      filteredProcessedByDate,
      take: number = 50,
      counter: number = 0,
      files: Record<string, any[]> = {}
   ) {
      if (filteredAnalyticByDate.length === 0) {
         return Promise.reject('No files');
      }

      const res: Record<string, any[]> = { ...files };
      const analyticalList = [];
      const detectedPeaksList = [];
      const promiseAnalytics: Array<Promise<any>> = [];
      const promisePeaks: Array<Promise<any>> = [];

      if (
         !filteredAnalyticByDate.slice(counter, counter + take).length ||
         !filteredProcessedByDate.slice(counter, counter + take).length
      ) {
         counter = filteredAnalyticByDate.length;
         this.totalTime = (this.filesCount * 100) / counter;
         return Promise.resolve(res);
      }

      for (const analytic of filteredAnalyticByDate.slice(counter, counter + take)) {
         const objPostData = {
            url: analytic['url'],
            isHeader: 'yes',
         };
         promiseAnalytics.push(
            this._purehttpService.callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData).toPromise()
         );
      }

      try {
         const data = await Promise.all(promiseAnalytics);
         analyticalList.push(...data);
      } catch (e) {
         return Promise.reject(e);
      }

      for (const detectedPeaks of filteredProcessedByDate.slice(counter, counter + take)) {
         if (detectedPeaks['data']) {
            for (let i = 0; i < detectedPeaks['data'].length; i++) {
               if (detectedPeaks['data'][i]['type'] === this.arrStrProcessedDataTypes[1]) {
                  const objPostData = {
                     url: detectedPeaks['data'][i]['url'],
                     isHeader: 'yes',
                  };
                  promisePeaks.push(
                     this._purehttpService
                        .callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
                        .toPromise()
                  );
               }
            }
         }
      }

      try {
         const data = await Promise.all(promisePeaks);
         detectedPeaksList.push(...data);
         res['analyticalData'] = counter === 0 ? [...analyticalList] : [...res['analyticalData'], ...analyticalList];
         res['detectedPeaks'] =
            counter === 0 ? [...detectedPeaksList] : [...res['detectedPeaks'], ...detectedPeaksList];
         this.filesCount += promiseAnalytics.length;
         this.totalTime = (this.filesCount * 100) / filteredAnalyticByDate.length;
         counter += take;
      } catch (e) {
         return Promise.reject(e);
      }

      return this.getListOfFiles(filteredAnalyticByDate, filteredProcessedByDate, take, counter, res);
   }

   public filterZones($event: string) {
      const timezones = [...this.timezones];
      this.filteredTimeZones = timezones.filter(t => t.toLowerCase().includes($event.trim().toLowerCase()));
   }

   onUpdateWeather(weather: string) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`, { weatherSensor: weather })
         .then(
            () => {
               this._snackBar.open('Sensor weather update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   updateWeatherSensorId(weatherSensorId: number) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`, { weatherSensorId: weatherSensorId })
         .then(
            () => {
               this._snackBar.open('Sensor weather update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   updateCleaningCycle(cleaningCycle: number) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`, {
            cleaningCycle: cleaningCycle,
            totalCyclesCount: 1,
         })
         .then(
            () => {
               this._snackBar.open('Cleaning cycle update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   calibrateSensor() {
      this._httpService
         .getAsObject(`${environment.APIS.SENSORCALIBRATION}/${this.sensorKey}/current/calibrationConfigFile/key`)
         .pipe(
            timeout(5000),
            catchError(error => {
               if (error instanceof TimeoutError) {
                  this._snackBar.open(`Can't start calibration. Try later`, 'Error', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
                  return throwError('Timeout Exception');
               }

               // Return other errors
               return throwError(error);
            }),
            takeUntil(this.destroy$)
         )
         .subscribe({
            next: (res: { key: string; $value: string }) => {
               const hasCalibrateEditPermission =
                  this.strUserType === this.arrStrUserTypes[0] || this.strUserRole === this.arrStrUserRoles[0];

               if (!(res && res.$value && !!res.$value.length) && !hasCalibrateEditPermission) {
                  this.dialog.open(NoPermissionsComponent, {
                     minWidth: '450px',
                     disableClose: false,
                  });
               } else {
                  this._router.navigate(['/', this.selectedSensor.key, 'calibration']);
               }
            },
            error: error => console.error('sensor-detail.component.ts -> calibrationPermission: ', error),
         });
   }

   showNewCalibrationModal(sensorType) {
      if (!(sensorType && sensorType.vocAnalytics && sensorType.vocAnalytics.heads && sensorType.vocAnalytics.rows)) {
         console.error('sensorType is empty');
         return;
      }
      console.log('sensorType', sensorType);
      let oldCalibrationDataUrl;
      let newCalibrationDataUrl;
      if (this.selectedSensor['fastMode']) {
         oldCalibrationDataUrl = `${environment['APIS']['SENSORDATA']}/vocAnalytics/${this.selectedSensor['key']}/fast/recent/value`;
         newCalibrationDataUrl = `${environment['APIS']['SENSORS']}/${this.selectedSensor['key']}/newIgcCalResultForFast`;
      } else if (!this.selectedSensor['fastMode']) {
         oldCalibrationDataUrl = `${environment['APIS']['SENSORDATA']}/vocAnalytics/${this.selectedSensor['key']}/recent/value`;
         newCalibrationDataUrl = `${environment['APIS']['SENSORS']}/${this.selectedSensor['key']}/newIgcCalResult`;
      }

      zip(this._httpService.getAsObject(oldCalibrationDataUrl), this._httpService.getAsObject(newCalibrationDataUrl))
         .pipe(
            map(([oldCalibrationDataSet, newCalibrationData]) => ({ oldCalibrationDataSet, newCalibrationData })),
            filter(({ newCalibrationData }) => !!newCalibrationData),
            takeUntil(merge(this.stopShowCalibrationUpdateModal$, this.destroy$))
         )
         .subscribe(
            ({ oldCalibrationDataSet, newCalibrationData }) => {
               delete oldCalibrationDataSet.key;
               const sensorTypeHeads = sensorType.vocAnalytics.heads;
               const sensorTypeRows = sensorType.vocAnalytics.rows;

               const existingHeads: { name: string; id?: string }[] = [{ name: 'Chemical' }];
               const transformedCalibrationData = sensorTypeRows.reduce((tRow, r) => {
                  const oldRow = oldCalibrationDataSet[r.id];
                  const newRow = newCalibrationData[r.name];
                  if (!newRow) {
                     return tRow;
                  }

                  const cells = sensorTypeHeads.reduce(
                     (tHead, h) => {
                        const oldPropValue = oldRow[h.id];
                        const newPropValue = newRow[h.name];

                        if (!newPropValue && newPropValue !== 0) {
                           return tHead;
                        }
                        if (existingHeads.findIndex(head => head.id === h.id) === -1) {
                           existingHeads.push(h);
                        }
                        tHead.push({
                           ...h,
                           oldPropValue,
                           newPropValue,
                        });
                        return tHead;
                     },
                     [r]
                  );

                  tRow.push({ id: r.id, name: r.name, cells });
                  return tRow;
               }, []);

               const config = {
                  minWidth: '450px',
                  disableClose: true,
                  data: {
                     heads: existingHeads,
                     transformedCalibrationData,
                  },
               };

               this.dialog.closeAll();
               const dialogRef = this.dialog.open(CalibrationUpdateModalComponent, config);
               dialogRef
                  .afterClosed()
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(async (result: 'Apply' | 'Delete') => {
                     this.stopShowCalibrationUpdateModal$.next(true);
                     this.stopShowCalibrationUpdateModal$.complete();

                     if (result === 'Apply') {
                        if (!this.selectedSensor['fastMode']) {
                           await this._httpService
                              .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}/oldIgcCalResult`, {
                                 ...oldCalibrationDataSet,
                              })
                              .catch(e => this._nofication.createNotification('warning', 'Warning', e));
                           const newIgcCalResult = transformedCalibrationData.reduce((res, row) => {
                              const newCells = row.cells.reduce((acc, cell) => {
                                 if (cell.newPropValue || cell.newPropValue === 0) {
                                    acc[cell.id] = cell.newPropValue;
                                 }
                                 return acc;
                              }, {});
                              res[row.id] = {
                                 ...(oldCalibrationDataSet[row.id] || {}),
                                 ...newCells,
                              };
                              return res;
                           }, {});
                           console.log(newIgcCalResult);

                           await this._httpService
                              .updateAsObject(`${oldCalibrationDataUrl}`, {
                                 ...newIgcCalResult,
                              })
                              .catch(e => this._nofication.createNotification('warning', 'Warning', e));
                        } else if (this.selectedSensor['fastMode']) {
                           await this._httpService
                              .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}/oldIgcCalResultForFast`, {
                                 ...oldCalibrationDataSet,
                              })
                              .catch(e => this._nofication.createNotification('warning', 'Warning', e));
                           const newIgcCalResultForFast = transformedCalibrationData.reduce((res, row) => {
                              const newCells = row.cells.reduce((acc, cell) => {
                                 if (cell.newPropValue || cell.newPropValue === 0) {
                                    acc[cell.id] = cell.newPropValue;
                                 }
                                 return acc;
                              }, {});
                              res[row.id] = {
                                 ...(oldCalibrationDataSet[row.id] || {}),
                                 ...newCells,
                              };
                              return res;
                           }, {});
                           console.log(newIgcCalResultForFast);

                           await this._httpService
                              .updateAsObject(`${oldCalibrationDataUrl}`, {
                                 ...newIgcCalResultForFast,
                              })
                              .catch(e => this._nofication.createNotification('warning', 'Warning', e));
                        }

                        await this._httpService
                           .deleteAsObject(newCalibrationDataUrl)
                           .catch(e => this._nofication.createNotification('warning', 'Warning', e));
                     }
                     if (result === 'Delete') {
                        await this._httpService
                           .deleteAsObject(newCalibrationDataUrl)
                           .catch(e => this._nofication.createNotification('warning', 'Warning', e));
                     }
                  });
            },
            error => console.error('sensor-detail.component.ts -> oldNewCalibrarion: ', error)
         );
   }

   updateDateSpan(values) {
      this.dataSpanFrom = moment(values.dataSpan);
      this.dataSpanDays = values.days;
      this.showExportDaysControl = false;
   }

   private resetProgressBar() {
      this.totalTime = 0;
      this.filesCount = 0;
   }

   onChangeFastMode($event: MatSlideToggleChange) {
      const {
         source: { checked },
      } = $event;

      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`, {
            fastMode: checked,
         })
         .then(
            () => {
               this._snackBar.open('Fast Mode update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   onChangeSamplingTime($event: Event) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`, {
            fastModeSamplingTime: +$event.currentTarget['value'] || 30,
         })
         .then(
            () => {
               this._snackBar.open('Sampling Time update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   onChangeMaxRuns($event: Event, isDefault: boolean = false) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`, {
            maxRuns: !isDefault ? +$event.currentTarget['value'] || 999 : this.selectedSensor['maxRuns'],
         })
         .then(
            () => {
               this._snackBar.open('Max Runs update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   private onLoadConfigFiles(): Observable<any> {
      return this._httpService.getListByOrder(`${environment.APIS.CONFIGURATIONS}`, 'mode', this.currentDataType, 1);
   }

   onChangeOneMinuteSamplingFile(event): Promise<any> {
      const strSensorUrl = environment.APIS.SENSORS;
      const doc = this.arrConfigs.find(file => file['key'] === event);
      // this.oneMinuteSamplingFile = doc;

      this.arrSamplingTimes[0] = { ...doc };
      this.arrSamplingTimes[0]['name'] = this.selectedSensor['fastModeConfigName1'];

      return this._httpService
         .updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, { fastModeFile: doc })
         .then(() => {
            this._snackBar.open('One minute files has been setup successfully!', 'Success', {
               duration: 1000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         });
   }

   onChangeTwoMinutesSamplingFile(event): Promise<any> {
      const strSensorUrl = environment.APIS.SENSORS;
      // this.twoMinuteSamplingFile = event;
      const doc = this.arrConfigs.find(file => file['key'] === event);
      this.arrSamplingTimes[1] = { ...doc };
      this.arrSamplingTimes[1]['name'] = this.selectedSensor['fastModeConfigName2'];
      return this._httpService
         .updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, { fastModeFile2: doc })
         .then(() => {
            this._snackBar.open('One minute files has been setup successfully!', 'Success', {
               duration: 1000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         });
   }

   onSearchElement(value: any, type: string) {
      if (!value.length) {
         this.filteredConfigsOne = this.arrConfigs.slice();
         this.filteredConfigsOne = this.arrConfigs.slice();

         return;
      }

      switch (type) {
         case 'one':
            this.filteredConfigsOne = this.arrConfigs.slice().filter(config => {
               const name = config['name'].toLowerCase().trim();

               return name.includes(value.toLowerCase().trim());
            });
            break;

         case 'two':
            this.filteredConfigsTwo = this.arrConfigs.slice().filter(config => {
               const name = config['name'].toLowerCase().trim();

               return name.includes(value.toLowerCase().trim());
            });
            break;
      }
   }

   onAssignSamplingTime($event: any) {
      const strSensorUrl = environment.APIS.SENSORS;
      this.samplingFile = $event;

      const doc = this.arrConfigs.find(file => file['key'] === $event);

      return this._httpService
         .updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, { samplingFile: doc })
         .then(() => {
            this._snackBar.open('Sampling File has been setup successfully!', 'Success', {
               duration: 1000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         })
         .then(() => {
            this.onLoadConfigData(doc);
         });
   }

   private onLoadConfigData(doc: any) {
      const { key, name, path, url } = doc;

      if (url) {
         const objPostData = {
            url,
         };

         const strFireFunctionUrl = environment['FirebaseFunctionUrlCloud']; // cloud

         if (this.onLoadConfigDataSub) {
            this.onLoadConfigDataSub.unsubscribe();
         }

         this.onLoadConfigDataSub = this._purehttpService
            .callFirebaseFunction(`${strFireFunctionUrl}/getData`, objPostData)
            .subscribe({
               next: (res: any) => {
                  const data = this.configDataService.fillDefaultConfigProps(res.data);

                  this._httpService
                     .postAsObject(`${environment['APIS']['SENSORCONFIGS']}/${this.selectedSensor['key']}`, {
                        Current_Modal_Type: 2,
                        File_Name: doc['name'] ? doc['name'] : '',
                     })
                     .then(
                        () => {
                           console.log('Modal type update is successful!');
                        },
                        error => console.error(error)
                     );

                  this._httpService
                     .postAsObject(`${environment['APIS']['SENSORCONFIGS']}/${this.selectedSensor['key']}/${2}`, data)
                     .then(
                        () => {
                           console.log('The sensor is configured successfully.');
                        },
                        error => console.error(error)
                     );

                  this._httpService
                     .updateAsObject(`${environment['APIS']['SENSORDEVICES']}/${this.selectedSensor['key']}`, {
                        File_Name: doc['name'] ? doc['name'] : '',
                        fileName: doc['name'] ? doc['name'] : '',
                        fileKey: doc['key'] ? doc['key'] : '',
                     })
                     .then(
                        () => {
                           console.log('Assign is successful!');
                        },
                        error => console.error(error)
                     );
               },
               error: error => {
                  console.log('Fail to getting the file data.');
                  console.log(error);
               },
            });
      } else {
         console.log('The Url is not defined');
      }
   }

   onChangeFastModeLabel($event: any, label: string) {
      const key = label === 'one' ? 'fastModeConfigName1' : 'fastModeConfigName2';

      if (!$event) {
         return;
      }
      const strSensorUrl = environment.APIS.SENSORS;

      return this._httpService
         .updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, { [key]: $event })
         .then(() => {
            // this._snackBar.open('Label setup successfully!', 'Success', {
            //   duration: 1000,
            //   verticalPosition: 'top',
            //   horizontalPosition: 'center'
            // });
         })
         .then(() => {
            if (this.arrSamplingTimes.length) {
               this.arrSamplingTimes[0]['name'] = this.selectedSensor['fastModeConfigName1'];
               this.arrSamplingTimes[1]['name'] = this.selectedSensor['fastModeConfigName2'];
            }
         });
   }
}
