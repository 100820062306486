<div class="calibration-steps p-4">
   <step-one-calibration
      *ngIf="step === 1"
      (nextStep)="onNextStep($event)"
      (onCancelWizard)="cancelWizard($event)"></step-one-calibration>
   <step-two-calibration
      (backStep)="onNextStep($event)"
      *ngIf="step === 2"
      (submitForm)="onSubmitForm($event)"
      (onCancelWizard)="cancelWizard($event)"></step-two-calibration>
</div>
