import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../../components/spinner/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth.service';
import { PurehttpService } from '../../services/purehttp.service';
import { first, takeUntil } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SensorSchema } from '../../helpers/sensor-schema';

@Component({
   selector: 'app-calibration',
   templateUrl: './calibration.component.html',
   styleUrl: './calibration.component.scss',
})
export class CalibrationComponent {
   step = 1;
   private sensorKey: string;
   private destroy$: Subject<boolean> = new Subject();
   private selectedSensor: SensorSchema;
   constructor(
      private _httpService: HttpService,
      private activatedRouter: ActivatedRoute,
      private router: Router,
      private _authService: AuthService,
      public _snackBar: MatSnackBar,
      private _spinner: SpinnerService,
      public _pureHttpService: PurehttpService
   ) {}

   ngOnInit() {
      this.activatedRouter.params.subscribe({
         next: params => {
            this.sensorKey = params['sensorKey'];
         },
         error: error =>
            console.error('calibration-steps.component.ts -> this.activatedRouter.params.subscribe: ', error),
      });
      this._httpService
         .getAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`)
         .pipe(first(), takeUntil(this.destroy$))
         .subscribe({
            next: (sensor: SensorSchema) => {
               this.selectedSensor = sensor;
            },
            error: error =>
               console.error('calibration-steps.component.ts -> this.activatedRouter.params.subscribe: ', error),
         });
   }

   ngOnDestroy() {
      this.destroy$.next(null);
      this.destroy$.complete();
   }

   onNextStep(stepNum: number) {
      this.step = stepNum;
   }

   // update sensorDevice status
   updateSensorStatus(status: number) {
      console.log('updateSensorStatus', { actionStatus: status });
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORDEVICES}/${this.sensorKey}`, { actionStatus: status })
         .then(
            () => {
               console.log('Ping to node.js server');
            },
            error => console.error(error)
         );
   }

   onSubmitForm(formValue) {
      this._spinner.start();
      const { displayName, description } = formValue;
      const gasObject = formValue.gasForm.reduce((prev, gas) => {
         prev[gas.name.toLowerCase()] = {
            ppb: gas['ppb' + gas.name],
            errPct: gas['err' + gas.name],
         };

         return prev;
      }, {});
      const dataForm = {
         dateTime: new Date().toISOString(),
         displayName,
         userId: this._authService.currentUserId,
         userName: this._authService.currentUserName,
         description,
         gasSetup: gasObject,
      };
    
         this._httpService
            .getAsObject(`${environment.APIS.SENSORCALIBRATION}/${this.sensorKey}/current/calibrationConfigMetadata`)
            .pipe(first(), takeUntil(this.destroy$))
            .subscribe({
               next: savedCalibrationConfigMetadata => {
                  if (!savedCalibrationConfigMetadata) {
                     dataForm['calibrationConfigMetadata'] = {
                        Num_of_Cycle: 3,
                        Sampling_Time: 600, // 10 min
                        Idle_Time: 30, // 30 sec
                     };
                  }

                  console.log('dataForm', dataForm);

                  this._httpService
                     .updateAsObject(`${environment.APIS.SENSORCALIBRATION}/${this.sensorKey}/current`, { ...dataForm })
                     .then(() => {
                        this._spinner.stop();
                        this._snackBar.open('The calibration data has been updated!', 'Success', {
                           duration: 3000,
                           verticalPosition: 'top',
                           horizontalPosition: 'center',
                        });

                        this._pureHttpService
                           .pureCallFirebaseFunction(
                              `${environment['FirebaseFunctionUrlCloud']}/${environment.SystemType}CalibrationSaveSimpleConfig`,
                              { sensorId: this.sensorKey }
                           )
                           .subscribe({
                              next: (res: any) => {
                                 console.log('res', res);
                                 if (res === null) {
                                    // Handle a 204 response

                                    this.updateSensorStatus(12);
                                    this.router.navigate(['/sensor', this.sensorKey, 'live'], {
                                       queryParams: { type: 'edit' },
                                    });
                                 } else {
                                    // Handle other response codes
                                    this._snackBar.open(`Fail to save calibration config: ${res.status}`, 'Error', {
                                       duration: 3000,
                                       verticalPosition: 'top',
                                       horizontalPosition: 'center',
                                    });
                                 }
                              },
                              error: error => {
                                 this._spinner.stop();
                                 console.error(
                                    'calibration-steps.component.ts -> this._httpService.getAsObject: ',
                                    error
                                 );

                                 this._snackBar.open(
                                    'Fail to save calibration because of an internet connection',
                                    'Error',
                                    {
                                       duration: 3000,
                                       verticalPosition: 'top',
                                       horizontalPosition: 'center',
                                    }
                                 );
                              },
                           });
                     })
                     .catch(e => {
                        this._snackBar.open('Something wrong...', 'Error', {
                           duration: 3000,
                           verticalPosition: 'top',
                           horizontalPosition: 'center',
                        });
                     });
               },
               error: error => console.error('calibration-steps.component.ts -> saveCalibrationConfigData: ', error),
            });
      
   }

   cancelWizard($event) {
      this._snackBar.open('Calibration cancelled, no data saved', 'Error', {
         duration: 3000,
         verticalPosition: 'top',
         horizontalPosition: 'center',
      });

      this.router.navigateByUrl(`sensor/${this.sensorKey}?type=edit`);
   }

}
