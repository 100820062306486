&nbsp;&nbsp;
<ng-container *ngIf="showExportDaysControl;else staticView">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <mat-form-field class="days">
      <input
        formControlName="days"
        matInput
        type="number"
      />
    </mat-form-field>

    days back from

    <mat-form-field class="data-picker" appearance="fill">
      <input matInput [matDatepicker]="picker1" formControlName="dataSpan" [max]="maxDate">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    &nbsp;&nbsp;
    <button type="submit" mat-raised-button color="primary">Set dates</button>
    <mat-error *ngFor="let error of (myForm.get('days').errors | keyvalue)">
      {{errorsDict[error.key]}}
    </mat-error>
    <mat-error *ngFor="let error of (myForm.get('dataSpan').errors | keyvalue)">
      {{errorsDict[error.key]}}
    </mat-error>
  </form>
</ng-container>
<ng-template #staticView>
  <b>{{days}}</b> days back from <b>{{getDataSpanFrom}} ({{range}})</b>
</ng-template>


