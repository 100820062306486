import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { global_variables } from '../../../environments/global_variables';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { PurehttpService } from '../../services/purehttp.service';

@Component({
   selector: 'app-calibration-config-modal',
   templateUrl: './calibration-config-modal.component.html',
   styleUrls: ['./calibration-config-modal.component.scss'],
})
export class CalibrationConfigModalComponent implements OnInit {
   arrStrDisplayedColumns = ['position', 'name', 'time', 'use'];
   dataSource: MatTableDataSource<any>;
   bIsGetConfigurations: boolean;
   objAssignedData: Object;

   bIsTryToGetCC: boolean;
   bIsGetCloudConfigs: boolean;
   arrStrDataTypes = global_variables['DataTypes'];
   @ViewChild(MatPaginator) paginator: MatPaginator;
   @ViewChild('table') table: any;
   private arrConfigs: any[];
   private strCurrentDataType: string;
   objSelectedSensor: Object;
   strSelectedFileKey: string;
   selectedFile: any;
   strFilerText = '';

   constructor(
      private _httpService: HttpService,
      public dialogRef: MatDialogRef<CalibrationConfigModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _authService: AuthService,
      private _purehttpService: PurehttpService,
      private _snackBar: MatSnackBar,
      private _router: Router
   ) {
      this.objSelectedSensor = data['sensor'];
   }

   ngOnInit() {
      this.strCurrentDataType = this.arrStrDataTypes[0];
      this.getConfigList();
   }

   /**
    * load the configuration data from the firebase
    */
   getConfigList() {
      this.bIsGetCloudConfigs = false;
      this.bIsTryToGetCC = true;

      this._httpService
         .getListByOrder(`${environment.APIS.CONFIGURATIONS}`, 'mode', this.strCurrentDataType, 1)
         .subscribe({
            next: (configs) => {
               this.arrConfigs = [];
               let pos = 0;

               this.arrConfigs = configs
                  .sort((a, b) => {
                     return parseFloat(b.timestamp) - parseFloat(a.timestamp);
                  })
                  .map(item => {
                     pos++;

                     return {
                        position: pos,
                        name: item['path'].split('/').pop(),
                        url: item['configUrl'],
                        path: item['path'],
                        time: this.convertToDate(item['timestamp']),
                        key: item['key'],
                     };
                  });

               this.bIsGetConfigurations = false;
               this.bIsGetCloudConfigs = true;
               this.initDataTable();
            },
            error: error => {
               console.error('calibration-config-modal.component.ts -> getConfigList(): ', error);
               this.arrConfigs = [];
               this.bIsGetConfigurations = false;
               this.bIsGetCloudConfigs = true;
            }
         });
   }

   /**
    * convert the firebase timestamp to local date
    * @param timestamp date
    */
   convertToDate(timestamp: any) {
      const date = new Date(timestamp);
      let day = date.getDate() + '';
      let month = date.getMonth() + 1 + '';
      let year = date.getFullYear() + '';
      let hour = date.getHours() + '';
      let minutes = date.getMinutes() + '';
      let seconds = date.getSeconds() + '';

      day = this.checkDate(day);
      month = this.checkDate(month);
      year = this.checkDate(year);
      hour = this.checkDate(hour);
      minutes = this.checkDate(minutes);
      seconds = this.checkDate(seconds);
      return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
   }

   initDataTable(nCount: number = 0) {
      if (nCount > 50) {
         console.log('Timeout to create the datatable');
      } else if (!this.table || !this.paginator) {
         setTimeout(() => this.initDataTable(nCount), 50);
      } else {
         this.dataSource = new MatTableDataSource(this.arrConfigs);
         this.dataSource.data = this.arrConfigs.map(config => ({ ...config, selected: false }));
         this.dataSource.paginator = this.paginator;
      }
   }

   /**
    * check if the time is zero
    * @param time of date
    */
   checkDate(time: string) {
      if (time.length === 1) {
         time = '0' + time;
      }
      return time;
   }

   onChangeFile($event: MatRadioChange, element) {
      const value = $event.source.checked;
      this.dataSource.data = this.dataSource.data.map(data => {
         return data.position === element.position ? { ...data, selected: value } : { ...data, selected: false };
      });
   }

   continue() {
      const selectedConfig = this.dataSource.data.find(d => d.selected);
      selectedConfig.time = new Date(selectedConfig['time']).toISOString();
      console.log('selectedConfig', selectedConfig);
      this.dialogRef.close(selectedConfig);
   }

   onUpdateFilter($event: any) {
      let filterValue = $event.target.value.toLowerCase();
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
   }
}
