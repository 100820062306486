<div class="repeat-confirm-modal-container">
  <h2 mat-dialog-title>
    {{ strTitle }}
  </h2>

  <mat-dialog-content>
    <div class="label mb-3">
      Enter or change <strong>Comments</strong> for all data files:
    </div>

    <div class="wrap">
      <textarea class="full-width" [(ngModel)]="value"></textarea>
    </div>

    <div class="alert alert-info mt-3">
      <strong>Estimated total time:</strong> {{ strEstimate }}
    </div>

    <div class="alert text-center">Are you sure you want to start this run?</div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="space-between center">
    <button mat-stroked-button class="mr-1" (click)="onClose()">Cancel</button>
    <button mat-stroked-button color="primary" (click)="onFinish()">Run Now</button>
  </mat-dialog-actions>
</div>