<table class="table table-bordered table-striped">
  <thead>
  <tr>
    <th>Title</th>
    <th>Value</th>
    <th *ngIf="hasEditPermission()">Action</th>
  </tr>
  </thead>

  <tbody>
  <tr>
    <td>Weather Sensor ID</td>
    <td>
      <div *ngIf="bulkAction === BULK_ACTION.EDIT">{{sensor?.weatherSensor}}</div>
      <div *ngIf="bulkAction === BULK_ACTION.SAVE">
        <mat-form-field class="full-width">
          <input
            [(ngModel)]="paramValue"
            matInput
            type="text"
          />
        </mat-form-field>
      </div>
    </td>
    <td *ngIf="hasEditPermission()">
      <div fxLayout fxLayoutAlign="center">
        <div
          class="edit-item pointer"
          fxLayout
          fxLayoutAlign="center center"
        >
          <mat-icon *ngIf="bulkAction === BULK_ACTION.EDIT" (click)="bulkAction = BULK_ACTION.SAVE">edit</mat-icon>
        </div>

        <mat-icon
          *ngIf="bulkAction === BULK_ACTION.SAVE"
          (click)="onUpdate()"
          class="pointer mr-1"
          color="primary"
        >check
        </mat-icon>

        <div
          *ngIf="bulkAction === BULK_ACTION.SAVE"
          class="delete-item pointer"
          fxLayout
          fxLayoutAlign="center center"
        >
          <mat-icon (click)="bulkAction = BULK_ACTION.EDIT">close</mat-icon>
        </div>
      </div>
    </td>
  </tr>

  <tr>
    <td>Weather Sensor URL</td>
    <td>
      <div *ngIf="bulkActionWeatherSensor === BULK_ACTION.EDIT">
        <ng-container *ngIf="sensor?.weatherSensor">
          <a target="_blank" [href]="'https://tempestwx.com/station/' + sensor?.weatherSensor">Click here for live weather</a>
        </ng-container>
        <ng-container *ngIf="!sensor?.weatherSensor"> Configure station ID to see live weather</ng-container>
      </div>
    </td>
    <td *ngIf="isAdminRole"></td>
  </tr>
  </tbody>
</table>
