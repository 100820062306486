import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from 'src/app/components/spinner/spinner.service';
import { HttpService } from 'src/app/services/http.service';
import { environment } from '../../../environments/environment';
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import * as _ from 'lodash-es';
import { EmailGroupsDict } from '../../models/email-group';
import { CustomAlertTemplateModalComponent } from '../custom-alert-template-modal/custom-alert-template-modal.component';
import { SyncAlertsComponent } from '../sync-alerts/sync-alerts.component';

@Component({
   selector: 'app-notification-modal',
   templateUrl: './notification-modal.component.html',
   styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit, OnDestroy {
   private destroy$: Subject<boolean> = new Subject();
   isSubmitted: boolean;

   constructor(
      public dialogRef: MatDialogRef<NotificationModalComponent>,
      private _httpService: HttpService,
      private authService: AuthService,
      public _snackBar: MatSnackBar,
      private httClient: HttpService,
      private spinner: SpinnerService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog
   ) {
      this.sensorKey = data.sensorKey;

      this.formLoaded = false;

      this.selectedSensor = data.selectedSensor;

      this.isAdminRole = data.isAdminRole;
      this.gases = data['Gases'];
      this.errors = data['Errors'];
      this.calibrations = data['Calibrations'];
      this.sensorType = data['sensorType'];
   }

   sensorType: object;
   gasesForm: FormGroup;
   errorsForm: FormGroup;
   calibrationsForm: FormGroup;
   sensorKey: string;
   emails: EmailGroupsDict;
   hiddenFormControlsGases = [];
   hiddenFormControlsErrors = [];
   hiddenFormControlsCalibrations = [];
   formControlsName = [
      'Label',
      'Enabled',
      'Low',
      'High',
      'ErrorCode',
      'Action',
      'NotifyList',
      'SubjectEmailTemplate',
      'BodyEmailTemplate',
   ];
   gases: any;
   errors: any;
   calibrations: any;
   selectedSensor: any;
   MODIFIED_CONTROLS: Array<string> = ['Low', 'High'];
   private emailForm: FormGroup;
   formLoaded: boolean;
   private isAdminRole: boolean;

   private static setAdditionalFields(response, form) {
      for (const key in form) {
         if (form.hasOwnProperty(key)) {
            if (!response.hasOwnProperty(key)) {
               response[key] = _.cloneDeep(form[key]);
            }
            if (!response[key].hasOwnProperty('UserChangeable')) {
               response[key]['UserChangeable'] = form[key]['UserChangeable'];
            }

            if (!response[key].hasOwnProperty('UserVisible')) {
               response[key]['UserVisible'] = form[key]['UserVisible'];
            }

            if (!response[key].hasOwnProperty('AdminChangeable')) {
               response[key]['AdminChangeable'] = form[key]['AdminChangeable'];
            }

            if (!response[key].hasOwnProperty('AdminVisible')) {
               response[key]['AdminVisible'] = form[key]['AdminVisible'];
            }

            if (!response[key].hasOwnProperty('Order')) {
               response[key]['Order'] = form[key]['Order'];
            }
         }
      }
   }

   ngOnInit() {
      this.getAlertFormFromAPI()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.hiddenFormControlsGases = [];
               this.hiddenFormControlsErrors = [];
               this.hiddenFormControlsCalibrations = [];
               if (response) {
                  NotificationModalComponent.setAdditionalFields(response['Gases'], this.gases);
                  const sensorTypeGases = _.cloneDeep(this.gases);
                  const sensorTypeErrors = _.cloneDeep(this.errors);
                  const sensorTypeCalibrations = _.cloneDeep(this.calibrations);

                  this.gases = this.extendObject(response['Gases'], sensorTypeGases);
                  this.sortByOrder(this.gases);

                  NotificationModalComponent.setAdditionalFields(response['Errors'], this.errors);
                  this.errors = this.extendObject(response['Errors'], sensorTypeErrors);
                  this.sortByOrder(this.errors);

                  NotificationModalComponent.setAdditionalFields(response['Calibrations'], this.calibrations);
                  this.calibrations = this.extendObject(response['Calibrations'], sensorTypeCalibrations);
                  this.sortByOrder(this.calibrations);

                  // this.emails = response['Emails'];
                  console.log("response['Emails']", response['Emails']);
               }

               this.createGasesForm();
               this.createErrorsForm();
               this.createCalibrationsForm();
               this.formLoaded = true;
            },
            error: error => console.error('notification-modal.component.ts -> getAlertFromAPI(): ', error)
         });

      this._httpService
         .observerAsObject(`${environment.APIS.CUSTOMERCONTACTGROUPS}/${this.selectedSensor.customerId}/groups`)
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (groups) => {
               console.log('groups', groups);
               this.emails = groups;
            },
            error: error => console.error('notification-modal.component.ts -> getAlertFromAPI(): ', error)
         });
   }

   private extendObject(targetObj, additionalObj) {
      for (const key in additionalObj) {
         if (additionalObj.hasOwnProperty(key)) {
            if (!targetObj[key]) {
               targetObj[key] = additionalObj[key];
            }
         }
      }
      return targetObj;
   }

   getAlertFormFromAPI() {
      return this._httpService.observerAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`).pipe(
         map(response => {
            return response['alerts'];
         })
      );
   }

   onClose() {
      this.dialogRef.close();
   }

   onSubmit() {
      this.spinner.start();

      const emails = this.prepareEmailGroups();
      const gases = this.prepareGroupValues(this.gasesForm.getRawValue());
      const errors = this.prepareGroupValues(this.errorsForm.getRawValue());
      const calibrations = this.prepareGroupValues(this.calibrationsForm.getRawValue());

      const dataForm = {
         alerts: {
            // Emails: {
            //   ...emails // FIXME remove from here
            // },
            Gases: {
               ...gases,
            },
            Errors: {
               ...errors,
            },
            Calibrations: {
               ...calibrations,
            },
         },
      };

      console.log('dataForm', dataForm);

      Promise.all([
         this._httpService.postAsObject(
            `${environment.APIS.CUSTOMERCONTACTGROUPS}/${this.selectedSensor.customerId}/groups`,
            { ...emails }
         ),
         this._httpService.updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`, dataForm),
      ])
         .then(() => {
            this._httpService
               .updateAsObject(`${environment.APIS.SENSORDEVICES}/${this.sensorKey}`, { actionStatus: 10 })
               .then(() => {
                  this.spinner.stop();
                  this.isSubmitted = true;
                  this._snackBar.open('The notification data has been updated successful!', 'Success', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               })
               .catch(err => {
                  this.spinner.stop();
                  this.dialogRef.close();
                  this._snackBar.open('Firebase Error!', 'Error', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               });
         })
         .catch(err => {
            this.spinner.stop();
            this.dialogRef.close();
            this._snackBar.open('Firebase Error!', 'Error', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         });
   }

   private createGasesForm() {
      this.gasesForm = new FormGroup({});
      const formGroup = {};

      // add controls to form group
      for (const arr of this.sortByOrder(this.gases)) {
         formGroup[arr] = new FormGroup({});
         Object.keys(this.gases[arr]).forEach(key => {
            if (key === 'ErrorCode' || key === 'Action') {
               formGroup[arr].addControl(key, new FormControl({ value: this.gases[arr][key], disabled: true }));
            } else {
               this.checkIsAdminRoleForFields(arr, formGroup, key, this.gases);
            }
         });

         const enabledAction = this.gases[arr]['Enabled'];

         for (const control of this.MODIFIED_CONTROLS) {
            if (enabledAction) {
               (<FormGroup>formGroup[arr]).controls[control].enable();
            } else {
               (<FormGroup>formGroup[arr]).controls[control].disable();
            }
         }

         const adminVisible = this.gases[arr]['AdminVisible'];
         const userVisible = this.gases[arr]['UserVisible'];

         if (this.customer && !userVisible) {
            this.hiddenFormControlsGases.push(arr);
         }

         if (this.user && !adminVisible) {
            this.hiddenFormControlsGases.push(arr);
         }

         if (!formGroup[arr].get('NotifyList')) {
            formGroup[arr].addControl('NotifyList', new FormControl([]));
         }
         if (formGroup[arr].get('NotifyList') && formGroup[arr].get('NotifyList').value === '[]') {
            formGroup[arr].get('NotifyList').patchValue([]);
         }
         this.gasesForm.addControl(arr, formGroup[arr]);
      }

      this.gasesForm.updateValueAndValidity();
   }

   isDirtyForm(): boolean {
      return (
         (this.gasesForm.dirty && this.gasesForm.touched) ||
         (this.errorsForm.dirty && this.errorsForm.touched) ||
         (this.emailForm.dirty && this.emailForm.touched)
      );
   }

   private checkIsAdminRoleForFields(arr: string, formGroup: {}, key: string, form) {
      if (this.user) {
         const adminChangeable = form[arr]['AdminChangeable'];

         if (adminChangeable) {
            formGroup[arr].addControl(key, new FormControl(form[arr][key]));
         } else {
            formGroup[arr].addControl(key, new FormControl({ value: form[arr][key], disabled: true }));
         }
      } else {
         const userChangeable = form[arr]['UserChangeable'];

         if (userChangeable) {
            formGroup[arr].addControl(key, new FormControl(form[arr][key]));
         } else {
            formGroup[arr].addControl(key, new FormControl({ value: form[arr][key], disabled: true }));
         }
      }
   }

   private get customer() {
      return !this.authService.isUserStaff;
   }

   private get user() {
      return this.authService.isUserStaff;
   }

   private createErrorsForm() {
      this.errorsForm = new FormGroup({});
      const formGroup = {};

      // add controls to form group
      for (const arr of this.sortByOrder(this.errors)) {
         formGroup[arr] = new FormGroup({});
         Object.keys(this.errors[arr]).forEach(key => {
            if (key === 'ErrorCode' || key === 'Action') {
               formGroup[arr].addControl(key, new FormControl({ value: this.errors[arr][key], disabled: true }));
            } else {
               this.checkIsAdminRoleForFields(arr, formGroup, key, this.errors);
            }
         });

         const enabledAction = this.errors[arr]['Enabled'];

         for (const control of this.MODIFIED_CONTROLS) {
            if (enabledAction) {
               (<FormGroup>formGroup[arr]).controls[control].enable();
            } else {
               (<FormGroup>formGroup[arr]).controls[control].disable();
            }
         }

         const adminVisible = this.errors[arr]['AdminVisible'];
         const userVisible = this.errors[arr]['UserVisible'];

         if (this.customer && !userVisible) {
            this.hiddenFormControlsErrors.push(arr);
         }

         if (this.user && !adminVisible) {
            this.hiddenFormControlsErrors.push(arr);
         }

         if (!formGroup[arr].get('NotifyList')) {
            formGroup[arr].addControl('NotifyList', new FormControl([]));
         }
         if (formGroup[arr].get('NotifyList') && formGroup[arr].get('NotifyList').value === '[]') {
            formGroup[arr].get('NotifyList').patchValue([]);
         }

         this.errorsForm.addControl(arr, formGroup[arr]);
      }
   }

   private createCalibrationsForm() {
      this.calibrationsForm = new FormGroup({});
      const formGroup = {};

      // add controls to form group
      for (const arr of this.sortByOrder(this.calibrations)) {
         formGroup[arr] = new FormGroup({});
         Object.keys(this.calibrations[arr]).forEach(key => {
            if (key === 'ErrorCode' || key === 'Action') {
               formGroup[arr].addControl(key, new FormControl({ value: this.calibrations[arr][key], disabled: true }));
            } else {
               this.checkIsAdminRoleForFields(arr, formGroup, key, this.calibrations);
            }
         });

         const enabledAction = this.calibrations[arr]['Enabled'];
         for (const control of this.MODIFIED_CONTROLS) {
            if (enabledAction) {
               (<FormGroup>formGroup[arr]).controls[control].enable();
            } else {
               (<FormGroup>formGroup[arr]).controls[control].disable();
            }
         }

         const adminVisible = this.calibrations[arr]['AdminVisible'];
         const userVisible = this.calibrations[arr]['UserVisible'];

         if (this.customer && !userVisible) {
            this.hiddenFormControlsCalibrations.push(arr);
         }

         if (this.user && !adminVisible) {
            this.hiddenFormControlsCalibrations.push(arr);
         }

         if (!formGroup[arr].get('NotifyList')) {
            formGroup[arr].addControl('NotifyList', new FormControl([]));
         }
         if (formGroup[arr].get('NotifyList') && formGroup[arr].get('NotifyList').value === '[]') {
            formGroup[arr].get('NotifyList').patchValue([]);
         }

         this.calibrationsForm.addControl(arr, formGroup[arr]);
      }
   }

   public toggleChanged($event: MatSlideToggleChange, formGroup: string, form: FormGroup) {
      const {
         source: { checked },
      } = $event;
      for (const control of this.MODIFIED_CONTROLS) {
         if (checked) {
            form.get(formGroup).get(control).enable();
         } else {
            form.get(formGroup).get(control).disable();
         }
      }
   }

   public ngOnDestroy(): void {
      this.destroy$.next(null);
      this.destroy$.complete();
   }

   private sortByOrder(form = {}) {
      return Object.keys(form).sort((a, b) => form[a]['Order'] - form[b]['Order']);
   }

   onEmailGroupsChangeEvent($event) {
      this.emailForm = $event;
   }

   prepareEmailGroups() {
      const SEPARATOR = ',';
      const emails = {};
      for (const [key, group] of Object.entries(this.emailForm.value)) {
         emails[key] = {
            key,
            name: group['name'],
            emails:
               group['emails'] && group['emails'].includes(SEPARATOR)
                  ? group['emails'].split(SEPARATOR)
                  : group['emails'],
         };
      }
      return emails;
   }

   editCustomEmailTemplate(typeKey, alertKey, form) {
      const alert = form.get(alertKey);
      const dialogRef = this.dialog.open(CustomAlertTemplateModalComponent, {
         panelClass: 'template-modal',
         disableClose: true,
         data: {
            SubjectEmailTemplate: String(alert.value.SubjectEmailTemplate),
            BodyEmailTemplate: String(alert.value.BodyEmailTemplate),
         },
      });

      dialogRef
         .afterClosed()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (result) => {
               if (result) {
                  this._httpService
                     .updateAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}/alerts/${typeKey}/${alertKey}`, {
                        SubjectEmailTemplate: result.SubjectEmailTemplate,
                        BodyEmailTemplate: result.BodyEmailTemplate,
                     })
                     .then(() => {
                        this._snackBar.open('The template is updated!', 'Success', {
                           duration: 3000,
                           verticalPosition: 'top',
                           horizontalPosition: 'center',
                        });
                     })
                     .catch(err => {
                        this.spinner.stop();
                        this.dialogRef.close();
                        this._snackBar.open('Firebase Error!', 'Error', {
                           duration: 3000,
                           verticalPosition: 'top',
                           horizontalPosition: 'center',
                        });
                     });
               }
            },
            error: error => console.error('notification-modal.component.ts -> editCustomEmailTemplate(): ', error)
         });
   }

   prepareGroupValues(rawValues) {
      for (const [alertKey, alert] of Object.entries(rawValues)) {
         if (!alert['NotifyList'] || (alert['NotifyList'] && alert['NotifyList'].length === 0)) {
            rawValues[alertKey]['NotifyList'] = '[]';
         }
      }
      return rawValues;
   }

   syncAlertsModal() {
      const dialogRef = this.dialog.open(SyncAlertsComponent, {
         panelClass: 'strict-dialog',
         disableClose: true,
         data: {
            currentAlerts: this.selectedSensor && this.selectedSensor.alerts ? this.selectedSensor.alerts : {},
            sensorTypeAlerts:
               this.sensorType && this.sensorType['alertTemplate'] ? this.sensorType['alertTemplate'] : {},
            sensorId: this.selectedSensor.key,
         },
      });

      dialogRef
         .afterClosed()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (result) => {
               console.log(result);
            },
            error: error => console.error('notification-modal.component.ts -> syncAlertModal(): ', error)
         });
   }
}
