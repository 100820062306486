import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorTypeInfoComponent } from './sensor-type-info.component';
import { SensortypeDetailPipe } from '../../filters/sensortype-detail.pipe';
import { MatModule } from '../../shared/modules/mat.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatModule,
    FormsModule
  ],
  declarations: [SensorTypeInfoComponent, SensortypeDetailPipe],
  exports: [SensorTypeInfoComponent]
})
export class SensorTypeInfoModule { }
