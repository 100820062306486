<div class="alert-modal-container">
  <h1 mat-dialog-title class="primary-color">{{ strTitle }}</h1>

  <mat-dialog-content class="accent-color">
    {{ strDescription }}
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close color="warn" (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>