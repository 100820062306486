// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const global_variables = {
   userTypes: ['staffs', 'customers'],
   userStatus: ['approved', 'pending'],
   userRoles: ['admin', 'developer', 'operator', 'viewer', 'debugger'],
   stepActions: ['Measurement Run', 'Data Processing', 'Data Upload', 'Chemical Recognition'],
   tableTypes: ['header_type', 'header_row_type'],
   algorithms: ['Peak detection'],
   sensorResponse: '111',
   deviceStatus: ['on', 'off'],
   onOffStatus: ['on', 'off'],
   FirebaseFunctionUrlCloud: 'https://us-central1-omni2100.cloudfunctions.net',
   FirebaseFunctionUrlLocal: 'http://localhost:5000/omni2100/us-central1',
   DataTypes: ['Frac_Delta', 'Absolute', 'Delta'],
   ProcessedDataTypes: ['Chromatogram', 'DetectedPeaks'],
   Categories: ['status', 'vocAnalytics', 'debug'],
   ValueTypes: ['number', 'text', 'textarea', 'date', 'option'],
   StepTypes: ['Forward Purge', 'Backward Purge', 'Sampling', 'Separation', 'Idle', 'Calibrate Flow', 'Other'],
   RententionProperties: [
      'Automation TimeStamp',
      'Peak Ratio',
      'Det1 Height',
      'Det2 Height',
      'Avg. Flow',
      'Avg. Chip Temperature',
      'Flag',
      'Interior T',
      'Interior RH',
      'Supply V',
      'tR',
      'tR.EL',
      'tR.EU',
   ],
   AnalyticalInputs: ['Calibrated (ppb)'],
   modalTypes: [0, 1, 2], // omni-1000, omni-2000, omni-2100
   modelModes: ['fast', 'normal', 'dual']
};
