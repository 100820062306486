import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { alert } from '../../../alert';
import * as _ from 'lodash-es';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';

@Component({
   selector: 'app-sync-alerts',
   templateUrl: './sync-alerts.component.html',
   styleUrls: ['./sync-alerts.component.scss'],
})
export class SyncAlertsComponent implements OnInit {
   constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<SyncAlertsComponent>,
      private _httpService: HttpService,
      public _snackBar: MatSnackBar
   ) {}

   alertTemplate = alert.alertTemplate;
   currentAlerts = {};
   sensorTypeAlerts = {};

   missedSensorTypeAlerts = [];
   missedAlertTemplates = [];
   isLoading = false;

   ngOnInit() {
      this.currentAlerts = this.data.currentAlerts;
      this.sensorTypeAlerts = this.data.sensorTypeAlerts;
      this.missedSensorTypeAlerts = this.fillMissedAlerts(this.sensorTypeAlerts);
      this.missedAlertTemplates = this.fillMissedAlerts(this.alertTemplate);
   }

   fillMissedAlerts(source) {
      const missedArr = new Set();
      for (const alertTypeKey in source) {
         if (source.hasOwnProperty(alertTypeKey)) {
            if (!this.currentAlerts[alertTypeKey]) {
               missedArr.add(alertTypeKey);
            } else {
               for (const alertKey in source[alertTypeKey]) {
                  if (source[alertTypeKey].hasOwnProperty(alertKey)) {
                     if (this.currentAlerts[alertTypeKey] && !this.currentAlerts[alertTypeKey][alertKey]) {
                        missedArr.add(`${alertTypeKey}.${alertKey}`);
                     } else {
                        const alertInstance = source[alertTypeKey][alertKey];
                        for (const propertyKey in alertInstance) {
                           if (alertInstance.hasOwnProperty(propertyKey)) {
                              if (this.currentAlerts[alertTypeKey][alertKey][propertyKey] === undefined) {
                                 missedArr.add(`${alertTypeKey}.${alertKey}.${propertyKey}`);
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
      return Array.from(missedArr);
   }

   addMissedAlertTemplates(source, missed) {
      missed.forEach(p => {
         const data = _.get(source, p);
         console.log(source, p, data);
         _.set(this.currentAlerts, p, data);
      });
      this.isLoading = true;
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.data.sensorId}/alerts`, this.currentAlerts)
         .then(() => {
            this.isLoading = false;
            this._snackBar.open('The sensor alerts is updated successful!', 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            this.onClose();
         })
         .catch(e => {
            this.isLoading = false;
            this._snackBar.open(`Can't sync alert templates. Try later`, 'Error', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         });
   }

   onClose() {
      this.dialogRef.close();
   }
}
