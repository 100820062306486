export const windDirectionTransform = (direction: number) => {
  return direction >= 0 && direction <= 11.25 ? '(N)'
    : direction <= 33.75 ? '(NNE)'
      : direction <= 56.25 ? '(NE)'
      : direction <= 78.75 ? '(ENE)'
      : direction <= 101.25 ? '(E)'
      : direction <= 123.75 ? '(ESE)'
      : direction <= 146.25 ? '(SE)'
      : direction <= 168.76 ? '(SSE)'
      : direction <= 191.25 ? '(S)'
      : direction <= 213.75 ? '(SSW)'
      : direction <= 236.25 ? '(SW)'
      : direction <= 258.75 ? '(WSW)'
      : direction <= 281.25 ? '(W)'
      : direction <= 303.75 ? '(WNW)'
      : direction <= 326.25 ? '(NW)'
      : direction <= 348.75 ? '(NNW)'
      : direction <= 360 ? '(N)'
      : '';
};
