import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() strInputType: string;
  @Input() value: any;
  @Input() arrStrOptions: string[];
  @Output() doneEdit = new EventEmitter();
  arrStrInputTypes: string[];
  bIsEdit: boolean;
  bIsShowEditBtn: boolean;

  constructor(private cdRef: ChangeDetectorRef) {
    this.arrStrInputTypes = ['number', 'text', 'textarea', 'date', 'option'];
    this.bIsEdit = false;
    this.bIsShowEditBtn = false;
  }

  ngOnInit() {
    if (!this.strInputType) {
      this.strInputType = this.arrStrInputTypes[0];
    }

    if (!this.arrStrOptions) {
      this.arrStrOptions = [];
    }
  }

  /**
   * mouse over on element
   */
  onOver() {
    this.bIsShowEditBtn = true;
    this.cdRef.detectChanges();
  }

  /**
   * mouse leave function from element
   */
  onLeave() {
    this.bIsShowEditBtn = false;
    this.cdRef.detectChanges();
  }

  /**
   * edit action function
   */
  onClickEdit() {
    this.bIsEdit = true;
    this.cdRef.detectChanges();
  }

  /**
   * edit doen function
   */
  onDone() {
    this.bIsEdit = false;
    this.bIsShowEditBtn = false;
    this.doneEdit.emit(this.value);
  }

}
