<table mat-table #table class="omni-table full-width" [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef style="width: 50%">
      <div class="omni-table-header">
        Name
      </div>
    </th>
    <td mat-cell *matCellDef="let element" style="width: 50%">
      <div class="omni-col-wrap">
        <div class="omni-col-item">
          {{ element?.name }}
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef style="width: 50%">
      <div class="omni-table-header">
        Key
      </div>
    </th>
    <td mat-cell *matCellDef="let element" style="width: 50%">
      <div class="omni-col-wrap">
        <div class="omni-col-item">
          {{ element?.id }}
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row class="no-padding" *matHeaderRowDef="columns"></tr>
  <tr mat-row class="no-padding" *matRowDef="let row; columns: columns;"></tr>
</table>
