import { CalibrationComponent } from './live-page/calibration/calibration.component';

import { Routes } from '@angular/router';
import { ConfigureComponent } from './configure/configure.component';
import { CreateComponent } from './create/create.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetComponent } from './reset/reset.component';
import { SensorCalibrationComponent } from './sensor/sensor-calibration/sensor-calibration.component';
import { SensorTypeInfoComponent } from './sensor/sensor-type-info/sensor-type-info.component';
import { SensorTypeComponent } from './sensor/sensor-type/sensor-type.component';
import { SensorComponent } from './sensor/sensor.component';
import { AuthguardService } from './services/authguard.service';
import { SignUpComponent } from './sign-up/sign-up.component';
import { VerifyComponent } from './verify/verify.component';
import { ZoneDetailComponent } from './zone/zone-detail/zone-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EulaComponent } from './eula/eula.component';
import { CalibrationStepsComponent } from './sensor/calibration-steps/calibration-steps.component';
import { ConfigurationPageComponent } from './live-page/configuration-page/configuration-page.component';
import { Test500Component } from './test500/test500.component';

export const routes: Routes = [
   {
      path: 'app-configuration-page',
      component: ConfigurationPageComponent,
   },
   {
      path: '',
      redirectTo: '/login',
      pathMatch: 'full',
   },
   {
      path: 'eula',
      component: EulaComponent,
   },
   {
      path: 'raw-data-plot',
      loadChildren: () => import('./raw-data-plot/raw-data-plot.module').then(m => m.RawDataPlotModule),
      canActivate: [AuthguardService],
   },
   { path: 'login', component: LoginComponent },
   { path: ':customerId/login', component: LoginComponent },
   { path: 'register', component: SignUpComponent },
   { path: ':customerId/register', component: SignUpComponent },
   { path: 'auth/:type', component: VerifyComponent },
   {
      path: 'dashboard',
      component: DashboardComponent,
      canActivate: [AuthguardService],
   },
   {
      path: ':customerId/dashboard',
      component: DashboardComponent,
      canActivate: [AuthguardService],
   },
   {
      path: 'embed-map',
      loadChildren: () => import('./embed/sensor-map/sensor-map.module').then(m => m.SensorMapModule),
   },
   {
      path: 'configure/:sensorKey',
      component: ConfigureComponent,
      canActivate: [AuthguardService],
   },
   {
      path: 'admin',
      loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      canActivate: [AuthguardService],
      data: {
         types: ['staffs', 'customers'],
         roles: ['admin'],
      },
   },
   {
      path: ':customId/admin',
      loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      canActivate: [AuthguardService],
      data: { roles: ['admin'] },
   },
   {
      path: 'create/:name',
      component: CreateComponent,
      canActivate: [AuthguardService],
   },
   {
      path: 'customer',
      loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
      canActivate: [AuthguardService],
      data: {
         types: ['staffs'],
         roles: ['admin', 'debugger', 'operator', 'viewer', 'developer'],
      },
   },
   {
      path: 'zone',
      loadChildren: () => import('./zone/zone.module').then(m => m.ZoneModule),
      canActivate: [AuthguardService],
      data: {
         types: ['staffs'],
         roles: ['admin', 'debugger', 'operator', 'viewer'],
      },
   },
   {
      path: 'sensor_list',
      loadChildren: () => import('./sensor/sensor-list/sensor-list.module').then(m => m.SensorListModule),
      canActivate: [AuthguardService],
      data: {
         types: ['staffs'],
         roles: ['admin', 'debugger', 'operator', 'viewer', 'developer'],
      },
   },
   {
      path: ':customId/sensor_list',
      canActivate: [AuthguardService],
      loadChildren: () => import('./sensor/sensor-list/sensor-list.module').then(m => m.SensorListModule),
      data: {
         types: ['customers'],
         roles: ['admin', 'debugger', 'operator', 'viewer'],
      },
   },
   {
      path: ':customId/zone',
      loadChildren: () => import('./zone/zone.module').then(m => m.ZoneModule),
      canActivate: [AuthguardService],
      data: {
         types: ['customers'],
         roles: ['admin', 'debugger', 'operator', 'viewer'],
      },
   },
   {
      path: 'sensor/:sensorId/live',
      loadChildren: () => import('./live-page/live-page.module').then(m => m.LivePageModule),
      canActivate: [AuthguardService],
   },
   {
      path: 'customer_detail/:id',
      loadChildren: () => import('./customer/customer-detail/customer-detail.module').then(m => m.CustomerDetailModule),
      canActivate: [AuthguardService],
   },
   {
      path: 'zone_report/:zoneId',
      loadChildren: () => import('./zone-report/zone-report.module').then(m => m.ZoneReportModule),
      canActivate: [AuthguardService],
   },
   {
      path: 'zone_detail/:id',
      component: ZoneDetailComponent,
      canActivate: [AuthguardService],
   },
   {
      path: 'sensor/:id',
      component: SensorComponent,
      canActivate: [AuthguardService],
   },
   {
      path: 'sensor_type',
      component: SensorTypeComponent,
      canActivate: [AuthguardService],
      data: {
         types: ['staffs'],
         roles: ['admin', 'developer'],
      },
   },
   {
      path: 'sensor_type_list',
      loadChildren: () => import('./sensor/sensor-type-list/sensor-type-list.module').then(m => m.SensorTypeListModule),
      canActivate: [AuthguardService],
   },
   {
      path: ':customId/sensor_type_list',
      loadChildren: () => import('./sensor/sensor-type-list/sensor-type-list.module').then(m => m.SensorTypeListModule),
      canActivate: [AuthguardService],
   },
   {
      path: 'sensor_type_info/:id',
      component: SensorTypeInfoComponent,
      canActivate: [AuthguardService],
   },
   {
      path: 'reset',
      component: ResetComponent,
   },
   {
      path: 'reset-password',
      component: ResetPasswordComponent,
   },
   {
      path: ':sensorKey/calibration',
      component: CalibrationStepsComponent,
      canActivate: [AuthguardService],
   },
   {
      path: ':sensorKey/live/calibration',
      component: CalibrationComponent,
      canActivate: [AuthguardService],
   },
   {
      path: 'calibration/:sensorKey',
      component: SensorCalibrationComponent,
      canActivate: [AuthguardService],
      data: {
         types: ['staffs'],
         roles: ['admin', 'developer'],
      },
   },
   {
      path: 'test500',
      component: Test500Component,
   },
   // {path: '**', redirectTo: '/dashboard'}
];
