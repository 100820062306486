<div class="omniscent-view">
	<h1 class="text-center omniscent-title">
		Sensor Type Edit
	</h1>

	<div class="omniscent-wrap omniscent-catalog-view">
		<app-sensor-type-detail
		class="full-width"
		></app-sensor-type-detail>
	</div>
</div>