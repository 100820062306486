<mat-card class="confirm-content">
   <h2 mat-dialog-title fxLayout fxLayoutAlign="start center">
      <mat-icon color="warn" class="mr-1">warning</mat-icon>
      The calibration process has not been configured
   </h2>

   <mat-dialog-content>
      <div>
         The calibration file is not selected.<br />
         Please contact Omniscent Support
      </div>
   </mat-dialog-content>

   <mat-dialog-actions align="end">
      <button mat-stroked-button color="primary" class="mr-1" (click)="onClose()">Close</button>
   </mat-dialog-actions>
</mat-card>
