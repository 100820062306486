<mat-card appearance="outlined" class='detail-viewer-container p-1'>
  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <div class="dis-flex">
          <div class="detail-viewer-title"><mat-icon mat-list-icon>title</mat-icon></div>
          <div class="detail-viewer-description">
            <h4 mat-line>Name</h4>
            <p mat-line>{{ strName }}</p>
          </div>  
        </div>     
      </mat-list-item>

      <mat-list-item class='mt-3'>
        <div class="dis-flex">
          <div class="detail-viewer-title"><mat-icon mat-list-icon>description</mat-icon></div>
          <div class="detail-viewer-description">
            <h4 mat-line>Description</h4>
            <p mat-line class="description-content" *ngIf="strDescription">{{ strDescription }}</p>
          </div>    
       </div> 
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
