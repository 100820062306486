<div *ngIf="!isLoaded;else loaded" class="text-center">
  <mat-spinner></mat-spinner>
</div>
<ng-template #loaded>
  <form [formGroup]="emailForm">
    <section *ngFor="let group of emailForm.controls | keyvalue">
      <div [formGroupName]="group.key">
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>Group name</mat-label>
              <input type="text" matInput formControlName="name" required>
            </mat-form-field>
          </div>
          <div class="col text-right">
            <button mat-stroked-button color="warn" (click)="deleteGroup(group.key)">
              <mat-icon aria-hidden="false" aria-label="delete email group">delete</mat-icon>
              Delete group
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Emails list</mat-label>
              <textarea placeholder="Type emails separate by comma" formControlName="emails" matInput></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </section>


    <div class="row">
      <div class="col">
        <button
          (click)="addNewGroup()"
          mat-stroked-button
          color="primary"
          class="mr-1"
        >
          Add new group
        </button>
      </div>
    </div>
  </form>
</ng-template>
