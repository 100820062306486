import moment from 'moment-timezone';
import { ISelectedSensor } from '../live-page/models';

export function convertToDate(timestamp: number, selectedSensor: ISelectedSensor) {
   const timezone = selectedSensor.timezone ? selectedSensor.timezone : 'America/Los_Angeles';

   return moment(timestamp).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
}

export function formatBytes(bytes: string) {
   let bytesInNum;
   bytesInNum = parseInt(bytes, 10);

   if (isNaN(bytesInNum)) {
      return 'Unknown';
   } else {
      if (bytesInNum < 1024) {
         return bytesInNum + ' Bytes';
      } else if (bytesInNum < 1048576) {
         return (bytesInNum / 1024).toFixed(3) + ' KB';
      } else if (bytesInNum < 1073741824) {
         return (bytesInNum / 1048576).toFixed(3) + ' MB';
      } else {
         return (bytesInNum / 1073741824).toFixed(3) + ' GB';
      }
   }
}

export function getFilterPredicate(filter: string) {
   return (data, filter): boolean => {
      if (this.fromDate && this.toDate) {
         const dateTo = new Date(this.toDate);
         dateTo.setHours(24, 0, 0, 0);
         return (
            new Date(data['startTimestamp']).getTime() >= new Date(this.fromDate).getTime() &&
            new Date(data['startTimestamp']).getTime() <= dateTo.getTime()
         );
      }

      return (
         data['data'][0]['comment'].toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase()) ||
         data['data'][0]['description'].toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase())
      );
   };
}
