import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import { global_variables } from '../../environments/global_variables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
   authState: any = null;
   nUserTypeCount: number;
   bIsStaff: boolean;
   bIsGetUserData: boolean;

   objCustomerUser: Object;
   objStaffUser: Object;
   objCompanyData: Object;

   loadAPI: Subject<any>;

   constructor(private afAuth: AngularFireAuth, private httpService: HttpService, private router: Router) {
      this.bIsStaff = false;
      this.bIsGetUserData = false;
      this.nUserTypeCount = 0;
      this.loadAPI = new Subject();

      this.afAuth.authState.subscribe({
         next: (auth) => {
            this.bIsGetUserData = false;
            this.authState = auth;

            if (this.authState) {
               this.getUserData();
            } else {
               this.bIsGetUserData = true;
               this.loadAPI.next({
                  status: -1,
               });
            }
         },
         error: error => {
            this.bIsGetUserData = true;
            this.authState = null;
            this.loadAPI.next({
               status: 0,
               message: 'Firebase connection error',
            });
         }
      });
   }

   setUserData(companyData: Object) {
      if (companyData) {
         this.objCompanyData = companyData;
         this.bIsGetUserData = true;
         this.loadAPI.next({
            status: 2,
         });
      } else {
         this.bIsGetUserData = true;
         this.loadAPI.next({
            status: 1,
            message: "Your company information isn't enough at a moment. Please contact support team",
         });
      }
   }

   checkCompany() {
      const user = this.bIsStaff ? this.objStaffUser : this.objCustomerUser;

      if (user.hasOwnProperty('action') && user['action']['status'] === global_variables['userStatus'][0]) {
         if (this.bIsStaff) {
            this.httpService.getAsObject(environment['APIS']['STAFFINFO'], 1).subscribe({
               next: (staffInfo) => {
                  this.setUserData(staffInfo);
               },
               error: error => {
                  console.log(error);
                  this.bIsGetUserData = true;
                  this.loadAPI.next({
                     status: 0,
                     message: 'Firebase Connection Error',
                  });
               }
            });
         } else {
            this.httpService
               .getAsObject(`${environment['APIS']['CUSTOMERPORTALS']}/${user['info']['customerId']}`, 1)
               .subscribe({
                  next: (companyInfo) => {
                     this.setUserData(companyInfo);
                  },
                  error: error => {
                     console.log(error);
                     this.bIsGetUserData = true;
                     this.loadAPI.next({
                        status: 0,
                        message: 'Firebase Connection Error',
                     });
                  }
               });
         }
      } else {
         this.bIsGetUserData = true;
         this.loadAPI.next({
            status: 1,
            message: 'Your account is pending approval, Please wait until Admin activates yours.',
         });
      }
   }

   checkUserStatus(user: Object, userType: string, fitCounter: number) {
      if (userType === 'customer') {
         this.objCustomerUser = user;
      } else {
         this.objStaffUser = user;
      }

      if (fitCounter > 1) {
         if (this.objCustomerUser && this.objCustomerUser['info']) {
            this.bIsStaff = false;
            this.checkCompany();
            return;
         } else if (this.objStaffUser && this.objStaffUser['info']) {
            this.bIsStaff = true;
            this.checkCompany();
            return;
         } else {
            this.bIsGetUserData = true;
            this.loadAPI.next({
               status: 1,
               message: "Your account isn't existed",
            });
         }
      }
   }

   getUserData() {
      let fitCounter = 0;

      /*=== Customer ===*/
      this.httpService.getAsObject(`${environment['APIS']['USERS']}/customers/${this.currentUserId}`, 1).subscribe({
         next: (user) => {
            fitCounter++;
            this.checkUserStatus(user, 'customer', fitCounter);
         },
         error: error => {
            console.log(error);
            this.bIsGetUserData = true;
            this.loadAPI.next({
               status: 0,
               message: 'Firebase connection error',
            });
         }
      });
      /*=== Customer ===*/

      /*=== Staff ===*/
      this.httpService.getAsObject(`${environment['APIS']['USERS']}/staffs/${this.currentUserId}`, 1).subscribe({
         next: (user) => {
            fitCounter++;
            this.checkUserStatus(user, 'staff', fitCounter);
         },
         error: error => {
            console.log(error);
            this.bIsGetUserData = true;
            this.loadAPI.next({
               status: 0,
               message: 'Firebase connection error',
            });
         }
      });
      /*=== Staff ===*/
   }

   get isCheckUser(): boolean {
      return this.bIsGetUserData;
   }

   get isUserStaff(): boolean {
      return this.bIsStaff;
   }

   get isUserAnonymousLoggedIn(): boolean {
      return this.authState !== null ? this.authState.isAnonymous : false;
   }

   get currentUserId(): string {
      return this.authState !== null ? this.authState.uid : '';
   }

   get currentUserName(): string {
      return this.authState['email'];
   }

   get currentUser(): any {
      return this.authState !== null ? this.authState : null;
   }

   get isUserEmailLoggedIn(): boolean {
      if (this.authState !== null && !this.isUserAnonymousLoggedIn && this.bIsGetUserData) {
         return true;
      } else {
         return false;
      }
   }

   get userData(): Object {
      const user = this.bIsStaff ? this.objStaffUser : this.objCustomerUser;

      if (this.bIsStaff) {
         return user['info'] ? user : null;
      } else {
         return user['info'] && user['info']['customerId'] ? user : null;
      }
   }

   get companyData(): Object {
      return this.objCompanyData;
   }

   resetPassword(email: string, host: string): Promise<any> {
      return this.afAuth.sendPasswordResetEmail(email, { url: host });
   }

   handleResetPassword(actionCode: string): Promise<any> {
      return this.afAuth.verifyPasswordResetCode(actionCode);
   }

   processResetPassword(actionCode: string, newPassword: string): Promise<any> {
      return this.afAuth.confirmPasswordReset(actionCode, newPassword);
   }

   signUpWithEmail(email: string, password: string): Promise<any> {
      return this.afAuth.createUserWithEmailAndPassword(email, password);
   }

   loginWithEmail(email: string, password: string) {
      return this.afAuth
         .signInWithEmailAndPassword(email, password)
         .then(user => {
            this.authState = user;
            this.bIsGetUserData = false;
            // this.getUserData();
         })
         .catch(error => {
            console.log(error);
            throw error;
         });
   }

   logout(bIsNavigate = true, bIsReturnUrl = true, customerPath = ''): Promise<any> {
      return new Promise<any>((resolve, reject) => {
         const mainUri = customerPath ? `/${customerPath}/login` : '/login';
         this.afAuth.signOut().then(() => {
            if (bIsNavigate) {
               // if (bIsReturnUrl) {console.log(this.router.url);
               //   return this.router.navigate([mainUri], { queryParams: { returnUrl: this.router.url }});
               // } else {
               //   return this.router.navigate([mainUri]);
               // }
               resolve(this.router.navigate([mainUri]));
            }
         }, reject);
      });
   }
}
