import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ConfigModalComponent} from './config-modal.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatModule} from '../../shared/modules/mat.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [ConfigModalComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ConfigModalComponent]
})
export class ConfigModalModule { }
