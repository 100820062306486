import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-mat-dropdown-search',
  templateUrl: './mat-dropdown-search.component.html',
  styleUrls: ['./mat-dropdown-search.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MatDropdownSearchComponent),
    multi: true
  }]
})
export class MatDropdownSearchComponent implements ControlValueAccessor {
  constructor() {
  }

  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }

  }
  val = '';

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
