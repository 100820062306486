<table class="table table-bordered table-striped">
   <thead>
      <tr>
         <th>Title</th>
         <th>Value</th>
         <th *ngIf="hasEditPermission()">Action</th>
      </tr>
   </thead>

   <tbody>
      <tr>
         <td>Weather Sensor ID</td>
         <td>
            <div *ngIf="bulkAction === BULK_ACTION.EDIT">{{ sensor?.weatherSensor }}</div>
            <div *ngIf="bulkAction === BULK_ACTION.SAVE">
               <mat-form-field class="full-width">
                  <input [(ngModel)]="paramValue" matInput type="text" />
               </mat-form-field>
            </div>
         </td>
         <td *ngIf="hasEditPermission()">
            <div fxLayout fxLayoutAlign="center">
               <div class="edit-item pointer" fxLayout fxLayoutAlign="center center">
                  <mat-icon *ngIf="bulkAction === BULK_ACTION.EDIT" (click)="bulkAction = BULK_ACTION.SAVE"
                     >edit</mat-icon
                  >
               </div>

               <mat-icon
                  *ngIf="bulkAction === BULK_ACTION.SAVE"
                  (click)="onUpdate()"
                  class="pointer mr-1"
                  color="primary"
                  >check
               </mat-icon>

               <div
                  *ngIf="bulkAction === BULK_ACTION.SAVE"
                  class="delete-item pointer"
                  fxLayout
                  fxLayoutAlign="center center">
                  <mat-icon (click)="bulkAction = BULK_ACTION.EDIT">close</mat-icon>
               </div>
            </div>
         </td>
      </tr>

      <tr>
         <td>Weather Sensor URL</td>
         <td>
            <div *ngIf="bulkActionWeatherSensor === BULK_ACTION.EDIT">
               <ng-container *ngIf="sensor?.weatherSensor">
                  <a target="_blank" [href]="'https://tempestwx.com/station/' + sensor?.weatherSensor"
                     >Click here for live weather</a
                  >
               </ng-container>
               <ng-container *ngIf="!sensor?.weatherSensor"> Configure station ID to see live weather</ng-container>
            </div>
         </td>
         <td *ngIf="isAdminRole"></td>
      </tr>
      <tr>
         <td>Latitude</td>
         <td>
            <div>
               <ng-container>
                  {{sensor.weather.lat}}
               </ng-container>
            </div>
         </td>
         <td *ngIf="isAdminRole"></td>
      </tr>
      <tr>
         <td>Longitude</td>
         <td>
            <div>
               <ng-container>
                  {{sensor.weather.lng}}
               </ng-container>
            </div>
         </td>
         <td *ngIf="isAdminRole"></td>
      </tr>
      <tr *ngIf="!isAdminRole">
         <td>Weather sensor used as Sensor location</td>
         <td>
            <div>
               <mat-slide-toggle
                  color="primary"
                  [(ngModel)]=weatherGeoloc
                  [disabled]="true">
               </mat-slide-toggle>
            </div>
         </td>
         <td *ngIf="isAdminRole"></td>
      </tr>
      <tr *ngIf="isAdminRole">
         <td>Weather sensor used as Sensor location</td>
         <td>
            <div>
               <mat-slide-toggle
                  (change)="onConfirmGeolocation('weather')"
                  color="primary"
                  [(ngModel)]=weatherGeoloc
                  [disabled]="!isAdminRole || (sensor.weather.lng == '' && sensor.weather.lat == '')">
               </mat-slide-toggle>
            </div>
         </td>
         <td></td>
      </tr>
   </tbody>
</table>
<h5>Modem</h5>
<table class="table table-bordered table-striped">
   <thead>
      <tr>
         <th>Title</th>
         <th>Value</th>
         <th *ngIf="hasEditPermission()">Action</th>
      </tr>
   </thead>

   <tbody>
      <tr>
         <td>Modem Sensor ID</td>
         <td>
            <div *ngIf="bulkActionModem === BULK_ACTION.EDIT">{{ sensor?.modem?.sensorId }}</div>
            <div *ngIf="bulkActionModem === BULK_ACTION.SAVE">
               <mat-form-field class="full-width">
                  <input [(ngModel)]="modparamValue" matInput type="text" />
               </mat-form-field>
            </div>
         </td>
         <td *ngIf="hasEditPermission()">
            <div fxLayout fxLayoutAlign="center">
               <div class="edit-item pointer" fxLayout fxLayoutAlign="center center">
                  <mat-icon *ngIf="bulkActionModem === BULK_ACTION.EDIT" (click)="bulkActionModem = BULK_ACTION.SAVE"
                     >edit</mat-icon
                  >
               </div>

               <mat-icon
                  *ngIf="bulkActionModem === BULK_ACTION.SAVE"
                  (click)="onUpdateModemSensorId()"
                  class="pointer mr-1"
                  color="primary"
                  >check
               </mat-icon>

               <div
                  *ngIf="bulkActionModem === BULK_ACTION.SAVE"
                  class="delete-item pointer"
                  fxLayout
                  fxLayoutAlign="center center">
                  <mat-icon (click)="bulkActionModem = BULK_ACTION.EDIT">close</mat-icon>
               </div>
            </div>
         </td>
      </tr>
      <tr>
         <td>Latitude</td>
         <td>
            <div>
               <ng-container>
                  {{sensor.modem.lat}}
               </ng-container>
            </div>
         </td>
         <td *ngIf="isAdminRole"></td>
      </tr>
      <tr>
         <td>Longitude</td>
         <td>
            <div>
               <ng-container>
                  {{sensor.modem.lng}}
               </ng-container>
            </div>
         </td>
         <td *ngIf="isAdminRole"></td>
      </tr>
      <tr *ngIf="!isAdminRole">
         <td>Modem sensor used as Sensor location</td>
         <td>
            <div>
               <mat-slide-toggle
                  color="primary"
                  [(ngModel)]=modemGeoloc
                  [disabled]="true">
               </mat-slide-toggle>
            </div>
         </td>
         <td *ngIf="isAdminRole"></td>
      </tr>
      <tr *ngIf="isAdminRole">
         <td>Modem sensor used as Sensor location</td>
         <td>
               <mat-slide-toggle
                  (change)="onConfirmGeolocation('modem')"
                  color="primary"
                  [(ngModel)]=modemGeoloc
                  [disabled]="!isAdminRole || (sensor?.modem?.lng == '' && sensor?.modem?.lat == '')">
               </mat-slide-toggle>
         </td>
         <td></td>
      </tr>
   </tbody>
</table>
