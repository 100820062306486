import { Injectable } from '@angular/core';
import { forkJoin, zip } from 'rxjs';
import { PurehttpService } from './purehttp.service';
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: 'root',
})
export class AnalyticalService {
   strFireFunctionUrl = environment['FirebaseFunctionUrlCloud'];
   public bIsMultipleAnalyticalData: boolean;

   constructor(private _purehttpService: PurehttpService) {}

   public loadMultipleAnalyticalData(analyticalDataItemsSelected, arrObjAnalyticList) {
      const selectedDataItems = this.getSelectedAnalyticalData(analyticalDataItemsSelected, arrObjAnalyticList);

      return selectedDataItems;
   }

   public loadAnalyticDataForMul(selectedItems) {
      const promises: Object[] = [];
      this.bIsMultipleAnalyticalData = false;

      for (const data of selectedItems) {
         const objPostData = {
            url: data['url'],
            isHeader: 'yes',
         };
         promises.push(
            this._purehttpService.callFirebaseFunction(`${this.strFireFunctionUrl}/getCSVData`, objPostData)
         );
      }

      return zip(promises);
   }

   private getSelectedAnalyticalData(analyticalDataItemsSelected, arrObjAnalyticList) {
      const selectedKeys = [];

      for (const key in analyticalDataItemsSelected) {
         if (analyticalDataItemsSelected.hasOwnProperty(key)) {
            selectedKeys.push(parseInt(key, 10));
         }
      }

      return arrObjAnalyticList.filter(item => {
         return selectedKeys.indexOf(item['cycleIndex']) > -1;
      });
   }
}
