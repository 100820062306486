import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimezoneApiService {
  private _API = 'https://maps.googleapis.com/maps/api/timezone/json';

  constructor(
    private http: HttpClient
  ) { }

  getTimezone(address) {
    return this.http.get(
      `${this._API}?location=${address}&timestamp=1331161200&key=${environment['googleAPIKey_tz']}`
    );
  }
}
