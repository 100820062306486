const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const WORDS = [
   'Omnipotent',
   'Omniscient',
   'Omnipresent',
   'Omnificent',
   'Omnibenevolent',
   'Omnivorous',
   'Omnifarious',
   'Omnirelevant',
   'Omnidirectional',
   'Omnidecadent',
   'Omnitronix',
   'Omnitopia',
   'Omnivision',
   'Omniconnect',
   'Omnimodal',
   'Omnitude',
   'Omnimatrix',
   'Omnimaven',
   'Omnistar',
   'Omnitech',
   'Omnifusion',
   'Omnigence',
   'Omnisense',
   'Omniscan',
   'Omnidrone',
   'Omnitron',
   'Omniray',
];

const NUMBERS = [
   'alpha',
   'beta',
   'gamma',
   'delta',
   'epsilon',
   'zeta',
   'eta',
   'theta',
   'iota',
   'kappa',
   'lambda',
   'mu',
   'nu',
   'xi',
   'omicron',
   'pi',
   'rho',
   'sigma',
   'tau',
   'upsilon',
   'phi',
   'chi',
   'psi',
   'omega',
];

export const nameGenerator = () =>
   `${WORDS[getRandomInt(0, WORDS.length)]} ${NUMBERS[getRandomInt(0, NUMBERS.length)]}`;
