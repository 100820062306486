<table class="table">
  <thead>
  <tr>
    <th>#</th>
    <th>Measurement Start Time</th>
    <th>Upload Time</th>
    <th>Name</th>
    <th>Color</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let cdc of arrMulCdcInfo; let i = index;">
    <td>
      {{ i + 1 }}
    </td>
    <td>
      {{ cdc?.startTimestamp }}
    </td>
    <td>
      {{ cdc.date }}
    </td>
    <td>
      {{ cdc.description }}
    </td>
    <td [ngStyle]="{'background-color': cdc?.color}">
      {{ cdc.color }}
    </td>
  </tr>
  </tbody>
</table>
