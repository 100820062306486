import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver-es';
import * as _ from 'lodash-es';
import {windDirectionTransform} from '../helpers/wind-direction.helper';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export class ExcelSheet {

  public process: any;
  public analytical: any;
  public raw: any;
  public config: any;

  constructor(process?: any, analytical?: any, raw?: any, config?: any) {
    this.process = process;
    this.analytical = analytical;
    this.raw = raw;
    this.config = config;
  }

  private static saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public generateExcelFromData(analyticalList: any, sensorKey: string, configData) {

    this.analytical.forEach((el, index) => {
      const {
        startTimestamp, comment, description, wind, wind_direction
      } = analyticalList[index];

      const windDirection = wind_direction ? `${wind_direction}° ${windDirectionTransform(wind_direction)}` : '';

      el.wind = wind;
      el.direction = windDirection;
      el.startTimestamp = startTimestamp;
      el.comment = comment;
      el.rawFileName = description;
      el.Sampling_Time = configData['Frac_Delta'].Sampling_Time;
      el.Idle_Time = configData['Frac_Delta'].Idle_Time;
    });

    const excelHeaders = ['startTimestamp', 'Sampling_Time', 'Idle_Time'];
    const parsedAnalyticalData = _.map(this.analytical, (val, key, coll) => {
      const excelRow = {
        startTimestamp: null,
        wind: null,
        direction: null,
        Sampling_Time: null,
        Idle_Time: null,
        Chemical_Name: null,
        comment: null,
        rawFileName: null
      };

      excelRow.startTimestamp = val['startTimestamp'];
      excelRow.Sampling_Time = val['Sampling_Time'];
      excelRow.Idle_Time = val['Idle_Time'];
      let unknownChemicalCount = 1;

      const chemicals = _.map(val['data'], function (v, k, c) {
        const prefix = _.includes(['benzene', 'toluene', 'm-xylene', 'o-xylene'], v['Chemical_Name']) ? v['Chemical_Name'] : (v['Chemical_Name'] + '_' + (unknownChemicalCount++)).replace(' ', '_');
        return _.mapKeys(v, function (v, k, c) {
          const fieldName = (k === 'Chemical_Name') ? 'Chemical_Name' : prefix + '_' + k;
          if (fieldName !== 'Chemical_Name' && !_.includes(excelHeaders, fieldName)) {
            excelHeaders.push(fieldName);
          }
          return fieldName;
        });
      });
      chemicals.forEach((val, key, arr) => {
        _.forOwn(val, (v, k) => {
          excelRow[k] = v;
        });
      });
      delete excelRow.Chemical_Name;
      excelRow.wind = val['wind'];
      excelRow.direction = val['direction'];
      excelRow.comment = val['comment'];
      excelRow.rawFileName = val['rawFileName'];

      return (excelRow);
    });

    excelHeaders.push('wind', 'direction', 'comment', 'rawFileName');
    const analyticalWorkSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(parsedAnalyticalData, {header: excelHeaders});
    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Analytical Data': analyticalWorkSheet
      },
      SheetNames: ['Analytical Data']
    };

    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    ExcelSheet.saveAsExcelFile(excelBuffer, 'Analytical_Results');
  }

  private clearCells(analyticalSheet, cells: Array<string>) {
    cells.forEach(cell => analyticalSheet[cell].v = '');
  }


  public generateExcelFromAllData(description: string) {
    const processSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.process[0]);
    const process_two_Sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.process[1]);
    const analyticalSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.analytical);
    const rawSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.raw);
    const config: XLSX.WorkSheet = XLSX.utils.json_to_sheet([this.config['Frac_Delta'] ? this.config['Frac_Delta'] : {}]);

    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Process Data (Chromatogram)': processSheet,
        'Process Data (DetectedPeaks)': process_two_Sheet,
        'Analytical Data': analyticalSheet,
        'Raw Data': rawSheet,
        'Config': config
      },
      SheetNames: ['Process Data (Chromatogram)', 'Process Data (DetectedPeaks)', 'Analytical Data', 'Raw Data', 'Config']
    };

    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    ExcelSheet.saveAsExcelFile(excelBuffer, description);
  }
}
