import { RolesEnum } from './../../../admin/roles.enum';
import { global_variables } from 'src/environments/global_variables';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { take } from 'rxjs';

export enum BULK_ACTION {
   EDIT,
   SAVE,
}
@Component({
   selector: 'weather-live',
   templateUrl: './weather-live.component.html',
   styleUrls: ['./weather-live.component.scss'],
})
export class WeatherLiveComponent {
   private _sensor: any;
   @Input() set sensor(sensor) {
      console.log('sensor', sensor);
      this._sensor = sensor;
      this.paramValue = (sensor && sensor.weatherSensor) || '';
      this.modparamValue = (sensor && sensor?.modem?.sensorId) || '';
      if(sensor?.chosenGeoLocationOption == 'weather'){
         this.weatherGeoloc = true;
      }
      if(sensor?.chosenGeoLocationOption == 'modem'){
         this.modemGeoloc = true;
      }
   }

   get sensor() {
      return this._sensor;
   }

   @Input() strUserType: string;
   @Input() strUserRole: string;
   @Output() updateWeather = new EventEmitter();
   @Output() updateWeatherSensor = new EventEmitter();
   @Output() updateModemSensor = new EventEmitter();
   @Output() updateGeolocation = new EventEmitter();
   @Output() updateTimezone = new EventEmitter();
   BULK_ACTION = BULK_ACTION;
   bulkAction: BULK_ACTION = BULK_ACTION.EDIT;
   bulkActionWeatherSensor: BULK_ACTION = BULK_ACTION.EDIT;
   bulkActionModem: BULK_ACTION = BULK_ACTION.EDIT;
   paramValue: string;
   modparamValue: string;
   weatherSensorId: number;

   weatherGeoloc: boolean = false;
   modemGeoloc: boolean = false;

   arrStrUserTypes = global_variables.userTypes;
   arrStrUserRoles = global_variables.userRoles;

   get isAdminRole() {
      return this.strUserRole === RolesEnum.ADMIN;
   }
   constructor(public dialog: MatDialog,) {}

   onUpdate() {
      this.updateWeather.emit(this.paramValue);
      this.bulkAction = BULK_ACTION.EDIT;
   }

   onUpdateWeatherSensorId() {
      this.updateWeatherSensor.emit(this.weatherSensorId);
      this.bulkActionWeatherSensor = BULK_ACTION.EDIT;
   }

   onUpdateModemSensorId() {
      this.updateModemSensor.emit(this.modparamValue);
      this.bulkActionModem = BULK_ACTION.EDIT;
   }

   onConfirmGeolocation(value = ''){
      var config = {};
      if(value == 'weather' && this.weatherGeoloc === true){
         config = {
            disableClose: true,
            data: {
               title: 'Confirmation',
               description: `This will override the sensor own coordinates entered in the sensor section and the Wireless Modem GPS coordinates, if the Wireless Modem GPS is used for sensor coordinates.`,
            },
         };
      }else if(value == 'modem' && this.modemGeoloc === true){
         config = {
            disableClose: true,
            data: {
               title: 'Confirmation',
               description: `This will override the sensor own coordinates entered in the sensor section and the Weather sensor GPS coordinates, if the Weather sensor GPS is used for sensor coordinates.`,
            },
         };
      }else if(value == 'weather' && this.weatherGeoloc === false){
         this.onChangeGeolocation(value);
      }else if(value == 'modem' && this.modemGeoloc === false){
         this.onChangeGeolocation(value);
      }
      if(Object.keys(config).length > 1){
         this.dialog
            .open(ConfirmModalComponent, config)
            .afterClosed()
            .pipe(take(1))
            .subscribe({
               next: (result) => {
                  if(result){
                     this.onChangeGeolocation(value);
                  }else{
                     if(value == "weather"){
                        this.weatherGeoloc = false;
                     }else if(value == "modem"){
                        this.modemGeoloc = false;
                     }
                  }
               },
               error: error => console.error('weather-live.component.ts -> confirmDeleteAnalyticalData(): ', error)
            });
      }
   }

   onChangeGeolocation(value = ''){
      console.log(value, this.weatherGeoloc, this.modemGeoloc)
      if(value == 'weather' && this.weatherGeoloc === true){
         this.updateGeolocation.emit(value);
         this.updateTimezone.emit({lat:this.sensor.weather.lat, lng: this.sensor.weather.lng});
         this.modemGeoloc = false;
      }else if(value == 'weather' && this.weatherGeoloc === false){
         this.updateGeolocation.emit('');
         this.updateTimezone.emit({lat:this.sensor.lat, lng: this.sensor.lng});
      }else if(value == 'modem' && this.modemGeoloc === true){
         this.updateGeolocation.emit(value);
         this.updateTimezone.emit({lat:this.sensor.modem.lat, lng: this.sensor.modem.lng});
         this.weatherGeoloc = false;
      }else if(value == 'modem' && this.modemGeoloc === false){
         this.updateGeolocation.emit('');
         this.updateTimezone.emit({lat:this.sensor.lat, lng: this.sensor.lng});
      }
   }

   hasEditPermission(): boolean {
      return (
         (this.strUserType === this.arrStrUserTypes[0] || // is OmniscentUser
            this.strUserRole === this.arrStrUserRoles[0]) &&
         this.strUserRole !== this.arrStrUserRoles[3]
      );
      // is Customer admin
   }
}
