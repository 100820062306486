<div class="table-responsive">
  <div class="mr-2 mb-3">
    {{ analytic.description }} - {{ analytic.startTimestamp }} - {{ analytic.date }}
  </div>
  <table class="table table-bordered">
    <thead>
    <tr>
      <th
        *ngFor="let item of analytic['data'][index] | analyticDataFilter : arrAnalyticalInputs[index][0] : arrStrRetentionProperties : bIsShowRententionProperties: arrParams[1] : strUserRole">
        {{ item.key }}
      </th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let data of analytic['data']; let dataInd = index;">
      <td
        *ngFor="let item of data | analyticDataFilter : arrAnalyticalInputs[index][dataInd] : arrStrRetentionProperties : bIsShowRententionProperties : arrParams[1] : strUserRole : bIsShowUnChemicals">
        <div *ngIf="!item.editable; else analyticalInputEle">
          {{ item.value }}
        </div>

        <ng-template #analyticalInputEle>
          <div *ngIf="arrStrAnalyticalInputs.indexOf(item.key) !== -1" class="analytical-input">
            <app-comment
              (updateComment)="onUpdateAnalyticInput($event, dataInd)"
              [key]="item.key"
              [strComment]="item.value"
            ></app-comment>
          </div>
        </ng-template>
      </td>
    </tr>
    </tbody>
  </table>
</div>
