<h3 class="text-center p-0 mb-3">Calibration - Step 1 - Preparation and Warning</h3>
<div class="row main-content">
  <div class="col-sm-4 col mb-3">
    <div class="calibration-history">
      <span class="calibration-title text-center">
        History
      </span>
      <mat-card appearance="outlined">
        <mat-card-content>
          <ng-container *ngIf="(calibrationHistories$ | async)?.length >= 1; else emptyHistory;">
            <div></div>
              <ul class="history-list">
                <li *ngFor="let history of activeCalibrationHistories" class="mb-3">
                  {{history.dateTime | date:'short'}}
                  <div class="ml-3">
                    <div>{{history.userName}}</div>
                    <div>{{history.description}}</div>
                  </div>
                </li>
              </ul>
              <mat-paginator
                #paginator
                [length]="calibrationHistoryLength"
                [pageSize]="historyPageSize"
                (page)="onPageHistoryChanged($event)"
              ></mat-paginator>
            </ng-container>
          <ng-template #emptyHistory>History is empty</ng-template>
          <!--          <ng-container *ngIf="(calibrationHistories$ | async) as histories">-->
          <!--            <ng-container *ngIf="histories.length >= 1; else emptyHistory;">-->
          <!--              <ul class="history-list">-->
          <!--                <li *ngFor="let history of histories" class="mb-3">-->
          <!--                  {{history.dateTime | date:'short'}}-->
          <!--                  <div class="ml-3">-->
          <!--                    <div>{{history.userName}}</div>-->
          <!--                    <div>{{history.description}}</div>-->
          <!--                  </div>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--              <mat-paginator #paginator [pageSize]="historyPageSize"></mat-paginator>-->
          <!--            </ng-container>-->
          <!--            <ng-template #emptyHistory>History is empty</ng-template>-->
          <!--          </ng-container>-->

        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="col-sm-8 col">

    <!-- Warnings !-->
    <div class="calibration-action">
        <span class="calibration-title text-center">
          Warnings
        </span>
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="row d-flex align-items-center">
            <div class="col-sm-9 col">
              <p>Calibration could take several hours. Any failure to complete the calibration will lead to calibration
                failure.</p>
              <p>Please have the calibration kit ready.</p>
              <p>It is critical to use the calibration gas cylinder recommended by Omniscent. For more information
                please contact your admin.
                Make sure gas cylinder for calibrations available and has at least 20 PSIG inlet pressure to
                regulator.</p>
            </div>
            <div class="col-sm-3 col">
              <mat-checkbox [(ngModel)]="warningAknowledge">Acknowledged</mat-checkbox>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- Steps to prepare !-->
    <div class="mt-4 calibration-action">
        <span class="calibration-title text-center">
          Steps to prepare
        </span>
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="row d-flex align-items-center">
            <div class="col-sm-9 col">
              <ol style="list-style: none">
                <li>
                  Step 1 - Instruction
                  <ul>
                    <li>Make sure analyzer is connected to power all the time during calibration process.</li>
                  </ul>
                </li>
                <li>
                  Step 2
                  <ul>
                    <li>Connect the calibration cylinder to the calibration port by provided tube and make sure the
                      connection is tight and no air leaks from the connections.
                    </li>
                  </ul>
                </li>
                <li>
                  Step 3
                  <ul>
                    <li>The inlet pressure from cylinder to regulator should be at least 20 psig(If the reading is below
                      20 psig stop. You don’t have enough gas to proceed with the calibration).
                    </li>
                  </ul>
                </li>
                <li>
                  Step 4
                  <ul>
                    <li>Open the cylinder valve when tube is connected to analyzer.
                    </li>
                  </ul>
                </li>
                <li>
                  Step 5
                  <ul>
                    <li>Do not move analyzer or disconnect the cylinder during the calibration steps.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div class="col-sm-3 col">
              <mat-checkbox [(ngModel)]="stepsAknowledge">Acknowledged</mat-checkbox>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div class="row">
  <div class="col mt-4 action-buttons w-100 d-flex justify-content-between">
    <button (click)="cancelWizard()" style="min-width: 150px;" mat-raised-button class="cancel btn btn-warning">
      Cancel
    </button>
    <button (click)="onChooseConfig()" *ngIf="hasEditPermission()" style="min-width: 200px;" mat-raised-button
            class="btn btn-info">Choose Config
    </button>
    <button [disabled]="!(warningAknowledge && stepsAknowledge) || !isCalibrationConfigFileKeyExist" (click)="nextStep.emit(2)" mat-raised-button
            class="btn btn-success">Acknowledge, Agree and Continue
    </button>
  </div>
</div>
