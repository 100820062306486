import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-peak-table',
  templateUrl: './peak-table.component.html',
  styleUrls: ['./peak-table.component.scss']
})
export class PeakTableComponent implements OnInit {
  @Input() peaks: any[];
  objectKeys = Object.keys;

  constructor() { }

  ngOnInit() {
  }

}
