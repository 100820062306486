<div class="daily-test-modal-container">
  <h2 mat-dialog-title>Daily Test Configuration Modal</h2>

  <mat-dialog-content>
    <div fxLayout fxLayoutAlign="space-between center" class="mt-3">
      <mat-form-field fxFlex="1 1 auto" class="full-width form-group pr-3">
        <mat-label>Select the modal type</mat-label>
        <mat-select 
          placeholder="Select the modal type" 
          class="full-width"
          [(ngModel)]="nModalType"
          [required]="true"
          (selectionChange)="onChangeSetting()">
          <mat-option [value]="0">Omni-1000</mat-option>
          <mat-option [value]="1">Omni-2000</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field fxFlex="1 1 auto" class="full-width form-group pl-3">
        <mat-label>Select the Mode</mat-label>
        <mat-select
          placeholder="Select the Mode"
          class="full-width"
          [(ngModel)]="strCurrentDataType"
          [required]="true"
          (selectionChange)="onChangeSetting()">
          <mat-option *ngFor="let type of arrDataTypes" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout fxLayoutAlign="space-between center" class="mb-3">
      <mat-form-field fxFlex="50">
        <mat-label>Search...</mat-label>
        <input type='text' matInput (keyup)='onUpdateFilter($event)'>
      </mat-form-field>
    </div>

    <div>
      <div class="load-area" *ngIf="isLoadTable;else loadedElement">
        <mat-spinner></mat-spinner>
      </div>

      <ng-template #loadedElement>
        <div class="alert alert-danger" *ngIf="arrConfigs.length === 0;else configTable">
          Empty configuration file
        </div>

        <ng-template #configTable>
          <table class="full-width" mat-table #table [dataSource]="dataSource">
            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element">
                <div class="cell-wrapper">
                  {{ element?.position }}
                </div>
              </td>
            </ng-container>
        
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> File Name </th>
              <td mat-cell *matCellDef="let element" class="px-3 file-name">
                <div class="cell-wrapper">
                  {{ element?.name }}
                </div>
              </td>
            </ng-container>
        
            <!-- Upload Time Column -->
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef> Upload Time </th>
              <td mat-cell *matCellDef="let element" class="px-3">
                <div class="cell-wrapper">
                  {{ element?.time }}
                </div>
              </td>
            </ng-container>
        
            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let element">
                <div class="cell-wrapper">
                  <mat-icon
                    *ngIf="strSelectConfigKey !== element?.key"
                    color="primary"
                    class="pointer"
                    (click)="onSelect(element)"
                  >check_box_outline_blank</mat-icon>

                  <mat-icon
                    *ngIf="strSelectConfigKey === element?.key"
                    color="primary"
                    class="pointer"
                    (click)="onUnSelect()"
                  >check_box</mat-icon>
                </div>
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="arrTableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrTableColumns;"></tr>
          </table>
    
          <mat-paginator #paginator
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </ng-template>
      </ng-template>
    </div>

    <div fxLayout fxLayoutAlign="space-between center" class="mt-3">
      <mat-form-field fxFlex="50">
        <mat-label>Select a schedule(PST)</mat-label>
        <mat-select placeholder="Select a schedule(PST)" [(ngModel)]="nScheduleTime" [required]="true" >
          <mat-option *ngFor="let schedule of arrSchedules" [value]="schedule?.value">
            {{ schedule?.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <div class="alert alert-danger mt-2" *ngIf="strError">
    {{ strError }}
  </div>

  <mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" class="mr-1" (click)="onSubmit()">Submit</button>
    <button mat-stroked-button color="warn" (click)="close()">Close</button>
  </mat-dialog-actions>
</div>
