<ng-container *ngIf="!(missedSensorTypeAlerts.length == 0 && missedAlertTemplates.length == 0); else empty">
  <h2 mat-dialog-title fxLayout fxLayoutAlign="space-between center">Here the list of the missed alerts on the sensor</h2>

  <mat-list>
    <div mat-subheader>missed sensor type's templates</div>
    <mat-list-item
      *ngFor="let sensorTypeAlert of missedSensorTypeAlerts"
    >
      <mat-icon mat-list-icon>report_problem</mat-icon>
      <div mat-line>{{sensorTypeAlert}}</div>
    </mat-list-item>
    <button
      style="font-size: 15px;" mat-raised-button
      class="btn mat-raised-button btn-warning w-100 text-white"
      (click)="addMissedAlertTemplates(sensorTypeAlerts, missedSensorTypeAlerts)"
      [disabled]="isLoading"
    >
      Sync with sensor type
    </button>


    <mat-divider></mat-divider>

    <div mat-subheader>missed alert templates</div>
    <mat-list-item
      *ngFor="let sensorTypeAlert of missedAlertTemplates"
    >
      <mat-icon mat-list-icon>report_problem</mat-icon>
      <div mat-line>{{sensorTypeAlert}}</div>
    </mat-list-item>
    <button
      style="font-size: 15px;" mat-raised-button
      class="btn mat-raised-button btn-warning w-100 text-white"
      (click)="addMissedAlertTemplates(alertTemplate, missedAlertTemplates)"
      [disabled]="isLoading"
    >
      Sync with alert templates
    </button>
    <button mat-stroked-button color="warn" (click)="onClose()">
      Close
    </button>

  </mat-list>
</ng-container>

<ng-template #empty>
  <h2 mat-dialog-title fxLayout fxLayoutAlign="space-between center">All alerts are updated. Nothing to sync</h2>
  <button mat-stroked-button color="warn" (click)="onClose()">
    Close
  </button>
</ng-template>
