import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponseType } from '../../models/response';


@Component({
  selector: 'app-repeat-confirm-modal',
  templateUrl: './repeat-confirm-modal.component.html',
  styleUrls: ['./repeat-confirm-modal.component.scss']
})
export class RepeatConfirmModalComponent implements OnInit {
  strTitle: string;
  strEstimate: string;
  value: string;

  constructor(public dialogRef: MatDialogRef<RepeatConfirmModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.strTitle = 'Confirmation - Run previous configuration';
    this.value = data['value'];
    this.strEstimate = data['estimate'];
  }

  ngOnInit() {
  }

  /**
   * close modal with updated value
   */
  onFinish() {
    const response: ResponseType = new ResponseType();
    response.status = 'success';
    response.msg = 'success';
    response.data = this.value;
    this.dialogRef.close(response);
  }

  /**
   * close modal
   */
  onClose() {
    const response: ResponseType = new ResponseType();
    response.status = 'none';
    response.msg = '';
    this.dialogRef.close(response);
  }

}
