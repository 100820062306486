<div class="edit-modal-container">
  <h2 mat-dialog-title>
    {{ strTitle }}
  </h2>

  <mat-dialog-content>
    <div class="label mb-3" *ngIf="strLabel">
      {{ strLabel }}
    </div>

    <div class="wrap">
      <mat-form-field class="full-width" *ngIf="strInputType === arrStrInputTypes[0]">
        <input type="number" matInput [(ngModel)]="value">
      </mat-form-field>

      <mat-form-field class="full-width" *ngIf="strInputType === arrStrInputTypes[1]">
        <input matInput type="text" [(ngModel)]="value">
      </mat-form-field>
  
      <textarea class="full-width" [(ngModel)]="value" *ngIf="strInputType === arrStrInputTypes[2]"></textarea>
    
      <mat-form-field class="full-width" *ngIf="strInputType === arrStrInputTypes[4]">
        <mat-select [(value)]="value">
          <mat-option *ngFor="let option of arrStrOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" class="mr-1" (click)="onFinish()">Finish</button>
    <button mat-stroked-button color="warn" (click)="onClose()">Cancel</button>
  </mat-dialog-actions>
</div>