<div class="omniscent-view mx-3" fxLayout fxLayoutAlign="space-between center">
	<button mat-mini-fab color="primary" class="mr-1" (click)="onBack()">
		<mat-icon>keyboard_return</mat-icon>
	</button>
	<div class="omniscent-title" fxLayout="column" fxLayoutAlign="center center">
		Sensor Detail Page
		<div class="text-center sub-title grey-text my-2">Name: {{ sensorName }}</div>
	</div>
	<div></div>
</div>
