<div class="invite-modal-area" *ngIf="bIsLoadModal">
  <mat-progress-bar mode="indeterminate" *ngIf="bIsCheckUser"></mat-progress-bar>
  <h2 mat-dialog-title>Invite a new user via email</h2>

  <form [formGroup]="inviteForm" (ngSubmit)="submit($event)">
    <mat-dialog-content>
      <div class="form-group">
        <mat-form-field class="full-width">
          <mat-label>E-MAIL</mat-label>
          <input matInput formControlName="email" name="email" required>
          <mat-error *ngIf="formErrors['email']">
            {{ formErrors['email'] }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field class="full-width capitalize">
          <mat-label>Select a user role</mat-label>
          <mat-select formControlName="role" placeholder="Select a user role" class="full-width">
            <mat-option *ngFor="let role of arrObjRoles" [value]="role.value">
              <div class="capitalize">
                {{ role.display }}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <div class="form-group" *ngIf="strError">
      <div class="alert alert-danger">
        <strong>Error!</strong> {{ strError }}
      </div>
    </div>

    <mat-dialog-actions align="end" *ngIf="!bIsCheckUser">
      <button mat-stroked-button type="submit" color="primary" class="mr-1" [disabled]="inviteForm.invalid">Submit</button>
      <button mat-stroked-button color="warn" (click)="close($event)">Close</button>
    </mat-dialog-actions>
  </form>
  </div>
