import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAgreement } from '../models/user.agreement';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpService } from '../services/http.service';
import { global_variables } from '../../environments/global_variables';
import { NotificationService } from '../services/notification.service';

@Component({
   selector: 'app-eula',
   templateUrl: './eula.component.html',
   styleUrls: ['./eula.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EulaComponent {
   userAgreement: UserAgreement;
   private readonly accountInfo: any;
   private uid: string;

   constructor(
      private router: Router,
      private httpService: HttpService,
      private nofication: NotificationService,
      private activatedRoute: ActivatedRoute
   ) {
      // this.userId = params['userId'] ? params['userId'] : null;
      const paramsData = activatedRoute.snapshot.params.data;
      this.uid = activatedRoute.snapshot.params.userId;
      this.accountInfo = JSON.parse(atob(paramsData));
      this.userAgreement = { accepted_datetime: undefined, agreement: false, signed_full_name: '' };
   }

   public onChangeAgreement(checked: boolean) {
      this.userAgreement.agreement = checked;
   }

   public onSubmitEula() {
      this.userAgreement.accepted_datetime = new Date().getTime().toString();

      this.saveUserEulaData();
   }

   saveUserEulaData() {
      let type = '';
      const userRecord = { ...this.userAgreement };

      const newUserData = {
         info: {
            email: this.accountInfo['email'],
         },
         action: {
            status: global_variables['userStatus'][0],
            role: this.accountInfo['role'],
         },
         userRecord,
      };

      if (this.accountInfo['userType'] === 0) {
         type = global_variables['userTypes'][0];
      } else {
         type = global_variables['userTypes'][1];
         newUserData['info']['customerId'] = this.accountInfo['customerKey'];
      }

      this.httpService.postAsObject(`${environment.APIS.USERS}/${type}/${this.uid}`, newUserData).then(
         () => {
            this.router.navigate(['/login']);
         },
         error => console.error(error)
      );
   }
}
