import { Injectable } from '@angular/core';
import { SnotifyPosition, SnotifyService, SnotifyToastConfig } from 'ng-alt-snotify';

@Injectable()
export class NotificationService {
  defaultOptions: SnotifyToastConfig;

  constructor(
    private _snotifyService: SnotifyService
  ) {
    this.defaultOptions = {
      pauseOnHover: true,
      position: SnotifyPosition.rightTop
    };
  }

  createNotification(type: string, title: string, content: string, options: SnotifyToastConfig = this.defaultOptions) {
    switch (type) {
      case 'success':
        this._snotifyService.success(content, title, options);
        break;

      case 'error':
        this._snotifyService.error(content, title, options);
        break;

      case 'info':
        this._snotifyService.info(content, title, options);
        break;

      case 'warning':
      case 'alert':
        this._snotifyService.warning(content, title, options);
        break;

      default:
        console.log('The alert type is not existed.');
        break;
    }
  }

}
