import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import { MatCard } from '@angular/material/card';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {
  private readonly GAS_ERR_PREFIX = 'err';
  private readonly GAS_CONCENTRATION_PREFIX = 'ppb';
  @Output() backStep: EventEmitter<number> = new EventEmitter<number>();
  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancelWizard: EventEmitter<boolean> = new EventEmitter<boolean>();

  gasArr = ['Benzene', 'Toluene', 'O-xylene', 'M-xylene', 'Ethylbenzene'];
  displayName = '';
  description = '';
  gasForm: FormArray;
  gasChecked = false;
  gasInstruction = false;
  userName = '';

  constructor(
      private fb: FormBuilder,
      private _authService: AuthService,
  ) {
    this.gasForm = this.fb.array([]);
  }

  ngOnInit() {
    this.userName = this._authService.currentUserName;
    this.createGasForm();
  }

  private createGasForm() {
    for (let i = 0; i < this.gasArr.length; i++) {
      const gas = this.gasArr[i];
      this.addGas(gas);
    }
  }

  private addGas(gas: string) {
    const group = this.fb.group({
      name: this.fb.control(gas),
      [this.GAS_ERR_PREFIX + gas]: this.fb.control('', [Validators.max(10)]),
      [this.GAS_CONCENTRATION_PREFIX + gas]: this.fb.control(''),
    });
    this.gasForm.push(group);
  }

  onErrorChange($event, index: number, gasName: string) {
    const errorValue = !!$event.currentTarget.value ? parseInt($event.currentTarget.value, 10) : 0;
    const value = errorValue > 100 ? 100 : errorValue < 0 ? 0 : errorValue;
    this.gasForm.controls[index].get(gasName).setValue(value);
    this.validateFields($event, gasName);
  }

  onConcentrationChange($event, gasName: string) {
    this.validateFields($event, gasName);
  }

  private validateFields($event, gasName: string) {
    const concentrationValue = $event.target.value;

    // check if Ethylbenzene
    if (gasName.includes('Ethylbenzene')) {
      const oxyleneIndex = this.gasForm.controls.findIndex(gas => gas.get('name').value === this.gasArr[2]);
      const mxyleneIndex = this.gasForm.controls.findIndex(gas => gas.get('name').value === this.gasArr[3]);
      const concentrationOx = this.gasForm.controls[oxyleneIndex].get('ppb' + this.gasArr[2]);
      const concentrationMx = this.gasForm.controls[mxyleneIndex].get('ppb' + this.gasArr[3]);
      const errorOx = this.gasForm.controls[oxyleneIndex].get('err' + this.gasArr[2]);
      const errorMx = this.gasForm.controls[mxyleneIndex].get('err' + this.gasArr[3]);

      if (concentrationValue && concentrationValue !== '0') {
        concentrationOx.setValue('');
        concentrationOx.disable();
        concentrationMx.disable();
        concentrationMx.setValue('');
        errorOx.disable();
        errorOx.setValue('');
        errorMx.disable();
        errorMx.setValue('');

        return;
      }
      concentrationOx.enable();
      concentrationMx.enable();
      errorOx.enable();
      errorMx.enable();
    }
  }

  onSubmitForm() {
    console.log(this.gasForm.getRawValue());
    this.submitForm.emit({
      gasForm: this.gasForm.getRawValue(),
      displayName: this.displayName,
      description: this.description
    });
  }

  cancelWizard() {
    this.onCancelWizard.emit(true);
  }
}
