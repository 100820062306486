<div *ngIf="formLoaded" class="confirm-content pt-0 pb-0 mt-0 mb-0">
<!--    <h2 mat-dialog-title fxLayout fxLayoutAlign="space-between center">-->
    <h2 mat-dialog-title fxLayout fxLayoutAlign="space-between center">
      <span></span>
      <span>Omniscent sensor data notification</span>
      <span>
        <button
          *ngIf="isAdminRole"
          style="font-size: 15px;" mat-raised-button
          class="btn mat-raised-button btn-warning w-100 text-white"
          (click)="syncAlertsModal()"
        >
          Sync alerts
        </button>
      </span>
    </h2>
<!--    <h2 mat-dialog-title>-->
<!--      <div>Omniscent sensor data notification</div>-->
<!--      <div>[btn]</div>-->
<!--    </h2>-->


  <mat-dialog-content>
    <mat-tab-group dynamicHeight>
      <mat-tab label="Email Addresses">
        <app-emails-widget
          [emailGroupsDict]="emails"
          (onEmailGroupsChange)="onEmailGroupsChangeEvent($event)"
        ></app-emails-widget>
        <!--        Table-->
        <span>To send alerts via SMS, please use your 10-digit phone number and one of the following email domains for your mobile provider. For example, if your mobile number is (202) 555-0001 and your mobile provider is AT&T - enter your email as 2025550001&#64;txt.att.net</span>
        <table class="table table-responsive w-100 d-table">
          <thead>
          <tr>
            <th>Provider</th>
            <th>Name</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Comcast</td>
            <td>&#64;comcastpcs.textmsg.com</td>
          </tr>
          <tr>
            <td>U.S. Cellular</td>
            <td>&#64;email.uscc.net</td>
          </tr>

          <tr>
            <td>Alltel</td>
            <td>&#64;message.alltel.com</td>
          </tr>

          <tr>
            <td>Sprint</td>
            <td>&#64;messaging.sprintpcs.com</td>
          </tr>
          <tr>
            <td>Cricket Wireless</td>
            <td>&#64;mms.cricketwireless.net</td>
          </tr>
          <tr>
            <td>Cellular One</td>
            <td>&#64;mobile.celloneusa.com</td>
          </tr>
          <tr>
            <td>Project Fi</td>
            <td>&#64;msg.fi.google.com</td>
          </tr>
          <tr>
            <td>Boost Mobile</td>
            <td>&#64;myboostmobile.com</td>
          </tr>
          <tr>
            <td>ACS Wireless</td>
            <td>&#64;paging.acswireless.com</td>
          </tr>
          <tr>
            <td>Republic Wireless</td>
            <td>&#64;text.republicwireless.com</td>
          </tr>
          <tr>
            <td>T-Mobile</td>
            <td>&#64;tmomail.net</td>
          </tr>
          <tr>
            <td>AT&T</td>
            <td>&#64;txt.att.net</td>
          </tr>

          <tr>
            <td>Bell Canada</td>
            <td>&#64;txt.bellmobility.ca</td>
          </tr>
          <tr>
            <td>Virgin Mobile</td>
            <td>&#64;vmobl.com</td>
          </tr>
          <tr>
            <td>Verizon</td>
            <td>&#64;vtext.com</td>
          </tr>
          </tbody>
        </table>
      </mat-tab>
      <mat-tab label="Thresholds">
        <form [formGroup]="gasesForm">
          <ng-container *ngFor="let formGroup of gasesForm.controls | keys; let i = index;">
            <div *ngIf="!hiddenFormControlsGases.includes(formGroup)" class="row align-items-center"
                 [formGroupName]="formGroup">
              <ng-container *ngFor="let controlName of formControlsName">
                <div *ngIf="controlName === 'Label' || controlName ==='Enabled'"
                     [ngClass]="{
                     'col-auto' : controlName === 'Enabled',
                     'col' : controlName !== 'Enabled'
                     }"
                >
                  <input *ngIf="controlName === 'Label'" disabled [formControlName]="controlName" matInput>
                  <div *ngIf="controlName === 'Enabled'" class="form-group">
                    <mat-slide-toggle (change)="toggleChanged($event, formGroup, gasesForm)"
                                      [formControlName]="controlName">
                    </mat-slide-toggle>
                  </div>
                </div>

                <ng-container *ngIf="(
                  controlName !== 'Label'
                  && controlName !=='Enabled'
                  && controlName !== 'Action'
                  && controlName !== 'NotifyList'
                  && controlName !== 'SubjectEmailTemplate'
                  && controlName !== 'BodyEmailTemplate'
                  )"
                >
                  <div class="col">
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>{{controlName}}</mat-label>
                        <input type="number" [formControlName]="controlName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="controlName === 'Action'">
                  <div class="col-auto">
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>{{controlName}}</mat-label>
                        <input type="text" [formControlName]="controlName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="controlName === 'NotifyList'">
                  <div class="col">
                    <div class="form-group">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Notify Emails</mat-label>
                        <mat-select [formControlName]="controlName" multiple>
                          <mat-option *ngFor="let group of emails | keyvalue" [value]="group.key">{{group.value?.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <hr *ngIf="!hiddenFormControlsGases.includes(formGroup)">
          </ng-container>
        </form>
      </mat-tab>
      <mat-tab label="Errors">
        <form [formGroup]="errorsForm">
          <ng-container *ngFor="let formGroup of errorsForm.controls | keys; let i = index;">
            <div *ngIf="!hiddenFormControlsErrors.includes(formGroup)" class="row align-items-center"
                 [formGroupName]="formGroup">
              <ng-container *ngFor="let controlName of formControlsName">
                <div *ngIf="controlName === 'Label' || controlName ==='Enabled'"
                     [ngClass]="{
                     'col-auto' : controlName === 'Enabled',
                     'col' : controlName !== 'Enabled'
                     }"
                >
                  <input *ngIf="controlName === 'Label'" disabled [formControlName]="controlName" matInput>
                  <div *ngIf="controlName === 'Enabled'" class="form-group">
                    <mat-slide-toggle (change)="toggleChanged($event, formGroup, errorsForm)"
                                      [formControlName]="controlName">
                    </mat-slide-toggle>
                  </div>
                </div>

                <ng-container *ngIf="(
                  controlName !== 'Label'
                  && controlName !=='Enabled'
                  && controlName !== 'Action'
                  && controlName !== 'NotifyList'
                  && controlName !== 'SubjectEmailTemplate'
                  && controlName !== 'BodyEmailTemplate'
                  )"
                >
                  <div class="col">
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>{{controlName}}</mat-label>
                        <input type="number" [formControlName]="controlName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="controlName === 'Action'">
                  <div class="col-auto">
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>{{controlName}}</mat-label>
                        <input type="text" [formControlName]="controlName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="controlName === 'NotifyList'">
                  <div class="col">
                    <div class="form-group">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Notify Emails</mat-label>
                        <mat-select [formControlName]="controlName" multiple>
                          <mat-option *ngFor="let group of emails | keyvalue" [value]="group.key">{{group.value?.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <hr *ngIf="!hiddenFormControlsErrors.includes(formGroup)">
          </ng-container>
        </form>
      </mat-tab>
      <mat-tab label="Calibrations">
        <form [formGroup]="calibrationsForm">
          <ng-container *ngFor="let formGroup of calibrationsForm.controls | keys; let i = index;">
            <div *ngIf="!hiddenFormControlsCalibrations.includes(formGroup)" class="row align-items-center"
                 [formGroupName]="formGroup">

              <ng-container *ngFor="let controlName of formControlsName">
                <div *ngIf="controlName === 'Label' || controlName ==='Enabled'"
                     [ngClass]="{
                       'col-auto' : controlName === 'Enabled',
                       'col' : controlName !== 'Enabled'
                       }"
                >
                  <input *ngIf="controlName === 'Label'" disabled [formControlName]="controlName" matInput>
                  <div *ngIf="controlName === 'Enabled'" class="form-group">
                    <mat-slide-toggle (change)="toggleChanged($event, formGroup, calibrationsForm)"
                                      [formControlName]="controlName">
                    </mat-slide-toggle>
                  </div>
                </div>

                <ng-container *ngIf="(
                  controlName !== 'Label'
                  && controlName !=='Enabled'
                  && controlName !== 'Action'
                  && controlName !== 'NotifyList'
                  && controlName !== 'SubjectEmailTemplate'
                  && controlName !== 'BodyEmailTemplate'
                  )"
                >
                  <div class="col">
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>{{controlName}}</mat-label>
                        <input type="number" [formControlName]="controlName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="controlName === 'Action'">
                  <div class="col-auto">
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>{{controlName}}</mat-label>
                        <input type="text" [formControlName]="controlName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="controlName === 'NotifyList'">
                  <div class="col">
                    <div class="form-group">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Notify Emails</mat-label>
                        <mat-select [formControlName]="controlName" multiple>
                          <mat-option *ngFor="let group of emails | keyvalue" [value]="group.key">{{group.value?.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="controlName === 'SubjectEmailTemplate'">
                  <div class="col">
                    <button
                      mat-icon-button
                      color="primary"
                      aria-label="Edit custom email template"
                      (click)="editCustomEmailTemplate('Calibrations', formGroup, calibrationsForm)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <hr *ngIf="!hiddenFormControlsCalibrations.includes(formGroup)">
          </ng-container>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>


  <mat-dialog-actions align="end">
    <button
      (click)="onSubmit()"
      mat-stroked-button
      color="primary"
      class="mr-1"
    >
      Save
    </button>
    <button mat-stroked-button color="warn" (click)="onClose()">
      Close
<!--      {{isDirtyForm() ? 'Cancel' : 'Close'}}-->
    </button>
  </mat-dialog-actions>
</div>
