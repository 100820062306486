import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { global_variables } from '../../../environments/global_variables';
import { HttpService } from '../../services/http.service';
import { SpinnerService } from '../../components/spinner/spinner.service';
//import { MapsAPILoader } from '@hudsontavares/agm-core';
//import { Loader } from "@googlemaps/js-api-loader";
import { Router } from '@angular/router';
import { AlertModalComponent } from '../../modals/alert-modal/alert-modal.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { serverTimestamp } from 'firebase/database';
import { TimezoneApiService } from '../../services/timezone-api.service';
import { debounceTime, distinctUntilChanged, first, map, takeUntil, tap } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { isEqual } from 'lodash-es';
import { timezones } from '../../shared/timezones';
import { MatSelect } from '@angular/material/select';
import moment from 'moment-timezone';
declare var google: any;

const OMNI_PREDEFINED_LABEL = 'Omni-22xx';
@Component({
   selector: 'app-create-sensor',
   templateUrl: './create-sensor.component.html',
   styleUrls: ['./create-sensor.component.scss'],
})
export class CreateSensorComponent implements OnInit, OnDestroy {
   private destroy$: Subject<boolean> = new Subject();
   private _cachedAddress;

   timezones: any = moment.tz.names();
   //private filteredTimeZones: any = moment.tz.names();
   public filteredTimeZones: ReplaySubject<[]> = new ReplaySubject<[]>(1);

   @Input() createName: string;
   @Input() customerId: string;
   @Input() zoneId: string;
   model: Object;

   @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
   public timezoneFilter: FormControl = new FormControl();

   OMNI_PREDEFINED_LABEL = OMNI_PREDEFINED_LABEL;
   customerTotal: number;
   customerOffCount: number;
   zoneTotal: number;
   zoneOffCount: number;
   customerName: string;
   zoneName: string;
   geocoder: any;
   sensorTypeSub: any;

   isCreateSensor: boolean;
   isGettingData: boolean;

   CREATENAMES: string[];
   sensorTypes: Object[];
   sensorModels: Object[];
   sensorTypesAll: Object[];

   sensorForm: FormGroup;

   formErrors: Object;

   validationMessages = {
      name: {
         required: 'Name is required.',
         minlength: 'Name must be at least 2 characters long.',
         maxlength: 'Name can not be more that 24 characters long.',
      },
      type: {
         required: 'Sensor type is required.',
      },
   };

   constructor(
      private _httpService: HttpService,
      //private _mapApiLoader: MapsAPILoader,
      private _spinner: SpinnerService,
      private _router: Router,
      public _snackBar: MatSnackBar,
      private dialog: MatDialog,
      private _location: Location,
      private _db: AngularFirestore,
      private _timezoneAPI: TimezoneApiService,
      private _cdRef: ChangeDetectorRef
   ) {
      this.customerTotal = -1;
      this.customerOffCount = -1;
      this.zoneTotal = -1;
      this.zoneOffCount = -1;
      this.CREATENAMES = ['customer', 'zone'];
      this.isGettingData = false;
      this.customerName = '';
      this.zoneName = '';
   }

   ngOnInit() {
      this.getCustomerAndZoneName();
      this.initForm();
      this.initData();
      this.filteredTimeZones.next(this.timezones.slice());
   }

   ngOnDestroy() {
      if (this.sensorTypeSub) {
         this.sensorTypeSub.unsubscribe();
      }

      this.destroy$.next(null);
      this.destroy$.complete();
   }

   /**
    * get customer name
    */
   getCustomerName(): Promise<any> {
      return new Promise<any>((resolve, reject) => {
         this._httpService.getAsObject(`${environment['APIS']['CUSTOMERS']}/${this.customerId}`).subscribe({
            next: (customer) => {
               if (customer) {
                  this.customerName = customer['name'];
                  resolve(true);
               } else {
                  resolve(false);
               }
            },
            error: error => {
               reject(error);
               console.error('create.sensor.component.ts -> getCustomerName(): ', error);
            }
         });
      });
   }

   /**
    * get zone name
    */
   getZoneName(): Promise<any> {
      return new Promise<any>((resolve, reject) => {
         this._httpService.getAsObject(`${environment['APIS']['ZONES']}/${this.zoneId}`).subscribe({
            next: (zone) => {
               if (zone) {
                  this.zoneName = zone['name'];
                  resolve(true);
               } else {
                  resolve(false);
               }
            },
            error: error => {
               reject(error);
               console.error('create.sensor.component.ts -> getZoneName(): ', error);
            }
         });
      });
   }

   /**
    * get customer and zone name
    */
   getCustomerAndZoneName() {
      this._spinner.start();

      Promise.all([this.getCustomerName(), this.getZoneName()]).then(
         values => {
            this._spinner.stop();

            if (!values[0] || !values[1]) {
               this._snackBar.open("Either customer or zone don't have enough data", 'Alert', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
               this._router.navigate(['/dashboard']);
            }
         },
         error => {
            this._spinner.stop();
            console.error('create.sensor.component.ts -> getZoneName(): ', error);

            this._snackBar.open('Network error', 'Error', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            this._router.navigate(['/dashboard']);
         }
      );
   }

   /**
    * init form
    */
   initForm() {
      this.formErrors = {
         name: '',
         type: '',
         serialNumber: '',
      };

      this.model = {
         address: '',
         name: '',
         description: '',
         customerId: '',
         zoneId: '',
         availability: global_variables.deviceStatus[1],
         lat: '',
         lng: '',
         type: '',
         sensorModel: '',
         serialNumber: '',
         timezone: '',
      };

      this.sensorForm = new FormGroup({
         name: new FormControl(this.model['name'], [
            <any>Validators.required,
            <any>Validators.minLength(2),
            <any>Validators.maxLength(25),
         ]),
         type: new FormControl(this.model['type'], <any>Validators.required),
         sensorModel: new FormControl(this.model['sensorModel'], <any>Validators.required),
         description: new FormControl(),
         situation: new FormControl(1),
         address: new FormControl(),
         lat: new FormControl(),
         lng: new FormControl(),
         serialNumber: new FormControl(this.model['serialNumber'], <any>Validators.required),
         timezone: new FormControl(this.model['timezone'], <any>Validators.required),
      });

      this.sensorForm.valueChanges
         .pipe(
            distinctUntilChanged(isEqual),
            tap(data => this.updateErrorsState(data)),
            tap(data => this.patchModel()),
            debounceTime(1000),
            takeUntil(this.destroy$)
         )
         .subscribe({
            next: (data) => this.onValueChanged(data),
            error: error => console.error('create.sensor.component.ts -> initForm(): ', error)
         });

      this.timezoneFilter
         .valueChanges.pipe(
            tap(data => this.patchModel()),
            takeUntil(this.destroy$)
         )
         .subscribe({
            next: (v) => this.filterZones(v),
            error: error => console.error('create.sensor.component.ts -> initForm(): ', error)
         });
   }

   /**
    * init data
    */
   initData() {
      this.sensorTypeSub = this._httpService.getAsList(environment['APIS']['SENSORTYPES']).subscribe({
         next: (sensorTypes) => {
            this.sensorTypesAll = sensorTypes;
            this.sensorTypes = sensorTypes
               .filter(item => item['typeName'] === this.OMNI_PREDEFINED_LABEL)
               .map(item => {
                  console.log(item);
                  return {
                     name: item['typeName'],
                     key: item['key'],
                  };
               });

            if (this.sensorTypes.length > 0) {
               this.model['type'] = this.sensorTypes[0]['key'];
               this.isGettingData = true;
               if (!this.sensorForm.value.type) {
                  this.changeSensorModel();
                  this.sensorForm.controls.type.patchValue(this.sensorTypes[0]['key'], { onlySelf: true });
               }
            } else {
               console.log('You need to create sensor type');
            }
         },
         error: error => {
            console.error('create.sensor.component.ts -> initData(): ', error);
         }
      });

      console.log('sensorTypeSub', this.sensorTypeSub);

      /* this._mapApiLoader.load().then(() => {
         this.geocoder = new google.maps.Geocoder();
      }); */

      /* const loader = new Loader({
         apiKey: environment.googleAPIKey,
         version: "weekly",
       }); */
   
      //loader.importLibrary('maps').then(() => {
      google.maps.importLibrary('maps').then(() => {
         this.geocoder = new google.maps.Geocoder();
      });
   }

   changeSensorModel(){
      console.log('type', this.model['type']);
      this._httpService.getListByOrder(`${environment.APIS.SENSORMODELS}`,'sensorTypeId',  this.model['type']).subscribe({
         next: (sensorModels) => {
            this.sensorModels = sensorModels;
         },
         error: error => {
            console.error('sensor-type-info.component.ts -> this._httpService.getAsList: ', error);
         }
      });
   }

   /**
    * detect form input changes
    * @param data form data
    */
   onValueChanged(data?: any) {
      if (data.situation === 1) {
         this.getLatitudeLongitude(this.showResult, this, this.showAddressError);
      } else {
         this.getTimezone(data);
      }
   }

   updateErrorsState(data?: any) {
      if (!this.sensorForm) {
         return;
      }
      const form = this.sensorForm;
      for (const field in this.formErrors) {
         if (this.formErrors.hasOwnProperty(field)) {
            this.formErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
               const messages = this.validationMessages[field];
               for (const key in control.errors) {
                  if (control.errors.hasOwnProperty(key)) {
                     this.formErrors[field] += messages[key] + ' ';
                  }
               }
            }
         }
      }
   }

   getTimezone(data) {
      if (!data.lat || !data.lng) {
         return;
      }
      const address = `${data.lat},${data.lng}`;
      if (this._cachedAddress === address) {
         return;
      }
      this.sensorForm.patchValue({ lat: data.lat.toFixed(6) });
      this.sensorForm.patchValue({ lng: data.lng.toFixed(6) });
      this._cachedAddress = address;

      this._timezoneAPI
         .getTimezone(address)
         .pipe(first(), takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               if (response && response['timeZoneId']) {
                  this.model = { ...this.model, timezone: response['timeZoneId'] };
                  this.sensorForm.patchValue({ timezone: response['timeZoneId'] });
                  this._cdRef.detectChanges();
               }
            },
            error: error => {
               console.error('create.sensor.component.ts -> getZoneName(): ', error);
            }
         });
   }

   /**
    * save sensor data
    */
   onSave() {
      if (this.sensorForm.valid) {
         var selectedSensorModelMode = '', fastModeset = null, gasesfromType = [], gasesfromModel = [], sensorMGasSelected = {};
         this.sensorTypesAll.forEach(elementtype => {
            if(elementtype['key'] == this.sensorForm.value.type){
               gasesfromType = elementtype['vocAnalytics']['rows'];
            }
         });
         if(this.sensorForm.value.sensorModel !== 'null'){
            this.sensorModels.forEach(element => {
               if(element['key'] == this.sensorForm.value.sensorModel){
                  selectedSensorModelMode = element['enabledModes'][0];
                  gasesfromModel = element['gasList'];
               }
            });      
            
            gasesfromType.forEach(element => {
               if(gasesfromModel.includes(element.id)){
                  sensorMGasSelected[element.id] = true;
               }else{
                  sensorMGasSelected[element.id] = false;
               }
            });            
            if(selectedSensorModelMode == 'fast'){
               fastModeset = true; 
            }else if(selectedSensorModelMode == 'normal'){
               fastModeset = false;
            }else{
               fastModeset = false;
            }
         }else{
            gasesfromType.forEach(element => {
               sensorMGasSelected[element.id] = true;
            });       
         }
         this.model = {
            address: this.sensorForm.value.address,
            name: this.sensorForm.value.name,
            description: this.sensorForm.value.description,
            customerId: this.customerId,
            customerName: this.customerName,
            zoneId: this.zoneId,
            zoneName: this.zoneName,
            availability: global_variables.deviceStatus[1],
            lat: this.sensorForm.value.lat,
            lng: this.sensorForm.value.lng,
            sensorTypeId: this.sensorForm.value.type,
            sensorModel: (this.sensorForm.value.sensorModel== 'null')?'':this.sensorForm.value.sensorModel,
            sensorModelGasSelection: sensorMGasSelected,
            serialNumber: this.sensorForm.value.serialNumber,
            //timestamp: database.ServerValue.TIMESTAMP,
            timestamp: serverTimestamp(),
            maxRuns: 999,
            timezone: this.sensorForm.value.timezone,
            fastMode: fastModeset
         };

         this.isCreateSensor = true;
         this.createNewSensor();
      } else {
         console.log('Your form is invalid.');
      }
   }

   showResult(result: any, that: any) {
      that.model['lat'] = result.geometry.location.lat();
      that.model['lng'] = result.geometry.location.lng();
      that.getTimezone(that.model);
   }

   showAddressError(status, that: any) {
      that._snackBar.open('Address error: ' + status, 'Alert', {
         duration: 3000,
         verticalPosition: 'top',
         horizontalPosition: 'center',
      });
      that.isCreateSensor = false;
   }

   patchModel() {
      this.model = { ...this.model, ...this.sensorForm.value };
   }

   /**
    * get lattitude and longtitude
    * @param callback callback
    * @param that this
    * @param fallback fallback
    */
   getLatitudeLongitude(callback, that, fallback) {
      const address = that.sensorForm.value['address'];
      // const address = that.model['address'];
      if (address) {
         // Initialize the Geocoder
         if (that.geocoder) {
            that.geocoder.geocode(
               {
                  address: address,
               },
               function (results, status) {
                  if (status === google.maps.GeocoderStatus.OK) {
                     callback(results[0], that);
                  } else {
                     fallback(status, that);
                  }
               }
            );
         }
      } else {
         console.log('incorrect address:', address);
      }
   }

   /**
    * create new sensor
    */
   createNewSensor() {
      this._httpService
         .getListByOrder(environment.APIS.SENSORS, 'serialNumber', this.model['serialNumber'], 1)
         .subscribe({
            next: (sensors) => {
               if (sensors && sensors.length > 0) {
                  const config = {
                     width: '400px',
                     disableClose: true,
                     data: {
                        title: 'Alert',
                        description: 'The serial number is already existed.',
                     },
                  };
                  this.dialog.open(AlertModalComponent, config);
                  this.isCreateSensor = false;
               } else {
                  // create new sensor
                  this._httpService.createAsList(environment.APIS.SENSORS, this.model).then(
                     res => {
                        this._httpService
                           .updateAsObject(`${environment['APIS']['SENSORDEVICES']}/${res}`, { isInRealTime: true })
                           .then(
                              () => {
                                 this.initForm();
                                 this._snackBar.open('New Sensor creation is successful!', 'Success', {
                                    duration: 3000,
                                    verticalPosition: 'top',
                                    horizontalPosition: 'center',
                                 });
                                 if (this.createName === this.CREATENAMES[0]) {
                                    this._router.navigate(['/customer'], {
                                       queryParams: { type: 'list', customerId: this.customerId, zoneId: this.zoneId },
                                    });
                                 } else {
                                    this._router.navigate(['/zone'], {
                                       queryParams: { type: 'list', zoneId: this.zoneId },
                                    });
                                 }

                                 this.isCreateSensor = false;
                              },
                              error => {
                                 this.isCreateSensor = false;
                                 console.error('create.sensor.component.ts -> createNewSensor(): ', error);
                              }
                           );
                     },
                     error => {
                        this.isCreateSensor = false;
                        console.error('create.sensor.component.ts -> createNewSensor(): ', error);
                     }
                  );
               }
            },
            error: error => {
               console.error('create.sensor.component.ts -> createNewSensor(): ', error);
            }
         });
   }

   onBack() {
      this._location.back();
   }

   public filterZones($event: string) {
      //const timezones = [...this.timezones];
      if (!this.timezones) {
         return;
       }
       // get the search keyword
       let search = this.timezoneFilter.value;
       if (!search) {
         this.filteredTimeZones.next(this.timezones.slice());
         return;
       } else {
         search = search.toLowerCase();
       }
       this.filteredTimeZones.next(
         this.timezones.filter((time: string) => time.toLowerCase().indexOf(search) > -1)
       );
      //this.filteredTimeZones = timezones.filter(t => t.toLowerCase().includes($event.trim().toLowerCase()));
   }
}
