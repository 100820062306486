<div class="omniscent-app">
  <div class="header">
    <app-top-bar></app-top-bar>
  </div>

  <div class="body">
    <router-outlet></router-outlet>		
  </div>

  <div class="notifications">
    <app-spinner></app-spinner>
    <ng-snotify class="material"></ng-snotify>
  </div>
</div>
