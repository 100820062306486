import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
   selector: 'no-permissions',
   templateUrl: './no-permissions.component.html',
   styleUrls: ['./no-permissions.component.scss'],
})
export class NoPermissionsComponent {
   constructor(public dialogRef: MatDialogRef<NoPermissionsComponent>) {}

   onClose() {
      this.dialogRef.close();
   }
}
