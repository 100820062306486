<div class="omniscent-view">
	<div class="omniscent-title mx-3" fxLayout fxLayoutAlign="space-between center">
		<button mat-mini-fab color="primary" class="mr-1" (click)="onBack()">
			<mat-icon>keyboard_return</mat-icon>
		</button>
		<div>
			Create a Zone
		</div>
		<div></div>
	</div>

	<div class="mx-3 mb-3">
		<mat-progress-bar mode="indeterminate" *ngIf="isCreateZone"></mat-progress-bar>

		<mat-card appearance="outlined">
			<mat-card-content>
				<form [formGroup]="zoneForm" (ngSubmit)="onCreateZone()">
					<div fxLayout>
						<div fxFlex="10" fxLayoutAlign="center center" class="border">
							<mat-icon class="zone-icon">map</mat-icon>
						</div>

						<div fxFlex="90" class="p-3 border">
							<div class="row">
								<div class="form-group col-sm-6">
									<mat-form-field class="full-width">
										<mat-label>Name</mat-label>
										<input matInput type="text" formControlName="name">
										<mat-error *ngIf="formErrors['name']">
											{{ formErrors['name'] }}
										</mat-error>
									</mat-form-field>
								</div>

								<div class="form-group col-sm-6">
									<mat-form-field class="full-width capitalize">
										<mat-label>Criticality</mat-label>
										<mat-select placeholder="Criticality*" formControlName="criticality" name="criticality">
											<mat-option *ngFor="let type of arrStrCriticalities" [value]="type" class="capitalize">
												{{ type }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>

							<div class="row">
								<div class="form-group col-sm-6">
									<mat-form-field class="full-width">
										<mat-label>Description</mat-label>
										<textarea matInput formControlName="description" rows="3"></textarea>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>

					<div fxLayout="row" fxLayoutAlign="center center" class="mt-3" *ngIf="!isCreateZone">
						<button mat-raised-button class="mr-3" color="primary" [disabled]="zoneForm.invalid">Create Zone</button>
						<a mat-raised-button class="btn btn-default" (click)="zoneForm.reset()">Reset</a>
					</div>
					
				</form>
			</mat-card-content>
		</mat-card>
	</div>
</div>
