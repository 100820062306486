import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-custom-alert-template-modal',
  templateUrl: './custom-alert-template-modal.component.html',
  styleUrls: ['./custom-alert-template-modal.component.scss']
})
export class CustomAlertTemplateModalComponent implements OnInit {
  templateForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {SubjectEmailTemplate: string, BodyEmailTemplate: string},
    private dialogRef: MatDialogRef<CustomAlertTemplateModalComponent>
  ) { }

  ngOnInit() {
    this.templateForm = new FormGroup({
      SubjectEmailTemplate: new FormControl(this.data.SubjectEmailTemplate || ''),
      BodyEmailTemplate: new FormControl(this.data.BodyEmailTemplate || ''),
    });
  }

  updateTemplate() {
    this.dialogRef.close(this.templateForm.getRawValue());
  }

}
