<div class="calibration-steps p-4">
  <app-step-one
    *ngIf="step === 1"
    (nextStep)="onNextStep($event)"
    (onCancelWizard)="cancelWizard($event)"
  ></app-step-one>
  <app-step-two
    (backStep)="onNextStep($event)"
    *ngIf="step === 2"
    (submitForm)="onSubmitForm($event)"
    (onCancelWizard)="cancelWizard($event)"
  ></app-step-two>
</div>
