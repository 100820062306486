<mat-dialog-content class="overflow-y mat-dialog-content">
  <mat-form-field class="full-width">
    <mat-label>Search...</mat-label>
    <input type='text' [(ngModel)]='strFilerText' matInput (keyup)='onUpdateFilter($event)'>
  </mat-form-field>
  <table mat-table #table [dataSource]="dataSource" class="text-center">
    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef> No.</th>
      <td mat-cell *matCellDef="let element" style="flex: 1"> {{ element?.position }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="px-3"> File Name</th>
      <td mat-cell *matCellDef="let element" class="px-3 file-name">
        {{ element?.name }}
      </td>
    </ng-container>

    <!-- Upload Time Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef class="px-3"> Upload Time</th>
      <td mat-cell *matCellDef="let element" class="px-3"> {{ element?.time }} </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="use">
      <th mat-header-cell *matHeaderCellDef> Use for calibration</th>
      <td mat-cell *matCellDef="let element">
        <mat-radio-button (change)="onChangeFile($event, element)"
                          [checked]="element.selected"></mat-radio-button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="arrStrDisplayedColumns"></tr>
    <tr [ngClass]="{'light-green-bg': row.selected}"
        mat-row *matRowDef="let row; columns: arrStrDisplayedColumns;"></tr>
  </table>
</mat-dialog-content>

<mat-paginator #paginator
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
<mat-dialog-actions class="footer">
  <button (click)="dialogRef.close()" mat-button color="warn">Cancel</button>
  <button mat-button (click)="continue()">Select and Continue</button>
</mat-dialog-actions>
