<h3 class="text-center p-0 mb-3">Calibration - Step 2 - Calibration gas cylinder composition</h3>
<div class="main-content">
   <div class="row mb-5">
      <div class="col-12">
         <div class="calibration-audit">
            <span class="calibration-title text-center"> Audit </span>
            <mat-card appearance="outlined">
               <mat-card-content>
                  <div class="row">
                     <div class="col-12 col-md-4 col-lg-3">
                        <mat-form-field appearance="outline">
                           <mat-label>Short label</mat-label>
                           <input maxlength="20" matInput placeholder="Short display name" [(ngModel)]="displayName" />
                        </mat-form-field>
                     </div>
                     <div class="col-12 col-md-8 col-lg-9">
                        <mat-form-field appearance="outline">
                           <mat-label>Description</mat-label>
                           <textarea
                              class="w-100"
                              matInput
                              placeholder="Put comments here"
                              [(ngModel)]="description"></textarea>
                        </mat-form-field>
                        <br />
                        <span
                           >Will be saved as performed by
                           <span class="calibration-username">{{ userName || '[error: empty username]' }}</span></span
                        >
                     </div>
                  </div>
               </mat-card-content>
            </mat-card>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-sm-8 col-12 flex-column mb-4">
         <div class="gas-setup">
            <span class="calibration-title text-center"> Gas setup </span>
            <mat-card appearance="outlined">
               <mat-card-content>
                  <div class="table-responsive">
                     <table class="table">
                        <thead>
                           <tr>
                              <th>Gas</th>
                              <th>Concentration (ppb)</th>
                              <th>Error (%)</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let gas of gasForm.controls; let i = index">
                              <ng-container [formGroup]="gas">
                                 <td class="cell">{{ gas.get('name').value | lowercase }}</td>
                                 <td class="cell">
                                    <mat-form-field appearance="outline" class="full-width">
                                       <input
                                          (change)="onConcentrationChange($event, 'ppb' + gas.get('name').value)"
                                          type="number"
                                          matInput
                                          required
                                          [formControlName]="'ppb' + gas.get('name').value" />
                                    </mat-form-field>
                                 </td>
                                 <td class="cell">
                                    <mat-form-field appearance="outline" class="full-width">
                                       <input
                                          (change)="onErrorChange($event, i, 'err' + gas.get('name').value)"
                                          type="number"
                                          matInput
                                          [formControlName]="'err' + gas.get('name').value" />
                                    </mat-form-field>
                                    <ng-container
                                       *ngIf="
                                          gas.get('err' + gas.get('name').value).invalid &&
                                          (gas.get('err' + gas.get('name').value).dirty ||
                                             gas.get('err' + gas.get('name').value).touched)
                                       ">
                                       <div
                                          *ngIf="gas.get('err' + gas.get('name').value).errors.max"
                                          class="text-danger">
                                          Must be {{ gas.get('err' + gas.get('name').value).errors.max.max }}% or less
                                       </div>
                                    </ng-container>
                                 </td>
                              </ng-container>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </mat-card-content>
               <mat-card-footer class="text-center">
                  <mat-checkbox [(ngModel)]="gasChecked">I double checked, I promise</mat-checkbox>
               </mat-card-footer>
            </mat-card>
         </div>
      </div>
      <div class="col-sm-4 col-12 flex-column">
         <div class="instruction h-100">
            <span class="calibration-title text-center"> Intructions </span>
            <mat-card appearance="outlined">
               <mat-card-content>
                  <ul>
                     <li>
                        <p>Insert zero if a chemical doesn't exist in your calibration cylinder</p>
                     </li>
                     <li>
                        <p>Put the certification error % in corresponding <br />column</p>
                     </li>
                     <li>
                        <p>The Values are listedon the cylinder label</p>
                     </li>
                     <li>
                        <p>
                           m-xylene and o-xylene can not be calibrated <br />if calibration gas cylinder contain
                           ethylbenzene
                        </p>
                     </li>
                  </ul>
               </mat-card-content>
               <mat-card-footer class="text-center">
                  <mat-checkbox [(ngModel)]="gasInstruction">All done per instructions</mat-checkbox>
               </mat-card-footer>
            </mat-card>
         </div>
      </div>
   </div>
</div>
<div class="row">
   <div class="col mt-4 action-buttons w-100 d-flex justify-content-between">
      <button (click)="backStep.emit(1)" style="min-width: 200px" mat-raised-button class="btn btn-info">Back</button>
      <button style="min-width: 150px" mat-raised-button class="cancel btn btn-warning" (click)="cancelWizard()">
         Cancel
      </button>
      <button
         (click)="onSubmitForm()"
         [disabled]="!(gasChecked && gasInstruction && displayName.length && description.length) || gasForm.invalid"
         mat-raised-button
         class="btn btn-success">
         Acknowledge, Agree and Continue
      </button>
   </div>
</div>
