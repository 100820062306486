import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { global_variables } from '../../../../environments/global_variables';
import { AuthService } from '../../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CalibrationConfigModalComponent } from '../../../modals/calibration-config-modal/calibration-config-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../services/http.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { SpinnerService } from '../../../components/spinner/spinner.service';
import { Location } from '@angular/common';
import { map, tap, filter, takeUntil, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash-es';

@Component({
   selector: 'app-step-one',
   templateUrl: './step-one.component.html',
   styleUrls: ['./step-one.component.scss'],
})
export class StepOneComponent implements OnInit, OnDestroy {
   private destroy$: Subject<boolean> = new Subject();
   arrStrUserTypes = global_variables.userTypes;
   arrStrUserRoles = global_variables.userRoles;
   @Output() nextStep: EventEmitter<number> = new EventEmitter<number>();
   @Output() onCancelWizard: EventEmitter<boolean> = new EventEmitter<boolean>();
   private strUserType: string;
   strUserRole = '';
   private sensorKey: string;
   private sensorSub: Subscription;
   private selectedSensor: Object;
   calibrationHistories$: Observable<any[]>;
   calibrationHistoriesDataSource: any[];
   activeCalibrationHistories: any[];
   calibrationHistoryLength = 0;
   warningAknowledge = false;
   stepsAknowledge = false;
   isCalibrationConfigFileKeyExist = false;

   historyPageSize = 5;

   constructor(
      private _authService: AuthService,
      public location: Location,
      private dialog: MatDialog,
      private _spinner: SpinnerService,
      private _router: Router,
      private _snackBar: MatSnackBar,
      private _httpService: HttpService,
      private _activeRoute: ActivatedRoute
   ) {}

   ngOnInit() {
      this.strUserRole = this._authService.userData['action']['role'];
      this._activeRoute.params.pipe(takeUntil(this.destroy$)).subscribe({
         next: (params) => {
            this.sensorKey = params['sensorKey'];
            this.calibrationHistories$ = this.getSensorHistories();
            this.sensorSub = this._httpService
               .getAsObject(`${environment.APIS.SENSORS}/${this.sensorKey}`)
               .pipe(takeUntil(this.destroy$))
               .subscribe({
                  next: (sensor) => {
                     console.log(sensor);
                     if (sensor) {
                        this.selectedSensor = sensor;
                     } else {
                        this._router.navigate(['/dashboard']);
                     }
                  },
                  error: error => {
                     console.error('step-one.component.ts -> ngOnInit(): ', error);
                  }
               });
         },
         error: error => {
            console.error('step-one.component.ts -> ngOnInit(): ', error);
         }
      });

      this._httpService
         .getAsObject(`${environment.APIS.SENSORCALIBRATION}/${this.sensorKey}/current/calibrationConfigFile/key`)
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (res: { key: string; $value: string }) => {
               this.isCalibrationConfigFileKeyExist = res && res.$value && !!res.$value.length;
               if (!this.isCalibrationConfigFileKeyExist && this.hasEditPermission()) {
                  this.onChooseConfig();
                  this._snackBar.open('Need to select calibration config', 'Info', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               }
            },
            error: error => {
               console.error('step-one.component.ts -> ngOnInit(): ', error);
            }
         });
   }

   ngOnDestroy() {
      this.destroy$.next(null);
      this.destroy$.complete();
   }

   hasEditPermission(): boolean {
      return (
         this.strUserType === this.arrStrUserTypes[0] || // is OmniscentUser
         this.strUserRole === this.arrStrUserRoles[0]
      ); // is Customer admin
   }

   checkUserType() {
      if (this._authService.isUserStaff) {
         // staff
         this.strUserType = this.arrStrUserTypes[0];
      } else {
         // customer
         this.strUserType = this.arrStrUserTypes[1];
      }
   }

   onChooseConfig() {
      const dialogRef = this.dialog.open(CalibrationConfigModalComponent, {
         minWidth: '560px',
         data: {
            sensor: this.selectedSensor,
         },
      });

      dialogRef
         .afterClosed()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (result) => {
               if (result) {
                  this.onUpdateConfig(result);
               }
            },
            error: error => {
               console.error('step-one.component.ts -> onChooseConfig(): ', error);
            }
         });
   }

   getSensorHistories() {
      return this._httpService.getAsObject(`${environment.APIS.SENSORCALIBRATION}/${this.sensorKey}/history`).pipe(
         map(data => {
            if (data) {
               delete data.key;
            }
            return _.orderBy(data || {}, 'dateTime', 'desc');
         }),
         tap(data => {
            this.calibrationHistoriesDataSource = data;
            this.activeCalibrationHistories = this.calibrationHistoriesDataSource.slice(0, this.historyPageSize);
            this.calibrationHistoryLength = this.calibrationHistoriesDataSource
               ? this.calibrationHistoriesDataSource.length
               : 0;
         })
      );
   }

   onUpdateConfig(result) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORCALIBRATION}/${this.sensorKey}/current`, {
            calibrationConfigFile: result,
         })
         .then(() => {
            this._spinner.stop();
            this._snackBar.open('The calibration data has been updated!', 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         })
         .catch(_ => {
            this._snackBar.open('Something wrong...', 'Error', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         });
   }

   cancelWizard() {
      this.onCancelWizard.emit(true);
   }

   onPageHistoryChanged($event) {
      const firstCut = $event.pageIndex * $event.pageSize;
      const secondCut = firstCut + $event.pageSize;
      this.activeCalibrationHistories = this.calibrationHistoriesDataSource.slice(firstCut, secondCut);
   }
}
