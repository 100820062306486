import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { global_variables } from '../../../environments/global_variables';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-multiple-analytic-table',
  templateUrl: './multiple-analytic-table.component.html',
  styleUrls: ['./multiple-analytic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleAnalyticTableComponent implements OnInit {
  arrStrAnalyticalInputs = global_variables['AnalyticalInputs'];
  arrStrRetentionProperties: string[];

  @Input() analytic: any;
  @Input() bIsShowUnChemicals: boolean;
  @Input() bIsShowRententionProperties: boolean;
  @Input() arrParams: Object[];
  @Input() arrAnalyticalInputs: any[];
  @Output() updateInput: EventEmitter<object> = new EventEmitter();
   strUserRole: any;

  constructor(
    private _authService: AuthService,
  ) {
    this.arrStrRetentionProperties = global_variables['RententionProperties'];
  }

  private _index: any;

  get index(): any {
    return this._index;
  }

  @Input('index')
  set index(value: any) {
    this._index = value;
  }

  ngOnInit() {

    if (this._authService.isCheckUser && this._authService.isUserEmailLoggedIn) {
      this.strUserRole = this._authService.userData['action']['role'];
    }
  }

  onUpdateAnalyticInput(event: any, nInd: number) {
    this.updateInput.emit({event, nInd, index: this._index});
  }
}
