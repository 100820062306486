<div class="omniscent-view" *ngIf="isGettingData">
	<div class="omniscent-title mx-3" fxLayout fxLayoutAlign="space-between center">
		<button mat-mini-fab color="primary" class="mr-1" (click)="onBack()">
			<mat-icon>keyboard_return</mat-icon>
		</button>
		<div>
			Create a Sensor
		</div>
		<div></div>
	</div>

	<div class="mx-3 mb-3">
		<mat-progress-bar mode="indeterminate" *ngIf="isCreateSensor"></mat-progress-bar>
		<mat-card appearance="outlined">
			<mat-card-content>
				<form [formGroup]="sensorForm" class="container-fluid" (ngSubmit)="onSave()">
					<div fxLayout>
						<div fxFlex="10" fxLayoutAlign="center center" class="border">
							<mat-icon class="sensor-icon">pin_drop</mat-icon>
						</div>

						<div fxFlex="90" class="p-3 border">
							<div class="row">
								<div class="form-group col-sm-6">
									<mat-form-field class="full-width">
										<mat-label>Name</mat-label>
										<input matInput type="text" formControlName="name">
									</mat-form-field>

									<div *ngIf="formErrors.name" class="alert alert-danger">
										{{formErrors.name}}
									</div>
								</div>

								<div class="form-group col-sm-6">
									<mat-form-field class="full-width">
										<mat-label>Description</mat-label>
										<textarea matInput formControlName="description" rows="1"></textarea>
									</mat-form-field>
								</div>
							</div>

							<div class="row">
								<div class="form-group col-sm-6">
									<mat-form-field class="full-width">
										<mat-label>Sensor Type</mat-label>
										<mat-select formControlName="type" name="type" (selectionChange)="changeSensorModel()">
											<mat-option *ngFor="let type of sensorTypes"
                                  [value]="type.key">
												{{ type.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="form-group col-sm-6">
									<mat-form-field class="full-width">
										<mat-label>Serial Number</mat-label>
										<input matInput type="text" formControlName="serialNumber" name="serialNumber">
									</mat-form-field>
								</div>
							</div>

							<div class="row">
								<div class="form-group col-sm-6">
									<mat-form-field class="full-width">
										<mat-label>Sensor Model</mat-label>
										<mat-select formControlName="sensorModel" name="sensorModel">
											<mat-option value="null">custom</mat-option>
											<mat-option *ngFor="let model of sensorModels"
                                  [value]="model.key">
												{{ model.modelName }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="form-group col-sm-6">
								</div>
							</div>

							<div class="row">
								<div class="form-group col-sm-6">
									<fieldset>
										<legend>Location *</legend>

										<mat-radio-group class="inline-radio-area" name="situation" formControlName="situation">
											<mat-radio-button color="primary" [value]="1" *ngIf="geocoder">
												With address
											</mat-radio-button>

											<mat-radio-button color="primary" [value]="0">
												With lattitude and longitude
											</mat-radio-button>
										</mat-radio-group>
									</fieldset>
								</div>

								<div class="form-group col-sm-6" *ngIf="sensorForm.value.situation === 1">
									<mat-form-field class="full-width">
										<mat-label>Address *</mat-label>
										<textarea rows="5" matInput type="text" formControlName="address" name="address"></textarea>
									</mat-form-field>
								</div>

								<div class="col-sm-6" *ngIf="sensorForm.value.situation === 0">
									<div class="form-group">
										<mat-form-field class="full-width">
											<mat-label>Lattitude *</mat-label>
											<input matInput type="number" formControlName="lat" name="lat">
										</mat-form-field>
									</div>

									<div class="form-group">
										<mat-form-field class="full-width">
											<mat-label>Longitude *</mat-label>
											<input matInput type="number" formControlName="lng" name="lng">
										</mat-form-field>
									</div>
								</div>
							</div>

              <div class="row">
                <div class="form-group col-sm-6">
                  <mat-form-field class="full-width">
	            	<mat-label>Timezone</mat-label>
                    <mat-select #singleSelect  formControlName="timezone">
                      <mat-option>
                        <ngx-mat-select-search
							ngDefaultControl
							noEntriesFoundLabel="'No matching timezones found'"
							[formControl]="timezoneFilter"
							placeholderLabel="Find Timezone...">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let timezone of filteredTimeZones | async" [value]="timezone">
                        {{timezone}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
						</div>
					</div>

					<div fxLayout="row" fxLayoutAlign="center center" class="mt-3" *ngIf="!isCreateSensor">
						<button mat-raised-button class="mr-3" color="primary" [disabled]="sensorForm.invalid">Create Sensor</button>
						<a mat-raised-button class="btn btn-default" (click)="sensorForm.reset()">Reset</a>
					</div>
				</form>
			</mat-card-content>
		</mat-card>
	</div>
</div>
