<div class="container-fluid">
  <div class="eula-agreement">
    <h1 class="text-center mt-3">End User License Agreement</h1>
    <p>
      PLEASE READ THIS END USER LICENCE AGREEMENT CAREFULLY. This End User License Agreement (“EULA”) governs your use
      of
      the software, application, their associated upgrades, patches, and related services (the “Product”) currently
      provided or which will be provided by Omniscent, Inc., or anyone of its subsidiaries or affiliated companies
      (collectively referred to as “OMNISCENT").</p>

    <p>This EULA sets out the basis on which OMNISCENT makes the Products available to You (“Enduser Company”, “Enduser
      Individual”, or “You”) and on which You may use them. By installing or using the Product, You agree to accept and
      to
      be bound by this EULA at all times. If You do not agree with this, please do not install or use the Product.</p>

    <b>EULA HIGHLIGHTS</b>
    <ol>
      <li>OMNISCENT (or its licensors) grants You a non-exclusive, non-transferable, non-sublicensed, non- commercial
        and
        personal license to install and/or use the Product (in whole or in part) and any Product (the “License”), for
        such
        time until either You or OMNISCENT terminates this EULA. THIS PRODUCT IS LICENSED TO YOU, NOT SOLD.
      </li>
      <li>
        You shall not, directly or indirectly (i) sell, rent out, lease, license, distribute, market, exploit the
        Product
        or any of its parts commercially, (ii) reverse engineer, decompile, disassemble, adapt, reproduce, or create
        derivate works of this Product (except if the Product enable You through a specific feature to create, generate
        or
        submit User Generated Content and for which You will need to create an Account and comply with the Terms of
        Use), in
        whole or in part; (iii) remove, alter, disable or circumvent any copyright and trademark indications or other
        authorship and origin information, notices or labels contained on or within this Product and (iv) export or
        re-export this Product or any copy of adaptation in violation of any applicable laws or regulations.
      </li>
      <li>
        While using the Product, You agree to comply will all applicable laws, rules and regulations. In all cases, You
        may only use the Product according to anticipated use of the Product.
      </li>
      <li>
        All title, ownership rights and intellectual property rights in and to the Product and any and all copies
        thereof
        are owned by OMNISCENT or its licensors. The Product is protected by national and international laws, copyright
        treaties and conventions and other laws. This License confers no title or ownership in the Product and should
        not be
        construed as a sale of any rights in the Product.
      </li>
      <li>
        Product Access. To use the Product on a Compatible Mobile Terminal, You must have access to an electronic
        communication network. The connection costs (including but not limited to mobile providers’ and/ or carriers’
        costs), shall be exclusively borne by You. You acknowledge that the quality of the Products, the response time
        or
        access to certain features may depend on the capacities of Your Compatible Mobile Terminal and of the electronic
        communication network. OMNISCENT may in no case be held responsible for reduced user comfort. You acknowledge
        that
        the Product may not be available for use on all mobile devices or through all carriers or network service
        providers.
      </li>
      <li>
        You are solely responsible for any damage caused to OMNISCENT, its licensors, channel partners and associated
        service providers and subcontractors, other users of the product or any other individual or legal entity as a
        result
        of Your violation of this EULA.
      </li>
    </ol>
    <p>
      YOU HEREBY AGREE TO READ THE FULL LENGTH VERSION OF THIS EULA IN ITS ENTIRETY PRIOR TO ACCEPTING ITS TERMS. For
      any
      questions concerning this EULA, you may contact OMNISCENT at the following address: <a
      href='mailto:info@OMNISCENT.com'>info&#64;OMNISCENT.com</a></p>

    <p>THIS EULA IS APPLICABLE ONLY TO THE EXTENT AUTHORIZED BY APPLICABLE LAW.
    </p>

    <a class="mb-3 d-block text-center" href="../../assets/Omniscent%20EULA_25March2020_Pages_V3.pdf">Click here to view
      or download
      full agreement</a>

    <div class="form-group text-center">
      <mat-checkbox
        [(ngModel)]="userAgreement.agreement"
        (change)="onChangeAgreement($event.checked)"
      >I have read and agree to the terms of this End-User License Agreement
      </mat-checkbox>
    </div>
    <div class="form-group text-center">
      <mat-form-field class="col-3">
        <mat-label>Please type your full name into this field</mat-label>
        <input matInput [(ngModel)]="userAgreement.signed_full_name">
      </mat-form-field>
    </div>
    <div class="form-group">
      <button (click)="onSubmitEula()" mat-raised-button class="eula-submit-btn" color="primary" mat-button
              [disabled]="userAgreement.agreement ? null : ''">
        Agree and Continue
      </button>
    </div>
  </div>
</div>
