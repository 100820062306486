<div class="login-password-outer">
<div class="logo-outer">
  <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/omniscent-2889e.appspot.com/o/logos%2FOmniscentLogo.png?alt=media&token=676e1f64-59a1-40de-8ab9-25efcb6112d2">
</div>
<div class="login-password">
  <div class="verify-area full-height" fxLayout="column" fxLayoutAlign="center center" *ngIf="isPageLoad">
  <div *ngIf="type === 0">
    <mat-progress-bar mode="indeterminate" *ngIf="isSend" class="mb-2"></mat-progress-bar>
    <mat-card appearance="outlined">
      <mat-card-title>
        Please set your password
      </mat-card-title>

      <mat-card-content>
        <form [formGroup]="signUpForm" (ngSubmit)="onSubmitSignUp()">
          <div class="form-group field-bg set-pass" style="display: flex;">
            <mat-form-field  class="full-width"> 
              <input matInput formControlName="password" name="password" required placeholder="PASSWORD" [type]="hide ? 'password' : 'text'">
              <mat-icon (click)="hide = !hide"  class="visiblepassicon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="formErrors['password']">
                {{ formErrors['password'] }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-group field-bg set-pass" style="display: flex;">
            <mat-form-field  class="full-width">
              <input matInput formControlName="confirmPassword" name="confirmPassword" required placeholder="CONFIRM PASSWORD" [type]="hide_confirm ? 'password' : 'text'">
              <mat-icon (click)="hide_confirm = !hide_confirm"  class="visiblepassicon">{{hide_confirm ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="formErrors['confirmPassword']">
                {{ formErrors['confirmPassword'] }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-group">
            <ul class="password-tip-container">
              <li>The password must contain at least 1 lowercase alphabetical character</li>
              <li>The password must contain at least 1 uppercase alphabetical character</li>
              <li>The password must contain at least 1 numeric character</li>
              <li>The password must contain at least one special character</li>
              <li>The password must be eight to 72 characters</li>
            </ul>
          </div>

          <div class="form-group">
            <button mat-raised-button class="full-width" color="primary" [disabled]="signUpForm.invalid || isSend">
              Create account
            </button>
          </div>


          <div class="text-center" *ngIf="!isSend">
            <a routerLink="/login" class="blue-text">
              Login
            </a>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="form-w380" *ngIf="type === 1">
    <mat-card appearance="outlined">
      <mat-progress-bar mode="indeterminate" *ngIf="isSend" class="mb-2"></mat-progress-bar>
      <mat-card-title>
        <h3>Can't sign in?</h3>
      </mat-card-title>

      <mat-card-content>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmitForgotPassword()">
          <div class="form-group field-bg">
            <mat-form-field class="full-width">
              <input matInput placeholder="E-MAIL" formControlName="email" name="email" required>
              <mat-error *ngIf="formErrors['email']">
                {{ formErrors['email'] }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-group">
            <button mat-raised-button class="full-width" color="primary" *ngIf="!isResend" [disabled]="forgotPasswordForm.invalid || isSend">
              Send reset link
            </button>

            <button mat-raised-button class="full-width btn btn-success" *ngIf="isResend" [disabled]="forgotPasswordForm.invalid || isSend">
              Resend reset link
            </button>
          </div>

          <div fxLayout fxLayoutAlign="flex-end center" *ngIf="!isSend">
            <a routerLink="/login" class="white-text omni-link font-18">
              Login
            </a>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
</div>
</div>
