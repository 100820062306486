export enum NStatus {
   None = 'none',
   Name = 'name',
   Default = 'default',
   Latitude = 'latitude',
   Longitude = 'longitude',
   TimeZone = 'timeZone',
   Address = 'address',
   Contact = 'contact',
   Phone = 'phone',
   WSensorID = 'wSensorID',
   WDataAccess = 'wDataAccess',
   MaxRuns = 'maxRuns',
   FastModeConfig = 'fastModeConfig',
   AdminFastMode = 'adminFastMode',
   AdminFastMode2 = 'adminFastMode2',
   CleanedNum = 'cleanedNum',
   CurrentCleanedCycles = 'currentCleanedCycles',
   CustomerName = 'customerName',
   ZoneName = 'zoneName',
   SerialNumber = 'serialNumber',
}
