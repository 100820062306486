<!--<div>-->
<!--  {{data.transformedCalibrationData|json}}-->
<!--</div>-->

<p mat-dialog-title>New calibration results are available. Review them and chose necessary action.</p>
<div mat-dialog-content class="table-responsive">
  <table class="table table-bordered fixed-table">
      <thead>
        <tr>
          <th *ngFor="let h of data?.heads">
            {{h.name}}
          </th>
        </tr>
      </thead>
    <tbody>
        <tr *ngFor="let row of data?.transformedCalibrationData">
          <td *ngFor="let cell of row.cells; let i = index">
            <div class="omni-col-item height-auto">
              <ng-container *ngIf="i === 0; else regularCell">{{cell?.name}}</ng-container>
              <ng-template #regularCell>
                <div>{{cell?.newPropValue}}</div>
                <div class="cell__old-value">{{cell?.oldPropValue}}</div>
              </ng-template>
            </div>
          </td>
        </tr>
    </tbody>
  </table>
</div>

<div mat-dialog-actions class="actions">
  <button class="btn btn-danger" mat-button mat-dialog-close [mat-dialog-close]="'Delete'">Delete</button>
  <button class="btn btn-warning" mat-button mat-dialog-close [mat-dialog-close]="'Apply'">Apply</button>
  <button class="btn btn-info" mat-button mat-dialog-close>Later</button>
</div>
