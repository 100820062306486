<table class="table table-bordered table-striped">
  <thead>
  <tr>
    <th>Title</th>
    <th>Value</th>
    <th *ngIf="hasEditPermission()">Action</th>
  </tr>
  </thead>

  <tbody>
  <tr>
    <td>Number of cycles to run cleaning</td>
    <td>
      <div *ngIf="bulkAction === BULK_ACTION.EDIT">{{sensor?.cleaningCycle}}</div>
      <div *ngIf="bulkAction === BULK_ACTION.SAVE">
        <mat-form-field class="full-width">
          <input
            [(ngModel)]="paramValue"
            matInput
            type="text"
          />
        </mat-form-field>
      </div>
    </td>
    <td *ngIf="hasEditPermission()">
      <div fxLayout fxLayoutAlign="center">
        <div
          class="edit-item pointer"
          fxLayout
          fxLayoutAlign="center center"
        >
          <mat-icon *ngIf="bulkAction === BULK_ACTION.EDIT" (click)="bulkAction = BULK_ACTION.SAVE">edit</mat-icon>
        </div>

        <mat-icon
          *ngIf="bulkAction === BULK_ACTION.SAVE"
          (click)="onUpdate()"
          class="pointer mr-1"
          color="primary"
        >check
        </mat-icon>

        <div
          *ngIf="bulkAction === BULK_ACTION.SAVE"
          class="delete-item pointer"
          fxLayout
          fxLayoutAlign="center center"
        >
          <mat-icon (click)="bulkAction = BULK_ACTION.EDIT">close</mat-icon>
        </div>
      </div>
    </td>
  </tr>
  <tr>
    <td>
      Current Cycle Number
    </td>
    <td>
      <div *ngIf="sensor?.totalCyclesCount || sensor?.totalCyclesCount === 0; else emptyTotalCyclesCount">
        {{sensor?.totalCyclesCount}}
      </div>
      <ng-template #emptyTotalCyclesCount>not available</ng-template>
    </td>
    <td></td>
  </tr>
  </tbody>
</table>
