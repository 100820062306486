<div class="input-component-area" (mouseover)="onOver()" (mouseleave)="onLeave()">
  <div class="edit-wrapper" *ngIf="bIsEdit">
    <mat-form-field class="full-width" *ngIf="strInputType === arrStrInputTypes[0]">
      <input type="number" matInput [(ngModel)]="value">
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="strInputType === arrStrInputTypes[1]">
      <input type="text" matInput [(ngModel)]="value">
    </mat-form-field>

    <textarea class="full-width" [(ngModel)]="value" *ngIf="strInputType === arrStrInputTypes[2]"></textarea>
  
    <mat-form-field class="full-width" *ngIf="strInputType === arrStrInputTypes[4]">
      <mat-select [(value)]="value">
        <mat-option *ngFor="let option of arrStrOptions" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="show-wrapper ellipsis" *ngIf="!bIsEdit">
    {{ value }}
    <div class="edit-item pointer" fxLayout fxLayoutAlign="center center" *ngIf="bIsShowEditBtn">
      <mat-icon (click)="onClickEdit()">edit</mat-icon>
    </div>
  </div>

  <div class="action-wrapper ml-3 pointer" *ngIf="bIsEdit">
    <mat-icon color="primary" (click)="onDone()">check</mat-icon>
  </div>
</div>
  