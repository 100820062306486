import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormControlOptions, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { PasswordValidation } from '../components/password-validation';

import { environment } from '../../environments/environment';
import { global_variables } from '../../environments/global_variables';
import { filter } from 'rxjs/operators';
import { StorageManagerService } from '../services/storage-manager.service';

declare var $: any;

@Component({
   selector: 'app-verify',
   templateUrl: './verify.component.html',
   styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
   signUpForm: FormGroup;
   forgotPasswordForm: FormGroup;
   formErrors: Object;
   validationMessages: Object;
   accountInfo: Object;

   type: number;
   isPageLoad: boolean;
   isSend: boolean;
   isResend: boolean;
   emailRegex: any;
   hide: boolean = true;
   hide_confirm: boolean = true;

   constructor(
      private _activeRoute: ActivatedRoute,
      private fb: FormBuilder,
      private _authService: AuthService,
      private _httpService: HttpService,
      private storageManagerService: StorageManagerService,
      private _nofication: NotificationService,
      private _cdRef: ChangeDetectorRef,
      private _router: Router
   ) {
      this.type = -1;
      this.isPageLoad = false;
      this.isSend = false;
      this.isResend = false;
      this.emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      this.validationMessages = {
         email: {
            required: 'Email is required',
            pattern: 'Please enter a valid address',
         },
         password: {
            required: 'Password is required',
            pattern: 'Password does not meet the strength requirements (see below)',
            maxlength: 'Max length is 72',
         },
         confirmPassword: {
            required: 'Confirm password is required',
            MatchPassword: 'Passwords do not match',
         },
      };
      this.formErrors = {
         email: '',
         password: '',
         confirmPassword: '',
      };

      //this.storageManagerService.clearStorage();
   }

   ngOnInit() {
      this._activeRoute.params.subscribe({
         next: (params) => {
            this._activeRoute.queryParams.pipe(filter(queryParams => queryParams.data)).subscribe({
               next: (queryParams) => {
                  this.accountInfo = JSON.parse(atob(queryParams.data));

                  if (params['type'] === 'set_password') {
                     const resultValideAccountInfo = this.validateAccountData();
                     if (resultValideAccountInfo['status']) {
                        this.type = 0;
                        this.createSignupForm();
                     } else {
                        this._nofication.createNotification('error', 'Error', resultValideAccountInfo['message']);
                        this._cdRef.detectChanges();
                        this._router.navigate(['/login']);
                     }
                  }

                  this.isPageLoad = true;
               },
               error: error => console.error('verify.component.ts -> ngOnInit(): ', error)
            });

            if (params['type'] === 'forgot_password') {
               this.type = 1;
               this.createForgotPasswordForm();
               this.isPageLoad = true;
            }
         },
         error: error => console.error('verify.component.ts -> ngOnInit(): ', error)
      });
   }

   /**
    * validate account data
    */
   validateAccountData() {
      if (!this.accountInfo['email']) {
         return {
            status: false,
            message: 'Empty email',
         };
      }

      if (!this.accountInfo['role']) {
         return {
            status: false,
            message: 'Empty user role',
         };
      }

      if (!this.accountInfo.hasOwnProperty('userType')) {
         return {
            status: false,
            message: 'Empty user type',
         };
      }

      if (this.accountInfo['userType'] === 1 && !this.accountInfo['customerKey']) {
         return {
            status: false,
            message: 'Empty customer key',
         };
      }

      return {
         status: true,
      };
   }

   /**
    * submit sign up form
    */
   onSubmitSignUp() {
      if (this.signUpForm.valid) {
         this.isSend = true;
         this._authService
            .signUpWithEmail(this.accountInfo['email'], this.signUpForm['value']['password'])
            .then(res => {
               if (res && res['user']) {
                  this.saveUser(res['user']);
               } else {
                  this._nofication.createNotification(
                     'error',
                     'Error',
                     'Firebase authentication function error. Please contact firebase administrator.'
                  );
                  this.isSend = false;
               }
            })
            .catch(error => {
               console.error('Error fetching users', error);
               this._nofication.createNotification('error', 'Error', error['message']);
               this.isSend = false;
            });
      }
   }

   /**
    * save user
    * @param userData user data
    */
   saveUser(userData: any) {
      if (userData['uid']) {
         let type = global_variables['userTypes'][0];

         const newUserData = {
            info: {
               email: userData['email'],
            },
            action: {
               status: global_variables['userStatus'][0],
               role: this.accountInfo['role'],
            },
         };

         if (this.accountInfo['userType'] === 0) {
            type = global_variables['userTypes'][0];
         } else {
            type = global_variables['userTypes'][1];
            newUserData['info']['customerId'] = this.accountInfo['customerKey'];
         }

         this._httpService.postAsObject(`${environment.APIS.USERS}/${type}/${userData['uid']}`, newUserData).then(
            () => {
               this.isSend = false;
               this._nofication.createNotification('success', 'Register', 'Successful register');
               this._cdRef.detectChanges();
               const encodeData = btoa(JSON.stringify(this.accountInfo));
               this._router.navigate(['/eula', { data: encodeData, userId: userData['uid'] }]);
            },
            error => {
               this.isSend = false;
               this._nofication.createNotification(
                  'error',
                  'Error',
                  'Internet connection error. Please contact firebase administrator.'
               );
               console.error(error);
            }
         );
      } else {
         this.isSend = false;
         this._nofication.createNotification(
            'error',
            'Error',
            'User ID is not arrived properly. Please contact firebase administrator.'
         );
      }
   }

   /**
    * submit forgot password form
    */
   onSubmitForgotPassword() {
      if (this.forgotPasswordForm.valid) {
         this.isSend = true;
         const host = window.location.protocol + '//' + window.location.host;
         this._authService
            .resetPassword(this.forgotPasswordForm['value']['email'], host)
            .then(() => {
               this._nofication.createNotification(
                  'success',
                  'Success',
                  'Email transmission is successful, please check your email!'
               );
               this.isSend = false;
               this.isResend = true;
            })
            .catch(error => {
               console.error('Error fetching users', error);
               this._nofication.createNotification('error', 'Error', 'Fail to send a email');
            });
      }
   }

   /**
    * create forgot password form
    */
   createForgotPasswordForm() {
      this.forgotPasswordForm = this.fb.group({
         email: new FormControl('', [<any>Validators.required, <any>Validators.pattern(this.emailRegex)]),
      });

      this.forgotPasswordForm.valueChanges.subscribe({
         next: (data) => this.onValueChanged(data),
         error: error => console.error(error)
      });
   }

   /**
    * create sign up form
    */
   createSignupForm() {
      const passwordRegEx = /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      this.signUpForm = this.fb.group(
         {
            password: new FormControl('', [
               Validators.required,
               Validators.pattern(passwordRegEx),
               Validators.maxLength(72),
            ]),
            confirmPassword: new FormControl('', [Validators.required]),
         },
         {
            validator: PasswordValidation.MatchPassword,
         } as FormControlOptions
      );

      this.signUpForm.valueChanges.subscribe({
         next: (data) => this.onValueChanged(data),
         error: error => console.error(error)
      });
   }

   /**
    * for value change
    * @param data form
    */
   onValueChanged(data?: any) {
      let form;
      if (this.type === 0) {
         // set password case
         form = this.signUpForm;
      } else {
         // forgot password case
         form = this.forgotPasswordForm;
      }
      if (!form) {
         return;
      }

      for (const field in this.formErrors) {
         if (this.formErrors.hasOwnProperty(field)) {
            this.formErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
               const messages = this.validationMessages[field];
               for (const key in control.errors) {
                  if (control.errors.hasOwnProperty(key)) {
                     this.formErrors[field] += messages[key] + ' ';
                  }
               }
            }
         }
      }
   }
}
