import {Pipe, PipeTransform} from '@angular/core';
import {windDirectionTransform} from '../helpers/wind-direction.helper';

@Pipe({
  name: 'windDirection'
})
export class WindDirectionPipe implements PipeTransform {

  transform(direction: number): string {
    return windDirectionTransform(direction);
  }

}
